import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { network } from "./network";


export const hooks = {
  useGetClients() {
    const response = useQuery(['clients'], network.getClient)

    return response;
  },
  useGetClientById(idClient: string) {
    const response = useQuery(['clients', idClient], () => network.getClientById(idClient))

    return response;
  },
  useGetVehiculeClient(idClient: string) {
    const response = useQuery(['vehiculeClients', idClient], () => network.getVehiculeClient(idClient))

    return response;
  },
  useGetClientPaginate(page: number) {
    const reponse = useQuery(['clientPages', page], () => network.getClientPaginate(page), { keepPreviousData: true });

    return reponse;
  },
  usePrefetchClient(page: number) {
    const queryClient = useQueryClient();

    return queryClient.prefetchQuery(['clientPages', page], () => network.getClientPaginate(page));
  },
  useDeleteClient() {
    const queryClient = useQueryClient();

    const reponse = useMutation(network.deleteClient, {
      onSuccess: () => {
        queryClient.invalidateQueries(['clientPages'])
      }
    });

    return reponse;
  }
}