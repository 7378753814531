import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { SendSMS, TeamReport, UpdateTeamMember } from "../../Modal";
import Details from "./Details";
import Payments from "./Payments";
import Tasks from "./Tasks";

export default function TeamDetails() {
  const [tab, setTab] = useState("");
  const [showUpdate, setShowUpdate] = useState(false);
  const [showSendSMS, setShowSendSMS] = useState(false);
  const [showReport, setShowReport] = useState(false);

  let navigate = useNavigate();
  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "This member's profile and data will be deleted permanently.",
      confirmButtonText: "Yes, delete!",
      cancelButtonText: `Cancel`,
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        ///When click Yes
      }
    });
  };
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between g-3">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">
              Team /<strong className="text-primary small"> Nghia Le</strong>
            </h3>
            <div className="nk-block-des text-soft">
              <ul className="list-inline">
                <li>
                  Team ID: <span className="text-base">AT0161</span>
                </li>
                <li>
                  Created On:{" "}
                  <span className="text-base">May 24, 2022 09:34am</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="nk-block-head-content">
            <ul className="nk-block-tools g-3">
              <li>
                <button
                  onClick={() => navigate(-1)}
                  className="btn btn-outline-light bg-white d-none d-sm-inline-flex"
                >
                  <em className="icon ni ni-arrow-left" />
                  <span>Back</span>
                </button>
                <a
                  onClick={() => navigate(-1)}
                  className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
                >
                  <em className="icon ni ni-arrow-left" />
                </a>
              </li>
              <li>
                <Dropdown>
                  <Dropdown.Toggle
                    as="a"
                    className="btn btn-dim btn-outline-primary"
                  >
                    <em className="icon ni ni-more-h" /> <span>More</span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu-right">
                    <ul className="link-list-opt no-bdr">
                      <li>
                        <a
                          className="fetch-display-click cursor"
                          onClick={() => setShowUpdate(true)}
                        >
                          <em className="icon ni ni-pen" />
                          <span>Edit Details</span>
                        </a>
                      </li>

                      <li>
                        <a
                          className="fetch-display-click cursor"
                          onClick={() => setShowSendSMS(true)}
                        >
                          <em className="icon ni ni-chat-circle" />
                          <span>Send SMS</span>
                        </a>
                      </li>

                      <li>
                        <a
                          onClick={() => setShowReport(true)}
                          className="fetch-display-click cursor"
                        >
                          <em className="icon ni ni-reports" />
                          <span>View Report</span>
                        </a>
                      </li>

                      <li className="divider" />
                      <li>
                        <a
                          className="send-to-server-click cursor"
                          onClick={handleDelete}
                        >
                          <em className="icon ni ni-trash" />
                          <span>Delete Member</span>
                        </a>
                      </li>
                    </ul>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="nk-block">
        <div className="card">
          <div className="card-aside-wrap">
            <div className="card-content">
              <ul className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
                <li className="nav-item">
                  <a
                    className={`nav-link cursor ${!tab && "active"}`}
                    onClick={() => setTab("")}
                  >
                    <em className="icon ni ni-file-text" />
                    <span>Details</span>
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className={`nav-link cursor ${tab === "tasks" && "active"}`}
                    onClick={() => setTab("tasks")}
                  >
                    <em className="icon ni ni-cards" />
                    <span>Tasks</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link cursor ${
                      tab === "payments" && "active"
                    }`}
                    onClick={() => setTab("payments")}
                  >
                    <em className="icon ni ni-align-left" />
                    <span>Payments</span>
                  </a>
                </li>
              </ul>
              {!tab && <Details />}
              {tab === "tasks" && <Tasks />}
              {tab === "payments" && <Payments />}
            </div>
          </div>
        </div>
      </div>
      <UpdateTeamMember show={showUpdate} onHide={() => setShowUpdate(false)} />

      <SendSMS show={showSendSMS} onHide={() => setShowSendSMS(false)} />
      <TeamReport show={showReport} onHide={() => setShowReport(false)} />
    </>
  );
}
