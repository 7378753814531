import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { fournisseurHooks } from "../../network/fournisseur";
import Swal from "sweetalert2";

export const UpdateSupplier = ({ show, onHide, data }: any) => {
  console.log(data, 'ito ilay donne passena')
  const [nom, setNom] = useState<string>();
  const [telephone, setTelephone] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [nipTva, setNipTva] = useState<string>();
  const [adresse, setAdresse] = useState<string>();

  const { mutate } = fournisseurHooks.useUpdateFournisseur();

  useEffect(() => {
    setNom(data.nom)
  }, [data])

  useEffect(() => {
    setTelephone(data.telephone)
  }, [data])

  useEffect(() => {
    setEmail(data.email)
  }, [data])

  useEffect(() => {
    setNipTva(data.nipTVA)
  }, [data])

  useEffect(() => {
    setAdresse(data.adresse)
  }, [data])


  const updateSupplier = () => {
    const supplier = {
      nom: nom,
      telephone: telephone,
      email: email,
      nipTVA: nipTva,
      adresse: adresse,
      id: data.idFournisseur
    }

    return mutate(supplier, {
      onSuccess: async (data) => {
        onHide()
      },
      onError: (error) => {
        console.log(error, 'ato ilay erreur ato')
        onHide()
        Swal.fire({
          icon: 'error',
          text: 'Erreur lors de l\'ajout fournisseur',
        });
      },
    })
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Mettre à jour les fournisseurs</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Mettre à jour le fournisseur.</p>
        <div className="row gy-4">
          <div className="col-sm-12 mt-0">
            <div className="form-group">
              <label className="form-label">Nom du fournisseur</label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Nom du fournisseur"
                  name="fullname"
                  required
                  value={nom}
                  onChange={e => setNom(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-6 mt-0">
            <div className="form-group">
              <label className="form-label">Numéro de téléphone</label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  className="form-control form-control-lg phone-input"
                  placeholder="Numéro de téléphone"
                  required
                  value={telephone}
                  onChange={e => setTelephone(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-6 mt-0">
            <div className="form-group">
              <label className="form-label">Adresse e-mail</label>
              <div className="form-control-wrap">
                <input
                  type="email"
                  className="form-control form-control-lg"
                  placeholder="Adresse e-mail"
                  name="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12 mt-0">
            <div className="form-group">
              <label className="form-label">Adresse</label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Adresse"
                  name="address"
                  value={adresse}
                  onChange={e => setAdresse(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12 mt-0">
            <div className="form-group">
              <label className="form-label">NIP TVA</label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="NIP TVA"
                  name="address"
                  value={nipTva}
                  onChange={e => setNipTva(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="bg-light">
        <button
          className="btn btn-white btn-dim btn-outline-light"
          type="button"
          onClick={onHide}
        >
          <em className="icon ni ni-cross-circle"></em>
          <span>Annuler</span>
        </button>
        <button className="btn btn-primary" type="submit" onClick={updateSupplier}>
          <em className="icon ni ni-check-circle-cut"></em>
          <span>Sauvegarder les modifications</span>
        </button>
      </Modal.Footer>
    </Modal>
  );
};
