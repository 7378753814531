import React from "react";
import { Modal } from "react-bootstrap";

export const UpdateInsurance = ({ show, onHide }: any) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Mettre à jour l'assurance</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Mettre à jour la compagnie d'assurance.</p>
        <div className="row gy-4">
          <div className="col-sm-12 mt-0">
            <div className="form-group">
              <label className="form-label">Nom de l'entreprise</label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Nom de l'entreprise                  "
                  name="fullname"
                  required
                />
              </div>
            </div>
          </div>
          <div className="col-sm-6 mt-0">
            <div className="form-group">
              <label className="form-label">Numéro de téléphone</label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  className="form-control form-control-lg phone-input"
                  placeholder="Numéro de téléphone"
                  required
                />
              </div>
            </div>
          </div>
          <div className="col-sm-6 mt-0">
            <div className="form-group">
              <label className="form-label">Adresse e-mail</label>
              <div className="form-control-wrap">
                <input
                  type="email"
                  className="form-control form-control-lg"
                  placeholder="Email Address"
                  name="email"
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12 mt-0">
            <div className="form-group">
              <label className="form-label">Adresse</label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Adresse"
                  name="address"
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="bg-light">
        <button
          className="btn btn-white btn-dim btn-outline-light"
          type="button"
          onClick={onHide}
        >
          <em className="icon ni ni-cross-circle"></em>
          <span>Annuler</span>
        </button>
        <button className="btn btn-primary" type="submit">
          <em className="icon ni ni-check-circle-cut"></em>
          <span>Sauvegarder les modifications</span>
        </button>
      </Modal.Footer>
    </Modal>
  );
};
