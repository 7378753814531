import React from "react";
import { Modal } from "react-bootstrap";
import Loading from "../../components/Loading";
import { projetHooks } from "../../network";
import { factureHooks } from "../../network";
import Swal from 'sweetalert2'

type inputForm = {
  quantite: number, coutUnite: number, impot: number, total: number, description: string
}

interface Projet {
  idProjet: number,
  vehicules: {
    clients: {
      nomPrenom: string
    },
    detailVehicules: {
      marque: string
    }
  }
}

export const CreateInvoices = ({ show, onHide }: any) => {
  const { data: dataProjet, isLoading: loadingProjet } = projetHooks.useGeTAllProject();
  const [idProjet, setIdProjet] = React.useState<number>(0);
  const [dateFacturation, setDateFacturation] = React.useState<string>('');
  const [datePaiement, setDatePaiement] = React.useState<string>('');
  const [remarque, setRemarque] = React.useState<string>('');
  const [sousTotal, setSousTotal] = React.useState<number>(0);
  const [impot, setImpot] = React.useState<number>(0);
  const [total, setTotal] = React.useState<number>(0);
  const [classDescription, setClassDescription] = React.useState<string>('form-control form-control-lg');
  const [errorDescription, setErrorDescription] = React.useState<boolean>(false);
  const [classDateFacture, setClassDateFacture] = React.useState<string>('form-control form-control-lg');
  const [errorDateFacturation, setErrorDateFacturation] = React.useState<boolean>(false);
  const [classDatePaiement, setClassDatePaiement] = React.useState<string>('form-control form-control-lg');
  const [errorDatePaiement, setErrorDatePaiement] = React.useState<boolean>(false);
  const [classRemarque, setClassRemarque] = React.useState<string>('form-control form-control-lg unset-mh');
  const [errorRemarque, setErrorRemarque] = React.useState<boolean>(false);
  const [infosFacture, setInfosFacture] = React.useState<inputForm[]>(
    [
      {
        'quantite': 0,
        'coutUnite': 0,
        'impot': 0,
        'total': 0,
        'description': '',
      }
    ]
  );
  const { mutate } = factureHooks.useAddFacture();

  const handleFormChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const data = [...infosFacture];
    if (event.target.name !== 'description') {
      data[index][event.target.name] = Number(event.target.value)
    }
    data[index][event.target.name] = event.target.value
    data[index]['total'] = data[index]['quantite'] * data[index]['coutUnite'];

    setInfosFacture(data);

    const somme = infosFacture.reduce((acumulator, object) => {
      return acumulator + object.total
    }, 0);

    const impotTotal = infosFacture.reduce((acumulator, object) => {
      return acumulator + (object.impot / 100 * object.total);
    }, 0);

    setSousTotal(somme);
    setImpot(impotTotal);
    setTotal(somme + impotTotal);
  }

  const handleAddForm = () => {
    let newFomrs = {
      quantite: 0,
      coutUnite: 0,
      impot: 0,
      total: 0,
      description: ''
    }

    setInfosFacture([...infosFacture, newFomrs]);
  }

  const handleRemoveForm = (index) => {
    const values = [...infosFacture];
    values.splice(index, 1);

    setInfosFacture(values);

    const somme = infosFacture.reduce((acumulator, object) => {
      return acumulator + object.total
    }, 0);

    const impotTotal = infosFacture.reduce((acumulator, object) => {
      return acumulator + (object.impot / 100 * object.total);
    }, 0);

    setSousTotal(somme);
    setImpot(impotTotal);
    setTotal(somme + impotTotal)
  }

  const handleSelectProjet = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    setIdProjet(Number(e.target.value));
  }

  /* const checkDescription = (event) => {
    if (event.target.value.trim().length === 0) {
      setErrorDescription(true)
      setClassDescription('form-control form-control-lg is-invalid')
      return true
    } else {
      setErrorDescription(false)
      setClassDescription('form-control form-control-lg')
      return false
    }
  } */

  const checkDateFacturation = () => {
    if (dateFacturation.trim().length === 0) {
      setErrorDateFacturation(true)
      setClassDateFacture('form-control form-control-lg is-invalid')
      return true
    } else {
      setErrorDateFacturation(false)
      setClassDateFacture('form-control form-control-lg')
      return false
    }
  }

  const checkDatePaiement = () => {
    if (datePaiement.trim().length === 0) {
      setErrorDatePaiement(true)
      setClassDatePaiement('form-control form-control-lg is-invalid')
      return true
    } else {
      setErrorDatePaiement(false)
      setClassDatePaiement('form-control form-control-lg')
      return false
    }
  }

  const checkRemarque = () => {
    if (remarque.trim().length === 0) {
      setErrorRemarque(true)
      setClassRemarque('form-control form-control-lg is-invalid unset-mh')
      return true
    } else {
      setErrorRemarque(false)
      setClassRemarque('form-control form-control-lg')
      return false
    }
  }

  const ajoutFacture = () => {
    const facture = {
      factures: {
        projets: idProjet,
        remarques: remarque,
        total: total,
        sousTotal: sousTotal,
        impot: impot,
        dateFacturation: dateFacturation,
        datePaiement: datePaiement,
        modePayement: "Cheque",
      },
      infosFacture: infosFacture
    }

    console.log(facture, 'ito ilay facture')

    return mutate(facture, {
      onSuccess: async (data) => {
        onHide()
      },
      onError: (error) => {
        onHide()
        Swal.fire({
          icon: 'error',
          text: 'Erreur lors de l\'ajout facture',
        });
      },
    })
  }

  return (
    <Modal show={show} onHide={onHide} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Créer une facture</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Créer une facture</p>
        <div className="item-totals border-bottom">
          <div className="row gy-4">
            <div className="col-12">
              <div className="form-group mb-2">
                <label className="form-label">Sélectionnez un projet</label>
                {loadingProjet ? <Loading /> :
                  <div className="form-control-wrap">
                    <select
                      className="form-control form-control-lg grouped"
                      name="project"
                      required
                      onChange={handleSelectProjet}
                    >
                      <option value="">Sélectionnez un projet</option>

                      <optgroup label="Projet">
                        {dataProjet?.data && dataProjet?.data.map((item: Projet, index: number) => (
                          <option key={index} value={item.idProjet}>{item.idProjet} - {item?.vehicules?.detailVehicules?.marque} ({item?.vehicules?.clients?.nomPrenom})</option>))}
                      </optgroup>
                    </select>
                  </div>}
              </div>
            </div>
          </div>
        </div>
        <div className="item-lines mt-2" data-type="quote">
          {infosFacture.map((input, index) => (
            <div className="row gy-4" key={index}>
              <div className="col-sm-4">
                <div className="form-group">
                  <label className="form-label">Libellé</label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      placeholder="Libellé"
                      name="description"
                      required
                      onChange={event => handleFormChange(index, event)}
                    />
                  </div>
                  {errorDescription && <div className="text-danger">Valeur obligatoire</div>}
                </div>
              </div>
              <div className="col-sm-1">
                <div className="form-group">
                  <label className="form-label">Qty</label>
                  <div className="form-control-wrap hide-arrows">
                    <input
                      type="number"
                      className="form-control form-control-lg line-quantity"
                      min="1"
                      placeholder="Quantity"
                      name="quantite"
                      required
                      value={input.quantite}
                      onChange={event => handleFormChange(index, event)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="form-group">
                  <label className="form-label">Coût unitaire ( € )</label>
                  <div className="form-control-wrap hide-arrows">
                    <input
                      type="number"
                      className="form-control form-control-lg line-cost"
                      placeholder="Unit Cost"
                      data-parsley-pattern="[0-9]*(\.?[0-9]{2}$)"
                      name="coutUnite"
                      step="0.01"
                      required
                      value={input.coutUnite}
                      onChange={event => handleFormChange(index, event)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="form-group">
                  <label className="form-label">Impôt (%)</label>
                  <div className="form-control-wrap hide-arrows">
                    <input
                      type="number"
                      className="form-control form-control-lg line-tax"
                      placeholder="Impôt (%)"
                      min="0"
                      name="impot"
                      value={input.impot}
                      onChange={event => handleFormChange(index, event)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="form-group">
                  <label className="form-label">Totale ( € )</label>
                  <div className="form-control-wrap">
                    <input
                      type="number"
                      className="form-control form-control-lg line-total"
                      placeholder="Total"
                      data-parsley-pattern="[0-9]*(\.?[0-9]{2}$)"
                      name="total"
                      step="0.01"
                      required
                      value={input.total}
                      readOnly
                      onChange={event => handleFormChange(index, event)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-1">
                <div className="form-group">
                  <div className="form-control-wrap">
                    <a
                      className="btn btn-icon btn-lg btn-round btn-dim btn-outline-danger mt-gs remove-line"
                      data-toggle="tooltip"
                      title="Retirer l'objet"
                      onClick={() => handleRemoveForm(index)}
                    >
                      <em className="icon ni ni-cross-circle-fill"></em>
                    </a>
                  </div>
                </div>
              </div>
            </div>))}
        </div>
        <div className="item-totals border-top mt-2 pt-2">
          <div className="row gy-4 d-flex justify-content-end">
            <div className="col-sm-7">
              <a
                className="btn btn-dim btn-outline-primary mt-2 add-item"
                data-type="invoice"
                onClick={() => handleAddForm()}
              >
                <em className="icon ni ni-plus"></em>
                <span>Ajouter un item</span>{" "}
              </a>
            </div>
            <div className="col-sm-4 text-right">
              <div className="fw-normal">
                Sous-total:<div className="fw-bold sub-total">{sousTotal} €</div>
              </div>
              <div className="fw-normal">
                Impôt:<div className="fw-bold tax-total">{impot} €</div>
              </div>
              <div className="fw-bold fs-19px border-top">
                Totale:<div className="fw-bold grand-total">{total} €</div>
              </div>
            </div>
            <div className="col-sm-1"></div>
          </div>
        </div>
        <div className="mt-2">
          <div className="row gy-4 border-top mt-1">
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label">Date de facturation</label>
                <div className="form-control-wrap">
                  <input
                    type="date"
                    className={classDateFacture}
                    placeholder="Date de facturation"
                    name="invoice_date"
                    required
                    onChange={e => setDateFacturation(e.target.value)}
                    onBlur={checkDateFacturation}
                  />
                </div>
                {errorDateFacturation && <div className="text-danger">Valeur obligatoire</div>}
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label">Paiement dû</label>
                <div className="form-control-wrap">
                  <input
                    type="date"
                    className={classDatePaiement}
                    placeholder="Paiement dû"
                    name="due_date"
                    required
                    onChange={e => setDatePaiement(e.target.value)}
                    onBlur={checkDatePaiement}
                  />
                </div>
                {errorDatePaiement && <div className="text-danger">Valeur obligatoire</div>}
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label">Remarques</label>
                <div className="form-control-wrap">
                  <textarea
                    className={classRemarque}
                    placeholder="Remarques"
                    rows={2}
                    name="notes"
                    onChange={e => setRemarque(e.target.value)}
                    onBlur={checkRemarque}
                  ></textarea>
                </div>
                {errorRemarque && <div className="text-danger">Valeur obligatoire</div>}
                <div className="form-note">
                  Les notes seront imprimées sur la facture.
                </div>
              </div>
            </div>
            {/* <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label">Détails de paiement</label>
                <div className="form-control-wrap">
                  <textarea
                    className="form-control form-control-lg unset-mh"
                    placeholder="Détails de paiement"
                    rows={2}
                    name="payment_details"
                  ></textarea>
                </div>
                <div className="form-note">
                  Les détails du paiement seront imprimés sur la facture.
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="bg-light">
        <button
          className="btn btn-white btn-dim btn-outline-light"
          type="button"
          onClick={onHide}
        >
          <em className="icon ni ni-cross-circle"></em>
          <span>Annuler</span>
        </button>
        <button className="btn btn-primary" type="submit" onClick={ajoutFacture}>
          <em className="icon ni ni-check-circle-cut"></em>
          <span>Créer un devis</span>
        </button>
      </Modal.Footer>
    </Modal>
  );
};
