import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { membreHooks } from '../../network';
import Loading from '../../components/Loading';
import { tacheHooks } from '../../network';
import { useParams } from 'react-router-dom';
import { pieceHooks } from "../../network/piece";
import Swal from "sweetalert2";
import { taskGroupHooks } from "../../network/task-group";


interface CreateTaskProps {
  show: boolean;
  onHide: () => void;
}
type taskGroupType = {
  idTaskGroup: number,
  taskGroupLabel: string,
  ordreTaskGroup: number,
  description: string,
  tasks: any[]
}
type memberType = {
  idMembre: number,
  email: string,
  prenom: string,
  nom: string,
}

type workStepType = {
  status: string,
  estimate: string,
  assignTo?: memberType,
  taskGroup: taskGroupType
}


export function CreateTask({ show, onHide }: CreateTaskProps) {
  const { data: dataMembre, isLoading: loadingMembre } = membreHooks.useGetMembres();
  const [titre, setTitre] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [cout, setCout] = useState<number>(0);
  const [dateEcheance, setDateEcheance] = useState<string>('');
  const [heureEcheance, setHeureEcheance] = useState<string>('');
  const [membre, setMembre] = useState<number>(0);
  const [errorTitre, setErrorTitre] = useState<boolean>(false);
  const [errorDescri, setErrorDescri] = useState<boolean>(false);
  const [errorCout, setErrorCout] = useState<boolean>(false);
  const [errorDate, setErrorDate] = useState<boolean>(false);
  const [errorHeure, setErrorHeure] = useState<boolean>(false);
  const { data: dataPiece, isLoading: loadingPiece } = pieceHooks.useGetPiece();
  const [pieces, setPieces] = useState<number[]>([]);
  const [classTitle, setClassTitle] = useState<string>('form-control form-control-lg');
  const [classDescription, setClassDescription] = useState<string>('form-control form-control-lg');
  const [classDate, setClassDate] = useState<string>('form-control form-control-lg');
  const [classCout, setClassCout] = useState<string>('form-control form-control-lg');
  const [classHeure, setClassHeure] = useState<string>('form-control form-control-lg');
  const [tabTaskGroup, setTabTaskGroup] = useState<any[]>([]);
  const allTaskGroups = taskGroupHooks.useGetAllTaskGroups();
  const [works, setWorks] = useState<workStepType[]>([]);
  const [selectedTaskGroup, setSelectedTaskGroup] = useState<taskGroupType>({
    idTaskGroup: 0,
    taskGroupLabel: '',
    ordreTaskGroup: 0,
    description: '',
    tasks: []
  });

  React.useEffect(() => {
    if(allTaskGroups.data?.data) {
      setTabTaskGroup(allTaskGroups.data.data);
    }
  }, [allTaskGroups])

  const params = useParams()
  let id = params.id || ""

  const { mutate } = tacheHooks.useCreateTask();

  const handleChangeStatut = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    setStatus(e.target.value)
  }

  const handleChangeMembre = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    setMembre(Number(e.target.value))
  }

  const handleGetPiece = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    /* let array = [Number(e.target.value)];
    setPieces(array); */

    setPieces([Number(e.target.value)])
  }

  const checkEmptyTitle = () => {
    if (titre.trim().length === 0) {
      setErrorTitre(true)
      setClassTitle('form-control form-control-lg is-invalid')
      return true
    } else {
      setErrorTitre(false)
      setClassTitle('form-control form-control-lg')
      return false
    }
  }

  const checkEmptyDescri = () => {
    if (description.trim().length === 0) {
      setErrorDescri(true)
      setClassDescription('form-control form-control-lg is-invalid')
      return true
    } else {
      setErrorDescri(false)
      setClassDescription('form-control form-control-lg')
      return false
    }
  }

  const checkEmptyCout = () => {
    if (cout === 0) {
      setErrorCout(true)
      setClassCout('form-control form-control-lg is-invalid')
      return true
    } else {
      setErrorCout(false)
      setClassCout('form-control form-control-lg')
      return false
    }
  }

  const checkEmptyDate = () => {
    if (dateEcheance.trim().length === 0) {
      setErrorDate(true);
      setClassDate('form-control form-control-lg is-invalid')
      return true
    } else {
      setErrorDate(false)
      setClassDate('form-control form-control-lg')
      return false
    }
  }

  const checkEmptyHeure = () => {
    if (heureEcheance.trim().length === 0) {
      setErrorHeure(true);
      setClassHeure('form-control form-control-lg is-invalid')
      return true
    } else {
      setErrorHeure(false)
      setClassHeure('form-control form-control-lg')
      return false
    }
  }

  const createTask = () => {

    const task = {
      tache: {
        titreTache: titre,
        status: status,
        description: description,
        dateEcheance: dateEcheance,
        heureEcheance: heureEcheance,
        projets: Number(id),
        membres: membre,
        coutTache: cout,
      },
      pieces: pieces
    }


    if (checkEmptyCout() || checkEmptyDescri() || checkEmptyTitle()) {
      checkEmptyCout()
      checkEmptyDescri()
      checkEmptyTitle()
      Swal.fire({
        icon: 'error',
        text: 'Veuillez remplir tous les champs',
      });
      return;
    }

    return mutate(task, {
      onSuccess: async (data) => {
        onHide()
      },
      onError: (error) => {
        onHide()
        Swal.fire({
          icon: 'error',
          text: 'Erreur lors de l\'ajout nouvele tache',
        });
      },
      onSettled: async (data) => {
        console.log('tonga ato oooo')
      },
    })
  }

  const addWork = () => {
    setTabTaskGroup(tabTaskGroup.filter(t => t.idTaskGroup !== selectedTaskGroup.idTaskGroup));
    const newWork: workStepType = {
      status: 'aFaire',
      estimate: '',
      assignTo: undefined,
      taskGroup: selectedTaskGroup
    }

    if(!works.find(work => work.taskGroup.idTaskGroup === selectedTaskGroup.idTaskGroup)) {
      setWorks([...works, ...[newWork]]);
    }
  }

  const removeWork = (idTaskGroup) => {
    const updatedWork = works.filter(work => work.taskGroup.idTaskGroup !== idTaskGroup);
    setWorks(updatedWork);
  }

  return (
    <Modal show={show} onHide={onHide} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Création de travaux</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row gy-4">
          <div className="col-sm-8">
            <div className="form-group">
              <label className="form-label">Ajouter un groupe de tâche à faire</label>
              <div className="form-control-wrap ">
                <div className="form-control-select">
                  <select
                    className="form-control select2"
                    name="taskGroup"
                    required
                    onChange={(e) => setSelectedTaskGroup(tabTaskGroup.find(t => t.idTaskGroup === Number(e.target.value)))}
                  >
                    <option></option>
                    {tabTaskGroup.map((tg: taskGroupType, index) => (
                      <option key={index} value={tg.idTaskGroup}>{tg.taskGroupLabel}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-2 align-self-end">
            <button
              className="btn btn-primary"
              type="button"
              onClick={addWork}
            >
              Ajouter
            </button>
          </div>
          {
            works && works.map((w, i) => (
              <div key={`work-step-${i}`}>
                <div className="nk-divider divider"></div>
                <div className="col-sm-12">
                  <h5>
                    {w.taskGroup.taskGroupLabel}
                    <button className="btn btn-small btn-danger float-right" onClick={() => removeWork(w.taskGroup.idTaskGroup)}>
                      <em className="icon ni ni-cross-circle"></em>
                    </button>
                  </h5>
                  <p>{w.taskGroup.description}</p>
                  <div className="row mt-5">
                    <div className="col-md-8">
                    <ol className="list-group list-group-numbered">
                      {
                        w.taskGroup.tasks.map((task, index) => (
                          <li key={`task-${index}`} className="list-group-item d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                              <div className="fw-bold">{task.taskTitle}</div>
                            </div>
                          </li>
                        ))
                      }
                    </ol>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">Assigner à</label>
                        <div className="form-control-wrap ">
                          {loadingMembre ? <Loading /> :
                            <div className="form-control-select">
                              <select
                                className="form-control select2"
                                name="member"
                                required
                                onChange={handleChangeMembre}
                              >
                                <option></option>
                                {dataMembre?.data.map((item, key) => (
                                  <option key={key} value={item.idMembre}>{item.prenom} {item.nom}</option>
                                ))}
                              </select>
                            </div>}
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="form-label">Statut</label>
                        <div className="form-control-wrap ">
                          <div className="form-control-select">
                            <select
                              className="form-control select2"
                              name="statut"
                              required
                              onChange={handleChangeStatut}
                            >
                              <option value=""></option>
                              <option value="aFaire">A faire</option>
                              <option value="enCours">En cours</option>
                              <option value="complete">Complete</option>
                              <option value="annuler">Annuler</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="form-label">Estimation</label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className={classCout}
                            placeholder="Estimation du travaux"
                            name="cost"
                            required
                            onChange={e=>setCout(Number(e.target.value))}
                          />
                        </div>
                        {errorCout && <div className="text-danger">Valeur obligatoire</div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          }
          {/* <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">Titre de la tâche</label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  className={classTitle}
                  placeholder="Titre de la tâche"
                  name="title"
                  required
                  onChange={e => setTitre(e.target.value)}
                  onBlur={checkEmptyTitle}
                />
              </div>
              {errorTitre && <div className="text-danger">Valeur obligatoire</div>}
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label className="form-label">Assigner à</label>
              <div className="form-control-wrap ">
                {loadingMembre ? <Loading /> :
                  <div className="form-control-select">
                    <select
                      className="form-control select2"
                      name="member"
                      required
                      onChange={handleChangeMembre}
                    >
                      <option></option>
                      {dataMembre.data.map((item: { nom: string, idMembre: number, prenom: string }) => (
                        <option value={item.idMembre}>{item.prenom} {item.nom}</option>
                      ))}
                    </select>
                  </div>}
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label className="form-label">Status</label>
              <div className="form-control-wrap ">
                <div className="form-control-select">
                  <select
                    className="form-control form-control-lg"
                    name="status"
                    onChange={handleChangeStatut}
                  >
                    <option value=""></option>
                    <option value="complete">Complete</option>
                    <option value="enCours">En cours</option>
                    <option value="annuler">Annuler</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">Description de la tâche</label>
              <div className="form-control-wrap">
                <textarea
                  className={classDescription}
                  placeholder="Description de la tâche"
                  rows={5}
                  name="description"
                  defaultValue={""}
                  onChange={e => setDescription(e.target.value)}
                  onBlur={checkEmptyDescri}
                />
              </div>
              {errorDescri && <div className="text-danger">Valeur obligatoire</div>}
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">Coût de la tâche</label>
              <div className="form-control-wrap">
                <div className="form-text-hint">
                  <span className="overline-title">$</span>
                </div>
                <input
                  type="number"
                  className={classCout}
                  placeholder="Coût de la tâche"
                  name="cost"
                  defaultValue={0.0}
                  step="0.01"
                  required
                  onChange={e => setCout(Number(e.target.value))}
                  onBlur={checkEmptyCout}
                />
              </div>
              {errorCout && <div className="text-danger">Valeur obligatoire</div>}
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label className="form-label">Dâte de livraison</label>
              <div className="form-control-wrap">
                <input
                  type="date"
                  className={classDate}
                  placeholder="Dâte de livraison"
                  name="due_date"
                  required
                  onChange={e => setDateEcheance(e.target.value)}
                  onBlur={checkEmptyDate}
                />
              </div>
              {errorDate && <div className="text-danger">Valeur obligatoire</div>}
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label className="form-label">Heure de livraison</label>
              <div className="form-control-wrap">
                <input
                  type="time"
                  className="form-control form-control-lg"
                  placeholder="Heure de livraison"
                  name="due_time"
                  required
                  onChange={e => setHeureEcheance(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="nk-divider divider mt-0 mb-0" />
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">Pièces nécessaires à la tâche</label>
              <div className="form-control-wrap ">
                {loadingPiece ? <Loading /> : <div className="form-control-select">
                  <select
                    className="form-control select2"
                    name="parts_required[]"
                    onChange={handleGetPiece}
                  >
                    <option value=""></option>
                    {dataPiece.data.map((item: { idPiece: number, nomPiece: string }) => (
                      <option value={item.idPiece}>{item.nomPiece}</option>
                    ))}
                  </select>

                </div>}
              </div>
            </div>
          </div> */}
        </div>
      </Modal.Body>
      <Modal.Footer className=" bg-light">
        <button
          className="btn btn-white btn-dim btn-outline-light"
          type="button"
          onClick={onHide}
        >
          <em className="icon ni ni-cross-circle"></em>
          <span>Annuler</span>
        </button>
        <button className="btn btn-primary" type="submit" onClick={createTask}>
          <em className="icon ni ni-check-circle-cut"></em>
          <span>Créer</span>
        </button>
      </Modal.Footer>
    </Modal>
  );
}
