import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import Swal from "sweetalert2";
import TableauPaiementClient from "../../components/Tableau/TableauPaiementClient";
import { paiementHooks } from "../../network";
import { useParams } from "react-router-dom";
import Loading from "../../components/Loading";

export default function Payments() {

  const param = useParams();
  let id = param.id || '';

  /* const { data, isLoading } = paiementHooks.useGetPaiementClient(id); */
  const [donne, setDonne] = useState<{ page: number, idClient: number }>({
    page: 1,
    idClient: Number(id)
  })
  const { data, isLoading } = paiementHooks.useGetPayementClientPaginate(donne)

  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: `This payment will be deleted permanently.`,
      confirmButtonText: "Yes, delete!",
      cancelButtonText: `Cancel`,
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: "#fff",
    }).then((result) => {
      if (result.isConfirmed) {
        ///When click Yes
      }
    });
  };

  const nextPage = () => {

    if (donne.page < data.data.countPage) {
      setDonne({ ...donne, page: donne.page + 1 });
    } else {
      setDonne({ ...donne, page: donne.page });
    }
  }

  const prevPage = () => {
    if (donne.page > 1) {
      setDonne({ ...donne, page: donne.page - 1 });
    } else {
      setDonne({ ...donne, page: donne.page });
    }
  }

  const goToPage = (numPage: number) => {
    setDonne({ ...donne, page: numPage });
  }

  React.useEffect(() => {
    if (data?.hasMore) {
      paiementHooks.usePrefetchPaiementClient({ page: donne.page, idClient: Number(id) })
    }
  }, [data, donne.page, id])
  return (
    <>
      <div className="card-inner">
        <div className="nk-block mb-2">
          <div className="nk-block-head">
            {/* <a
              className="btn btn-primary pull-right"
              onClick={() => setShowAdd(true)}
            >
              <em className="icon ni ni-plus" />
              <span>Add Payment</span>
            </a> */}
            <h5 className="title overline-title text-base">Paiements du client</h5>
            <p>Liste de tous les paiements du client</p>
          </div>
        </div>
        {isLoading ? <Loading /> : <TableauPaiementClient data={data.data} nextPage={nextPage} prevPage={prevPage} goToPage={goToPage} />}
      </div>
    </>
  );
}
