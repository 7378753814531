import React from "react";
import Swal from "sweetalert2";

export default function Receiveables() {
  const handleConfirm = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "This item will be added to inventory.",
      confirmButtonText: "Yes, delete!",
      cancelButtonText: `Cancel`,
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        ///When click Yes
      }
    });
  };
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">Créances</h3>
            <div className="nk-block-des text-soft">
              <p>Confirmer la réception des pièces dans l'inventaire.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="nk-block">
        <div className="card card-stretch">
          <div className="card-inner">
            <table
              className="datatable-init nk-tb-list nk-tb-ulist"
              data-auto-responsive="false"
            >
              <thead>
                <tr className="nk-tb-item nk-tb-head">
                  <th className="nk-tb-col text-center">#</th>
                  <th className="nk-tb-col tb-col-md">
                    <span className="sub-text">Le fournisseur</span>
                  </th>
                  <th className="nk-tb-col">
                    <span className="sub-text">Véhicule</span>
                  </th>
                  <th className="nk-tb-col">
                    <span className="sub-text">Article</span>
                  </th>
                  <th className="nk-tb-col tb-col-md">
                    <span className="sub-text">Quantité</span>
                  </th>
                  <th className="nk-tb-col tb-col-md">
                    <span className="sub-text">Coût unitaire</span>
                  </th>
                  <th className="nk-tb-col nk-tb-col-tools text-right">
                    Confirmer
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="nk-tb-item">
                  <td className="nk-tb-col text-center">1</td>
                  <td className="nk-tb-col tb-col-md">
                    <span className="tb-lead">Moshi SPares</span>

                    {/* <span>--|--</span> */}
                  </td>
                  <td className="nk-tb-col">
                    <span className="tb-lead">TATA • 23424</span>
                  </td>
                  <td className="nk-tb-col">
                    <span className="tb-lead">abc</span>
                  </td>
                  <td className="nk-tb-col tb-col-mb">
                    <span className="tb-lead">1.00</span>
                  </td>
                  <td
                    className="nk-tb-col tb-col-mb"
                    data-order="{{ $item->unit_cost }}"
                  >
                    <span className="tb-amount">$0.00 </span>
                  </td>
                  <td className="nk-tb-col text-right">
                    <a
                      className="btn btn-primary btn-sm send-to-server-click cursor"
                      onClick={handleConfirm}
                    >
                      <em className="icon ni ni-check"></em>
                      <span>Confirmer la réception</span>{" "}
                    </a>
                  </td>
                </tr>
                {/* <tr>
                                                        <td className="text-center" colSpan={7}>C'est vide ici !</td>
                                                    </tr> */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
