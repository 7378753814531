import React, { memo } from "react";
interface SvgFuel {
  rotate: Number;
}

function SvgFuel({ rotate }: SvgFuel) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="400"
      version="1"
      viewBox="0 0 990 765"
    >
      <g transform="translate(-223.9 518.24)">
        <path
          fill="#000"
          d="M740.87-180.08c-5.075 0-9.214 3.434-9.214 7.622v80.2l44.935.626.056-46.87h8.475c1.948 0 3.527 1.29 3.527 2.901v35.322c0 4.737 5.383 8.874 11.49 8.874 5.768 0 12.172-3.784 12.172-8.874-.088-1.184-4.664-22.24-4.664-22.24 0-.048-.967-5.915-.967-5.915v-26.904c0-2.881-1.166-5.258-3.527-7.224l-15.016-12.4c-1.032 0-3.924 2.39-3.924 2.39 0 .866 7.45 6.995 7.45 6.995l-.227 10.466c0 3.7 3.635 6.712 8.134 6.712h2.048l-.74 19.965 1.024 6.883 4.55 20.192c.178 3.284-3.451 5.233-6.313 5.233-2.64 0-5.745-1.821-5.745-4.153l.057-35.379c0-4.31-4.25-7.792-9.442-7.792h-9.044c.634-.037.626-23.235.626-28.155v-.853c0-4.188-4.098-7.622-9.158-7.622H740.87zm0 5.517h26.563c1.416 0 2.503.945 2.503 2.105v18.486c0 1.172-1.087 2.048-2.503 2.048H740.87c-1.431 0-2.503-.876-2.503-2.048v-18.486c0-1.16 1.072-2.105 2.503-2.105zm55.401 14.846s2.256.39 2.787.512c1.092.232 1.79 1.59 1.82 2.787.03 1.21 0 4.835 0 4.835-3.348-.61-4.607-2.037-4.607-3.356v-4.778z"
        ></path>
        <path
          fill="none"
          d="M-219.85 533.12a201.71 201.71 0 11.377 403.42l-.078-201.71z"
          transform="translate(1038.7 -1473.3) scale(1.8202)"
        ></path>
        <path
          fill="#fff"
          stroke="#000"
          strokeWidth="0.086"
          d="M653.03-135.743a13.922 13.922 0 11-27.844 0 13.922 13.922 0 1127.843 0z"
        ></path>
        <rect
          width="77.258"
          height="18.648"
          x="919.92"
          y="-145.07"
          fill="#000"
          rx="4.677"
          ry="14.616"
        ></rect>
        <rect
          width="59.979"
          height="9.932"
          x="803.61"
          y="398.18"
          fill="red"
          rx="3.631"
          ry="7.784"
          transform="rotate(-50.091)"
        ></rect>
        <rect
          width="77.258"
          height="18.648"
          x="719.24"
          y="475.1"
          fill="#000"
          rx="4.677"
          ry="14.616"
          transform="rotate(-59.844)"
        ></rect>
        <rect
          width="77.258"
          height="18.648"
          x="484.46"
          y="-630.14"
          fill="#000"
          rx="4.677"
          ry="14.616"
          transform="rotate(59.847)"
        ></rect>
        <rect
          width="77.258"
          height="18.648"
          x="902.07"
          y="192.97"
          fill="#000"
          rx="4.677"
          ry="14.616"
          transform="rotate(-30.027)"
        ></rect>
        <rect
          width="77.258"
          height="18.648"
          x="765.71"
          y="-447.23"
          fill="#000"
          rx="4.677"
          ry="14.616"
          transform="rotate(30.093)"
        ></rect>
        <rect
          width="59.979"
          height="9.932"
          x="866.79"
          y="300.91"
          fill="red"
          rx="3.631"
          ry="7.784"
          transform="rotate(-39.905)"
        ></rect>
        <rect
          width="59.979"
          height="9.932"
          x="936.18"
          y="87.897"
          fill="red"
          rx="3.631"
          ry="7.784"
          transform="rotate(-20.162)"
        ></rect>
        <rect
          width="59.979"
          height="9.932"
          x="942.41"
          y="-27.842"
          fill="red"
          rx="3.631"
          ry="7.784"
          transform="rotate(-9.984)"
        ></rect>
        <rect
          width="59.979"
          height="9.932"
          x="843.47"
          y="-351.3"
          fill="red"
          rx="3.631"
          ry="7.784"
          transform="rotate(20.02)"
        ></rect>
        <rect
          width="59.979"
          height="9.932"
          x="894.87"
          y="-250.63"
          fill="red"
          rx="3.631"
          ry="7.784"
          transform="rotate(10.096)"
        ></rect>
        <rect
          width="59.979"
          height="9.932"
          x="691.02"
          y="-520.35"
          fill="red"
          rx="3.631"
          ry="7.784"
          transform="rotate(40.084)"
        ></rect>
        <rect
          width="59.979"
          height="9.932"
          x="594.07"
          y="-582.97"
          fill="red"
          rx="3.631"
          ry="7.784"
          transform="rotate(50.218)"
        ></rect>
        <g fill="#000">
          <path
            d="M746.75-430.11c.119-1.496.216-3.069.292-4.72l.241-5.088c.085-1.724.14-3.121.166-4.192-.06.192-.205.365-.436.517-.232.153-.87.502-1.916 1.048.043-.728.079-1.418.108-2.068.03-.651.044-1.202.044-1.653.371-.212.802-.466 1.292-.764.49-.297.78-.476.87-.537.09-.06.272-.2.547-.418.49.017 1.132.025 1.926.025.788 0 1.54-.008 2.255-.025l-.143 2.013c-.035.411-.074.981-.115 1.708l-.51 10.133-.037 1.481c-.008.311-.011.627-.01.946l.013 1.594a98.98 98.98 0 00-2.166-.025c-.785 0-1.592.008-2.421.025z"
            baselineShift="super"
          ></path>
          <path d="M749.77-413.77l6.452-11.84 6.733-12.046 1.926-3.614c.717.026 1.304.039 1.76.039.473 0 1.14-.013 2.004-.039l-2.991 5.675-10.386 18.697-1.644 3.128a50.524 50.524 0 00-1.596-.04c-.473 0-1.226.014-2.258.04z"></path>
          <path
            d="M767.38-405.6c.119-1.496.216-3.069.292-4.72l.241-5.088c.085-1.724.14-3.121.166-4.192-.06.192-.205.365-.436.517-.232.153-.87.502-1.916 1.048.043-.728.079-1.418.108-2.068.03-.651.044-1.202.044-1.653a56.98 56.98 0 001.292-.764c.49-.297.78-.476.87-.537.09-.06.272-.2.547-.418.49.017 1.132.025 1.926.025.788 0 1.54-.008 2.255-.025l-.143 2.013c-.035.411-.074.981-.115 1.708L772-409.62l-.037 1.481c-.008.311-.01.627-.01.946l.013 1.594a98.98 98.98 0 00-2.166-.025c-.785 0-1.592.008-2.421.025z"
            baselineShift="sub"
          ></path>
        </g>
        <g fill="#000">
          <path
            d="M889.23-138.82c.097-1.214.175-2.49.237-3.83l.196-4.128c.069-1.399.113-2.532.134-3.4-.048.155-.166.295-.354.419-.188.124-.706.407-1.555.85.035-.591.065-1.15.088-1.679.024-.528.036-.975.036-1.34.301-.172.65-.379 1.049-.62.397-.241.633-.387.706-.436.072-.05.22-.162.443-.339.398.014.919.02 1.562.02.64 0 1.25-.007 1.83-.02l-.116 1.633c-.028.334-.06.796-.093 1.386l-.413 8.221-.03 1.202c-.007.252-.01.508-.01.767l.012 1.294a80.512 80.512 0 00-3.722 0z"
            baselineShift="super"
          ></path>
          <path d="M891.68-125.56l5.235-9.607 5.463-9.773 1.562-2.932c.582.021 1.059.032 1.429.032.383 0 .925-.01 1.626-.032l-2.427 4.605-8.427 15.17-1.334 2.537a41.096 41.096 0 00-1.295-.032c-.383 0-.994.011-1.832.032z"></path>
          <path
            d="M901.73-118.93c.02-.413.03-.695.03-.846l.066-1.589a56.32 56.32 0 002.415-2.288 69.37 69.37 0 002.179-2.306c.725-.801 1.28-1.459 1.669-1.973.29-.377.484-.665.586-.865.101-.2.167-.363.199-.49.031-.128.047-.254.047-.376 0-.323-.099-.587-.295-.793-.197-.206-.477-.37-.842-.494-.364-.123-.792-.184-1.283-.185-.46 0-.973.043-1.54.13s-1.38.242-2.437.469l.198-1.287c.045-.277.113-.74.204-1.388 1.725-.313 3.322-.47 4.792-.47.675 0 1.336.067 1.985.2a5.176 5.176 0 011.738.683c.51.322.886.701 1.128 1.137.242.436.363.888.363 1.358 0 .265-.027.537-.083.816a3.843 3.843 0 01-.27.83c-.17.389-.397.8-.684 1.236-.286.435-.741 1.022-1.365 1.761-.623.74-1.287 1.48-1.993 2.223a16.399 16.399 0 01-2.342 2.033h.706l2.3-.01c.802 0 1.366-.009 1.692-.024.327-.016.831-.049 1.513-.1-.016.367-.039.72-.07 1.06-.03.339-.072.855-.122 1.548a636.877 636.877 0 00-10.484 0z"
            baselineShift="sub"
          ></path>
        </g>
        <g fill="#000">
          <path
            d="M749.09 130.54c1.393 0 2.591.304 3.596.911s1.78 1.563 2.322 2.867c.542 1.303.814 2.813.814 4.53 0 1.35-.164 2.698-.492 4.043-.328 1.346-.787 2.46-1.376 3.34-.59.88-1.4 1.566-2.433 2.057a7.597 7.597 0 01-3.309.737c-1.138 0-2.153-.22-3.045-.66s-1.618-1.054-2.175-1.846a7.31 7.31 0 01-1.161-2.637 13.61 13.61 0 01-.325-2.986c0-1.406.163-2.787.489-4.143.325-1.356.804-2.5 1.436-3.432a5.87 5.87 0 012.462-2.09 7.612 7.612 0 013.197-.69zm-.102 3.163c-.277 0-.553.057-.828.17a2.549 2.549 0 00-.817.573c-.27.27-.495.59-.677.962a8.22 8.22 0 00-.501 1.367c-.153.54-.272 1.186-.36 1.938a20.12 20.12 0 00-.13 2.322c0 1.138.11 2.05.33 2.736.22.686.539 1.208.954 1.566.416.359.888.538 1.419.538.289 0 .57-.058.847-.173.275-.116.562-.34.86-.674.299-.333.573-.84.825-1.52.252-.681.436-1.434.554-2.26.117-.826.176-1.655.176-2.49 0-1.146-.108-2.087-.325-2.822-.217-.736-.527-1.292-.93-1.668a1.986 1.986 0 00-1.397-.564z"
            baselineShift="super"
          ></path>
          <path d="M749.83 165.07l6.452-11.84 6.733-12.046 1.926-3.614c.717.026 1.304.039 1.76.039.473 0 1.14-.013 2.004-.039l-2.991 5.675-10.386 18.697-1.644 3.128a50.523 50.523 0 00-1.596-.04c-.473 0-1.226.014-2.258.04z"></path>
          <path
            d="M767.44 173.24c.119-1.496.216-3.069.292-4.72l.241-5.088c.085-1.724.14-3.121.166-4.192-.06.192-.205.365-.436.517-.232.153-.87.502-1.916 1.048.043-.728.079-1.418.108-2.068.03-.651.044-1.202.044-1.653a56.98 56.98 0 001.292-.764c.49-.297.78-.476.87-.537.09-.06.272-.2.547-.418.49.017 1.132.025 1.926.025.788 0 1.54-.008 2.255-.025l-.143 2.013c-.035.411-.074.981-.115 1.708l-.51 10.133-.037 1.481c-.008.311-.011.627-.01.946l.013 1.594a98.98 98.98 0 00-2.166-.025c-.785 0-1.592.008-2.421.025z"
            baselineShift="sub"
          ></path>
        </g>
        <g fill="#000">
          <path
            d="M849.51-10.726c.097-1.214.175-2.49.237-3.83l.196-4.128c.069-1.399.113-2.532.134-3.4-.048.155-.166.295-.354.419-.188.124-.706.407-1.555.85.035-.591.065-1.15.088-1.679.024-.528.036-.974.036-1.34.301-.172.65-.379 1.049-.62.397-.241.633-.387.706-.436.072-.05.22-.162.443-.339.398.014.919.02 1.562.02.64 0 1.25-.006 1.83-.02l-.116 1.633c-.028.334-.06.796-.093 1.386l-.413 8.221-.03 1.202c-.007.252-.01.508-.01.767l.012 1.294a80.572 80.572 0 00-3.722 0z"
            baselineShift="super"
          ></path>
          <path d="M851.96 2.535l5.235-9.606 5.463-9.774 1.562-2.932c.582.021 1.059.032 1.429.032.383 0 .925-.01 1.626-.032l-2.427 4.605-8.427 15.17-1.334 2.537a41.04 41.04 0 00-1.295-.031c-.383 0-.994.01-1.832.031z"></path>
          <path
            d="M867.54 9.166c.131-1.542.22-2.959.268-4.25h-1.848l-4.032.061c.015-.254.037-.713.068-1.379.03-.665.046-1.145.046-1.437l.889-1.123c.115-.154.39-.527.825-1.118l2.212-3.03c.26-.37.477-.668.651-.89.174-.223.455-.668.844-1.336a109.65 109.65 0 004.223-.001c-.208 3.09-.336 5.651-.382 7.683.073.002.388.003.945.003.537 0 .975-.01 1.315-.031-.02.63-.03 1.32-.03 2.067v.561a51.826 51.826 0 00-2.328-.041c-.013.2-.024.424-.034.675-.01.251-.02.706-.03 1.366-.01.66-.014 1.134-.013 1.421v.799a90.063 90.063 0 00-1.748-.02c-.438 0-1.051.006-1.84.02zm.67-6.725c.014-.261.021-.424.021-.487l.196-4.787c-.778 1.48-1.354 2.46-1.728 2.94l-1.874 2.334z"
            baselineShift="sub"
          ></path>
        </g>
        <g fill="#000">
          <path
            d="M845.66-267.83a72.33 72.33 0 00-.216-2.804c1.612.516 3.013.774 4.204.774.575 0 1.09-.09 1.547-.268.456-.179.813-.432 1.072-.76.26-.327.389-.713.389-1.156 0-.368-.104-.69-.313-.965-.208-.274-.549-.484-1.022-.629-.473-.145-1.093-.217-1.859-.217-.491 0-1.095.03-1.81.093.07-.953.13-1.843.179-2.67.561.069 1.033.103 1.414.103.593 0 1.142-.054 1.65-.163.506-.108.896-.325 1.167-.649.272-.324.408-.674.408-1.051a1.436 1.436 0 00-.257-.827 1.3 1.3 0 00-.306-.31 1.746 1.746 0 00-.41-.221 3.178 3.178 0 00-.656-.146c-.288-.04-.59-.06-.904-.06-.437 0-.895.029-1.375.086-.48.058-1.186.17-2.117.337l.208-1.147.266-1.455a36.861 36.861 0 012.192-.251c.568-.043 1.16-.065 1.775-.065.734 0 1.45.06 2.148.18.697.121 1.305.336 1.821.646.517.31.898.712 1.144 1.204.246.492.37 1.027.37 1.606 0 .375-.055.746-.163 1.112a3.6 3.6 0 01-.486 1.02 3.387 3.387 0 01-.727.78c-.269.206-.53.37-.78.493-.253.122-.562.171-.929.15.38-.009.711.036.992.136.28.1.558.25.831.454.274.204.506.44.697.707.19.268.334.578.43.93.097.352.145.711.145 1.078a4.06 4.06 0 01-1.883 3.429 5.966 5.966 0 01-2.062.835c-.764.159-1.558.238-2.383.238-.611 0-1.236-.038-1.873-.114-.638-.076-1.478-.23-2.518-.463z"
            baselineShift="super"
          ></path>
          <path d="M852.04-254.24l5.235-9.607 5.463-9.773 1.562-2.932c.582.021 1.058.032 1.429.032.383 0 .925-.01 1.626-.032l-2.428 4.605-8.426 15.17-1.334 2.537a41.096 41.096 0 00-1.295-.031c-.384 0-.994.01-1.832.03z"></path>
          <path
            d="M867.63-247.61c.131-1.542.22-2.958.268-4.25h-1.848l-4.032.061c.015-.253.037-.713.068-1.379.03-.665.046-1.144.046-1.437l.889-1.123c.115-.154.39-.527.825-1.118l2.212-3.03c.26-.37.477-.667.651-.89.174-.223.455-.668.844-1.336a111.437 111.437 0 004.223-.001c-.208 3.09-.336 5.652-.382 7.683.073.002.388.003.945.003.537 0 .975-.01 1.315-.03-.02.63-.03 1.319-.03 2.066v.562a51.853 51.853 0 00-2.328-.041c-.013.199-.024.424-.034.675-.01.25-.02.706-.03 1.366-.01.66-.014 1.133-.012 1.421v.798a90.003 90.003 0 00-1.75-.02c-.437 0-1.05.007-1.84.02zm.67-6.724c.014-.262.021-.425.021-.488l.196-4.787c-.778 1.48-1.354 2.46-1.728 2.94l-1.874 2.335z"
            baselineShift="sub"
          ></path>
        </g>
        <path
          fill="#f60"
          d="M626.21-131.11c-3.34-7.406.806-13.284 3.2-15.691 1.69-1.624 4.097-2.582 4.097-2.582l321.39-123.29-310.34 148.94s-2.24.82-4.75 1.011c-3.413.262-10.536-.84-13.598-8.389z"
          className="fuel-level"
          transform={`rotate(${rotate},635 -139)`}
        ></path>
        <path
          fill="#f60"
          stroke="#000"
          strokeWidth="0.008"
          d="M640.489-135.74a1.372 1.372 0 11-2.745 0 1.372 1.372 0 112.745 0z"
        ></path>
      </g>
    </svg>
  );
}
export default memo(SvgFuel);
