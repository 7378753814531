import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { membreHooks } from "../../network";
import Swal from 'sweetalert2';
import { userHooks } from "../../network";

export const CreateAccess = ({ show, onHide, data }: any) => {
  const [passwordType, setPasswordType] = useState<string>('password');
  const [password, setPassword] = useState<string>('');
  const [confPassword, setConfPassword] = useState<string>('');
  const [isConfPassDirty, setIsConfPassDirty] = useState(false);
  const [cPasswordClass, setCPasswordClass] = useState('form-control form-control-lg');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const { mutate } = userHooks.useRegisterUser();

  const changePasswordType = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }

  React.useEffect(() => {
    if (isConfPassDirty) {
      if (password === confPassword) {
        setShowErrorMessage(false);
        setCPasswordClass('form-control form-control-lg is-valid')
      } else {
        setShowErrorMessage(true)
        setCPasswordClass('form-control form-control-lg is-invalid')
      }
    }
  }, [confPassword])

  const handleCPassword = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    setConfPassword(e.target.value);
    setIsConfPassDirty(true);
  }

  const registerUser = () => {
    const donne = {
      password: password,
      membres: data?.idMembre
    }
    if (!data){
      return;
    }
    return mutate(donne, {
      onSuccess: async (data) => {
        onHide();
        Swal.fire({
          icon: 'success',
          text: 'Ajout accés reussi avec succés',
        });
        setPassword('');
        setConfPassword('');
        setIsConfPassDirty(false);
      },
      onError: (error) => {
        onHide();
        Swal.fire({
          icon: 'error',
          text: 'Erreur lors de l\`ajout accés',
        });
      },
    })
  }

  React.useEffect (()=>{
    setPassword('')
    setConfPassword('')
    setIsConfPassDirty(false)
  }, [show])

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Créer un compte d'accés</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Créer un compte d'accés {data?.prenom ? `pour ${data?.prenom}` : ''}</p>
        <div className="row gy-4">
          <div className="col-sm-12"> 
            <div className="form-group">
              <label className="form-label">Créer un mot de passe</label>
              <div className="form-control-wrap">
                <a
                  onClick={changePasswordType}
                  className="form-icon form-icon-right passcode-switch"
                  data-target="current"
                >
                  {passwordType === "password" ? <em className="passcode-icon icon-show icon ni ni-eye"></em> :
                    <em className="passcode-icon icon-show icon ni ni-eye-off"></em>
                  }
                </a>
                <input
                  type={passwordType}
                  className="form-control form-control-lg"
                  id="current"
                  name="current"
                  placeholder="Mot de passe"
                  required
                  value={password}
                  onChange={(e) => { setPassword(e.target.value) }}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">Ressaisir le mot de passe</label>
              <div className="form-control-wrap">
                <input
                  type={passwordType}
                  className={cPasswordClass}
                  id="current"
                  name="current"
                  placeholder="Mot de passe"
                  required
                  value={confPassword}
                  onChange={handleCPassword}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="bg-light">
        <button
          className="btn btn-white btn-dim btn-outline-light"
          type="button"
          onClick={onHide}
        >
          <em className="icon ni ni-cross-circle"></em>
          <span>Annuler</span>
        </button>
        <button className="btn btn-primary" onClick={registerUser}>
          <em className="icon ni ni-check-circle-cut"></em>
          <span>Donner l'accès</span>
        </button>
      </Modal.Footer>
    </Modal>
  );
};
