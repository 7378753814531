import React from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logoDark from "../../assets/images/logo_site.jpeg";
import { Link } from 'react-router-dom';


export default function Validation() {
  return (
    <div className='w-100 d-flex flex-column h-screen align-items-center justify-content-evenly px-8'>
      <img src={logoDark} alt="" />
      <h3>
        Votre compte a bien été validé. Veuillez-vous connecter <Link to='/'>ici</Link>
      </h3>
    </div>
  )
}
