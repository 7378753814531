import React, { useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { CreateNote } from "../../Modal";
import { projetHooks } from "../../network/projet";
import { projetEntityType } from "../../types";
import moment, { Moment } from "moment";

moment.locale('fr');

export default function Details(props:{data: projetEntityType}) {
  const [showCreateNote, setShowCreateNote] = useState(false);

  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "This note will be deleted permanently.",
      confirmButtonText: "Yes, delete!",
      cancelButtonText: `Cancel`,
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: "#fff",
    }).then((result) => {
      if (result.isConfirmed) {
        ///When click Yes
      }
    });
  };
  return (
    <>
      <div className="card-inner">
        <div className="nk-block">
          <div className="nk-block-head">
            {/* <Link
              to="/projects/report/view"
              className="btn btn-primary pull-right"
            >
              <em className="icon ni ni-reports" />
              <span>Vehicle Report</span>
            </Link>

            <Link
              to="/projects/booking"
              className="btn btn-dim btn-outline-primary pull-right mr-1"
            >
              <em className="icon ni ni-todo" />
              <span>Vehicle Booking</span>
            </Link> */}
            <h5 className="title overline-title text-base">Informations du projet/véhicule</h5>
            <p>Informations basiques sur le projet pour avoir un aperçu</p>
          </div>
          {/* .nk-block-head */}
          <div className="profile-ud-list">
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label"
                  style={{
                    width: 'auto',
                    display: 'block'
                  }}
                >Numéro d'immatriculation - A</span>
                <span className="profile-ud-value"
                  style={{
                    width: 'fit-content',
                    marginLeft: 'auto',
                    whiteSpace: 'nowrap'
                  }}
                >{props.data?.vehicules.detailVehicules.numeroImmatriculation}</span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label"
                  style={{
                    width: 'auto',
                    display: 'block'
                  }}
                >Date de première immatriculation - B</span>
                <span className="profile-ud-value"
                  style={{
                    width: 'fit-content',
                    marginLeft: 'auto',
                    whiteSpace: 'nowrap'
                  }}
                >{moment(props.data?.vehicules.detailVehicules.date1Immatriculation).format('DD / MM / YYYY')}</span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Type de Version - D.2</span>
                <span className="profile-ud-value">{props.data?.vehicules.detailVehicules.typeVersion}</span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Code national d'identité - D.2.1</span>
                <span className="profile-ud-value">{props.data?.vehicules.detailVehicules.codeNationalIdentification}</span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Marque - D.1</span>
                <span className="profile-ud-value">{props.data?.vehicules.detailVehicules.marque}</span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Modele - D.3</span>
                <span className="profile-ud-value">{props.data?.vehicules.detailVehicules.model}</span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">VIN - E</span>
                <span className="profile-ud-value">{props.data?.vehicules.detailVehicules.vin}</span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Moteur</span>
                <span className="profile-ud-value">{props.data?.vehicules.detailVehicules.moteur}</span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider" style={{
                justifyContent: 'space-between'
              }}>
                <span className="profile-ud-label">Statut</span>
                <span className="badge badge-sm badge-dot has-bg badge-warning d-mb-inline-flex">
                  {props.data?.status}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="nk-divider divider md" />

        <div className="nk-block">
          <div className="nk-block-head nk-block-head-line">
            <h6 className="title overline-title text-base">
              Informations Clients
            </h6>
          </div>
          <div className="profile-ud-list">
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Nom complet</span>
                <span className="profile-ud-value">{props.data?.vehicules.clients.nomPrenom}</span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Numéro téléphone</span>
                <span className="profile-ud-value">{props.data?.vehicules.clients.telephone}</span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Email</span>
                <span className="profile-ud-value">{props.data?.vehicules.clients.email}</span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Adresse</span>
                <span className="profile-ud-value">{props.data?.vehicules.clients.adresse}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CreateNote
        show={showCreateNote}
        onHide={() => setShowCreateNote(false)}
      />
    </>
  );
}
