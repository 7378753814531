import React from "react";
import { Modal } from "react-bootstrap";
interface CreateNoteProps {
  show: boolean;
  onHide: () => void;
}

export function CreateNote({ show, onHide }: CreateNoteProps) {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Ajouter une note</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Ajouter une note sur le compte de ce client</p>
        <div className="row gy-4">
          <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">Écrivez votre note</label>
              <div className="form-control-wrap">
                <textarea
                  className="form-control form-control-lg"
                  placeholder="Écrivez votre note"
                  name="note"
                  rows={5}
                  required
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className=" bg-light">
        <button
          className="btn btn-white btn-dim btn-outline-light"
          type="button"
          onClick={onHide}
        >
          <em className="icon ni ni-cross-circle" />
          <span>Annuler</span>
        </button>
        <button className="btn btn-primary" type="button" onClick={onHide}>
          <em className="icon ni ni-check-circle-cut" />
          <span>Enregistrer la note</span>
        </button>
      </Modal.Footer>
    </Modal>
  );
}
