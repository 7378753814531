import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { Balance, CreateCampaign } from "../../Modal";

export default function Marketing() {
  const [showCreate, setShowCreate] = useState(false);
  const [showBalance, setShowBalance] = useState(false);
  const [showPageMenu, setShowPageMenu] = useState(false);
  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "This campaign will be deleted permanently.",
      confirmButtonText: "Yes, delete!",
      cancelButtonText: `Cancel`,
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        ///When click Yes
      }
    });
  };
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">Commercialisation</h3>
            <div className="nk-block-des text-soft">
              <p>Un total de 27 campagnes.</p>

              {/* <p>Create and manage your campaigns here.</p> */}
            </div>
          </div>
          <div className="nk-block-head-content">
            <div className="toggle-wrap nk-block-tools-toggle">
              <a
                onClick={() => setShowPageMenu(!showPageMenu)}
                className="btn btn-icon btn-trigger toggle-expand mr-n1"
                data-target="pageMenu"
              >
                <em className="icon ni ni-menu-alt-r"></em>
              </a>
              <div
                className={`toggle-expand-content ${showPageMenu && "d-block"}`}
                data-content="pageMenu"
              >
                <ul className="nk-block-tools g-3">
                  <li>
                    <Dropdown>
                      <Dropdown.Toggle
                        as="a"
                        className="btn btn-dim btn-outline-primary"
                      >
                        <em className="icon ni ni-coins"></em>{" "}
                        <span>Équilibre & Historique</span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu-right">
                        <ul className="link-list-opt no-bdr">
                          <li>
                            <Link to="recipients">
                              <em className="icon ni ni-histroy"></em>
                              <span>Historique des SMS</span>
                            </Link>
                          </li>
                          <li>
                            <a
                              className="fetch-display-click cursor"
                              onClick={() => setShowBalance(true)}
                            >
                              <em className="icon ni ni-coins"></em>
                              <span>Solde SMS</span>
                            </a>
                          </li>
                        </ul>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                  <li>
                    <button
                      onClick={() => setShowCreate(true)}
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#create"
                    >
                      <em className="icon ni ni-plus"></em>
                      <span>Créer une campagne</span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="nk-block">
        <div className="card card-stretch">
          <div className="card-inner">
            <table
              className="datatable-init nk-tb-list nk-tb-ulist"
              data-auto-responsive="false"
            >
              <thead>
                <tr className="nk-tb-item nk-tb-head">
                  <th className="nk-tb-col text-center">#</th>
                  <th className="nk-tb-col">
                    <span className="sub-text">Campagne</span>
                  </th>
                  <th className="nk-tb-col tb-col-md">
                    <span className="sub-text">Date</span>
                  </th>
                  <th className="nk-tb-col tb-col-md">
                    <span className="sub-text">SMS envoyés</span>
                  </th>
                  <th className="nk-tb-col tb-col-md">
                    <span className="sub-text">Crédits</span>
                  </th>
                  <th className="nk-tb-col tb-col-md">
                    <span className="sub-text">Statut</span>
                  </th>
                  <th className="nk-tb-col nk-tb-col-tools text-right"></th>
                </tr>
              </thead>
              <tbody>
                <tr className="nk-tb-item">
                  <td className="nk-tb-col text-center">1</td>
                  <td className="nk-tb-col">
                    <span className="tb-amount">Google Analytics</span>
                  </td>
                  <td className="nk-tb-col tb-col-md">
                    <span>14 septembre 2020</span>
                  </td>
                  <td className="nk-tb-col tb-col-md">
                    <span className="tb-amount">1 / 1</span>
                  </td>
                  <td className="nk-tb-col tb-col-md">
                    <span className="tb-amount">0.80</span>
                  </td>
                  <td className="nk-tb-col tb-col-md">
                    <span className="badge badge-sm badge-dot has-bg badge-success d-mb-inline-flex">
                      Complété
                    </span>

                    {/* <span className="badge badge-sm badge-dot has-bg badge-warning d-mb-inline-flex">Envoi en cours</span> */}
                  </td>
                  <td className="nk-tb-col nk-tb-col-tools">
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <Dropdown>
                          <Dropdown.Toggle
                            as="a"
                            className="btn btn-icon btn-trigger"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <Link to="recipients/?campaign=38">
                                  <em className="icon ni ni-eye"></em>
                                  <span>Afficher les destinataires</span>
                                </Link>
                              </li>

                              <li className="divider"></li>
                              <li>
                                <a
                                  className="send-to-server-click cursor"
                                  onClick={handleDelete}
                                >
                                  <em className="icon ni ni-trash"></em>
                                  <span>Supprimer la campagne</span>
                                </a>
                              </li>
                            </ul>
                          </Dropdown.Menu>
                        </Dropdown>
                      </li>
                    </ul>
                  </td>
                </tr>
                {/* <tr>
                                                    <td className="text-center" colSpan={7}>C'est vide ici !</td>
                                                </tr> */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <CreateCampaign show={showCreate} onHide={() => setShowCreate(false)} />
      <Balance show={showBalance} onHide={() => setShowBalance(false)} />
    </>
  );
}
