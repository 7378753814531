import api from "../../axios";
import type { userEntityType } from "../../types";

export const network = {
  async login(user: Object) {
    const { data } = await api.withoutToken.post('/users/login', user);

    return data;
  },
  async forgotPassword(email: Object) {
    const { data } = await api.withoutToken.post('/users/forgot-password', email);

    return data;
  },
  async editPassword(password: Object) {
    const { data } = await api.withToken.put('/users/edit-password', password);

    return data
  },
  async newPassword(newPassword: { token: string, password: string }) {
    const { data } = await api.withoutToken.post('/users/reset-password/' + newPassword.token, { password: newPassword.password });

    return data;
  },
  async registerUser(donne: Object) {
    const { data } = await api.withToken.post('/users', donne);

    return data;
  },
  async getUserByToken() {
    const { data } = await api.withToken.get('/me');

    return data;
  }
}