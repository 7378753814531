import api from "../../axios";
import type { statusType, workStepEntityPaginationType, workStepEntityType } from "../../types";
import { UploadFile } from "antd";

export const network = {
  async getAllWorkSteps(memberIds: number[] = [-1], 
    vehicleIds: number[]= [-1], 
    status: statusType[] = ['tous'], 
    date: Date[] | number [] = [-1]): Promise <{data: workStepEntityType[]}> {
    const { data } = await api.withToken.post('/get/work-step', {memberIds, vehicleIds, status, date});
    return data;
  },
  async getWorkStepByMember(memberId: number, status: statusType[] = ['tous']): Promise <{data: workStepEntityType[]}>  {
    const { data } = await api.withToken.get('/work-step/by-member/' + memberId + '?status=' + status.join('-'));

    return data;
  },
  // async getWorkStepByVehicule(donne: { page: number, idVehicule: number }) {
  //   const { data } = await api.withToken.get('/work-vehicule/page/5/' + donne.page + '/' + donne.idVehicule);

  //   return data;
  // },
  async createWorkStep(WorkStep: Object): Promise <{data: workStepEntityType}>{
    const { data } = await api.withToken.post('/work-step', WorkStep);

    return data;
  },
  async getWorkStepPaginated(page: number): Promise <{data: workStepEntityPaginationType
    hasMore?: boolean
  }>  {
    const { data } = await api.withToken.get('/work-step/page/5/' + page);

    return data;
  },
  // async getWorkStepStatusPaginated(donne: { page: number, status: string }) {
  //   const { data } = await api.withToken.get('/work/by-status/page/5/' + donne.page + '/' + donne.status);

  //   return data
  // },
  async updateWorkStep(dataWorkStep: { idWorkStep: number, workStep: Object }): Promise <{data: workStepEntityType}>{
    const { data } = await api.withToken.put(`/work-step/${dataWorkStep.idWorkStep}`, dataWorkStep.workStep)

    return data;
  },

  async deleteWorkStep(donne: { id: number }): Promise <{data: workStepEntityType[]}> {
    const { data } = await api.withToken.delete('/work-step/' + donne.id);

    return data;
  },

  async addWorkStepImage(data: {idWorkStep: number, images: UploadFile[]}){
    try {
      const formData = new FormData();
      data.images.forEach((image) => {
        if (image.originFileObj){
          formData.append('images', image.originFileObj);
        }
      });
      formData.append('workStepId', data.idWorkStep.toString());

      await api.withToken.post('/work-step-image', formData); 

    } catch(e) {
      console.error("Erreur lors de l'upload d'image: " + e)
      //Swal.fire('Erreur', "Une erreur est survenue lors de l'importation de l'image", 'error')
    }
  },
}