import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import TableauAllTask from "../../../components/Tableau/TableauAllTask";
import Loading from "../../../components/Loading";
import { tacheHooks } from "../../../network/tache";

export default function Pending() {
  const [donnePage, setDonnePage] = useState<{ page: number, status: string }>({
    page: 1,
    status: 'enCours'
  })
  const { data, isLoading } = tacheHooks.useGetTaskStatusPaginated(donnePage)

  const nextPage = () => {
    if ( data?.data) {
      if (donnePage.page < data?.data.countPage) {
        setDonnePage({ ...donnePage, page: donnePage.page + 1 });
      } else {
        setDonnePage({ ...donnePage, page: donnePage.page });
      }
    }
  }

  const prevPage = () => {
    if (donnePage.page > 1) {
      setDonnePage({ ...donnePage, page: donnePage.page - 1 });
    } else {
      setDonnePage({ ...donnePage, page: donnePage.page });
    }
  }

  const goToPage = (numPage: number) => {
    setDonnePage({ ...donnePage, page: numPage });
  }

  useEffect(() => {
    if (data?.hasMore) {
      tacheHooks.usePrefetchTaskStatus({ page: donnePage.page, status: 'enCours' })
    }
  }, [data, donnePage.page])

  console.log(data, 'ato ary ilay izy oooooooo');


  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "This task will be deleted permanently.",
      confirmButtonText: "Yes, delete!",
      cancelButtonText: `Cancel`,
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: "#fff",
    }).then((result) => {
      if (result.isConfirmed) {
        ///When click Yes
      }
    });
  };
  const handleCancel = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "This task will be marked as cancelled.",
      confirmButtonText: "Yes, Cancel!",
      cancelButtonText: `Cancel`,
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: "#fff",
    }).then((result) => {
      if (result.isConfirmed) {
        ///When click Yes
      }
    });
  };

  if (isLoading){
    return (<div className="loading__wrapper">
    <Loading />
  </div>)
  }
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          {isLoading ? <Loading /> :
            <div className="nk-block-head-content">
              <h3 className="nk-block-title page-title">Tâches en attente</h3>
              <div className="nk-block-des text-soft">
                <p>Un total de {data?.data.countTotal} tâches en attente.</p>
              </div>
            </div>}
        </div>
      </div>
      {isLoading ? <Loading /> : (data && data.data ? <TableauAllTask data={data?.data} nextPage={nextPage} prevPage={prevPage} goToPage={goToPage} /> : null)}
    </>
  );
}
