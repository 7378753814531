import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { membreHooks } from '../../network';
import Loading from '../../components/Loading';
import { tacheHooks } from '../../network';
import { useParams } from 'react-router-dom';
import { pieceHooks } from "../../network/piece";
import Swal from "sweetalert2";
import type { projetEntityType } from "../../types";


interface CreateTaskInListProps {
  show: boolean;
  loading: boolean;
  dataProject: projetEntityType[];
  onHide: () => void;
}


export function CreateTaskInList({ show, onHide, dataProject, loading }: CreateTaskInListProps) {
  const { data: dataMembre, isLoading: loadingMembre } = membreHooks.useGetMembres();
  const [titre, setTitre] = useState<string>('');
  const [status, setStatus] = useState<string>('aFaire');
  const [description, setDescription] = useState<string>('');
  const [cout, setCout] = useState<number>(0);
  const [dateEcheance, setDateEcheance] = useState<string>('');
  const [dateDebut, setDateDebut] = useState<string>('');
  const [heureEcheance, setHeureEcheance] = useState<string>('');
  const [membre, setMembre] = useState<number>(0);
  const [errorTitre, setErrorTitre] = useState<boolean>(false);
  const [errorDescri, setErrorDescri] = useState<boolean>(false);
  const [errorCout, setErrorCout] = useState<boolean>(false);
  const [errorDate, setErrorDate] = useState<boolean>(false);
  const [errorHeure, setErrorHeure] = useState<boolean>(false);
  const { data: dataPiece, isLoading: loadingPiece } = pieceHooks.useGetPiece();
  const [pieces, setPieces] = useState<number[]>([]);
  const [classTitle, setClassTitle] = useState<string>('form-control form-control-lg');
  const [classDescription, setClassDescription] = useState<string>('form-control form-control-lg');
  const [classDate, setClassDate] = useState<string>('form-control form-control-lg');
  const [classCout, setClassCout] = useState<string>('form-control form-control-lg');
  const [classHeure, setClassHeure] = useState<string>('form-control form-control-lg');
  const [id, setId] = useState<string>('');

  const params = useParams()

  const { mutate } = tacheHooks.useCreateTask();

  const handleChangeStatut = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    setStatus(e.target.value)
  }

  const handleChangeMembre = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    setMembre(Number(e.target.value))
  }

  const handleGetPiece = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    /* let array = [Number(e.target.value)];
    setPieces(array); */

    setPieces([Number(e.target.value)])
  }

  const checkEmptyTitle = () => {
    if (titre.trim().length === 0) {
      setErrorTitre(true)
      setClassTitle('form-control form-control-lg is-invalid')
      return true
    } else {
      setErrorTitre(false)
      setClassTitle('form-control form-control-lg')
      return false
    }
  }

  const checkEmptyDescri = () => {
    if (description.trim().length === 0) {
      setErrorDescri(true)
      setClassDescription('form-control form-control-lg is-invalid')
      return true
    } else {
      setErrorDescri(false)
      setClassDescription('form-control form-control-lg')
      return false
    }
  }

  const checkEmptyCout = () => {
    if (cout === 0) {
      setErrorCout(true)
      setClassCout('form-control form-control-lg is-invalid')
      return true
    } else {
      setErrorCout(false)
      setClassCout('form-control form-control-lg')
      return false
    }
  }

  const checkEmptyDate = () => {
    if (dateEcheance.trim().length === 0) {
      setErrorDate(true);
      setClassDate('form-control form-control-lg is-invalid')
      return true
    } else {
      setErrorDate(false)
      setClassDate('form-control form-control-lg')
      return false
    }
  }

  const checkEmptyHeure = () => {
    if (heureEcheance.trim().length === 0) {
      setErrorHeure(true);
      setClassHeure('form-control form-control-lg is-invalid')
      return true
    } else {
      setErrorHeure(false)
      setClassHeure('form-control form-control-lg')
      return false
    }
  }

  const handleChangeProject = (e) => {
    setId(e.target.value);
  }

  const createTask = () => {

    const task = {
      tache: {
        titreTache: titre,
        status: status,
        description: description,
        dateDebut: dateDebut,
        dateEcheance: dateEcheance,
        heureEcheance: heureEcheance,
        projets: Number(id),
        membres: membre,
        coutTache: cout,
      },
      pieces: pieces
    }


    if (checkEmptyCout() || checkEmptyDescri() || checkEmptyTitle()) {
      checkEmptyCout()
      checkEmptyDescri()
      checkEmptyTitle()
      Swal.fire({
        icon: 'error',
        text: 'Veuillez remplir tous les champs',
      });
      return;
    }

    return mutate(task, {
      onSuccess: async (data) => {
        onHide()
        Swal.fire({
          icon: 'success',
          text: 'Création de tache avec succès',
        });
      },
      onError: (error) => {
        onHide()
        Swal.fire({
          icon: 'error',
          text: 'Erreur lors de l\'ajout nouvele tache',
        });
      },
      onSettled: async (data) => {
        console.log('tonga ato oooo')
      },
    })
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Création de tâche</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Création de tâche</p>
        <div className="row gy-4">
          <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">Séléctionner un projet:</label>
              <div className="form-control-wrap ">
                {loading ? <Loading /> :
                  <div className="form-control-select">
                    <select
                      className="form-control select2"
                      name="member"
                      required
                      onChange={handleChangeProject}
                    >
                      <option></option>
                      {dataProject && dataProject?.map((item) => (
                        <option value={item.idProjet}>
                          {item.vehicules?.clients?.nomPrenom}&nbsp;-&nbsp;
                          {item.vehicules?.detailVehicules?.marque}&nbsp;-&nbsp;
                          {item.vehicules?.detailVehicules?.model}
                        </option>
                      ))}
                    </select>
                  </div>}
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">Titre de la tâche</label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  className={classTitle}
                  placeholder="Titre de la tâche"
                  name="title"
                  required
                  onChange={e => setTitre(e.target.value)}
                  onBlur={checkEmptyTitle}
                />
              </div>
              {errorTitre && <div className="text-danger">Valeur obligatoire</div>}
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label className="form-label">Assigner à</label>
              <div className="form-control-wrap ">
                {loadingMembre ? <Loading /> :
                  <div className="form-control-select">
                    <select
                      className="form-control select2"
                      name="member"
                      required
                      onChange={handleChangeMembre}
                    >
                      <option></option>
                      {dataMembre?.data.map((item: { nom: string, idMembre: number, prenom: string }) => (
                        <option value={item.idMembre}>{item.prenom} {item.nom}</option>
                      ))}
                    </select>
                  </div>}
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label className="form-label">Status</label>
              <div className="form-control-wrap ">
                <div className="form-control-select">
                  <select
                    className="form-control form-control-lg"
                    name="status"
                    onChange={handleChangeStatut}
                  >
                    <option value="aFaire">A faire</option>
                    <option value="enCours">En cours</option>
                    <option value="complete">Complete</option>
                    <option value="annuler">Annuler</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">Description de la tâche</label>
              <div className="form-control-wrap">
                <textarea
                  className={classDescription}
                  placeholder="Description de la tâche"
                  rows={5}
                  name="description"
                  defaultValue={""}
                  onChange={e => setDescription(e.target.value)}
                  onBlur={checkEmptyDescri}
                />
              </div>
              {errorDescri && <div className="text-danger">Valeur obligatoire</div>}
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">Coût de la tâche</label>
              <div className="form-control-wrap">
                <div className="form-text-hint">
                  <span className="overline-title">$</span>
                </div>
                <input
                  type="number"
                  className={classCout}
                  placeholder="Coût de la tâche"
                  name="cost"
                  defaultValue={0.0}
                  step="0.01"
                  required
                  onChange={e => setCout(Number(e.target.value))}
                  onBlur={checkEmptyCout}
                />
              </div>
              {errorCout && <div className="text-danger">Valeur obligatoire</div>}
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label className="form-label">Dâte de debut</label>
              <div className="form-control-wrap">
                <input
                  type="date"
                  className={classDate}
                  placeholder="Dâte de livraison"
                  name="due_date"
                  required
                  onChange={e => setDateDebut(e.target.value)}
                  onBlur={checkEmptyDate}
                />
              </div>
              {errorDate && <div className="text-danger">Valeur obligatoire</div>}
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label className="form-label">Dâte de livraison</label>
              <div className="form-control-wrap">
                <input
                  type="date"
                  className={classDate}
                  placeholder="Dâte de livraison"
                  name="due_date"
                  required
                  onChange={e => setDateEcheance(e.target.value)}
                  onBlur={checkEmptyDate}
                />
              </div>
              {errorDate && <div className="text-danger">Valeur obligatoire</div>}
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label className="form-label">Heure de livraison</label>
              <div className="form-control-wrap">
                <input
                  type="time"
                  className="form-control form-control-lg"
                  placeholder="Heure de livraison"
                  name="due_time"
                  required
                  onChange={e => setHeureEcheance(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="nk-divider divider mt-0 mb-0" />
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">Pièces nécessaires à la tâche</label>
              <div className="form-control-wrap ">
                {loadingPiece ? <Loading /> : <div className="form-control-select">
                  <select
                    className="form-control select2"
                    name="parts_required[]"
                    onChange={handleGetPiece}
                  >
                    <option value=""></option>
                    {dataPiece.data.map((item: { idPiece: number, nomPiece: string }) => (
                      <option value={item.idPiece}>{item.nomPiece}</option>
                    ))}
                  </select>

                </div>}
              </div>
              {/* <div className="form-note">
                If this task requires parts recorded on the system, please
                select the parts below. A task can't be completed if any of the
                required parts are not delivered. Parts are added on{" "}
                <a href="{{ url('Projects@details', array('projectid' => $project->id)) }}?view=expenses">
                  Expenses Tab
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className=" bg-light">
        <button
          className="btn btn-white btn-dim btn-outline-light"
          type="button"
          onClick={onHide}
        >
          <em className="icon ni ni-cross-circle"></em>
          <span>Annuler</span>
        </button>
        <button className="btn btn-primary" type="submit" onClick={createTask}>
          <em className="icon ni ni-check-circle-cut"></em>
          <span>Créer la tâche</span>
        </button>
      </Modal.Footer>
    </Modal>
  );
}
