import api from "../../axios";
import * as _ from 'lodash';

export const network = {
  async getFournisseur() {
    const { data } = await api.withToken.get('/fournisseur');

    return data;
  },
  async addFournisseur(fournisseur: Object) {
    const { data } = await api.withToken.post('/fournisseur', fournisseur);

    return data;
  },
  async updateFournisseur(fournisseur: { id: string }) {
    const { data } = await api.withToken.put('/fournisseur/' + fournisseur.id, _.omit(fournisseur, ['id']));

    return data;
  },
  async getFournisseurPaginate(page: number) {
    const { data } = await api.withToken.get('/fournisseur/page/5/' + page);

    return data;
  }
}