import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { network } from "./network";

export const hooks = {
  useGetPiece() {
    const reponse = useQuery(['pieces'], network.getPiece);

    return reponse
  },
  useGetPieceDepense(donne: { page: number, idProjet: string }) {
    const reponse = useQuery(['piecePaginate', donne], () => network.getPieceDepensePaginate(donne), { keepPreviousData: true });

    return reponse;
  },
  usePrefectPieceDepense(donne: { page: number, idProjet: string }) {
    const queryClient = useQueryClient();

    return queryClient.prefetchQuery(['piecePaginate', donne], () => network.getPieceDepensePaginate(donne));
  },
  useAddPieceDepense() {
    const queryClient = useQueryClient();

    const reponse = useMutation(network.addPieceDepense, {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(['piecePaginate'])
      }
    });

    return reponse
  },
  useDeleteDepense() {
    const queryClient = useQueryClient();

    const reponse = useMutation(network.deletePieceDepense, {
      onSuccess: () => {
        queryClient.invalidateQueries(['piecePaginate'])
      }
    });

    return reponse;
  }
}