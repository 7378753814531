import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { AddPart, OrderParts, UpdatePart } from "../../../Modal";

export default function BookingParts() {
  const [showPageMenu, setShowPageMenu] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [showOrder, setShowOrder] = useState(false);
  const navigate = useNavigate();
  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "This part will be deleted permanently.",
      confirmButtonText: "Yes, delete!",
      cancelButtonText: `Cancel`,
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        ///When click Yes
      }
    });
  };
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">Pièces de réservation</h3>
            <div className="nk-block-des text-soft">
              <p>Un total de 2 parties.</p>

              {/* <p>Créez et gérez vos pièces pour vérifier la réservation du véhicule.</p> */}
            </div>
          </div>
          <div className="nk-block-head-content">
            <div className="toggle-wrap nk-block-tools-toggle">
              <a
                onClick={() => setShowPageMenu(!showPageMenu)}
                className="btn btn-icon btn-trigger toggle-expand mr-n1"
                data-target="pageMenu"
              >
                <em className="icon ni ni-menu-alt-r"></em>
              </a>
              <div
                className={`toggle-expand-content ${showPageMenu && "d-block"}`}
                data-content="pageMenu"
              >
                <ul className="nk-block-tools g-3">
                  <li>
                    <a
                      onClick={() => navigate(-1)}
                      className="btn btn-outline-light bg-white d-none d-sm-inline-flex go-back cursor"
                    >
                      <em className="icon ni ni-arrow-left"></em>
                      <span>Arrière</span>
                    </a>
                    <a
                      href=""
                      className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none go-back"
                    >
                      <em className="icon ni ni-arrow-left"></em>
                    </a>
                  </li>
                  <li>
                    <Dropdown>
                      <Dropdown.Toggle as="a" className="btn btn-primary">
                        <em className="icon ni ni-more-h"></em>{" "}
                        <span>Ajouter et commander</span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu-right">
                        <ul className="link-list-opt no-bdr">
                          <li>
                            <a
                              className="cursor"
                              onClick={() => setShowAdd(true)}
                            >
                              <em className="icon ni ni-plus"></em>
                              <span>Ajouter une pièce</span>
                            </a>
                          </li>
                          <li>
                            <a
                              className="cursor"
                              onClick={() => setShowOrder(true)}
                            >
                              <em className="icon ni ni-sort"></em>
                              <span>Liste de commandes</span>
                            </a>
                          </li>
                        </ul>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="nk-block">
        <div className="card card-stretch">
          <div className="card-inner">
            <table
              className="datatable-init nk-tb-list nk-tb-ulist"
              data-auto-responsive="false"
            >
              <thead>
                <tr className="nk-tb-item nk-tb-head">
                  <th className="nk-tb-col text-center">#</th>
                  <th className="nk-tb-col">
                    <span className="sub-text">Nom</span>
                  </th>
                  <th className="nk-tb-col">
                    <span className="sub-text">Saisir</span>
                  </th>
                  <th className="nk-tb-col">
                    <span className="sub-text">Ajouté le</span>
                  </th>
                  <th className="nk-tb-col">
                    <span className="sub-text">Statut</span>
                  </th>
                  <th className="nk-tb-col nk-tb-col-tools text-right"></th>
                </tr>
              </thead>
              <tbody>
                <tr className="nk-tb-item">
                  <td className="nk-tb-col text-center">1</td>
                  <td className="nk-tb-col tb-col-mb">
                    <span className="tb-amount">Ext/Mirrors P/M</span>
                  </td>
                  <td className="nk-tb-col tb-col-mb">
                    <span className="tb-amount">Combien?</span>

                    {/* <span className="tb-amount">-|-</span> */}
                  </td>
                  <td className="nk-tb-col">
                    <span>February 24, 2021 </span>
                  </td>
                  <td className="nk-tb-col tb-col-mb">
                    <span className="badge badge-sm badge-dot has-bg badge-success d-mb-inline-flex">
                      Activé
                    </span>
                  </td>
                  <td className="nk-tb-col nk-tb-col-tools">
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <Dropdown>
                          <Dropdown.Toggle
                            as="a"
                            className="btn btn-icon btn-trigger"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <a
                                  className="fetch-display-click cursor"
                                  onClick={() => setShowUpdate(true)}
                                >
                                  <em className="icon ni ni-pen"></em>
                                  <span>Modifier la partie</span>
                                </a>
                              </li>
                              <li className="divider"></li>
                              <li>
                                <a
                                  className="send-to-server-click cursor"
                                  onClick={handleDelete}
                                >
                                  <em className="icon ni ni-trash"></em>
                                  <span>Supprimer la partie</span>
                                </a>
                              </li>
                            </ul>
                          </Dropdown.Menu>
                        </Dropdown>
                      </li>
                    </ul>
                  </td>
                </tr>

                {/* <tr>
                                                        <td className="text-center" colSpan={8}>C'est vide ici !</td>
                                                    </tr> */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <AddPart show={showAdd} onHide={() => setShowAdd(false)} />
      <UpdatePart show={showUpdate} onHide={() => setShowUpdate(false)} />
      <OrderParts show={showOrder} onHide={() => setShowOrder(false)} />
    </>
  );
}
