import TableauWorkStep from '../../components/Tableau/TableauWorkStep'
import Loading from '../../components/Loading';
import { workStepHooks } from '../../network/work-step';
import { useEffect, useState } from 'react';

function WorksAdmin() {
  //Liste des workSteps
  const [cb, setCB] = useState('')
  
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCB(new Date().toISOString());
    }, 60000); // Met à jour cb toutes les minutes

    return () => clearInterval(intervalId); // Nettoie l'intervalle lors du démontage du composant
  }, []);
  const {data, isLoading} = workStepHooks.useGetAllWorkSteps([-1], [-1], ['tous'], [-1], cb);

  return (
    <>
      <div className="row g-gs pt-3">
        <div className="col-md-12">
          <div className="card card-full border-top border-primary">
            <div className="card-inner">
              <div style={{display: 'flex', alignItems: 'center'}}>
                <div className="nk-block-head">
                  <h5 className="title overline-title text-base">Travaux affectes</h5>
                  <p>
                    Liste des travaux pour un total de {data?.data.length ?? 0}
                  </p>
                </div>
                {/*loadingTechniciens? <Loading /> :
                <div style={{position: 'relative', marginLeft: 'auto'}}>
                  <Button id='dropdown' onClick={(e)=>{
                    e.stopPropagation()
                    setIsFilterOpen(!isFilterOpen)
                  }} icon={<FilterList />}>
                    Filtre par technicien
                  </Button>
                  <div style={DropdownStle} onClick={(e)=>{
                    e.stopPropagation()
                    setIsFilterOpen(true)
                  }}>
                    <Input.Search value={name} onChange={e =>{setName(e.target.value)}} placeholder='rechercher' />
                    <Tree 
                    checkable
                    defaultCheckedKeys={['tous']}
                    defaultExpandedKeys={['tous']}
                    treeData={loop(listTechniciens)}
                    onCheck={setCheckedID}
                    height={400}
                    style={{backgroundColor: 'white'}}
                    />
                  </div>
                </div>
                */}
              </div>
              {isLoading ? <Loading /> : <TableauWorkStep data={data?.data ?? []} />}
            </div>
          </div>
        </div>
      </div>

      
      {/* <CreateWork show={showCreate} onHide={() => setShowCreate(false)} />
      {isLoading ? <Loading /> : <UpdateWork dataWork={data.data} show={showUpdate} onHide={() => setShowUpdate(false)} />} */}
    </>
  )
}

export default WorksAdmin