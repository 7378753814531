import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {
  CreateInvoices,
  CreateJobCard,
  CreateQuote,
  SendSMS,
  UpdateInsurance,
} from "../../Modal";
import Details from "./Details";
import Invoices from "./Invoices";
import JobCards from "./JobCards";
import Payments from "./Payments";
import Projects from "./Projects";
import Quotes from "./Quotes";

export default function InsuranceDetails() {
  const [tab, setTab] = useState("");
  const [showUpdate, setShowUpdate] = useState(false);
  const [showCreateJobCard, setShowCreateJobCard] = useState(false);
  const [showCreateQuote, setShowCreateQuote] = useState(false);
  const [showCreateInvoice, setShowCreateInvoice] = useState(false);
  let navigate = useNavigate();

  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "This company's profile and data will be deleted permanently.",
      confirmButtonText: "Yes, delete!",
      cancelButtonText: `Cancel`,
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: "#fff",
    }).then((result) => {
      if (result.isConfirmed) {
        ///When click Yes
      }
    });
  };
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between g-3">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">
              Insurance /
              <strong className="text-primary small">
                {" "}
                Jubilee Insurance Company
              </strong>
            </h3>
            <div className="nk-block-des text-soft">
              <ul className="list-inline">
                <li>
                  Insurance ID: <span className="text-base">AI0003</span>
                </li>
                <li>
                  Created On:{" "}
                  <span className="text-base">May 24, 2022 09:34am</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="nk-block-head-content">
            <ul className="nk-block-tools g-3">
              <li>
                <button
                  onClick={() => navigate(-1)}
                  className="btn btn-outline-light bg-white d-none d-sm-inline-flex"
                >
                  <em className="icon ni ni-arrow-left" />
                  <span>Back</span>
                </button>
                <a
                  onClick={() => navigate(-1)}
                  className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
                >
                  <em className="icon ni ni-arrow-left" />
                </a>
              </li>
              <li>
                <Dropdown>
                  <Dropdown.Toggle
                    as="a"
                    className="btn btn-dim btn-outline-primary"
                  >
                    <em className="icon ni ni-more-h" /> <span>More</span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu-right">
                    <ul className="link-list-opt no-bdr">
                      <li>
                        <a
                          className="fetch-display-click cursor"
                          onClick={() => setShowUpdate(true)}
                        >
                          <em className="icon ni ni-pen" />
                          <span>Edit Details</span>
                        </a>
                      </li>

                      <li>
                        <a
                          className="fetch-display-click cursor"
                          onClick={() => setShowCreateJobCard(true)}
                        >
                          <em className="icon ni ni-property-add" />
                          <span>Create Job Card</span>
                        </a>
                      </li>
                      <li>
                        <a
                          className="fetch-display-click cursor"
                          onClick={() => setShowCreateQuote(true)}
                        >
                          <em className="icon ni ni-cards" />
                          <span>Create Quote</span>
                        </a>
                      </li>
                      <li>
                        <a
                          className="fetch-display-click cursor"
                          onClick={() => setShowCreateInvoice(true)}
                        >
                          <em className="icon ni ni-cc" />
                          <span>Create Invoice</span>
                        </a>
                      </li>

                      <li className="divider" />
                      <li>
                        <a
                          className="send-to-server-click cursor"
                          onClick={handleDelete}
                        >
                          <em className="icon ni ni-trash" />
                          <span>Delete Company</span>
                        </a>
                      </li>
                    </ul>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="nk-block">
        <div className="card">
          <div className="card-aside-wrap">
            <div className="card-content">
              <ul className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
                <li className="nav-item">
                  <a
                    className={`nav-link cursor ${!tab && "active"}`}
                    onClick={() => setTab("")}
                  >
                    <em className="icon ni ni-file-text" />
                    <span>Details</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link cursor ${
                      tab === "projects" && "active"
                    }`}
                    onClick={() => setTab("projects")}
                  >
                    <em className="icon ni ni-todo" />
                    <span>Projects</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link cursor ${
                      tab === "jobcards" && "active"
                    }`}
                    onClick={() => setTab("jobcards")}
                  >
                    <em className="icon ni ni-property-add" />
                    <span>Job Cards</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link cursor ${
                      tab === "invoices" && "active"
                    }`}
                    onClick={() => setTab("invoices")}
                  >
                    <em className="icon ni ni-cc" />
                    <span>Invoices</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link cursor ${
                      tab === "quotes" && "active"
                    }`}
                    onClick={() => setTab("quotes")}
                  >
                    <em className="icon ni ni-cards" />
                    <span>Quotes</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link cursor ${
                      tab === "payments" && "active"
                    }`}
                    onClick={() => setTab("payments")}
                  >
                    <em className="icon ni ni-align-left" />
                    <span>Payments</span>
                  </a>
                </li>
              </ul>
              {!tab && <Details />}
              {tab === "projects" && <Projects />}
              {tab === "jobcards" && <JobCards />}
              {tab === "invoices" && <Invoices />}
              {tab === "quotes" && <Quotes />}
              {tab === "payments" && <Payments />}
            </div>
          </div>
        </div>
      </div>
      <UpdateInsurance show={showUpdate} onHide={() => setShowUpdate(false)} />

      <CreateJobCard
        show={showCreateJobCard}
        onHide={() => setShowCreateJobCard(false)}
      />
      <CreateQuote
        show={showCreateQuote}
        onHide={() => setShowCreateQuote(false)}
      />
      <CreateInvoices
        show={showCreateInvoice}
        onHide={() => setShowCreateInvoice(false)}
      />
    </>
  );
}
