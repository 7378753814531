import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

export default function Settings() {
  const [tab, setTab] = useState("personal");
  const [showPageMenu, setShowPageMenu] = useState(false);
  const divFormRef = useRef<HTMLDivElement>(null);
  const [password, setPassword] = useState('');
  const [newPassowrd, setNewPassword] = useState('');
  const [newPassword2, setNewPassword2] = useState('');
  const [passwordType, setPasswordType] = useState<string>('password');
  const [passwordType2, setPasswordType2] = useState<string>('password');

  const changePasswordType = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }

  const changePasswordType2 = () => {
    if (passwordType === "password") {
      setPasswordType2("text")
      return;
    }
    setPasswordType2("password")
  }

  const handleClickOutside = (event: any) => {
    if (divFormRef.current && !divFormRef.current.contains(event.target)) {
      // if (showMobile) {
      //   setShowSideBarMb();
      // }
      setShowPageMenu(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const changePassword = (event: any) => {
    event.prevent.default()
    console.log('un test si ça marche ou pas');
  }
  return (
    <>
      <div className="nk-block">
        <div className="card">
          <div className="card-aside-wrap">
            <div
              className={`card-inner card-inner-lg custom-tab-body ${tab !== "personal" && "d-none"
                }`}
              id="personal"
            >
              <div className="nk-block-head nk-block-head-lg">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h4 className="nk-block-title">
                      Renseignements personnels
                    </h4>
                    <div className="nk-block-des">
                      <p>
                        Informations de base, comme votre nom et votre adresse
                        e-mail, que vous utilisez sur Sayna.
                      </p>
                    </div>
                  </div>
                  <div className="nk-block-head-content align-self-start d-lg-none">
                    <a
                      onClick={() => setShowPageMenu(!showPageMenu)}
                      className="toggle btn btn-icon btn-trigger mt-n1"
                      data-target="userAside"
                    >
                      <em className="icon ni ni-menu-alt-r"></em>
                    </a>
                  </div>
                </div>
              </div>
              <div className="nk-block card">
                <div className="row">
                  <div className="col-sm-8">
                    <form
                      className="simcy-form"
                      action="{{ url('Settings@updateprofile')"
                    >
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label" htmlFor="password">
                            Prénom
                          </label>
                        </div>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            placeholder="Prénom"
                            name="fname"
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label" htmlFor="password">
                            Nom de famille
                          </label>
                        </div>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            placeholder="Nom de famille"
                            name="lname"
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label" htmlFor="password">
                            Adresse e-mail
                          </label>
                        </div>
                        <div className="form-control-wrap">
                          <input
                            type="email"
                            className="form-control form-control-lg"
                            placeholder="Adresse e-mail"
                            name="email"
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label" htmlFor="password">
                            Numéro de téléphone
                          </label>
                        </div>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control form-control-lg phone-input"
                            placeholder="Numéro de téléphone"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label" htmlFor="password">
                            Adresse
                          </label>
                        </div>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            placeholder="Adresse"
                            name="address"
                          />
                        </div>
                      </div>
                      <div className="form-group text-right">
                        <button className="btn btn-lg btn-primary">
                          Sauvegarder les modifications
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`card-inner card-inner-lg custom-tab-body ${tab !== "company" && "d-none"
                }`}
              id="company"
            >
              <div className="nk-block-head nk-block-head-lg">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h4 className="nk-block-title">
                      Informations sur la société
                    </h4>
                    <div className="nk-block-des">
                      <p>
                        Informations de base sur l'entreprise et préférences
                        système.
                      </p>
                    </div>
                  </div>
                  <div className="nk-block-head-content align-self-start d-lg-none">
                    <a
                      onClick={() => setShowPageMenu(!showPageMenu)}
                      className="toggle btn btn-icon btn-trigger mt-n1"
                      data-target="userAside"
                    >
                      <em className="icon ni ni-menu-alt-r"></em>
                    </a>
                  </div>
                </div>
              </div>
              <div className="nk-block card">
                <div className="row">
                  <div className="col-sm-8">
                    <form className="simcy-form">
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label">
                            Nom de l'entreprise
                          </label>
                        </div>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            placeholder="Nom de l'entreprise"
                            name="name"
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label">
                            Logo d'entreprise
                          </label>
                        </div>
                        <div className="form-control-wrap">
                          <input
                            type="file"
                            name="logo"
                            className="croppie"
                            accept="image/*"
                          />
                        </div>
                        <div className="form-note">
                          Votre logo sera affiché sur vos factures et devis.
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label">Adresse e-mail</label>
                        </div>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            placeholder="Adresse e-mail"
                            name="email"
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label">
                            Numéro de téléphone
                          </label>
                        </div>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control form-control-lg phone-input"
                            placeholder="Numéro de téléphone"
                            name="phone"
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label">Adresse</label>
                        </div>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            placeholder="Adresse"
                            name="address"
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label">Ville/Ville</label>
                        </div>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            placeholder="Ville/Ville"
                            name="city"
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label">Pays</label>
                        </div>
                        <div className="form-control-wrap">
                          <select
                            className="form-control form-control-lg select2"
                            name="country"
                          >
                            <option value="name">American</option>
                          </select>
                        </div>
                      </div>
                      <div className="nk-divider divider md"></div>
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label">Fuseau horaire</label>
                        </div>
                        <div className="form-control-wrap">
                          <select
                            className="form-control form-control-lg select2"
                            name="timezone"
                          >
                            <option value="zone">
                              (UTC-05:00) America/New_York
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label">Monnaie</label>
                        </div>
                        <div className="form-control-wrap">
                          <select
                            className="form-control form-control-lg select2"
                            name="currency"
                          >
                            <option value="code">
                              United States Dollar (USD)
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="nk-divider divider md"></div>
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label">BROCHE KRA</label>
                        </div>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            placeholder="BROCHE KRA"
                            name="kra_pin"
                          />
                        </div>
                        <div className="form-note">
                          Ceci imprimé sur les factures et les devis.
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label">
                            Taxe sur la valeur ajoutée (%)
                          </label>
                        </div>
                        <div className="form-control-wrap">
                          <input
                            type="number"
                            className="form-control form-control-lg"
                            placeholder="Taxe sur la valeur ajoutée (%)"
                            data-parsley-pattern="^[0-9]\d*(\.\d+)?$"
                            step="0.01"
                            min="0"
                            name="vat"
                            value="vat"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label">
                            Détails de paiement
                          </label>
                        </div>
                        <div className="form-control-wrap">
                          <textarea
                            className="form-control form-control-lg unset-mh"
                            placeholder="Mode de paiement"
                            rows={4}
                            name="payment_details"
                          ></textarea>
                        </div>
                        <div className="form-note">
                          Les détails de paiement seront imprimés sur les
                          factures.
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label">
                            Clause de non-responsabilité relative à la facture
                          </label>
                        </div>
                        <div className="form-control-wrap">
                          <textarea
                            className="form-control form-control-lg unset-mh"
                            placeholder="Clause de non-responsabilité relative à la facture"
                            rows={4}
                            name="invoice_disclaimer"
                          ></textarea>
                        </div>
                        <div className="form-note">
                          Les détails de paiement seront imprimés sur les
                          factures.
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label">
                            Citations Avis de non-responsabilité
                          </label>
                        </div>
                        <div className="form-control-wrap">
                          <textarea
                            className="form-control form-control-lg unset-mh"
                            placeholder="Citations Avis de non-responsabilité"
                            rows={4}
                            name="quote_disclaimer"
                          ></textarea>
                        </div>
                        <div className="form-note">
                          Les détails de paiement seront imprimés sur les devis.
                        </div>
                      </div>
                      <div className="nk-divider divider md"></div>
                      <div className="form-group">
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            name="sms_tasks"
                            id="sms_tasks"
                            className="custom-control-input"
                            value="Enabled"
                            defaultChecked
                          />

                          <label
                            className="custom-control-label"
                            htmlFor="sms_tasks"
                          >
                            Envoyez des SMS aux membres de l'équipe lorsque de
                            nouvelles tâches sont créées.
                          </label>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            name="insurance"
                            id="insurance-features"
                            className="custom-control-input"
                            value="Enabled"
                            defaultChecked
                          />

                          <label
                            className="custom-control-label"
                            htmlFor="insurance-features"
                          >
                            Réparer les réparations couvertes par l'assurance.
                          </label>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            name="parts_to_inventory"
                            id="parts-to-inventory"
                            className="custom-control-input"
                            value="Enabled"
                            defaultChecked
                          />

                          <label
                            className="custom-control-label"
                            htmlFor="parts-to-inventory"
                          >
                            Ajouter des pièces/dépenses de véhicule à
                            l'inventaire.
                          </label>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            name="forced_completion"
                            id="forced_completion"
                            className="custom-control-input"
                            value="Enabled"
                            defaultChecked
                          />

                          <label
                            className="custom-control-label"
                            htmlFor="forced_completion"
                          >
                            Les tâches du véhicule doivent être terminées avant
                            de marquer un véhicule comme terminé.
                          </label>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            name="forced_checkout"
                            id="forced_checkout"
                            className="custom-control-input"
                            value="Enabled"
                            defaultChecked
                          />

                          <label
                            className="custom-control-label"
                            htmlFor="forced_checkout"
                          >
                            Les véhicules doivent être marqués comme terminés
                            avant le départ.
                          </label>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            name="restricted_checkout"
                            id="restricted_checkout"
                            className="custom-control-input"
                            value="Enabled"
                            defaultChecked
                          />

                          <label
                            className="custom-control-label"
                            htmlFor="restricted_checkout"
                          >
                            Restreindre le départ jusqu'à ce que toutes les
                            pièces, dépenses, les créances et les débiteurs sont
                            compensés.
                          </label>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            name="invoice_signing"
                            id="invoice_signing"
                            className="custom-control-input"
                            value="Enabled"
                            defaultChecked
                          />

                          <label
                            className="custom-control-label"
                            htmlFor="invoice_signing"
                          >
                            Activez les signatures des clients sur les factures.
                          </label>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            name="quote_signing"
                            id="quote_signing"
                            className="custom-control-input"
                            value="Enabled"
                            defaultChecked
                          />

                          <label
                            className="custom-control-label"
                            htmlFor="quote_signing"
                          >
                            {" "}
                            Activez les signatures des clients sur les devis.
                          </label>
                        </div>
                      </div>
                      <div className="form-group text-right">
                        <button className="btn btn-lg btn-primary">
                          Sauvegarder les modifications
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`card-inner card-inner-lg custom-tab-body ${tab !== "booking" && "d-none"
                }`}
              id="booking"
            >
              <div className="nk-block-head nk-block-head-lg">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h4 className="nk-block-title">
                      Formulaire de reservation
                    </h4>
                    <div className="nk-block-des">
                      <p>
                        Gérez les réservations de votre entreprise à partir
                        d'ici.
                      </p>
                    </div>
                  </div>
                  <div className="nk-block-head-content align-self-start d-lg-none">
                    <a
                      onClick={() => setShowPageMenu(!showPageMenu)}
                      className="toggle btn btn-icon btn-trigger mt-n1"
                      data-target="userAside"
                    >
                      <em className="icon ni ni-menu-alt-r"></em>
                    </a>
                  </div>
                </div>
              </div>
              <div className="nk-block card">
                <div className="row">
                  <div className="col-sm-8">
                    <form
                      className="simcy-form"
                      action="{{ url('Settings@updatebooking')"
                    >
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label">
                            Formulaire de réservation de véhicule
                          </label>
                        </div>
                        <div className="form-control-wrap">
                          <select
                            className="form-control form-control-lg"
                            name="booking_form"
                          >
                            <option value="Basic">Basique</option>
                            <option value="Detailed">Détaillée</option>
                          </select>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            name="car_diagram"
                            id="car-diagram"
                            className="custom-control-input"
                            value="Enabled"
                            defaultChecked
                          />

                          <label
                            className="custom-control-label"
                            htmlFor="car-diagram"
                          >
                            Activer le diagramme de voiture pour marquer les
                            bosses et les rayures.
                          </label>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label">
                            Formulaire de réservation de véhicule Avis de
                            non-responsabilité
                          </label>
                        </div>
                        <div className="form-control-wrap">
                          <textarea
                            className="form-control form-control-lg unset-mh"
                            placeholder="Formulaire de réservation de véhicule Avis de non-responsabilité"
                            rows={5}
                            name="booking_disclaimer_in"
                          ></textarea>
                        </div>
                        <div className="form-note">
                          Cette clause de non-responsabilité sera imprimée au
                          bas du formulaire de réservation.
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label">
                            Avis de non-responsabilité du formulaire de
                            réservation de véhicule
                          </label>
                        </div>
                        <div className="form-control-wrap">
                          <textarea
                            className="form-control form-control-lg unset-mh"
                            placeholder="Avis de non-responsabilité du formulaire de réservation de véhicule"
                            rows={5}
                            name="booking_disclaimer_out"
                          ></textarea>
                        </div>
                        <div className="form-note">
                          Cette clause de non-responsabilité sera imprimée au
                          bas du formulaire de réservation.
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label">
                            Termes et conditions
                          </label>
                        </div>
                        <div className="form-control-wrap">
                          <textarea
                            className="form-control form-control-lg unset-mh"
                            placeholder="Termes et conditions"
                            rows={15}
                            name="booking_terms"
                          ></textarea>
                        </div>
                        <div className="form-note">
                          Cette clause de non-responsabilité sera imprimée au
                          bas du formulaire de réservation.
                        </div>
                      </div>
                      <div className="form-group text-right">
                        <button className="btn btn-lg btn-primary">
                          Sauvegarder les modifications
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`card-inner card-inner-lg custom-tab-body ${tab !== "system" && "d-none"
                }`}
              id="system"
            >
              <div className="nk-block-head nk-block-head-lg">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h4 className="nk-block-title">
                      Les paramètres du système
                    </h4>
                    <div className="nk-block-des">
                      <p>Gérer les paramètres système et les clés API.</p>
                    </div>
                  </div>
                  <div className="nk-block-head-content align-self-start d-lg-none">
                    <a
                      onClick={() => setShowPageMenu(!showPageMenu)}
                      className="toggle btn btn-icon btn-trigger mt-n1"
                      data-target="userAside"
                    >
                      <em className="icon ni ni-menu-alt-r"></em>
                    </a>
                  </div>
                </div>
              </div>
              <div className="nk-block card">
                <div className="row">
                  <div className="col-sm-8">
                    <form
                      className="simcy-form"
                      action="{{ url('Settings@updatesystem')"
                    >
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label" htmlFor="password">
                            Nom du système
                          </label>
                        </div>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            placeholder="Nom du système"
                            name="APP_NAME"
                            required
                          />
                        </div>
                      </div>
                      <div className="nk-divider divider md"></div>
                      <p>Les clés API parlantes de l'Afrique</p>
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label" htmlFor="password">
                            Nom d'utilisateur
                          </label>
                        </div>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            placeholder="Nom d'utilisateur"
                            name="AFRICASTALKING_USERNAME"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label" htmlFor="password">
                            clé API
                          </label>
                        </div>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            placeholder="clé API"
                            name="AFRICASTALKING_KEY"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label" htmlFor="password">
                            Identifiant du serveur
                          </label>
                        </div>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            placeholder="Identifiant du serveur"
                            name="AFRICASTALKING_SENDERID"
                          />
                        </div>
                      </div>
                      <div className="nk-divider divider md"></div>
                      <p>Paramètres SMTP</p>
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label" htmlFor="password">
                            Nom d'utilisateur
                          </label>
                        </div>
                        <div className="form-control-wrap">
                          <input
                            type="email"
                            className="form-control form-control-lg"
                            placeholder="Nom d'utilisateur"
                            name="MAIL_USERNAME"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label" htmlFor="password">
                            Envoyer un e-mail en tant que
                          </label>
                        </div>
                        <div className="form-control-wrap">
                          <input
                            type="email"
                            className="form-control form-control-lg"
                            placeholder="Envoyer un e-mail en tant que"
                            name="MAIL_SENDER"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label" htmlFor="password">
                            Hôtesse
                          </label>
                        </div>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            placeholder="Hôtesse"
                            name="SMTP_HOST"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label" htmlFor="password">
                            Mot de passe
                          </label>
                        </div>
                        <div className="form-control-wrap">
                          <input
                            type="password"
                            className="form-control form-control-lg"
                            placeholder="Mot de passe"
                            name="SMTP_PASSWORD"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label" htmlFor="password">
                            Port
                          </label>
                        </div>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            placeholder="Port"
                            name="SMTP_PORT"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label" htmlFor="password">
                            Chiffrement
                          </label>
                        </div>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            placeholder="Chiffrement"
                            name="MAIL_ENCRYPTION"
                          />
                        </div>
                      </div>
                      <div className="form-group text-right">
                        <button className="btn btn-lg btn-primary">
                          Sauvegarder les modifications
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`card-inner card-inner-lg custom-tab-body ${tab !== "security" && "d-none"
                }`}
              id="security"
            >
              <div className="nk-block-head nk-block-head-lg">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h4 className="nk-block-title">
                      Les paramètres de sécurité
                    </h4>
                    <div className="nk-block-des">
                      <p>
                        Définissez un mot de passe unique pour protéger votre
                        compte.
                      </p>
                    </div>
                  </div>
                  <div className="nk-block-head-content align-self-start d-lg-none">
                    <a
                      onClick={() => setShowPageMenu(!showPageMenu)}
                      className="toggle btn btn-icon btn-trigger mt-n1"
                      data-target="userAside"
                    >
                      <em className="icon ni ni-menu-alt-r"></em>
                    </a>
                  </div>
                </div>
              </div>
              <div className="nk-block card">
                <div className="row">
                  <div className="col-sm-8">
                    <form
                      className="simcy-form"
                      onSubmit={changePassword}
                    >
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label" htmlFor="password">
                            Mot de passe actuel
                          </label>
                        </div>
                        <div className="form-control-wrap">
                          <a
                            href="#"
                            className="form-icon form-icon-right passcode-switch"
                            data-target="current"
                          >
                            <em className="passcode-icon icon-show icon ni ni-eye"></em>
                            <em className="passcode-icon icon-hide icon ni ni-eye-off"></em>
                          </a>
                          <input
                            type="password"
                            className="form-control form-control-lg"
                            id="current"
                            name="current"
                            placeholder="Entrer votre mot de passe actuel"
                            required
                            onChange={e => setPassword(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label" htmlFor="password">
                            Nouveau mot de passe
                          </label>
                        </div>
                        <div className="form-control-wrap">
                        <a
                            onClick={changePasswordType2}
                            className="form-icon form-icon-right passcode-switch"
                            data-target="current"
                          >
                            {passwordType2 === "password" ? <em className="passcode-icon icon-show icon ni ni-eye"></em> :
                              <em className="passcode-icon icon-show icon ni ni-eye-off"></em>
                            }
                          </a>
                          <input
                            type={passwordType2}
                            className="form-control form-control-lg"
                            minLength={6}
                            id="password"
                            name="password"
                            placeholder="Entrez votre nouveau mot de passe"
                            required
                            onChange={e => setNewPassword(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label" htmlFor="password">
                            Confirmer le nouveau mot de passe
                          </label>
                        </div>
                        <div className="form-control-wrap">
                          <a
                            onClick={changePasswordType}
                            className="form-icon form-icon-right passcode-switch"
                            data-target="current"
                          >
                            {passwordType === "password" ? <em className="passcode-icon icon-show icon ni ni-eye"></em> :
                              <em className="passcode-icon icon-show icon ni ni-eye-off"></em>
                            }
                          </a>
                            <input
                              type={passwordType}
                              className="form-control form-control-lg"
                              id="confirm"
                              name="password"
                              placeholder="Confirmer le nouveau mot de passe"
                              onChange={e => setNewPassword2(e.target.value)}
                            />
                        </div>
                      </div>
                      <div className="form-group text-right">
                        <button
                          className="btn btn-lg btn-primary"
                          type="submit"
                        >
                          Changer le mot de passe
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div
              ref={divFormRef}
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${showPageMenu && "content-active"
                }`}
            >
              <div className="card-inner-group">
                <div className="card-inner">
                  <div className="user-card">
                    <div className="user-avatar bg-primary">
                      <span>JD</span>
                    </div>
                    <div className="user-info">
                      <span className="lead-text">Jane Doe</span>
                      <span className="sub-text">+254720783834</span>
                    </div>
                  </div>
                </div>
                <div className="card-inner p-0">
                  <ul className="link-list-menu custom-tab">
                    <li>
                      <a
                        className={`custom-tab-trigger cursor ${tab == "personal" && "active"
                          }`}
                        onClick={() => setTab("personal")}
                      >
                        <em className="icon ni ni-user-fill-c"></em>
                        <span>Renseignements personnels</span>
                      </a>
                    </li>

                    <li>
                      <a
                        className={`custom-tab-trigger cursor ${tab == "company" && "active"
                          }`}
                        onClick={() => setTab("company")}
                      >
                        <em className="icon ni ni-building-fill"></em>
                        <span>Informations sur la société</span>
                      </a>
                    </li>
                    <li>
                      <a
                        className={`custom-tab-trigger cursor ${tab == "booking" && "active"
                          }`}
                        onClick={() => setTab("booking")}
                      >
                        <em className="icon ni ni-calendar-fill"></em>
                        <span>Formulaire de reservation</span>
                      </a>
                    </li>
                    <li>
                      <Link className="" to="/settings/bookingparts">
                        <em className="icon ni ni-check-circle-fill"></em>
                        <span>Booking Parts List</span>
                      </Link>
                    </li>
                    <li>
                      <a
                        className={`custom-tab-trigger cursor ${tab == "system" && "active"
                          }`}
                        onClick={() => setTab("system")}
                      >
                        <em className="icon ni ni-activity-round-fill"></em>
                        <span>Les paramètres du système</span>
                      </a>
                    </li>

                    <li>
                      <a
                        className={`custom-tab-trigger cursor ${tab == "security" && "active"
                          }`}
                        onClick={() => setTab("security")}
                      >
                        <em className="icon ni ni-lock-alt-fill"></em>
                        <span>Les paramètres de sécurité</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {showPageMenu && (
              <div className="toggle-overlay" data-target="userAside" />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
