import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { network } from "./network";


export const hooks = {
  useGeTAllProject() {
    const reponse = useQuery(['allProjects'], network.getAllProject);

    return reponse;
  },
  useCreateProject() {

    const queryClient = useQueryClient();
    const reponse = useMutation(network.createProjet, {
      onSuccess: () => {
        queryClient.invalidateQueries(['projectPage'])
      },
    });

    return reponse;
  },
  useGetProjectById(idProject: string) {
    const reponse = useQuery(['project', idProject], () => network.getProjetById(idProject));

    return reponse;
  },
  useUpdateProject() {
    const queryClient = useQueryClient();
    const reponse = useMutation(network.updateProjet, {
      onSuccess: ()=>{
        (queryClient.invalidateQueries(['projectPage']))
      }
    });
    return reponse;
  },
  useGetProjectPaginate(page: number) {
    const reponse = useQuery(['projectPage', page], () => network.getProjectPaginate(page), { keepPreviousData: true });

    return reponse;
  },
  usePrefetchProject(page: number) {
    const queryClient = useQueryClient();

    return queryClient.prefetchQuery(['projectPage', page], () => network.getProjectPaginate(page))
  },
  useDeleteProject() {
    const queryClient = useQueryClient();

    const reponse = useMutation(network.deleteProjet, {
      onSuccess: () => {
        queryClient.invalidateQueries(['projectPage']);
      }
    })

    return reponse;
  },
  useUploadCarteGrise() {

    const queryClient = useQueryClient();
    const reponse = useMutation(network.uploadCarteGrise, {
      onSuccess: () => {
        queryClient.invalidateQueries(['projectPage'])
      },
    });

    return reponse;
  },
}