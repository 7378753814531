import axios from "axios";
import { useNavigate } from "react-router-dom";

const token = localStorage.getItem('token')


const api = {
  withoutToken: axios.create({
    baseURL: process.env.REACT_APP_BACK,
    headers: {
      "Content-type": "application/json"
    }
  }),
  withToken: axios.create({
    baseURL: process.env.REACT_APP_BACK,
    headers: {
      "Content-type": "application/json",
      "Authorization": `Bearer ${token}`
    }
  })
  
}

//Redirige vers la page de connexion en cas de déconnexion
api.withToken.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('token');
      const navigate = useNavigate();
      navigate('/'); //redirection
    }
    return Promise.reject(error);
  }
);



export default api;
