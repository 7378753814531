import React, { useState } from "react";
import { Modal } from "react-bootstrap";
interface UpdateExpensesProps {
  show: boolean;
  onHide: () => void;
}

export function UpdateExpenses({ show, onHide }: UpdateExpensesProps) {
  const [showInventory, setShowInventory] = useState(false);
  const [showCreateSupplier, setShowCreateSupplier] = useState(false);
  const [showExpectedDelivery, setShowExpectedDelivery] = useState(false);
  const [showPaymentDueOn, setShowPaymentDueOn] = useState(false);
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Manage Info</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Update a project expense</p>
        <div className="row gy-4">
          <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">Source</label>
              <div className="form-control-wrap ">
                <div className="form-control-select">
                  <select
                    className="form-control form-control-lg source-select"
                    name="source"
                    onChange={(e) =>
                      e.target.value === "Inventory"
                        ? setShowInventory(true)
                        : setShowInventory(false)
                    }
                  >
                    <option value="Suppliers">External Suppliers</option>
                    <option value="Inventory">From Inventory</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showInventory ? (
          <div className="row inventory-source gy-4">
            <div className="col-sm-12">
              <div className="form-group">
                <label className="form-label">Item / Part</label>
                <div className="form-control-wrap">
                  <div className="form-control-select">
                    <select
                      className="form-control select2 inventory-select"
                      name="inventory"
                    >
                      <option value="">Select Item</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group">
                <label className="form-label">Units Consumed</label>
                <div className="form-control-wrap">
                  <div className="form-text-hint">
                    <span className="overline-title units">Units</span>
                  </div>
                  <input
                    type="number"
                    className="form-control form-control-lg expense-consumed"
                    placeholder="Units Consumed"
                    name="consumed"
                    defaultValue={0.0}
                    step="0.01"
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label">Amount</label>
                <div className="form-control-wrap">
                  <div className="form-text-hint">
                    <span className="overline-title">$</span>
                  </div>
                  <input
                    type="number"
                    className="form-control form-control-lg expense-total-amount"
                    placeholder="Amount"
                    name="totalamount"
                    defaultValue={0.0}
                    step="0.01"
                    required
                    readOnly
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label">Expense Date</label>
                <div className="form-control-wrap">
                  <input
                    type="date"
                    className="form-control form-control-lg"
                    placeholder="Expense Date"
                    name="expensedate"
                    required
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="row external-source gy-4">
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label">Expense / Item name</label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    placeholder="Expense / Item name"
                    name="expense"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label">Supplier</label>
                <div className="form-control-wrap">
                  <div className="form-control-select">
                    <select
                      className="form-control select2 supplier-select"
                      name="supplier"
                      onChange={(e) =>
                        e.target.value === "create"
                          ? setShowCreateSupplier(true)
                          : setShowCreateSupplier(false)
                      }
                    >
                      <option value="">Select Supplier</option>
                      <optgroup label="New Supplier">
                        <option value="create" className="fw-bold">
                          Create New Supplier
                        </option>
                      </optgroup>
                      <optgroup label="Select from suppliers">
                        <option value="id">test</option>
                      </optgroup>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            {showCreateSupplier && (
              <>
                <div className="col-sm-6 new-supplier-input">
                  <div className="form-group">
                    <label className="form-label">Supplier Name</label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="Supplier Name"
                        name="suppliername"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 new-supplier-input">
                  <div className="form-group">
                    <label className="form-label">Phone Number</label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control form-control-lg phone-input"
                        placeholder="Phone Number"
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label">Quantity</label>
                <div className="form-control-wrap">
                  <input
                    type="number"
                    className="form-control form-control-lg"
                    placeholder="Quantity"
                    name="quantity"
                    defaultValue={1}
                    step="0.01"
                    min="0.01"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label">Quantity Unit</label>
                <div className="form-control-wrap">
                  <select
                    className="form-control form-control-lg"
                    name="quantity_unit"
                  >
                    <option value="Pieces">Pieces</option>
                    <option value="Units">Units</option>
                    <option value="Litres">Litres</option>
                    <option value="Kilograms">Kilograms</option>
                    <option value="Pounds">Pounds</option>
                    <option value="Gallons">Gallons</option>
                    <option value="Meters">Meters</option>
                    <option value="Set">Set</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label">Total Amount</label>
                <div className="form-control-wrap">
                  <div className="form-text-hint">
                    <span className="overline-title">$</span>
                  </div>
                  <input
                    type="number"
                    className="form-control form-control-lg"
                    placeholder="Total Amount"
                    name="amount"
                    defaultValue={0.0}
                    step="0.01"
                    min={0}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label">Expense Date</label>
                <div className="form-control-wrap">
                  <input
                    type="date"
                    className="form-control form-control-lg"
                    placeholder="Expense Date"
                    name="expense_date"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label">Type</label>
                <div className="form-control-wrap ">
                  <div className="form-control-select">
                    <select
                      className="form-control form-control-lg"
                      name="type"
                    >
                      <option value="Part">Part</option>
                      <option value="Service">Service</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label">Status</label>
                <div className="form-control-wrap ">
                  <div className="form-control-select">
                    <select
                      className="form-control form-control-lg expense-status"
                      name="status"
                      onChange={(e) =>
                        e.target.value === "Ordered" ||
                        e.target.value === "Awaiting Delivery"
                          ? setShowExpectedDelivery(true)
                          : setShowExpectedDelivery(false)
                      }
                    >
                      <option value="Delivered">Delivered</option>
                      <option value="Ordered">Ordered</option>
                      <option value="Awaiting Delivery">
                        Awaiting Delivery
                      </option>
                      <option value="To Order">To Order</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            {showExpectedDelivery && (
              <>
                <div className="col-sm-6 expense-delivery">
                  <div className="form-group">
                    <label className="form-label">Expected Delivery Date</label>
                    <div className="form-control-wrap">
                      <input
                        type="date"
                        className="form-control form-control-lg"
                        placeholder="Expected Delivery Date"
                        name="expected_delivery_date"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 expense-delivery">
                  <div className="form-group">
                    <label className="form-label">Expected Delivery Time</label>
                    <div className="form-control-wrap">
                      <input
                        type="time"
                        className="form-control form-control-lg"
                        placeholder="Expected Delivery Time"
                        name="expected_delivery_time"
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="col-sm-12">
              <div className="form-group">
                <div className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    name="paid"
                    id="expense-paid"
                    className="custom-control-input expense-paid"
                    defaultValue="Yes"
                    defaultChecked
                    onChange={(e) => setShowPaymentDueOn(!e.target.checked)}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="expense-paid"
                  >
                    Expense / Item supplier paid
                  </label>
                </div>
              </div>
            </div>
            {showPaymentDueOn && (
              <div className="col-sm-12 expense-payment">
                <div className="form-group">
                  <label className="form-label">Payment due on</label>
                  <div className="form-control-wrap">
                    <input
                      type="date"
                      className="form-control form-control-lg"
                      placeholder="Payment due on"
                      name="payment_due"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className=" bg-light">
        <button
          className="btn btn-white btn-dim btn-outline-light"
          type="button"
          onClick={onHide}
        >
          <em className="icon ni ni-cross-circle"></em>
          <span>Cancel</span>
        </button>
        <button className="btn btn-primary" type="submit">
          <em className="icon ni ni-check-circle-cut"></em>
          <span>Save Expense</span>
        </button>
      </Modal.Footer>
    </Modal>
  );
}
