import React from "react";
import { Modal } from "react-bootstrap";
interface SelectJobCardProps {
  show: boolean;
  onHide: () => void;
  onSelectJobCard: () => void;
}

export function SelectJobCard({
  show,
  onHide,
  onSelectJobCard,
}: SelectJobCardProps) {
  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Select Jobcard</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Select a jobcard to import from.</p>
        <input type="hidden" name="jobcardid" />
        <div className="bq-note">
          <div className="bq-note-item">
            <div className="bq-note-text">
              <h6 className="title">Body Report</h6>
              <ol className="styled-list">
                <li>Test 1</li>
              </ol>
              <div className="nk-divider divider md" />
              <h6 className="title">Mechanical Report</h6>
              <ol className="styled-list">
                <li>Test 2</li>
                <li>Test 3</li>
              </ol>
              <div className="nk-divider divider md" />
              <h6 className="title">Electrical Report</h6>
              <ol className="styled-list">
                <li>Test 4</li>
              </ol>
              <div className="nk-divider divider md" />
              <button
                className="btn btn-dim btn-outline-primary select-jobcard"
                onClick={onSelectJobCard}
              >
                <em className="icon ni ni-check-circle-cut" />
                <span>Select Jobcard</span>
              </button>
            </div>
          </div>

          <div className="nk-divider divider md" />

          {/* <div className="empty text-center text-muted">
            <em className="icon ni ni-info" />
            <p>No job card created yet!</p>
          </div> */}
        </div>
      </Modal.Body>
      <Modal.Footer className=" bg-light">
        <button
          className="btn btn-white btn-dim btn-outline-light"
          type="button"
          onClick={onHide}
        >
          <em className="icon ni ni-cross-circle"></em>
          <span>Cancel</span>
        </button>
      </Modal.Footer>
    </Modal>
  );
}
