import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { taskGroupHooks } from "../../network/task-group";
import Swal from "sweetalert2";


interface CreateTaskGroupProps {
  show: boolean;
  onHide: () => void;
}


export function CreateTaskGroup({ show, onHide }: CreateTaskGroupProps) {
  const [titre, setTitre] = useState<string>('');
  const [errorTitre, setErrorTitre] = useState<boolean>(false);
  const [classTitle, setClassTitle] = useState<string>('form-control form-control-lg');

  const { mutate } = taskGroupHooks.useCreateTaskGroup();

  const checkEmptyTitle = () => {
    if (titre.trim().length === 0) {
      setErrorTitre(true)
      setClassTitle('form-control form-control-lg is-invalid')
      return true
    } else {
      setErrorTitre(false)
      setClassTitle('form-control form-control-lg')
      return false
    }
  }

  const createTaskGroup = () => {

    const taskGroup = {
      taskGroupLabel: titre,
    }


    if (checkEmptyTitle()) {
      checkEmptyTitle()
      Swal.fire({
        icon: 'error',
        text: 'Veuillez remplir tous les champs',
      });
      return;
    }

    return mutate(taskGroup, {
      onSuccess: async (data) => {
        onHide()
        Swal.fire({
          icon: 'success',
          text: "Création du groupe avec succès",
        });
      },
      onError: (error) => {
        onHide()
        Swal.fire({
          icon: 'error',
          text: 'Erreur lors de l\'ajout nouveau groupe',
        });
      },
      onSettled: async (data) => {
        console.log('tonga ato oooo')
      },
    })
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Création d'étape de tâche</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row gy-4">
          <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">Label de l'étape</label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  className={classTitle}
                  placeholder="Label"
                  name="title"
                  required
                  onChange={e => setTitre(e.target.value)}
                  onBlur={checkEmptyTitle}
                />
              </div>
              {errorTitre && <div className="text-danger">Valeur obligatoire</div>}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className=" bg-light">
        <button
          className="btn btn-white btn-dim btn-outline-light"
          type="button"
          onClick={onHide}
        >
          <em className="icon ni ni-cross-circle"></em>
          <span>Annuler</span>
        </button>
        <button className="btn btn-primary" type="submit" onClick={createTaskGroup}>
          <em className="icon ni ni-check-circle-cut"></em>
          <span>Créer le groupe</span>
        </button>
      </Modal.Footer>
    </Modal>
  );
}
