import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { CreateInvoices, SendEmail, UpdateInvoices } from "../../Modal";
import TableauFacture from "../../components/Tableau/TableauFacture";
import { factureHooks, tacheHooks } from "../../network";
import Loading from "../../components/Loading";

export default function Invoices() {
  const [showCreate, setShowCreate] = useState(false);
  const [showPageMenu, setShowPageMenu] = useState(false);
  const [showSendEmail, setShowSendEmail] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [pageActive, setPageActive] = useState<number>(1);
  const { data, isLoading } = factureHooks.useGetFacturePaginate(pageActive);

  console.log(data, 'ato ary ilay data alaina ooo')
  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "This invoice will be deleted permanently.",
      confirmButtonText: "Yes, delete!",
      cancelButtonText: `Cancel`,
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: "#fff",
    }).then((result) => {
      if (result.isConfirmed) {
        ///When click Yes
      }
    });
  };


  const nextPage = () => {

    if (pageActive < data.data.countPage) {
      setPageActive(pageActive + 1);
    } else {
      setPageActive(pageActive);
    }
  }

  const prevPage = () => {
    if (pageActive > 1) {
      setPageActive(pageActive - 1);
    } else {
      setPageActive(pageActive);
    }
  }

  const goToPage = (numPage: number) => {
    setPageActive(numPage);
  }

  useEffect(() => {
    if (data?.hasMore) {
      factureHooks.usePrefetchFacture(pageActive)
    }
  }, [data, pageActive])

  if (isLoading){
    return (<div className="loading__wrapper">
    <Loading />
  </div>)
  }

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">Factures</h3>
            {isLoading ? <Loading /> : <div className="nk-block-des text-soft">
              <p>Un total de {data.data.countTotal} factures.</p>

              {/* <p>Créez et gérez les factures de vos clients ici.</p> */}
            </div>}
          </div>
          <div className="nk-block-head-content">
            <div className="toggle-wrap nk-block-tools-toggle">
              <a
                onClick={() => setShowPageMenu(!showPageMenu)}
                className="btn btn-icon btn-trigger toggle-expand mr-n1"
                data-target="pageMenu"
              >
                <em className="icon ni ni-menu-alt-r"></em>
              </a>
              <div
                className={`toggle-expand-content ${showPageMenu && "d-block"}`}
                data-content="pageMenu"
              >
                <ul className="nk-block-tools g-3">
                  <li>
                    <button
                      onClick={() => setShowCreate(true)}
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#create"
                    >
                      <em className="icon ni ni-plus"></em>
                      <span>Créer une facture</span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading ? <Loading /> : <TableauFacture data={data.data} nextPage={nextPage} prevPage={prevPage} goToPage={goToPage} />}
      <CreateInvoices show={showCreate} onHide={() => setShowCreate(false)} />
      <SendEmail show={showSendEmail} onHide={() => setShowSendEmail(false)} />
      <UpdateInvoices show={showUpdate} onHide={() => setShowUpdate(false)} />
    </>
  );
}
