
import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { UpdateTask, ViewTask } from "../../../Modal";
import { Pagination } from "react-bootstrap";
import { tacheHooks } from '../../../network/tache';
import { tacheEntityPaginationType, tacheEntityType } from "../../../types";

type Props = {
  data: tacheEntityPaginationType
  nextPage: Function
  prevPage: Function
  goToPage: Function
}


export default function TableauAllTask({ data, nextPage, prevPage, goToPage }: Props) {
  const [showView, setShowView] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [tache, setTache] = useState<tacheEntityType>({
    idTache: 0,
    titreTache: '',
    status: 'A faire',
    heureEcheance: '',
    description: '',
  });
  const [activePage, setActivePage] = useState<number>(1);
  const { mutate } = tacheHooks.useDeleteTask();

  const handleDelete = (id: number) => {
    Swal.fire({
      title: "Are you sure?",
      text: "This task will be deleted permanently.",
      confirmButtonText: "Oui, supprimer",
      cancelButtonText: `Retour`,
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: "#fff",
    }).then((result) => {
      if (result.isConfirmed) {
        return mutate({ id: id }, {
          onSuccess: async (data) => {
          },
          onError: (error) => {
            Swal.fire({
              icon: 'error',
              text: 'Erreur lors de la suppression du tâche',
            });
          },
        })
      }
    });
  };
  const handleCancel = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "This task will be marked as cancelled.",
      confirmButtonText: "Yes, Cancel!",
      cancelButtonText: `Cancel`,
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: "#fff",
    }).then((result) => {
      if (result.isConfirmed) {
        ///When click Yes
      }
    });
  };

  const goToNextPage = () => {
    if (activePage < data.countPage) {
      setActivePage(activePage + 1);
    } else {
      setActivePage(activePage)
    }
    nextPage();
  }

  const goToPrevPage = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1)
    } else {
      setActivePage(activePage)
    }
    prevPage()
  }

  const goToPageOne = (items: number) => {
    setActivePage(items)
    goToPage(items)
  }

  const sendData = (item: tacheEntityType) => {
    setShowView(true)
    setTache(item)
  }
  return (
    <>
      <div className="nk-block">
        <div className="card card-stretch">
          <div className="card-inner d-flex flex-column align-items-end">
            <table className="datatable-init nk-tb-list nk-tb-ulist mt">
              <thead>
                <tr className="nk-tb-item nk-tb-head">
                  <th className="nk-tb-col text-center">#</th>
                  <th className="nk-tb-col tb-col-md">
                    <span className="sub-text">Voiture</span>
                  </th>
                  <th className="nk-tb-col tb-col-md">
                    <span className="sub-text">Client</span>
                  </th>
                  <th className="nk-tb-col">
                    <span className="sub-text">Titre de la tâche</span>
                  </th>
                  <th className="nk-tb-col tb-col-md">
                    <span className="sub-text">Date d'échéance</span>
                  </th>
                  <th className="nk-tb-col tb-col-md">
                    <span className="sub-text">Coût</span>
                  </th>
                  <th className="nk-tb-col tb-col-md">
                    <span className="sub-text">Statut d'avancement</span>
                  </th>
                  <th className="nk-tb-col tb-col-md">
                    <span className="sub-text">Assigné à</span>
                  </th>
                  <th className="nk-tb-col nk-tb-col-tools text-right"></th>
                </tr>
              </thead>
              {data.list.map((item) => (item && item.projets) ? (
                <tbody>
                <tr className="nk-tb-item">
                  <td className="nk-tb-col text-center">{item.idTache}</td>
                  <td className="nk-tb-col tb-col-md">
                    <div className="user-card">
                      <div className="user-info">
                        <span className="tb-lead">{item.projets.vehicules !== null ?
                          item.projets.vehicules.detailVehicules.marque : ''}</span>
                      </div>
                    </div>
                  </td>
                  <td className="nk-tb-col">
                    <span className="tb-amount">{item.projets.vehicules !== null ?
                      item.projets.vehicules.clients.nomPrenom : ''}</span>
                  </td>
                  <td className="nk-tb-col">
                    <span className="tb-amount">
                      <span
                        data-toggle="tooltip"
                        title="No parts required"
                        className="text-light"
                      />
                      <span
                        data-toggle="tooltip"
                        title="required parts have not been delivered"
                        className="text-warning"
                      />
                      <span
                        data-toggle="tooltip"
                        title="All required parts have been delivered"
                        className="text-success"
                      >
                        <em className="icon ni ni-info-fill"></em>
                      </span>{" "}
                      {item.titreTache}
                    </span>
                  </td>
                  <td className="nk-tb-col tb-col-md">

                    <span>{item?.dateEcheance?.toString()} • {item.heureEcheance}</span>
                  </td>
                  <td className="nk-tb-col tb-col-md">
                    <span className="tb-amount">{item.coutTache} €</span>
                  </td>
                  <td className="nk-tb-col tb-col-md">
                    {item.status === 'Complété' && <span className="badge badge-sm badge-dot has-bg badge-success d-mb-inline-flex">
                      Complété
                    </span>}

                    {item.status === 'Annulé' && <span className="badge badge-sm badge-dot has-bg badge-secondary d-mb-inline-flex">
                      Annulé
                    </span>}
                    {item.status === 'En cours' && <span className="badge badge-sm badge-dot has-bg badge-warning d-mb-inline-flex">
                      En cours
                    </span>}
                    {item.status === 'A faire' && <span className="badge badge-sm badge-dot has-bg badge-warning d-mb-inline-flex">
                      A faire
                    </span>}
                  </td>
                  <td className="nk-tb-col tb-col-md">
                    {item.membres? <span className="tb-amount">{item.membres?.nom}</span> : <span className="tb-amount"></span>}
                  </td>
                  <td className="nk-tb-col nk-tb-col-tools">
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <Dropdown>
                          <Dropdown.Toggle
                            as="a"
                            className="btn btn-icon btn-trigger"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <a
                                  className="fetch-display-click cursor"
                                  onClick={() => sendData(item)}
                                >
                                  <em className="icon ni ni-eye"></em>
                                  <span>Voir les détails</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  className="fetch-display-click cursor"
                                  onClick={() => setShowUpdate(true)}
                                >
                                  <em className="icon ni ni-pen"></em>
                                  <span>Modifier les détails</span>
                                </a>
                              </li>
                              <li>
                                <Link to="/vehicule/details">
                                  <em className="icon ni ni-eye"></em>
                                  <span>Voir la voiture</span>
                                </Link>
                              </li>

                              <li>
                                <a
                                  className="send-to-server-click cursor"
                                  onClick={handleCancel}
                                >
                                  <em className="icon ni ni-na"></em>
                                  <span>Annuler la tâche</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  className="send-to-server-click cursor"
                                  onClick={() => handleDelete(item.idTache)}
                                >
                                  <em className="icon ni ni-trash"></em>
                                  <span>Supprimer la tache</span>
                                </a>
                              </li>
                            </ul>
                          </Dropdown.Menu>
                        </Dropdown>
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
              ) : null
              
            )}
            </table>
            <Pagination className="nk-block-end">
              <Pagination.First onClick={() => goToPageOne(1)} />
              <Pagination.Prev onClick={goToPrevPage} />
              {
                Array.from(
                  { length: data.countPage }, (v, k) => k + 1
                ).map(items => <Pagination.Item key={items} active={items === activePage} onClick={() => goToPageOne(items)}>{items}</Pagination.Item>)
              }
              <Pagination.Next onClick={goToNextPage} />
              <Pagination.Last onClick={() => goToPageOne(data.countPage)} />
            </Pagination>
          </div>
        </div>
      </div>
      <ViewTask show={showView} onHide={() => setShowView(false)} data={tache} />
      <UpdateTask show={showUpdate} onHide={() => setShowUpdate(false)} />
    </>
  );
}
