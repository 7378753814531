import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { network } from "./network";


export const hooks = {
  useGetAllTaskGroups() {
    const reponse = useQuery(['allTaskGroups'], network.getAllTaskGroups);

    return reponse;
  },
  useGetTaskGroupById(taskId: string) {
    const reponse = useQuery(['taskGroup', taskId], () => network.getTaskGroupById(taskId));

    return reponse;
  },
  useCreateTaskGroup() {
    const queryClient = useQueryClient()
    const reponse = useMutation(network.createTaskGroup, {
      onSuccess: () => {
        queryClient.invalidateQueries(['taskGroupPaginate'])
      }
    });

    return reponse;
  },
  useGetTaskGroupPaginated(page: number) {
    const reponse = useQuery(['taskGroupPaginate', page], () => network.getTaskGroupPaginated(page), { keepPreviousData: true });

    return reponse;
  },
  usePrefetchTaskGroup(page: number) {
    const queryClient = useQueryClient();

    return queryClient.prefetchQuery(['taskGroupPaginate', page], () => network.getTaskGroupPaginated(page))
  },

  useUpdateTaskGroup() {
    const queryClient = useQueryClient()
    const reponse = useMutation(network.updateTaskGroup, {
      onSuccess: () => {
        queryClient.invalidateQueries(['taskGroupPaginate'])
      }
    });

    return reponse;
  },

  useDeleteTaskGroup() {
    const queryClient = useQueryClient();

    const reponse = useMutation(network.deleteTaskGroup, {
      onSuccess: () => {
        queryClient.invalidateQueries(['taskGroupPaginate'])
      }
    });

    return reponse;
  }
} 