import api from "../../axios";

export const network = {
  async getFactureClient(id: string) {
    const { data } = await api.withToken.get('/facture-client/' + id);

    return data;
  },
  async getAllFacture() {
    const { data } = await api.withToken.get('/facture');

    return data;
  },
  async getFacturePaginate(page: number) {
    const { data } = await api.withToken.get('/facture/page/5/' + page);

    return data;
  },
  async addFacture(facture: Object) {
    const { data } = await api.withToken.post('/facture', facture);

    return data;
  },
  async deleteFacture(donne: { id: number }) {
    const { data } = await api.withToken.delete('/facture/' + donne.id);

    return data;
  }
}