import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { UpdatePayment } from "../../../Modal";

import { Pagination } from "react-bootstrap";
import { paiementHooks } from '../../../network/paiement';

type Props = {
  data: {
    list: [],
    countPage: number,
  }
  nextPage: Function
  prevPage: Function
  goToPage: Function
}

export default function TableauAllPaiement({ data, nextPage, prevPage, goToPage }: Props) {
  const [showAdd, setShowAdd] = useState(false);
  const [showPageMenu, setShowPageMenu] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const { mutate } = paiementHooks.useDeletePaiement();
  const [paiementData, setPaiementData] = useState<{
    idPayement: number, montant: number, modePayement: string,
    datePaiement: string, note: string
  }>({
    idPayement: 0,
    montant: 0,
    modePayement: '',
    datePaiement: '',
    note: '',
  });
  const [activePage, setActivePage] = useState<number>(1);
  const handleDelete = (id: number) => {
    Swal.fire({
      title: "Être vous sure?",
      text: "Ce paiement sera supprimer definitivement",
      confirmButtonText: "Oui supprimer",
      cancelButtonText: `Retour`,
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: "#fff",
    }).then((result) => {
      if (result.isConfirmed) {
        return mutate({ id: id }, {
          onSuccess: async (data) => {
          },
          onError: (error) => {
            Swal.fire({
              icon: 'error',
              text: 'Erreur lors de la suppression de l\'inventaire',
            });
          },
        })
      }
    });
  };

  interface Paiement {
    idPayement: number,
    montant: number,
    satuts: boolean,
    modePayement: string,
    datePaiement: string,
    note: string,
    factures: {
      projets: {
        vehicules: {
          clients: {
            nomPrenom: string,
            telephone: string
          },
          detailVehicules: {
            model: string,
            marque: string,
          }
        }
      },
      idFacture: number,
    }
  }

  const updatePayement = (dataPayement: Paiement) => {
    setPaiementData(dataPayement)
    setShowUpdate(true);
  }

  const goToNextPage = () => {
    if (activePage < data.countPage) {
      setActivePage(activePage + 1);
    } else {
      setActivePage(activePage)
    }
    nextPage();
  }

  const goToPrevPage = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1)
    } else {
      setActivePage(activePage)
    }
    prevPage()
  }

  const goToPageOne = (items: number) => {
    setActivePage(items)
    goToPage(items)
  }
  return (
    <>
      <div className="d-flex flex-column align-items-end">
        <table
          className="datatable-init nk-tb-list nk-tb-ulist"
          data-auto-responsive="false"
        >
          <thead>
            <tr className="nk-tb-item nk-tb-head">
              <th className="nk-tb-col text-center">#</th>
              <th className="nk-tb-col">
                <span className="sub-text">Client</span>
              </th>
              <th className="nk-tb-col tb-col-md">
                <span className="sub-text">Projet</span>
              </th>
              <th className="nk-tb-col tb-col-md">
                <span className="sub-text">Facture</span>
              </th>
              <th className="nk-tb-col tb-col-md">
                <span className="sub-text">Date d'échéance</span>
              </th>
              <th className="nk-tb-col tb-col-md">
                <span className="sub-text">Solde total</span>
              </th>
              <th className="nk-tb-col tb-col-md">
                <span className="sub-text">Statut</span>
              </th>
              <th className="nk-tb-col nk-tb-col-tools text-right"></th>
            </tr>
          </thead>
          {data.list.map((item: Paiement) => (<tbody>
            <tr className="nk-tb-item">
              <td className="nk-tb-col text-center">{item.idPayement}</td>
              <td className="nk-tb-col">
                <div className="user-card">
                  <div className="user-avatar bg-dim-primary d-none d-sm-flex">
                    <span>te</span>
                  </div>
                  <div className="user-info">
                    <span className="tb-lead">{item.factures.projets.vehicules.clients.nomPrenom}</span>
                    <span>{item.factures.projets.vehicules.clients.telephone}</span>
                  </div>
                </div>
              </td>
              <td className="nk-tb-col tb-col-md">
                <div className="user-card">
                  <div className="user-info">
                    <span className="tb-lead">{item.factures.projets.vehicules.detailVehicules.marque}</span>
                    <span>{item.factures.projets.vehicules.detailVehicules.model}</span>
                  </div>
                </div>
              </td>
              <td className="nk-tb-col tb-col-md">
                <span className="tb-amount">Facture d'achat #{item.factures.idFacture}</span>
              </td>
              <td className="nk-tb-col tb-col-md">
                <span>{item.datePaiement}</span>
              </td>
              <td className="nk-tb-col tb-col-md">
                <span className="tb-amount">{item.montant} €</span>
                <span>{item.modePayement}</span>
              </td>
              <td className="nk-tb-col tb-col-md">
                {item.satuts && <span className="badge badge-sm badge-dot has-bg badge-success d-mb-inline-flex">
                  Payé
                </span>}
              </td>
              <td className="nk-tb-col nk-tb-col-tools">
                <ul className="nk-tb-actions gx-1">
                  <li>
                    <Dropdown>
                      <Dropdown.Toggle
                        as="a"
                        className="btn btn-icon btn-trigger"
                      >
                        <em className="icon ni ni-more-h"></em>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu-right">
                        <ul className="link-list-opt no-bdr">
                          <li>
                            <Link to="/invoices/view">
                              <em className="icon ni ni-eye" />
                              <span>Voir la facture</span>
                            </Link>
                          </li>
                          <li>
                            <a
                              className="fetch-display-click cursor"
                              onClick={() => updatePayement(item)}
                            >
                              <em className="icon ni ni-pen"></em>
                              <span>Modifier le paiement</span>
                            </a>
                          </li>

                          <li className="divider"></li>
                          <li>
                            <a
                              className="send-to-server-click cursor"
                              onClick={() => handleDelete(item.idPayement)}
                            >
                              <em className="icon ni ni-trash"></em>
                              <span>Supprimer le paiement</span>
                            </a>
                          </li>
                        </ul>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                </ul>
              </td>
            </tr>

            {/* <tr>
                                                    <td className="text-center" colSpan={6}>C'est vide ici !</td>
                                                </tr> */}
          </tbody>))}
        </table>
        <Pagination className="nk-block-end">
          <Pagination.First onClick={() => goToPageOne(1)} />
          <Pagination.Prev onClick={goToPrevPage} />
          {
            Array.from(
              { length: data.countPage }, (v, k) => k + 1
            ).map(items => <Pagination.Item key={items} active={items === activePage} onClick={() => goToPageOne(items)}>{items}</Pagination.Item>)
          }
          <Pagination.Next onClick={goToNextPage} />
          <Pagination.Last onClick={() => goToPageOne(data.countPage)} />
        </Pagination>
      </div>
      <UpdatePayment show={showUpdate} onHide={() => setShowUpdate(false)} data={paiementData} />
    </>
  );
}
