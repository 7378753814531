import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import Swal from 'sweetalert2'
import Loading from "../../components/Loading";
import SvgFuel from "../../components/SvgFuel";
import { projetHooks } from "../../network/projet";
import ConfirmAction from "../ConfirmAction";
import { useNavigate } from "react-router-dom";
import { clientEntityType, detailVehiculeEntityType, projetEntityType, vehiculeEntityType } from "../../types";



//Details du véhicule
const ARRAY_VEHICLE = [
  {
    id: "1",
    name: "Ext/Mirrors P/M",
  },
  {
    id: "2",
    name: "Ext/Badge/Stickers",
  },
  {
    id: "3",
    name: "Spare Wheel",
  },
  {
    id: "4",
    name: "Door Locks",
  },
  {
    id: "5",
    name: "Sport Lights",
  },
  {
    id: "6",
    name: "Bonnet Badge",
  },
  {
    id: "7",
    name: "W/Arms/Blades",
  },
  {
    id: "8",
    name: "Insurance Cert",
  },
  {
    id: "9",
    name: "Inspection Stickers",
  },
  {
    id: "10",
    name: "Fire Extinguisher",
  },
  {
    id: "11",
    name: "Fuel Tank Cap",
  },
  {
    id: "12",
    name: "Fuel Tank Lid Lock",
  },
  {
    id: "13",
    name: "Relay",
  },
  {
    id: "14",
    name: "Washer Bag",
  },
  {
    id: "15",
    name: "Horns",
  },
  {
    id: "16",
    name: "Oil Filter Cap",
  },
  {
    id: "17",
    name: "Radiator Cap",
  },
  {
    id: "18",
    name: "Battery MK",
  },
  {
    id: "19",
    name: "Hub Caps",
  },
  {
    id: "20",
    name: "Mud Flaps",
  },
  {
    id: "21",
    name: "Arial Auto/MAN",
  },
  {
    id: "22",
    name: "First Aid Box",
  },
  {
    id: "23",
    name: "Screw Driver",
  },
  {
    id: "24",
    name: "Interior Light",
  },
  {
    id: "25",
    name: "Cigar Lighter",
  },
  {
    id: "26",
    name: "Head Rest",
  },
  {
    id: "27",
    name: "Seat Belts",
  },
  {
    id: "28",
    name: "Winder Handles Switches",
  },
  {
    id: "29",
    name: "Door Lock Knobs",
  },
  {
    id: "30",
    name: "Heater Knobs",
  },
  {
    id: "31",
    name: "Clock",
  },
  {
    id: "32",
    name: "Radio Speaker",
  },
  {
    id: "33",
    name: "Rear View Mirror",
  },
  {
    id: "34",
    name: "Floor Carpets",
  },
  {
    id: "35",
    name: "Pliers",
  },
  {
    id: "36",
    name: "W/Spanner",
  },
  {
    id: "37",
    name: "W/Triangle",
  },
  {
    id: "38",
    name: "Jack / Handle",
  },
  {
    id: "39",
    name: "Tool Kit/Brace",
  },
  {
    id: "40",
    name: "Wheel Lock key",
  },
  {
    id: "41",
    name: "Boot Mats",
  },
  {
    id: "42",
    name: "Boot Badge",
  },
  {
    id: "43",
    name: "Rubber Mats",
  },
  {
    id: "44",
    name: "Radio",
  },
];

//Les types de données manipulés
type inputForm = {
  id: number,
  nom: string,
}

type Travaux = { //type des taches 
  id: number;
  nom: string;
}


export const UpdateProjects = ({ show, onHide, idProjet }: any) => {

  const [tab, setTab] = useState(1); //Permet la mise en étape de la modification

  const [showInsuranceCompany, setShowInsuranceCompany] = useState(false);
  const [fuelValue, setFuelValue] = useState(0);
  const [piece, setPiece] = useState<string[]>([]);
  const { data, isLoading } = projetHooks.useGetProjectById(idProjet);

  const {mutate} = projetHooks.useUpdateProject();
  const navigate = useNavigate (); //Utilisé pour la redirection après modification


  const [detailsVehicule, setDetailsVehicule] = useState<detailVehiculeEntityType>({
    marque: '',
    model: '',
    vin: "",
    moteur: "",
    numeroImmatriculation: "",
    date1Immatriculation: "",
    typeVersion: "",
    codeNationalIdentification: "",
  });


  //Les données du client 
  const [client, setClient] = useState<clientEntityType>({
    idClient: 0,
    email: '',
    nomPrenom: '',
    telephone: '',
    adresse: ''
  });



  const [status, setStatus] = useState<string>('');
  const [startDate, setDateDebut] = useState<string>('');
  const [endDate, setDateFin] = useState<string>('');
  const [roadTestIn, setRoadTestIn] = useState<string>('');
  const [details, setDetails] = useState<string>('');
  const [preAccident, setPreAccident] = useState<string>('');
  const [nomTravaux, setTravaux1] = useState<string>('');
  const [nomTravaux2, setTravaux2] = useState<string>('');
  const [nomTravaux3, setTravaux3] = useState<string>('');
  const [idClient, setIdClient] = useState<number>(0);
  const [idAssurance, setIdAssurance] = useState<number>(0);
  const [porteurVehicule, setPorteurVehicule] = useState<{ nomPrenom: string, telephone: string, email: string }>({
    nomPrenom: '',
    telephone: '',
    email: ''
  });


  //Hooks pour les erreurs

  const [errorMarque, setErrorMarque] = useState<boolean>(false);
  const [errorModele, setErrorModele] = useState<boolean>(false);
  const [errorVin, setErrorVin] = useState<boolean>(false);
  const [errorMoteur, setErrorMoteur] = useState<boolean>(false);
  const [errorClientEmail, setErrorClientEmail] = useState<boolean>(false);
  const [errorClientPhone, setErrorClientPhone] = useState<boolean>(false);
  const [errorClientAddress, setErrorClientAddress] = useState<boolean>(false);
  const [errorClient, setErrorClient] = useState<boolean>(false);
  const [errorPreAccident, setErrorPreAccident] = useState<boolean>(false);
  const [errorNumeroImmatriculation, setErrorNumeroImmatriculation] = useState<boolean>(false);
  const [errorDate1Immatriculation, setErrorDate1Immatriculation] = useState<boolean>(false);
  const [errorTypeVersion, setErrorTypeVersion] = useState<boolean>(false);
  const [errorCodeNationaleIdentification, setErrorCodeNationaleIdentification] = useState<boolean>(false);
  const [classMarque, setClassMarque] = useState<string>('form-control form-control-lg');
  const [classModele, setClassModele] = useState<string>('form-control form-control-lg');
  const [classVin, setClassVin] = useState<string>('form-control form-control-lg');
  const [classMoteur, setClassMoteur] = useState<string>('form-control form-control-lg');
  const [classKilometre, setClassKilometre] = useState<string>('form-control form-control-lg');
  const [classColor, setClassColor] = useState<string>('form-control form-control-lg');
  const [classDateAssurance, setClassDateAssurance] = useState<string>('form-control form-control-lg');
  const [classCompagnie, setClassCompagnie] = useState<string>('form-control form-control-lg');
  const [classDateEntre, setClassDateEntre] = useState<string>('form-control form-control-lg');
  const [classHeureEntre, setClassHeureEntre] = useState<string>('form-control form-control-lg');
  const [classDateDebut, setClassDateDebut] = useState<string>('form-control form-control-lg');
  const [classDateFin, setClassDateFin] = useState<string>('form-control form-control-lg');
  const [classPreAccident, setClassPreAccident] = useState<string>('form-control defects');
  const [classNumeroImmatriculation, setClassNumeroImmatriculation] = useState<string>('form-control form-control-lg');
  const [classDate1Immatriculation, setClassDate1Immatriculation] = useState<string>('form-control form-control-lg');
  const [classTypeVersion, setClassTypeVersion] = useState<string>('form-control form-control-lg');
  const [classCodeNationaleIdentification, setClassCodeNationaleIdentification] = useState<string>('form-control form-control-lg');
  const [classClientEmail, setClassClientEmail] = useState<string>('form-control form-control-lg');
  const [classClientPhone, setClassClientPhone] = useState<string>('form-control form-control-lg');
  const [classClient, setClassClient] = useState<string>('form-control form-control-lg');
  const [classClientAddress, setClassClientAddress] = useState<string>('form-control form-control-lg');
  const [travaux, setTravaux] = useState<inputForm[]>([
    {
      id: 0,
      nom: ''
    }
  ])



  const [defautCarburants, setDefautCarburant] = useState<{
    preAccident: string, niveauCarburant: number, travauxDemmander: Travaux[]
  }>({
    preAccident: '',
    niveauCarburant: 0,
    travauxDemmander: []
  });
  const [pieceVehicules, setPieceVehicule] = useState<{ nomEntrePiece: string, piece: number }[]>([])

  //Gestion de la confirmation des modificatons
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);


  //Gestion de l'annulation des modifications
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);




  //Initialise la valeur des champs 

  useEffect(() => {
    if(data?.data) {
      setTravaux(data?.data?.vehicules?.defautCarburants?.travauxDemmander);
      setDetailsVehicule(data?.data?.vehicules?.detailVehicules);
      setDateDebut(data?.data?.dateDebut);
      setDateFin(data?.data?.dateFin);
      setDefautCarburant(data?.data?.vehicules?.defautCarburants);
      setClient(data?.data?.vehicules?.clients);
    }
  }, [data])


  //Reinitialise le tab si un nouveau projet est ouvert

  useEffect (()=>{
    setTab(1)
  }, [idProjet])

  const handleAddForm = () => {
    let newFomrs = {
      id: 0,
      nom: ''
    }

    setTravaux([...travaux, newFomrs]);
  }
  const handleRemoveForm = (index) => {
    let values = [...travaux];
    const dc = defautCarburants;

    values.splice(index, 1);
    dc.travauxDemmander = values;

    setTravaux(values);
    setDefautCarburant(dc);
  }
  const handleFormChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const data = [...travaux];
    const dc = defautCarburants;

    data[index][event.target.name] = event.target.value
    dc.travauxDemmander = data;

    setTravaux(data);
    setDefautCarburant(dc);
  }

  const handleChangePiece = (e: { target: { name: React.SetStateAction<string>, checked: React.SetStateAction<boolean> }; }) => {

    if (e.target.checked === true) {
      setPiece(current => [...current, String(e.target.name)])
      console.log(piece, 'ireto avy ilay piece')
    } else {
      setPiece(current =>
        current.filter(element => {
          return element !== e.target.name
        })
      )
    }
  }

  //const handleChangeUnit = (e: { target: { value: React.SetStateAction<string>; }; }) => {
  //  setDetailsVehicule({ ...detailsVehicule, uniteKilometrage: String(e.target.value) })
  //}
//
  //const handleChangeStatut = (e: { target: { value: React.SetStateAction<string>; }; }) => {
  //  setStatus(e.target.value)
  //}
//
  //const handleTestRoutier = (e: { target: { value: React.SetStateAction<string>; }; }) => {
  //  setRoadTestIn(e.target.value)
  //}


  //Functions qui reperent les valeurs manquantes
  const handleTestRoutier = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    setRoadTestIn(e.target.value)
  }

  const checkEmptyMarque = () => {
    if (detailsVehicule.marque.trim().length === 0) {
      setErrorMarque(true)
      setClassMarque('form-control form-control-lg is-invalid')
      return true
    } else {
      setErrorMarque(false)
      setClassMarque('form-control form-control-lg')
      return false
    }
  }

  const checkEmptyModele = () => {
    if (detailsVehicule.model.trim().length === 0) {
      setErrorModele(true)
      setClassModele('form-control form-control-lg is-invalid')
      return true
    } else {
      setErrorModele(false)
      setClassModele('form-control form-control-lg')
      return false
    }
  }

  const checkEmptyVin = () => {
    if (detailsVehicule.vin?.trim().length === 0) {
      setErrorVin(true)
      setClassVin('form-control form-control-lg is-invalid')
      return true
    } else {
      setErrorVin(false)
      setClassVin('form-control form-control-lg')
      return false
    }
  }

  const checkEmptyMoteur = () => {
    if (detailsVehicule.moteur?.trim().length === 0) {
      setErrorMoteur(true)
      setClassMoteur('form-control form-control-lg is-invalid')
      return true
    } else {
      setErrorMoteur(false)
      setClassMoteur('form-control form-control-lg')
      return false
    }
  }

  //const checkEmptyKm = () => {
  //  if (detailsVehicule.kilometrage === 0) {
  //    setErrorKilometre(true)
  //    setClassKilometre('form-control form-control-lg is-invalid')
  //    return true
  //  } else {
  //    setErrorKilometre(false)
  //    setClassKilometre('form-control form-control-lg')
  //    return false
  //  }
  //}

  //const checkEmptyColor = () => {
  //  if (detailsVehicule.couleur.trim().length === 0) {
  //    setErrorColor(true)
  //    setClassColor('form-control form-control-lg is-invalid')
  //    return true
  //  } else {
  //    setErrorColor(false)
  //    setClassColor('form-control form-control-lg')
  //    return false
  //  }
  //}

  //const checkEmptyDateAssurance = () => {
  //  if (detailsVehicule.dateAssurance.trim().length === 0) {
  //    setErrorDateAssurance(true)
  //    setClassDateAssurance('form-control form-control-lg is-invalid')
  //    return true
  //  } else {
  //    setErrorDateAssurance(false)
  //    setClassDateAssurance('form-control form-control-lg')
  //    return false
  //  }
  //}

  //const checkEmptyCompagnie = () => {
  //  if (detailsVehicule.campagnieAssurance.trim().length === 0) {
  //    setErrorCompagnie(true)
  //    setClassCompagnie('form-control form-control-lg is-invalid')
  //    return true
  //  } else {
  //    setErrorCompagnie(false)
  //    setClassCompagnie('form-control form-control-lg')
  //    return false
  //  }
  //}

  //const checkDateEntre = () => {
  //  if (detailsVehicule.dateEntre.trim().length === 0) {
  //    setErrorDateEntre(true)
  //    setClassDateEntre('form-control form-control-lg is-invalid')
  //    return true
  //  } else {
  //    setErrorDateEntre(false)
  //    setClassDateEntre('form-control form-control-lg')
  //    return false
  //  }
  //}

  //const checkHeureEntre = () => {
  //  if (detailsVehicule.dateEntre.trim().length === 0) {
  //    setErrorHeureEntre(true)
  //    setClassHeureEntre('form-control form-control-lg is-invalid')
  //    return true
  //  } else {
  //    setErrorHeureEntre(false)
  //    setClassHeureEntre('form-control form-control-lg')
  //    return false
  //  }
  //}

  //const checkDateDebut = () => {
  //  if (startDate.trim().length === 0) {
  //    setErrorDateDebut(true)
  //    setClassDateDebut('form-control form-control-lg is-invalid')
  //    return true
  //  } else {
  //    setErrorDateDebut(false)
  //    setClassDateDebut('form-control form-control-lg')
  //    return false
  //  }
  //}

  //const checkDateFin = () => {
  //  if (endDate.trim().length === 0) {
  //    setErrorDateFin(true)
  //    setClassDateFin('form-control form-control-lg is-invalid')
  //    return true
  //  } else {
  //    setErrorDateFin(false)
  //    setClassDateFin('form-control form-control-lg')
  //    return false
  //  }
  //}

  //const checkPreAccident = () => {
  //  if (defautCarburants.preAccident.trim().length === 0) {
  //    setErrorPreAccident(true)
  //    setClassPreAccident('form-control defects is-invalid')
  //    return true
  //  } else {
  //    setErrorPreAccident(false)
  //    setClassPreAccident('form-control defects')
  //    return false
  //  }
  //}

  const checkEmptyNumeroImmatriculation = () => {
    if (detailsVehicule.numeroImmatriculation.trim().length === 0) {
      setErrorNumeroImmatriculation(true)
      setClassNumeroImmatriculation('form-control form-control-lg is-invalid')
      return true
    } else {
      setErrorNumeroImmatriculation(false)
      setClassNumeroImmatriculation('form-control form-control-lg')
      return false
    }
  }

  const checkEmptyDate1Immatriculation = () => {
    if (detailsVehicule?.date1Immatriculation?.trim().length === 0) {
      setErrorDate1Immatriculation(true)
      setClassDate1Immatriculation('form-control form-control-lg is-invalid')
      return true
    } else {
      setErrorDate1Immatriculation(false)
      setClassDate1Immatriculation('form-control form-control-lg')
      return false
    }
  }
  

  const checkEmptyTypeVersion = () => {
    if (detailsVehicule?.typeVersion?.trim().length === 0) {
      setErrorTypeVersion(true)
      setClassTypeVersion('form-control form-control-lg is-invalid')
      return true
    } else {
      setErrorTypeVersion(false)
      setClassTypeVersion('form-control form-control-lg')
      return false
    }
  }

  const checkEmptyCodeNationaleIdentification = () => {
    if (detailsVehicule.codeNationalIdentification?.trim().length === 0) {
      setErrorCodeNationaleIdentification(true)
      setClassCodeNationaleIdentification('form-control form-control-lg is-invalid')
      return true
    } else {
      setErrorCodeNationaleIdentification(false)
      setClassCodeNationaleIdentification('form-control form-control-lg')
      return false
    }
  }

  const checkEmptyClientEmail = () => {
    if (client.email?.trim().length === 0) {
      setErrorClientEmail(true)
      setClassClientEmail('form-control form-control-lg is-invalid')
      return true
    } else {
      setErrorClientEmail(false)
      setClassClientEmail('form-control form-control-lg')
      return false

    }
  }

  const checkEmptyClientPhone = () => {
    if (client.telephone?.trim().length === 0) {
      setErrorClientPhone(true)
      setClassClientPhone('form-control form-control-lg is-invalid')
      return true
    } else {
      setErrorClientPhone(false)
      setClassClientPhone('form-control form-control-lg')
      return false
    }
  }

  const checkEmptyClient = () => {
    if (client.nomPrenom.trim().length === 0) {
      setErrorClient(true)
      setClassClient('form-control form-control-lg is-invalid')
      return true
    } else {
      setErrorClient(false)
      setClassClient('form-control form-control-lg')
      return false
    }
  }

  const checkEmptyClientAddress = () => {
    if (client.adresse.trim().length === 0) {
      setErrorClientAddress(true)
      setClassClientAddress('form-control form-control-lg is-invalid')
      return true
    } else {
      setErrorClientAddress(false)
      setClassClientAddress('form-control form-control-lg')
      return false
    }
  }



  //FINALITY: Function who UPDATES the project after we've checked required fields

  const updateProject = () => {
    
    //vérifie si tout est ok!
    if (isFormInvalid()) {
      return;
    }
    else{
    //si ok, on procède

      try{
        for (let i = 0; i < piece.length; i++) {
          setPieceVehicule(prevPiece => [
            ...prevPiece,
            { nomEntrePiece: piece[i], piece: 1 },
          ])
        }
    
        setDefautCarburant({ ...defautCarburants, travauxDemmander: travaux });
    
        //Prepare les données

        let projet = data?.data;
        
        if (projet){
          projet.vehicules.clients = client;
          projet.vehicules.defautCarburants = defautCarburants
          projet.vehicules.detailVehicules = detailsVehicule
          delete projet.remarques;

          mutate(projet, {
            onSuccess: async (data) => {
              onHide();
              setTab (1);
              Swal.fire({
                icon: 'success',
                text: 'Mise à jour réussi',
              });
      
            },
            onError: (error) => {
              onHide()
              Swal.fire({
                icon: 'error',
                text: 'Erreur lors de la modification',
              });
            },
            onSettled: async (data) => {
              console.log('tonga ato oooo')
            },
          })
        }
      } catch (e){
        console.error ("Erreur lors de l'ajout: " + e)
      } finally{
        setIsConfirmModalOpen(false) //Ferme la fenêtre de confirmation
        setTab(1)
      }
      
    }
  }
      




  /*Check if any required field is missing value, if yes then it display error message and stop modification process */

  function isFormInvalid(): boolean{
    if (checkEmptyClient() 
    || checkEmptyClientAddress() 
    || checkEmptyClientEmail()
    || checkEmptyClientPhone()
    || checkEmptyCodeNationaleIdentification()
    || checkEmptyDate1Immatriculation()
    || checkEmptyModele()
    || checkEmptyMoteur()
    || checkEmptyNumeroImmatriculation()
    || checkEmptyTypeVersion()
    || checkEmptyVin()
    || checkEmptyMarque()){
      Swal.fire({
        icon: "error",
        text: "Veuillez remplir tous les champs"
      })
      return true;
    }else{
      return false;
    }
  }

  return (
    <Modal show={show} onHide={()=>{setIsCancelModalOpen(true)}} size="xl" hi>
      <Modal.Header closeButton>
        <Modal.Title>Modification du véhicule</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading? <div className="loading__wrapper--small">
          <Loading />
        </div> : <form
          className="simcy-form nk-wizard nk-wizard-simple is-alter"
          method="POST"
        >
          {isLoading ? <Loading /> :
            <div className={`nk-wizard-content ${tab !== 1 && "d-none"}`}>
              <div className="row mb-4 mt-4">
                <div className="col-sm-12">
                  <div className="form-group">
                    <h5>Client</h5>
                  </div>
                </div>
              </div>
              <div className="row gy-4 new-client-inputs">
                <div className="col-sm-4">
                  <div className="form-group">
                    <label className="form-label">Nom complet <span className="required-star">*</span></label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className={classClient}
                        placeholder="Nom complet"
                        name="nomPrenom"
                        required
                        onChange={e => setClient({ ...client, nomPrenom: e.target.value })}
                        value={client.nomPrenom}
                      />
                    </div>
                    {errorClient && <div className="text-danger">Valeur obligatoire</div>}
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                    <label className="form-label">Numéro de téléphone <span className="required-star">*</span></label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className={classClientPhone}
                        placeholder="Numéro de téléphone"
                        name="telephone"
                        required
                        onChange={e => setClient({ ...client, telephone: e.target.value })}
                        onBlur={checkEmptyClientPhone}
                        value={client.telephone}
                      />
                    </div>
                    {errorClientPhone && <div className="text-danger">Valeur obligatoire</div>}
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                    <label className="form-label">Adresse e-mail <span className="required-star">*</span></label>
                    <div className="form-control-wrap">
                      <input
                        type="email"
                        className={classClientEmail}
                        placeholder="Adresse e-mail"
                        name="email"
                        required
                        onChange={e => setClient({ ...client, email: e.target.value })}
                        onBlur={checkEmptyClientEmail}
                        value={client.email}
                      />
                    </div>
                    {errorClientEmail && <div className="text-danger">Valeur obligatoire</div>}
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Address <span className="required-star">*</span></label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className={classClientAddress}
                        placeholder="Address"
                        name="adresse"
                        required
                        onChange={e => setClient({ ...client, adresse: e.target.value })}
                        onBlur={checkEmptyClientAddress}
                        value={client.adresse}
                      />
                    </div>
                    {errorClientAddress && <div className="text-danger">Valeur obligatoire</div>}
                  </div>
                </div>
              </div>
              <div className="row gy-4 deliveredby-inputs d-none">
                <div className="col-sm-12 pb-0">
                  <p>
                    Enter the details of the person who brought the car on behalf
                    of the client.
                  </p>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                    <label className="form-label">Nom complet</label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="Nom complet"
                        name="deliveredby_fullname"
                        defaultValue={data?.data.vehicules.clients.nomPrenom}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                    <label className="form-label">Numéro de téléphone</label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control form-control-lg phone-input deliveredby"
                        placeholder="Numéro de téléphone"
                        defaultValue={data?.data.vehicules.clients.telephone}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                    <label className="form-label">Adresse e-mail</label>
                    <div className="form-control-wrap">
                      <input
                        type="email"
                        className="form-control form-control-lg"
                        placeholder="Adresse e-mail"
                        name="deliveredby_email"
                        defaultValue={data?.data.vehicules.clients.email}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                    <label className="form-label">Numéro d'identification</label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="Numéro d'identification"
                        name="deliveredby_idnumber"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row gy-4">
              <div className="nk-divider divider mt-5"></div>
                <div className="row mb-4">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <h5>Les détails du véhicule.</h5>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                    <label className="form-label">Numéro immatriculation <span className="required-star">*</span></label>
                    <div className="form-control-wrap">
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          className={classNumeroImmatriculation}
                          placeholder="Numéro immatriculation"
                          name="numero_immatriculation"
                          required
                          onChange={e => setDetailsVehicule({ ...detailsVehicule, numeroImmatriculation: e.target.value })}
                          onBlur={checkEmptyNumeroImmatriculation}
                          value={detailsVehicule.numeroImmatriculation}
                        />
                      </div>
                      {errorNumeroImmatriculation && <div className="text-danger">Valeur obligatoire</div>}
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                    <label className="form-label">Date 1ère immatriculation <span className="required-star">*</span></label>
                    <div className="form-control-wrap">
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          className={classDate1Immatriculation}
                          placeholder="Date 1ère immatriculation"
                          name="date1_immatriculation"
                          required
                          onChange={e => setDetailsVehicule({ ...detailsVehicule, date1Immatriculation: e.target.value })}
                          onBlur={checkEmptyDate1Immatriculation}
                          value={detailsVehicule.date1Immatriculation}
                        />
                      </div>
                      {errorDate1Immatriculation && <div className="text-danger">Valeur obligatoire</div>}
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                    <label className="form-label">Type version <span className="required-star">*</span></label>
                    <div className="form-control-wrap">
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          className={classTypeVersion}
                          placeholder="Type version"
                          name="type_version"
                          required
                          onChange={e => setDetailsVehicule({ ...detailsVehicule, typeVersion: e.target.value })}
                          onBlur={checkEmptyTypeVersion}
                          value={detailsVehicule.typeVersion}
                        />
                      </div>
                      {errorTypeVersion && <div className="text-danger">Valeur obligatoire</div>}
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                    <label className="form-label">Code nationale d'identification <span className="required-star">*</span></label>
                    <div className="form-control-wrap">
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          className={classCodeNationaleIdentification}
                          placeholder="Code nationale d'identification"
                          name="code_national_identification"
                          required
                          onChange={e => setDetailsVehicule({ ...detailsVehicule, codeNationalIdentification: e.target.value })}
                          onBlur={checkEmptyCodeNationaleIdentification}
                          value={detailsVehicule.codeNationalIdentification}
                        />
                      </div>
                      {errorCodeNationaleIdentification && <div className="text-danger">Valeur obligatoire</div>}
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                    <label className="form-label">Marque <span className="required-star">*</span></label>
                    <div className="form-control-wrap">
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          className={errorMarque? classMarque : "form-control form-control-lg"}
                          placeholder="Marque"
                          name="marque"
                          required
                          onChange={e => setDetailsVehicule({ ...detailsVehicule, marque: e.target.value })}
                          defaultValue={data?.data?.vehicules?.detailVehicules.marque}
                          onBlur={checkEmptyMarque}
                        />
                      </div>
                      {errorMarque && <div className="text-danger">Valeur obligatoire</div>}
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                    <label className="form-label">Modèle <span className="required-star">*</span></label>
                    <div className="form-control-wrap">
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          className={errorModele? classModele :"form-control form-control-lg"}
                          placeholder="Modèle"
                          name="modele"
                          required
                          onChange={e => setDetailsVehicule({ ...detailsVehicule, model: e.target.value })}
                          defaultValue={data?.data?.vehicules?.detailVehicules.model}
                          onBlur={checkEmptyModele}
                        />
                      </div>
                      {errorModele && <div className="text-danger">Valeur obligatoire</div>}
                    </div>
                  </div>
                </div>
                {/*                <div className="col-sm-4">
                  <div className="form-group">
                    <label className="form-label">Numéro d'enregistrement</label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="Numéro d'enregistrement"
                        name="registration_number"
                        required
                        onChange={e => setDetailsVehicule({ ...detailsVehicule, numero: e.target.value })}
                        defaultValue={data?.data.vehicules.detailVehicules.numero}
                      />
                    </div>
                  </div>
                </div> */}
                <div className="col-sm-4">
                  <div className="form-group">
                    <label className="form-label">VIN / Châssis Non <span className="required-star">*</span></label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className={errorVin? classVin: "form-control form-control-lg"}
                        placeholder="VIN / Châssis Non"
                        name="vin"
                        onChange={e => setDetailsVehicule({ ...detailsVehicule, vin: e.target.value })}
                        defaultValue={data?.data.vehicules.detailVehicules.vin}
                        onBlur={checkEmptyVin}
                      />
                    </div>
                    {errorVin && <div className="text-danger">Valeur obligatoire</div>}
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                    <label className="form-label">Moteur <span className="required-star">*</span></label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className={errorMoteur? classMoteur: "form-control form-control-lg"}
                        placeholder="Moteur Non"
                        name="engine_number"
                        onChange={e => setDetailsVehicule({ ...detailsVehicule, moteur: e.target.value })}
                        defaultValue={data?.data.vehicules.detailVehicules.moteur}
                        onBlur={checkEmptyMoteur}
                      />
                    </div>
                    {errorMoteur && <div className="text-danger">Valeur obligatoire</div>}
                  </div>
                </div>
                {/*<div className="col-sm-4">
                  <div className="form-group">
                    <label className="form-label">Kilométrage en</label>
                    <div className="form-control-wrap">
                      <input
                        type="number"
                        className="form-control form-control-lg"
                        placeholder="Kilométrage en"
                        name="milleage"
                        onChange={e => setDetailsVehicule({ ...detailsVehicule, kilometrage: Number(e.target.value) })}
                        defaultValue={data?.data.vehicules.detailVehicules.kilometrage}
                      />
                    </div>
                    {errorKilometre && <div className="text-danger">Valeur obligatoire</div>}
                  </div>
                </div> */}
                {/*<div className="col-sm-4">
                  <div className="form-group">
                    <label className="form-label">Unité de kilométrage</label>
                    <div className="form-control-wrap ">
                      <div className="form-control-select">
                        <select
                          className="form-control form-control-lg"
                          name="milleage_unit"
                          onChange={handleChangeUnit}
                          defaultValue={data?.data.vehicules.detailVehicules.uniteKilometrage}
                        >
                          <option value="Kilometers">Kilomètres</option>
                          <option value="Miles">Milles</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/*<div className="col-sm-4">
                  <div className="form-group">
                    <label className="form-label">Couleur</label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="Couleur"
                        name="color"
                        onChange={e => setDetailsVehicule({ ...detailsVehicule, couleur: e.target.value })}
                        defaultValue={data?.data.vehicules.detailVehicules.couleur}
                      />
                    </div>
                    {errorColor && <div className="text-danger">Valeur obligatoire</div>}
                  </div>
                </div> */}
                {/*<div className="col-sm-4">
                  <div className="form-group">
                    <label className="form-label">
                      Expiration de l'assurance
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="date"
                        className="form-control form-control-lg"
                        placeholder="Expiration de l'assurance"
                        name="insurance_expiry"
                        required
                        onChange={e => setDetailsVehicule({ ...detailsVehicule, dateAssurance: e.target.value })}
                        defaultValue={data?.data.vehicules.detailVehicules.dateAssurance}
                      />
                    </div>
                    {errorDateAssurance && <div className="text-danger">Valeur obligatoire</div>}
                  </div>
                </div> */}
                {/*<div className="col-sm-4">
                  <div className="form-group">
                    <label className="form-label">Compagnie d'assurance</label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="Compagnie d'assurance"
                        name="insurance_company"
                        required
                        onChange={e => setDetailsVehicule({ ...detailsVehicule, campagnieAssurance: e.target.value })}
                        defaultValue={data?.data.vehicules.detailVehicules.campagnieAssurance}
                      />
                    </div>
                    {errorCompagnie && <div className="text-danger">Valeur obligatoire</div>}
                  </div>
                </div> */}
                {/*<div className="col-sm-4">
                  <div className="form-group">
                    <label className="form-label">Date d'entrée</label>
                    <div className="form-control-wrap">
                      <input
                        type="date"
                        className="form-control form-control-lg"
                        placeholder="Date d'entrée"
                        name="date_in"
                        onChange={e => setDetailsVehicule({ ...detailsVehicule, dateEntre: e.target.value })}
                        defaultValue={data?.data.vehicules.detailVehicules.dateEntre}
                        required
                      />
                    </div>
                    {errorDateEntre && <div className="text-danger">Valeur obligatoire</div>}
                  </div>
                </div> */}
                {/*<div className="col-sm-4">
                  <div className="form-group">
                    <label className="form-label">Heure d'entrée</label>
                    <div className="form-control-wrap">
                      <input
                        type="time"
                        className="form-control form-control-lg"
                        placeholder="Heure d'entrée"
                        name="time_in"
                        onChange={e => setDetailsVehicule({ ...detailsVehicule, heureEntre: e.target.value })}
                        defaultValue={data?.data.vehicules.detailVehicules.heureEntre}
                        required
                      />
                    </div>
                    {errorHeureEntre && <div className="text-danger">Valeur obligatoire</div>}
                  </div>
                </div> */}
                {/*<div className="col-sm-4">
                  <div className="form-group">
                    <label className="form-label">Statut</label>
                    <div className="form-control-wrap ">
                      <div className="form-control-select">
                        <select
                          className="form-control form-control-lg"
                          name="status"
                          onChange={handleChangeStatut}
                          defaultValue={data?.data.status}
                        >
                          <option value="En cours">En cours</option>
                          <option value="Réservé dans">Réservé dans</option>
                          <option value="Complété">Complété</option>
                          <option value="Annulé">Annulé</option>
                          <option value="A faire">A faire</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/*<div className="col-sm-4">
                  <div className="form-group">
                    <label className="form-label">Date de début</label>
                    <div className="form-control-wrap">
                      <input
                        type="date"
                        className="form-control form-control-lg"
                        placeholder="Date de début"
                        name="start_date"
                        required
                        onChange={e => setDateDebut(e.target.value)}
                        defaultValue={data?.data.dateDebut}
                      />
                    </div>
                    {errorDateDebut && <div className="text-danger">Valeur obligatoire</div>}
                  </div>
                </div> */}
                 {/*<div className="col-sm-4">
                  <div className="form-group">
                    <label className="form-label">Date d'achèvement prévue</label>
                    <div className="form-control-wrap">
                      <input
                        type="date"
                        className="form-control form-control-lg"
                        placeholder="Date d'achèvement prévue"
                        name="end_date"
                        required
                        onChange={e => setDateFin(e.target.value)}
                        defaultValue={data?.data.dateFin}
                      />
                    </div>
                    {errorDateFin && <div className="text-danger">Valeur obligatoire</div>}
                  </div>
                </div> */}
                {/*<div className="col-sm-4">
                  <div className="form-group">
                    <label className="form-label">Test routier</label>
                    <div className="form-control-wrap ">
                      <div className="form-control-select">
                        <select
                          className="form-control form-control-lg"
                          name="road_test_in"
                          onChange={handleTestRoutier}
                          defaultValue={data?.data.testRoutier}
                        >
                          <option value="None">Rien</option>
                          <option value="With Client">Avec le client</option>
                          <option value="With Client and Mechanic">
                            Avec client et mécanicien
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/*<div className="col-sm-12">
                  <div className="form-group">
                    <label className="form-label">
                      Détails / Remarques sur le remorquage
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="Détails / Remarques sur le remorquage"
                        name="towing_details"
                        onChange={e => setDetailsVehicule({ ...detailsVehicule, details: String(e.target.value) })}
                        defaultValue={data?.data.vehicules.detailVehicules.details}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-sm-12">
                  <div className="nk-divider divider mt-0 mb-0"></div>
                </div> */}
                {/*<div className="col-sm-12">
                  <div className="form-group">
                    <div className="custom-control custom-switch">
                      <input
                        type="checkbox"
                        name="covered"
                        id="covered"
                        className="custom-control-input"
                        defaultChecked={showInsuranceCompany}
                        onChange={(e) =>
                          setShowInsuranceCompany(e.target.checked)
                        }
                      />
                      <label className="custom-control-label" htmlFor="covered">
                        Frais de réparation pris en charge par l'assurance
                      </label>
                    </div>
                  </div>
                </div> */}
                {/*showInsuranceCompany && (
                  <div className="col-sm-12 covered">
                    <div className="form-group">
                      <label className="form-label">
                        Select Insurance Company
                      </label>
                      <div className="form-control-wrap ">
                        <div className="form-control-select">
                          <select
                            className="form-control select2-dynamic"
                            name="insurance"
                          >
                            <option value="">No Insurance company added</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                )*/}
              </div>
            </div>
          }

          {isLoading ? <Loading /> : <div className={`nk-wizard-content ${tab !== 2 && "d-none"}`}>
            <div className="row gy-4">
              <div className="col-sm-12 pb-0">
                <div className="nk-divider divider mt-0 mb-1"></div>
                <p>Vehicle parts check.</p>
              </div>
              {ARRAY_VEHICLE.map((item, key) => (
                <div className="col-sm-4 part-item-holder" key={key}>
                  <div className="form-group">
                    <div className="custom-control custom-switch">
                      <input
                        type="checkbox"
                        name={item.name}
                        id={item.id}
                        className="custom-control-input"
                        onChange={handleChangePiece}
                        defaultChecked={piece.find(p => p === item.name) != null}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={item.id}
                      >
                        {item.name}
                      </label>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* <div className="row gy-2">
              <div className="col-sm-12">
                <div className="nk-divider divider mt-3 mb-0"></div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label className="form-label">Booking In Notes</label>
                  <div className="form-control-wrap">
                    <textarea
                      className="form-control"
                      name="booking_in_notes"
                      placeholder="Booking In Notes"
                      rows={3}
                    ></textarea>
                  </div>
                  <div className="form-note">
                    Serial numbers, part description/condition, additional parts
                    etc
                  </div>
                </div>
              </div>
            </div> */}
          </div>}

          {isLoading ? <Loading /> : <div className={`nk-wizard-content ${tab !== 3 && "d-none"}`}>
            <div className="row gy-2">
              <div className="col-md-7">
                <div className="row gy-2">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="form-label">
                        Pre Accident / Other Defects
                      </label>
                      <div className="form-control-wrap">
                        <textarea
                          className="form-control defects"
                          name="other_defects"
                          placeholder="Pre Accident / Other Defects"
                          rows={7}
                          onChange={e => setDefautCarburant({ ...defautCarburants, preAccident: e.target.value })}
                          defaultValue={data?.data?.vehicules?.defautCarburants?.preAccident}
                        ></textarea>
                      </div>
                      {errorPreAccident && <div className="text-danger">Valeur obligatoire</div>}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="form-label">
                        Travaux demander
                      </label>
                      <div className="asilify-stack">
                        {travaux.map((input, index) => (<div className="stacked-inputs" key={index}>
                          <div className="form-control-wrap stacked">
                            <a
                              className="btn btn-round btn-sm btn-icon btn-dim btn-danger remove-stack"
                              onClick={() => handleRemoveForm(index)}
                            >
                              <em className="icon ni ni-trash"></em>
                            </a>
                            <input
                              type="text"
                              className="form-control form-control-lg"
                              placeholder="Travaux demandés / Instructions des propriétaires"
                              name="nom"
                              onChange={event => handleFormChange(index, event)}
                              value={travaux[index].nom}
                            />
                          </div>
                        </div>))}
                        <div className="">
                          <a
                            className="btn btn-dim btn-primary add-stack"
                            data-name="work_requested[]"
                            data-placeholder="Work Requested / Owners Instructions"
                            onClick={() => handleAddForm()}
                          >
                            <em className="icon ni ni-plus"></em>
                            <span>Ajout Item</span>{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="form-group text-center">
                  <label className="form-label" htmlFor="fw-telegram-username">
                    Entry Fuel Level
                  </label>
                  <SvgFuel rotate={fuelValue === 0 ? Number(data?.data?.vehicules?.defautCarburants?.niveauCarburant) : fuelValue } />
                  <input
                    type="range"
                    min="-36"
                    max="82"
                    name="fuel_level"
                    className="fuel-slider"
                    onChange={(e) => {
                      setFuelValue(Number(e.target.value));
                      setDefautCarburant({ ...defautCarburants, niveauCarburant: Number(e.target.value) })
                    }}
                    defaultValue={Number(data?.data?.vehicules?.defautCarburants?.niveauCarburant)}
                  />
                  <p>Drag to increase or decrease fuel level</p>
                </div>
              </div>
            </div>
          </div>}

          <div className="actions clearfix">
            <ul role="menu">
              <li aria-hidden="true" onClick={()=>{
                  if(isFormInvalid()) return;
                  setIsConfirmModalOpen(true);
                }}>
                  <a className="cursor">Sauvegarder</a>
              </li>
            </ul>
          </div>
          <ConfirmAction 
            confirmMessage={"Toutes les modification seront annulées"}
            isOpen={isCancelModalOpen}
            onCancel={()=>{
              setIsCancelModalOpen(false)
            }}
            onConfirm={()=>{
              onHide() //annuller la modification
              setTab(1)
              setIsCancelModalOpen(false) //Ferme la fenêtre de confirmation
            }}
            
          />
          <ConfirmAction 
            confirmMessage={"Voulez-vous apporter ces modification?"}  
            isOpen={isConfirmModalOpen}
            onCancel={()=>{setIsConfirmModalOpen(false)}}
            onConfirm={()=>{
              updateProject()
            }}
          />
        </form>}
      </Modal.Body>
    </Modal>
  );
};
