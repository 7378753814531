import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { paiementHooks } from "../../network";
import { factureHooks } from "../../network";
import Loading from "../../components/Loading";
import Swal from "sweetalert2";

export const AddPayment = ({ show, onHide }: any) => {
  const { data: dataFacture, isLoading: loadingFacture } = factureHooks.useGetAllFacture();
  const [montant, setMontant] = useState<number>(0);
  const [date, setDate] = useState<string>('');
  const [facture, setFacture] = useState<number>(0);
  const [mode, setMode] = useState<string>('');
  const [note, setNote] = useState<string>('');
  const [errorMontant, setErrorMontant] = useState<boolean>(false);
  const [errorDate, setErrorDate] = useState<boolean>(false);
  const [errorNote, setErrorNote] = useState<boolean>(false);
  const [classMontant, setClassMontant] = useState<string>('form-control form-control-lg');
  const [classDate, setClassDate] = useState<string>('form-control form-control-lg');
  const [classNote, setClassNote] = useState<string>('form-control form-control-lg');

  interface Facture {
    idFacture: number,
    total: number,
    projets: {
      vehicules: {
        clients: {
          nomPrenom: string,
        },
        detailVehicules: {
          marque: string
        }
      }
    }

  }

  const { mutate } = paiementHooks.useCreatePaiement()

  const handleChangeFacture = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFacture(Number(e.target.value))
  }

  const handleSelectMode = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setMode(e.target.value)
  }

  const checkEmpyMontant = () => {
    if (montant === 0) {
      setErrorMontant(true)
      setClassMontant('form-control form-control-lg is-invalid')
      return true
    } else {
      setErrorMontant(false)
      setClassMontant('form-control form-control-lg')
      return false
    }
  }

  const checkEmptyDate = () => {
    if (date.trim().length === 0) {
      setErrorDate(true)
      setClassDate('form-control form-control-lg is-invalid')
      return true
    } else {
      setErrorDate(false)
      setClassDate('form-control form-control-lg')
      return false
    }
  }

  const checkEmptyNote = () => {
    if (note.trim().length === 0) {
      setErrorNote(true)
      setClassNote('form-control form-control-lg is-invalid')
      return true
    } else {
      setErrorNote(false)
      setClassNote('form-control form-control-lg')
      return false
    }
  }


  const addPaiement = () => {
    const paiement = {
      montant: montant,
      note: note,
      datePaiement: date,
      modePayement: mode,
      factures: facture
    }

    if (checkEmptyDate() || checkEmptyNote() || checkEmpyMontant()) {
      checkEmptyDate()
      checkEmptyNote()
      checkEmpyMontant()
      Swal.fire({
        icon: 'error',
        text: 'Veuillez remplir tous les champs',
      });
      return;
    }

    return mutate(paiement, {
      onSuccess: async (data) => {
        onHide()
      },
      onError: (error) => {
        onHide()
        Swal.fire({
          icon: 'error',
          text: 'Erreur lors de l\'ajout paiement',
        });
      },
    })
  }


  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Ajouter un paiement</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Ajouter un paiement</p>
        <div className="row gy-4">
          <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">Sélectionnez Facture</label>
              <div className="form-control-wrap ">
                {loadingFacture ? <Loading /> : <div className="form-control-select">
                  <select
                    className="form-control form-control-lg grouped"
                    name="invoice"
                    required
                    onChange={handleChangeFacture}
                  >
                    <option value="">Sélectionnez Facture</option>

                    <optgroup label="Factures">
                      {dataFacture.data.map((item: Facture) => (<option value={item.idFacture}>
                        {item.projets.vehicules.clients.nomPrenom} - {item.projets.vehicules.detailVehicules.marque} ({item.total} €)
                      </option>))}
                    </optgroup>
                  </select>
                </div>}
                <div className="form-note">
                  Le montant entre parenthèses correspond au solde dû.
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">Montant</label>
              <div className="form-control-wrap">
                <div className="form-text-hint">
                  <span className="overline-title">$</span>
                </div>
                <input
                  type="number"
                  className={classMontant}
                  placeholder="Montant"
                  /* data-parsley-pattern="[0-9]*(\.?[0-9]{2}$)"
                  name="amount"
                  value="0.00"
                  step="0.01"
                  min="0.01" */
                  required
                  onChange={e => setMontant(Number(e.target.value))}
                  onBlur={checkEmpyMontant}
                />
              </div>
              {errorMontant && <div className="text-danger">Valeur obligatoire</div>}
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label className="form-label">Date de paiement</label>
              <div className="form-control-wrap">
                <input
                  type="date"
                  className={classDate}
                  placeholder="Date de paiement"
                  name="payment_date"
                  required
                  onChange={e => setDate(e.target.value)}
                  onBlur={checkEmptyDate}
                />
              </div>
              {errorDate && <div className="text-danger">Valeur obligatoire</div>}
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label className="form-label">Mode de paiement</label>
              <div className="form-control-wrap ">
                <div className="form-control-select">
                  <select
                    className="form-control form-control-lg"
                    name="method"
                    onChange={handleSelectMode}
                  >
                    <option value="En espèces">En espèces</option>
                    <option value="Carte bancaire">Carte bancaire</option>
                    <option value="Argent mobile">Argent mobile</option>
                    <option value="Banque">Banque</option>
                    <option value="Cheque">Chèque</option>
                    <option value="Paiement en ligne">Paiement en ligne</option>
                    <option value="Autre">Autre</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">Noter</label>
              <div className="form-control-wrap">
                <textarea
                  className={classNote}
                  placeholder="Noter"
                  rows={2}
                  name="note"
                  onChange={e => setNote(e.target.value)}
                  onBlur={checkEmptyNote}
                ></textarea>
              </div>
              {errorNote && <div className="text-danger">Valeur obligatoire</div>}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="bg-light">
        <button
          className="btn btn-white btn-dim btn-outline-light"
          type="button"
          onClick={onHide}
        >
          <em className="icon ni ni-cross-circle"></em>
          <span>Annuler</span>
        </button>
        <button className="btn btn-primary" type="submit" onClick={addPaiement}>
          <em className="icon ni ni-check-circle-cut"></em>
          <span>Ajouter un paiement</span>
        </button>
      </Modal.Footer>
    </Modal>
  );
};
