import React from "react";
import { Modal } from "react-bootstrap";
interface IssueInventoryProps {
  show: boolean;
  onHide: () => void;
}

export function IssueInventory({ show, onHide }: IssueInventoryProps) {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Manage Inventory</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Issue an item to a vehicle.</p>
        <div className="row gy-4">
          <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">Item;</label>
              <div className="form-control-wrap">
                <h5>Test</h5>
              </div>
            </div>
          </div>

          <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">
                Select Vehicle / Project issue to
              </label>
              <div className="form-control-wrap">
                <div className="form-control-select">
                  <select
                    className="form-control select2-dynamic"
                    name="project"
                    required
                  >
                    <option value="">Select Vehicle / Project</option>

                    <option value="id">TATA PRIMA • 23424</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">
                Units Consumed (<span className="fw-bold">Available: 2.00</span>
                )
              </label>
              <div className="form-control-wrap">
                <div className="form-text-hint">
                  <span className="overline-title units">UNITS</span>
                </div>
                <input
                  type="number"
                  className="form-control form-control-lg"
                  placeholder="Units Consumed"
                  name="consumed"
                  defaultValue={0.0}
                  step="0.01"
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">Issue Date</label>
              <div className="form-control-wrap">
                <input
                  type="date"
                  className="form-control form-control-lg"
                  placeholder="Issue Date"
                  name="issued_on"
                  required
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">Staff issued to</label>
              <div className="form-control-wrap ">
                <div className="form-control-select">
                  <select
                    className="form-control select2-dynamic"
                    name="issued_to"
                    required
                  >
                    <option value="">Select Staff</option>
                    <option value="">Jane Doe</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className=" bg-light">
        <button
          className="btn btn-white btn-dim btn-outline-light"
          type="button"
          onClick={onHide}
        >
          <em className="icon ni ni-cross-circle"></em>
          <span>Cancel</span>
        </button>
        <button className="btn btn-primary" type="button" onClick={onHide}>
          <em className="icon ni ni-check-circle-cut" />
          <span>Issue Item</span>
        </button>
      </Modal.Footer>
    </Modal>
  );
}
