import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Payments() {
  const [showPageMenu, setShowPageMenu] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">Facturation Paiements</h3>
            <div className="nk-block-des text-soft">
              <p>Une liste de tous vos paiements de facturation.</p>
            </div>
          </div>
          <div className="nk-block-head-content">
            <div className="toggle-wrap nk-block-tools-toggle">
              <a
                onClick={() => setShowPageMenu(!showPageMenu)}
                className="btn btn-icon btn-trigger toggle-expand mr-n1"
                data-target="pageMenu"
              >
                <em className="icon ni ni-menu-alt-r"></em>
              </a>
              <div
                className={`toggle-expand-content ${showPageMenu && "d-block"}`}
                data-content="pageMenu"
              >
                <ul className="nk-block-tools g-3">
                  <li>
                    <a
                      onClick={() => navigate(-1)}
                      className="btn btn-outline-light bg-white d-sm-inline-flex curosr go-back"
                    >
                      <em className="icon ni ni-arrow-left"></em>
                      <span>Retour</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="nk-block">
        <div className="card card-stretch">
          <div className="card-inner">
            <table
              className="datatable-init nk-tb-list nk-tb-ulist"
              data-auto-responsive="false"
            >
              <thead>
                <tr className="nk-tb-item nk-tb-head">
                  <th className="nk-tb-col text-center">#</th>
                  <th className="nk-tb-col">
                    <span className="sub-text">Trans. ID</span>
                  </th>
                  <th className="nk-tb-col tb-col-md">
                    <span className="sub-text">Facturer pour</span>
                  </th>
                  <th className="nk-tb-col tb-col-md">
                    <span className="sub-text">Date</span>
                  </th>
                  <th className="nk-tb-col tb-col-md">
                    <span className="sub-text">Méthode</span>
                  </th>
                  <th className="nk-tb-col">
                    <span className="sub-text">Montante</span>
                  </th>
                  <th className="nk-tb-col tb-col-md">
                    <span className="sub-text">Statut</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="nk-tb-item">
                  <td className="nk-tb-col text-center">1</td>
                  <td className="nk-tb-col">
                    <span className="tb-amount text-primary">test</span>
                  </td>
                  <td className="nk-tb-col tb-col-md">
                    <div className="user-card">
                      <div className="user-info">
                        <span className="tb-lead">Crédits SMS recharger</span>

                        {/* <span className="tb-lead">abc</span> */}
                      </div>
                    </div>
                  </td>
                  <td className="nk-tb-col tb-col-md">
                    <span>10 janvier 2021 </span>
                  </td>
                  <td className="nk-tb-col tb-col-md">
                    <span className="text-capitalize">test</span>
                  </td>
                  <td className="nk-tb-col">
                    <span className="tb-amount">$123</span>
                  </td>
                  <td className="nk-tb-col tb-col-md">
                    <span className="badge badge-sm badge-dot has-bg badge-primary d-mb-inline-flex">
                      Nouvelle
                    </span>

                    {/* <span className="badge badge-sm badge-dot has-bg badge-success d-mb-inline-flex">Payé</span> */}
                  </td>
                </tr>
                {/* <tr>
                  <td className="text-center" colSpan={7}>
                    C'est vide ici !
                  </td>
                </tr> */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
