import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { clientHooks } from "../../network";
import TableauClient from "../../components/Tableau/TableauClient";
import Loading from "../../components/Loading";
import { CreateClient } from "../../Modal";


const INIT_STATE_MODAL = {
  showCreate: false,
  showPageMenu: false,
  showUpdate: false,
  showSendSMS: false,
  showCreateJobCard: false,
  showCreateQuote: false,
  showCreateInvoice: false,
};

export default function Clients() {
  const [showModal, setShowModal] = useState(INIT_STATE_MODAL);
  const [showPageMenu, setShowPageMenu] = useState(false);
  const [nom, setNom] = useState<string>('Test');
  const [prenom, setPrenom] = useState<string>('Test');
  const [email, setEmail] = useState<string>('test@gmail.com');
  const [tache, setTache] = useState<string>('0/0');
  const [dateCreation, setDateCreation] = useState<string>('May 20,2022');
  const [statut, setStatut] = useState<string>('Active');
  const [pageActive, setPageActive] = useState<number>(1);
  const { data, isLoading } = clientHooks.useGetClientPaginate(pageActive);


  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "This client's profile and data will be deleted permanently.",
      confirmButtonText: "Yes, delete!",
      cancelButtonText: `Cancel`,
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: "#fff",
    }).then((result) => {
      if (result.isConfirmed) {
        ///When click Yes
      }
    });
  };

  const nextPage = () => {
    if (data?.data){
      if (pageActive < data.data.countPage) {
        setPageActive(pageActive + 1);
      } else {
        setPageActive(pageActive);
      }
    }
  }

  const prevPage = () => {
    if (pageActive > 1) {
      setPageActive(pageActive - 1);
    } else {
      setPageActive(pageActive);
    }
  }

  const goToPage = (numPage: number) => {
    setPageActive(numPage);
  }

  useEffect(() => {
    if (data?.hasMore) {
      clientHooks.usePrefetchClient(pageActive)
    }
  }, [data, pageActive])

  if (isLoading ){
    return (<div className="loading__wrapper">
    <Loading />
  </div>)
  }
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          {isLoading ? <Loading /> : <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">Liste des clients</h3>
            <div className="nk-block-des text-soft">
              <p>Un total de {data?.data.countTotal} clients.</p>

              {/* <p>Créez et gérez vos clients ici.</p> */}
            </div>
          </div>}
          {/* .nk-block-head-content */}
          <div className="nk-block-head-content">
            <div className="toggle-wrap nk-block-tools-toggle">
              <a
                onClick={() => setShowPageMenu(!showPageMenu)}
                className="btn btn-icon btn-trigger toggle-expand mr-n1"
                data-target="pageMenu"
              >
                <em className="icon ni ni-menu-alt-r"></em>
              </a>
              <div
                className={`toggle-expand-content ${showPageMenu && "d-block"}`}
                data-content="pageMenu"
              >
                <ul className="nk-block-tools g-3">
                  <li>
                    <button
                      onClick={() =>
                        setShowModal({ ...showModal, showCreate: true })
                      }
                      className="btn btn-primary"
                    >
                      <em className="icon ni ni-plus"></em>
                      <span>Créer un client</span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            {/* .toggle-wrap */}
          </div>
          {/* .nk-block-head-content */}
        </div>
        {/* .nk-block-between */}
      </div>
      {isLoading ? <Loading /> : (data && data.data ? <TableauClient data={data?.data} nextPage={nextPage} prevPage={prevPage} goToPage={goToPage} /> : null)}


      <CreateClient
        show={showModal.showCreate}
        onHide={() => setShowModal({ ...showModal, showCreate: false })}
      />

    </>
  );
}
