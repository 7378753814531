import {Navigate, Outlet, useLocation} from 'react-router-dom'

const useAuth=()=>{
  const token=localStorage.getItem('token')
  if(token){
    return true
  } else {
    return false
  }
}

const  PrivateRoutes=(props:any) =>{

  const auth = useAuth()

  const location = useLocation();

  localStorage.setItem('lastNavigate', location.pathname);

  return auth ? <Outlet/>: <Navigate to="/" />
}

export default PrivateRoutes;