import React, { useState } from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Swal from 'sweetalert2'


import './LoginStyle.css';
import './LoginComponent.css';
import { Link, useNavigate } from 'react-router-dom';
import { userHooks } from '../../network';
import Loading from '../../components/Loading';
import logoDark from "../../assets/images/logo_site.jpeg";

export default function Authentication() {
    const [passwordType, setPasswordType] = useState<string>('password');
    const route = useNavigate();
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const { data, mutate, isLoading, isSuccess, isError } = userHooks.useLogin();


    const changePasswordType = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }



    const handleSubmit = async (event: any) => {
        event.preventDefault();

        return mutate({ email, password }, {
            onSuccess: async (data) => {
                await localStorage.setItem('token', data.data.accessToken.token)
                await localStorage.setItem('AUTH_USER', JSON.stringify(data.data.user))

                const lastNavigate = localStorage.getItem('lastNavigate');

                window.location.href = lastNavigate ? lastNavigate : '/home';
            },
            onError: (data) => {
                Swal.fire({
                    icon: 'error',
                    text: 'Email ou mot de passe incorrect',
                });
            },
            onSettled: async (data) => {
                console.log('tonga ato oooo')
            },
        });
    }

    return (
        <>
            <div className={`containers m-0  p-0 w-100 `}>
                <div className="forms-containers">
                    <div className="signin-signup">
                        <Row>
                            <Col md={{ span: 8, offset: 2 }}>
                                <Card>
                                    <Card.Body>
                                        <h3>
                                            Connexion
                                        </h3>
                                        <form onSubmit={handleSubmit}>
                                            <div className="row gy-4">
                                                <div className="col-sm-12 mt-0">
                                                    <div className="form-group">
                                                        <label className="form-label">Adresse e-mail</label>
                                                        <div className="form-control-wrap">
                                                            <input
                                                                type="email"
                                                                className="form-control form-control-lg"
                                                                placeholder="Adresse e-mail"
                                                                name="email"
                                                                required
                                                                onChange={e => setEmail(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 mt-0">
                                                    <div className="form-group">
                                                        <label className="form-label">Mot de passe</label>
                                                        <div className="form-control-wrap">
                                                            <a
                                                                onClick={changePasswordType}
                                                                className="form-icon form-icon-right passcode-switch"
                                                                data-target="current"
                                                            >
                                                                {passwordType === "password" ? <em className="passcode-icon icon-show icon ni ni-eye"></em> :
                                                                    <em className="passcode-icon icon-show icon ni ni-eye-off"></em>
                                                                }
                                                            </a>
                                                            <input
                                                                type={passwordType}
                                                                className="form-control form-control-lg"
                                                                id="current"
                                                                name="current"
                                                                placeholder="Mot de passe"
                                                                required
                                                                onChange={e => setPassword(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-sm-6 offset-9'>
                                                <br />
                                                <Link to="/forgot-password">Mot de passe oublié</Link>
                                            </div>
                                            <div className='col-sm-4 offset-9'>
                                                <br />
                                                <button className="btn btn-primary" type="submit">
                                                    <span>Se connecter</span>
                                                </button>
                                            </div>
                                        </form>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>

                <div className="panels-containers">
                    <div className="panel left-panel">
                        <img
                            src="https://imgur.com/u3ksg82.png"
                            className="imageV"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
