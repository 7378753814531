import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { AddSupplier, UpdateSupplier } from "../../Modal";
import { SupplierReport } from "../../Modal/SupplierReport";
import TableauSuppliers from '../../components/Tableau/TableauSuppliers';
import { fournisseurHooks } from '../../network/fournisseur';
import Loading from '../../components/Loading';

export default function Suppliers() {
  const [showAdd, setShowAdd] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [showPageMenu, setShowPageMenu] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [pageActive, setPageActive] = useState<number>(1);
  const { data, isLoading } = fournisseurHooks.useGetFournisseurPaginate(pageActive);

  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "This supplier's profile will be deleted permanently.",
      confirmButtonText: "Yes, delete!",
      cancelButtonText: `Cancel`,
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        ///When click Yes
      }
    });
  };

  const nextPage = () => {

    if (pageActive < data.data.countPage) {
      setPageActive(pageActive + 1);
    } else {
      setPageActive(pageActive);
    }
  }

  const prevPage = () => {
    if (pageActive > 1) {
      setPageActive(pageActive - 1);
    } else {
      setPageActive(pageActive);
    }
  }

  const goToPage = (numPage: number) => {
    setPageActive(numPage);
  }

  useEffect(() => {
    if (data?.hasMore) {
      fournisseurHooks.usePrefetchFournisseur(pageActive)
    }
  }, [data, pageActive])


  if (isLoading){
    return (<div className="loading__wrapper">
    <Loading />
  </div>)
  }
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          {isLoading ? <Loading /> : <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">Fournisseurs</h3>
            <div className="nk-block-des text-soft">
              <p>Un total de {data.data.countTotal} fournisseurs.</p>

              {/* <p>Créez et gérez la liste de vos fournisseurs ici.</p> */}
            </div>
          </div>}
          <div className="nk-block-head-content">
            <div className="toggle-wrap nk-block-tools-toggle">
              <a
                onClick={() => setShowPageMenu(!showPageMenu)}
                className="btn btn-icon btn-trigger toggle-expand mr-n1"
                data-target="pageMenu"
              >
                <em className="icon ni ni-menu-alt-r"></em>
              </a>
              <div
                className={`toggle-expand-content ${showPageMenu && "d-block"}`}
                data-content="pageMenu"
              >
                <ul className="nk-block-tools g-3">
                  <li>
                    <button
                      onClick={() => setShowAdd(true)}
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#create"
                    >
                      <em className="icon ni ni-plus"></em>
                      <span>Ajouter un fournisseur</span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading ? <Loading /> : <TableauSuppliers data={data.data} nextPage={nextPage} prevPage={prevPage} goToPage={goToPage} />}
      <AddSupplier show={showAdd} onHide={() => setShowAdd(false)} />
    </>
  );
}
