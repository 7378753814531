import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import {
  CreateTeamMember,
  SendSMS,
  TeamReport,
  UpdateTeamMember,
} from "../../Modal";

export default function Team() {
  const [showCreate, setShowCreate] = useState(false);
  const [showPageMenu, setShowPageMenu] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [showSendSMS, setShowSendSMS] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "This member's profile and data will be deleted permanently.",
      confirmButtonText: "Yes, delete!",
      cancelButtonText: `Cancel`,
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        ///When click Yes
      }
    });
  };
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">Membres de l'équipe</h3>
            <div className="nk-block-des text-soft">
              <p>Un total de 1 membres de l'équipe.</p>

              {/* <p>Create and manage your team here.</p> */}
            </div>
          </div>
          <div className="nk-block-head-content">
            <div className="toggle-wrap nk-block-tools-toggle">
              <a
                onClick={() => setShowPageMenu(!showPageMenu)}
                className="btn btn-icon btn-trigger toggle-expand mr-n1"
                data-target="pageMenu"
              >
                <em className="icon ni ni-menu-alt-r"></em>
              </a>
              <div
                className={`toggle-expand-content ${showPageMenu && "d-block"}`}
                data-content="pageMenu"
              >
                <ul className="nk-block-tools g-3">
                  <li>
                    <button
                      onClick={() => setShowCreate(true)}
                      className="btn btn-primary"
                    >
                      <em className="icon ni ni-plus"></em>
                      <span>Créer un membre</span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="nk-block">
        <div className="card card-stretch">
          <div className="card-inner">
            <table
              className="datatable-init nk-tb-list nk-tb-ulist"
              data-auto-responsive="false"
            >
              <thead>
                <tr className="nk-tb-item nk-tb-head">
                  <th className="nk-tb-col text-center">#</th>
                  <th className="nk-tb-col">
                    <span className="sub-text">Membre</span>
                  </th>
                  <th className="nk-tb-col tb-col-md">
                    <span className="sub-text">Rôle</span>
                  </th>
                  <th className="nk-tb-col tb-col-md">
                    <span className="sub-text">Solde</span>
                  </th>
                  <th className="nk-tb-col tb-col-md text-center">
                    <span
                      className="sub-text"
                      data-toggle="tooltip"
                      title="Tâches en cours"
                    >
                      Tâches en cours <em className="icon ni ni-info-fill"></em>
                    </span>
                  </th>
                  <th className="nk-tb-col tb-col-md">
                    <span className="sub-text">Créé sur</span>
                  </th>
                  <th className="nk-tb-col tb-col-md">
                    <span className="sub-text">Statut</span>
                  </th>
                  <th className="nk-tb-col nk-tb-col-tools text-right"></th>
                </tr>
              </thead>
              <tbody>
                <tr className="nk-tb-item">
                  <td className="nk-tb-col text-center">1</td>
                  <td className="nk-tb-col">
                    <div className="user-card">
                      <div className="user-avatar bg-dim-primary d-none d-sm-flex">
                        <span>Ng</span>
                      </div>
                      <div className="user-info">
                        <span className="tb-lead">
                          Nghia Le{" "}
                          <span className="dot dot-success d-md-none ml-1"></span>
                        </span>
                        <span>+84832127402</span>
                      </div>
                    </div>
                  </td>
                  <td className="nk-tb-col tb-col-mb">
                    <span className="badge badge-sm badge-dot has-bg badge-success d-mb-inline-flex">
                      Propriétaire
                    </span>
                    {/* <span className="badge badge-sm badge-dot has-bg badge-primary d-mb-inline-flex">Gestionnaire</span> */}
                    {/* <span className="badge badge-sm badge-dot has-bg badge-danger d-mb-inline-flex">Gestionnaire d'inventaire</span> */}
                    {/* <span className="badge badge-sm badge-dot has-bg badge-secondary d-mb-inline-flex">Responsable des réservations</span> */}
                    {/* <span className="badge badge-sm badge-dot has-bg badge-warning d-mb-inline-flex">Personnelle</span> */}
                  </td>
                  <td
                    className="nk-tb-col tb-col-mb"
                    data-order="{{ $member->balance }}"
                  >
                    <span className="tb-amount">$0.00</span>
                  </td>
                  <td className="nk-tb-col tb-col-md text-center">
                    <span>1 / 3</span>
                  </td>
                  <td className="nk-tb-col tb-col-md">
                    <span>May 24, 2022 </span>
                  </td>
                  <td className="nk-tb-col tb-col-md">
                    <span className="text-success">Active</span>
                    {/* <span className="text-warning">En congé</span> */}
                    {/* <span className="text-danger">Indisponible</span> */}
                  </td>
                  <td className="nk-tb-col nk-tb-col-tools">
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <Dropdown>
                          <Dropdown.Toggle
                            as="a"
                            className="btn btn-icon btn-trigger"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <Link to="details">
                                  <em className="icon ni ni-eye"></em>
                                  <span>Voir les détails</span>
                                </Link>
                              </li>
                              <li>
                                <a
                                  className="fetch-display-click cursor"
                                  onClick={() => setShowUpdate(true)}
                                >
                                  <em className="icon ni ni-pen"></em>
                                  <span>Modifier les détails</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  className="send-sms cursor"
                                  onClick={() => setShowSendSMS(true)}
                                >
                                  <em className="icon ni ni-chat-circle"></em>
                                  <span>Envoyer un SMS</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  className="team-report cursor"
                                  onClick={() => setShowReport(true)}
                                >
                                  <em className="icon ni ni-reports"></em>
                                  <span>Voir le rapport</span>
                                </a>
                              </li>

                              <li className="divider"></li>
                              <li>
                                <a
                                  className="send-to-server-click cursor"
                                  onClick={handleDelete}
                                >
                                  <em className="icon ni ni-trash"></em>
                                  <span>Supprimer le membre</span>
                                </a>
                              </li>
                            </ul>
                          </Dropdown.Menu>
                        </Dropdown>
                      </li>
                    </ul>
                  </td>
                </tr>
                {/* <tr>
                                                        <td className="text-center" colSpan={8}>C'est vide ici !</td>
                                                    </tr> */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <CreateTeamMember show={showCreate} onHide={() => setShowCreate(false)} />
      <UpdateTeamMember show={showUpdate} onHide={() => setShowUpdate(false)} />

      <SendSMS show={showSendSMS} onHide={() => setShowSendSMS(false)} />
      <TeamReport show={showReport} onHide={() => setShowReport(false)} />
    </>
  );
}
