import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import {
  arrayMove,
  SortableContainer,
  SortableElement,
} from "react-sortable-hoc";
import SortableList from "./SortableList";

interface OrderPartsProps {
  show: boolean;
  onHide: () => void;
}
const DATA = [
  "Ext/Mirrors P/M",
  "Ext/Badge/Stickers",
  "Spare Wheel",
  "Door Locks",
  "Sport Lights",
  "Bonnet Badge",
  "W/Arms/Blades",
  "Insurance Cert",
  "Inspection Stickers",
  "Fire Extinguisher",
  "Fuel Tank Cap",
  "Fuel Tank Lid Lock",
  "Relay",
  "Washer Bag",
  "Horns",
  "Oil Filter Cap",
  "Radiator Cap",
  "Battery MK",
  "Hub Caps",
  "Mud Flaps",
  "Arial Auto/MAN",
  "First Aid Box",
  "Screw Driver",
  "Interior Light",
  "Cigar Lighter",
  "Head Rest",
  "Seat Belts",
  "Winder Handles Switches",
  "Door Lock Knobs",
  "Heater Knobs",
  "Clock",
  "Radio Speaker",
  "Rear View Mirror",
  "Floor Carpets",
  "Pliers",
  "W/Spanner",
  "W/Triangle",
  "Jack / Handle",
  "Tool Kit/Brace",
  "Wheel Lock key",
  "Boot Mats",
  "Boot Badge",
  "Rubber Mats",
  "Radio",
];

export function OrderParts({ show, onHide }: OrderPartsProps) {
  const [items, setItems] = useState(
    DATA.map((x, index) => ({ value: x, id: index }))
  );
  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    setItems(arrayMove(items, oldIndex, newIndex));
  };
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Order Parts</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Arrange parts order</p>
        <div className="row gy-4">
          <div className="col-md-12 reorder">
            <SortableList onSortEnd={onSortEnd} items={items} />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className=" bg-light">
        <button
          className="btn btn-white btn-dim btn-outline-light"
          type="button"
          onClick={onHide}
        >
          <em className="icon ni ni-cross-circle"></em>
          <span>Cancel</span>
        </button>
      </Modal.Footer>
    </Modal>
  );
}
