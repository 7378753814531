import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import Swal from "sweetalert2";
import { UpdateTask, ViewTask } from "../../Modal";

export default function Tasks() {
  const [showView, setShowView] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "This task will be deleted permanently.",
      confirmButtonText: "Yes, delete!",
      cancelButtonText: `Cancel`,
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: "#fff",
    }).then((result) => {
      if (result.isConfirmed) {
        ///When click Yes
      }
    });
  };
  const handleCancel = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "This task will be marked as cancelled.",
      confirmButtonText: "Yes, Cancel!",
      cancelButtonText: `Cancel`,
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: "#fff",
    }).then((result) => {
      if (result.isConfirmed) {
        ///When click Yes
      }
    });
  };
  return (
    <>
      <div className="card-inner">
        <div className="nk-block mb-2">
          <div className="nk-block-head">
            <h5 className="title">Assigned Tasks</h5>
            <p>A list of tasks assigned to Nghia Le.</p>
          </div>
        </div>
        <table className="datatable-init nk-tb-list nk-tb-ulist mt">
          <thead>
            <tr className="nk-tb-item nk-tb-head">
              <th className="nk-tb-col text-center">#</th>
              <th className="nk-tb-col tb-col-md">
                <span className="sub-text">Project</span>
              </th>
              <th className="nk-tb-col">
                <span className="sub-text">Title</span>
              </th>
              <th className="nk-tb-col tb-col-md">
                <span className="sub-text">Due Date</span>
              </th>
              <th className="nk-tb-col tb-col-md">
                <span className="sub-text">Cost</span>
              </th>
              <th className="nk-tb-col tb-col-md">
                <span className="sub-text">Status</span>
              </th>
              <th className="nk-tb-col nk-tb-col-tools text-right"></th>
            </tr>
          </thead>
          <tbody>
            <tr className="nk-tb-item">
              <td className="nk-tb-col text-center">1</td>
              <td className="nk-tb-col tb-col-md">
                <div className="user-card">
                  <div className="user-info">
                    <span className="tb-lead">TATA PRIMA</span>
                  </div>
                </div>
                <span>23424</span>
              </td>
              <td className="nk-tb-col">
                <span className="tb-amount">
                  <span
                    data-toggle="tooltip"
                    title="All required parts have been delivered"
                    className="text-success"
                  >
                    <em className="icon ni ni-info-fill" />
                  </span>{" "}
                  test task
                </span>
              </td>
              <td className="nk-tb-col tb-col-md">
                <span>May 24, 2022 • 02:10pm</span>
              </td>
              <td className="nk-tb-col tb-col-md">
                <span className="tb-amount">$1.23</span>
              </td>
              <td className="nk-tb-col tb-col-md">
                <span className="badge badge-sm badge-dot has-bg badge-success d-mb-inline-flex">
                  Completed
                </span>
                {/* <span className="badge badge-sm badge-dot has-bg badge-secondary d-mb-inline-flex">
                  Cancelled
                </span>
                <span className="badge badge-sm badge-dot has-bg badge-warning d-mb-inline-flex">
                  In Progress
                </span> */}
              </td>
              <td className="nk-tb-col nk-tb-col-tools">
                <ul className="nk-tb-actions gx-1">
                  <li>
                    <Dropdown>
                      <Dropdown.Toggle
                        className="btn btn-icon btn-trigger"
                        as="a"
                      >
                        <em className="icon ni ni-more-h" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <ul className="link-list-opt no-bdr">
                          <li>
                            <a
                              className="fetch-display-click cursor"
                              onClick={() => setShowView(true)}
                            >
                              <em className="icon ni ni-eye" />
                              <span>View Details</span>
                            </a>
                          </li>
                          <li>
                            <a
                              className="fetch-display-click cursor"
                              onClick={() => setShowUpdate(true)}
                            >
                              <em className="icon ni ni-pen" />
                              <span>Edit Details</span>
                            </a>
                          </li>
                          <li>
                            <a className="cursor">
                              <em className="icon ni ni-download-cloud" />
                              <span>Download</span>
                            </a>
                          </li>
                          <li className="divider" />
                          <li>
                            <a
                              className="send-to-server-click cursor"
                              onClick={handleCancel}
                            >
                              <em className="icon ni ni-na" />
                              <span>Cancel Task</span>
                            </a>
                          </li>
                          <li>
                            <a
                              className="send-to-server-click cursor"
                              onClick={handleDelete}
                            >
                              <em className="icon ni ni-trash" />
                              <span>Delete Task</span>
                            </a>
                          </li>
                        </ul>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                </ul>
              </td>
            </tr>
            {/* <tr>
              <td className="text-center" colSpan={8}>
                It's empty here!
              </td>
            </tr> */}
          </tbody>
        </table>
      </div>
      {/* <ViewTask show={showView} onHide={() => setShowView(false)} /> */}
      <UpdateTask show={showUpdate} onHide={() => setShowUpdate(false)} />
    </>
  );
}
