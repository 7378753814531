import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { ImportTasks } from "../../../Modal";
import { SelectJobCard } from "../../../Modal/SelectJobCard";
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import PausePresentationIcon from '@mui/icons-material/PausePresentation';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { IconButton } from "@mui/material";
import { workStepHooks } from "../../../network/work-step";
import moment, { Moment } from "moment";
import ConfirmActionWork from "../../../Modal/ConfirmActionWork";
import { workTimeHooks } from "../../../network/work-time";
import { useQueryClient } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { PlanWork } from "../../../Modal/PlanWork";
import ConfirmEndWork from "../../../Modal/confirmEndWork";
import type { UploadFile } from "antd";
import api from "../../../axios";
import { workEntityType, workStepEntityType, statusType } from "../../../types";
import { formatTime } from "../TableResumeTasks";

const user = JSON.parse(localStorage.getItem('AUTH_USER') ?? '{}');

type Props = {
  data: workStepEntityType[]
}

export default function TableauWorkStep({ data }: Props) {

  const queryClient = useQueryClient()
  const [showImport, setShowImport] = useState(false);
  const [showSelect, setShowSelect] = useState(false);
  const [openConfirmStart,setOpenConfirmStart] = useState(false)
  const [openConfirmPause,setOpenConfirmPause] = useState(false)
  const [openConfirmEnd,setOpenConfirmEnd] = useState(false)
  const [openConfirmAbort,setOpenConfirmAbort] = useState(false)
  const [openSetPlanning,setOpenSetPlanning] = useState(false) 
  const [fileList, setFileList] = useState<UploadFile[]>([])

  const [selectedWorkStep, setSelectedWorkStep] = useState<any>({});

  const { mutate: mutateUpdateWorkStep } = workStepHooks.useUpdateWorkStep();
  const { mutate: mutateAddWorkStepImage } = workStepHooks.useAddWorkStepImage();


  const [work, setWork] = useState<workEntityType>({
    idWork: 0,
    workSteps: [],
    projets: {
      idProjet: 0,
      vehicules: {
        detailVehicules: {
          marque: '',
          model: '',
          numeroImmatriculation: '',
        },
        clients: {
          nomPrenom: '',
          adresse: ''
        }
      },
      status: 'A faire',
      dateDebut: '',
      dateFin: '', 
    }
  });



  const handleClickOpenConfirmStart = (item) => {
    setOpenConfirmStart(true);
    setSelectedWorkStep(item)
  };

  const handleClickOpenConfirmEnd = (item) => {
    setSelectedWorkStep(item)
    setOpenConfirmEnd(true);
  };

  const handleClickOpenConfirmAbort = (item) => {
    setOpenConfirmAbort(true);
    setSelectedWorkStep(item)
  };

  const handleConfirmStart = (isConfirmed) => {
    if(isConfirmed) {
      updateWorkStep('En cours', isConfirmed)
    }
    setOpenConfirmStart(false);
  }

  //const handleConfirmEnd = async (isConfirmed) => {
  //  if(isConfirmed) {
  //    await updateWorkStep('Complété', selectedWorkStep)
  //  }
  //}

  const handleConfirmAbort = (isConfirmed) => {
    if(isConfirmed) {
      updateWorkStep('Annulé', selectedWorkStep)
    }
    setOpenConfirmAbort(false);
  }

  const handleConfirmPause = (isConfirmed) => {
    if(isConfirmed) {
      updateWorkStep('Suspendu', isConfirmed)
    }
    setOpenConfirmPause(false);
  }


  const updateWorkStep = async (status: statusType, item: workStepEntityType, startAtValue = moment()) => {
    let workStepData = {...item, status};
    if (status === 'Planifié') {
      workStepData = {
        ...workStepData,
        startAt: startAtValue.toISOString(),
      };
    }
    if (status === 'Complété'){
      if (fileList.length > 0 && item.idWorkStep) {
        mutateAddWorkStepImage({
          idWorkStep: item.idWorkStep,
          images: fileList,
        }, {
          onSuccess: () => {
            setFileList([]);
          }
        })
        try {
          
        } catch(e) {
          Swal.fire('Erreur', "Une erreur est survenue lors de l'importation des l'image", 'error')
        }
       }
       
    }
    return mutateUpdateWorkStep({idWorkStep: item.idWorkStep ?? -1, workStep: workStepData}, {
      onSuccess: () => {
        Swal.fire("Terminé", "Mis à jour réussi", 'success')
        setOpenConfirmEnd(false);
        queryClient.invalidateQueries(['WorkStepByMember'])
      },
    })
  }

  const submitPlanWork = (value) => {
    updateWorkStep(
      'Planifié',
      selectedWorkStep,
      moment(value?.toDate()));
  }

  return (
    <>
      <div className="d-flex flex-column align-items-end">
        <table className="datatable-init nk-tb-list nk-tb-ulist mt">
          <thead>
            <tr className="nk-tb-item nk-tb-head">
              <th className="nk-tb-col text-center">#</th>
              <th className="nk-tb-col tb-col-md">
                <span className="sub-text">Responsable</span>
              </th>
              <th className="nk-tb-col tb-col-md">
                <span className="sub-text">Vehicules</span>
              </th>
              <th className="nk-tb-col">
                <span className="sub-text">Tâches a faire</span>
              </th>
              <th className="nk-tb-col tb-col-md">
                <span className="sub-text">Estimation</span>
              </th>
              <th className="nk-tb-col tb-col-md">
                <span className="sub-text">Realisation</span>
              </th>
              <th className="nk-tb-col tb-col-md">
                <span className="sub-text">Status</span>
              </th>
              <th className="nk-tb-col nk-tb-col-tools text-right"></th>
            </tr>
          </thead>
          {data && data.map((item, index) => (
            <tbody key={index}>
              <tr className="nk-tb-item">
                <td className="nk-tb-col text-center">{index + 1}</td>
                <td className="nk-tb-col tb-col-md">
                  <div className="user-card">
                    <div className="user-info">
                      <span className="tb-lead">{`${item?.assignTo?.prenom} ${item?.assignTo?.nom}`}</span>
                    </div>
                  </div>
                </td>
                <td className="nk-tb-col">
                  <div className="user-card">
                    <div className="user-info">
                      <Link to={`/vehicule/details/${item?.works?.projets?.idProjet}`}>
                        <span className="tb-lead">{
                          `${item?.works?.projets?.vehicules?.detailVehicules?.marque} ${item?.works?.projets?.vehicules?.detailVehicules?.model} (${item?.works?.projets?.vehicules?.detailVehicules?.numeroImmatriculation})`
                        }</span>
                      </Link>
                    </div>
                  </div>
                </td>
                <td className="nk-tb-col tb-col-md">
                  <div className="user-card">
                    <div className="user-info">
                      <span className="tb-lead">{item?.taskGroup?.taskGroupLabel}</span>
                      <ul>
                        {
                          item?.taskGroup?.tasks?.map( (t, index) => (
                            <li key={index}>
                              {t.taskTitle}
                            </li>
                          ))
                        }
                      </ul>
                    </div>
                  </div>
                </td>
                <td className="nk-tb-col">
                  <div className="user-card">
                    <div className="user-info">
                      <span className="tb-lead">{formatTime(item.estimate)}</span>
                    </div>
                  </div>
                </td>
                <td className="nk-tb-col">
                  <div className="user-card">
                    <div className="user-info">
                      <span className="tb-lead">{item.status === 'En cours' 
                      ?formatTime (((new Date().getTime() - new Date(item.lastBreakStart ?? 0).getTime()) / 1000) + (item.duration ?? 0))  
                      : formatTime(item.duration)}</span>
                    </div>
                  </div>
                </td>
                <td className="nk-tb-col">
                  <div className="user-card">
                    <div className="user-info">
                      {
                        item.status === 'A faire' ? (
                          <>
                            <span className="badge badge-sm badge-dot has-bg badge-info d-mb-inline-flex">
                              {item.status}
                            </span>
                          </>
                        ) : (<></>)
                      }
                      {
                        item.status === 'Planifié' ? (
                          <>
                            <span className="badge badge-sm badge-dot has-bg badge-info d-mb-inline-flex">
                              {item.status}
                            </span>
                          </>
                        ) : (<></>)
                      }
                      {
                        item.status === 'En cours' ? (
                          <span className="badge badge-sm badge-dot has-bg badge-warning d-mb-inline-flex">
                            {item.status}
                          </span>
                        ) : (<></>)
                      }
                      {
                        item.status === 'Suspendu' ? (
                          <span className="badge badge-sm badge-dot has-bg badge-warning d-mb-inline-flex">
                            {item.status}
                          </span>
                        ) : (<></>)
                      }
                      {
                        item.status === 'Complété' ? (
                          <span className="badge badge-sm badge-dot has-bg badge-success d-mb-inline-flex">
                            {item.status}
                          </span>
                        ) : (<></>)
                      }
                      {
                        item.status === 'Annulé' ? (
                          <span className="badge badge-sm badge-dot has-bg badge-danger d-mb-inline-flex">
                            {item.status}
                          </span>
                        ) : (<></>)
                      }
                    </div>
                  </div>
                </td>
                <td className="nk-tb-col tb-col-md">
                  <ul className="d-flex flex-row flex-wrap">
                    {
                      (item.status !== 'En cours'  && item.status !== 'Annulé' && item.status !== 'Suspendu' && item.status !== 'Complété' && user.membres.role !== 'Admin') ? (
                        <li>
                          <IconButton
                            color="info"
                            title="Commencer"
                            onClick={() => handleClickOpenConfirmStart(item)}
                          ><ScheduleSendIcon/></IconButton>
                        </li>
                      ) : (<></>)
                    }
                    {
                      (item.status === 'En cours' && user.membres.role !== 'Admin') ? (
                        <li>
                          <IconButton
                            color="warning"
                            title="Suspendre"
                            onClick={() => handleConfirmPause(item)}
                          ><PausePresentationIcon/></IconButton>
                        </li>
                      ) : (<></>)
                    }
                    {
                      (item.status === 'Suspendu' && user.membres.role !== 'Admin') ? (
                        <li>
                          <IconButton
                            color="success"
                            title="Reprendre"
                            onClick={() => handleClickOpenConfirmStart(item)}
                          ><ScheduleSendIcon/></IconButton>
                        </li>
                      ) : (<></>)
                    }
                    {
                       user.membres.role !== 'Admin' && item.status === 'A faire' ? (
                        <li>
                          <IconButton
                            color="success"
                            title="Planifier"
                            disabled={item.status !== 'A faire'}
                            onClick={() => {
                              setOpenSetPlanning(true);
                              setSelectedWorkStep(item);
                            }}
                          ><FactCheckIcon/></IconButton>
                        </li>
                       ) : (<></>)
                    }
                    {
                       user.membres.role !== 'Admin' && item.status !== 'Complété' && item.status !== 'Annulé' ? (
                        <li>
                          <IconButton
                            color="error"
                            title="Annuler"
                            onClick={() => handleClickOpenConfirmAbort(item)}
                          ><EventBusyIcon/></IconButton>
                        </li>
                       ) : (<></>)
                    }
                    {
                      item.status !== 'Planifié' && item.status !== 'Complété' && item.status !== 'A faire' && item.status !== 'Annulé' && (
                        <li>
                          <IconButton
                            color="success"
                            title="Terminer"
                            onClick={() => handleClickOpenConfirmEnd(item)}
                          ><TaskAltIcon/></IconButton>
                        </li>
                      )
                    }
                  </ul>
                </td>
              </tr>
              <ImportTasks show={showImport} onHide={() => setShowImport(false)} />
              <SelectJobCard
                show={showSelect}
                onHide={() => setShowSelect(false)}
                onSelectJobCard={() => {
                  setShowSelect(false);
                  setShowImport(true);
                }}
              />
            </tbody>))}
        </table>
        <ConfirmActionWork open={openConfirmPause} value={selectedWorkStep} onClose={handleConfirmPause} message={'Reprendre les travaux ?'} />
        <ConfirmActionWork open={openConfirmStart} value={selectedWorkStep} onClose={handleConfirmStart} message={'Commencer les travaux ?'} />
        {/*<ConfirmActionWork open={openConfirmEnd} value={selectedWorkStep} onClose={handleConfirmEnd} message={'Terminer les travaux ?'} /> */}
        <ConfirmEndWork fileList={fileList} setFileList={setFileList} isOpen={openConfirmEnd} workStep={selectedWorkStep} onCancel={()=>{setFileList([]);setOpenConfirmEnd(false)}} onConfirm={async ()=>{await updateWorkStep('Complété', selectedWorkStep)}}/>
        <ConfirmActionWork open={openConfirmAbort} value={selectedWorkStep} onClose={handleConfirmAbort} message={'Annuler les travaux ?'} />
        <PlanWork show={openSetPlanning} onHide={() => setOpenSetPlanning(false)} onSubmit={submitPlanWork} />
      
      </div>
    </>
  );
}
