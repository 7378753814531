import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { fournisseurHooks } from "../../network/fournisseur";
import Swal from "sweetalert2";

export const AddSupplier = ({ show, onHide }: any) => {
  const [nom, setNom] = useState<string>('');
  const [telephone, setTelephone] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [adresse, setAdresse] = useState<string>('');
  const [nipTva, setNipTva] = useState<string>('');
  const [errorNom, setErrorNom] = useState<boolean>(false);
  const [errorPhone, setErrorPhone] = useState<boolean>(false);
  const [errorEmail, setErrorEmail] = useState<boolean>(false);
  const [errorAdresse, setErrorAdresse] = useState<boolean>(false);
  const [errorTva, setErrorTva] = useState<boolean>(false);
  const [classNom, setClassNom] = useState<string>('form-control form-control-lg')
  const [classPhone, setClassPhone] = useState<string>('form-control form-control-lg phone-input');
  const [classEmail, setClassEmail] = useState<string>('form-control form-control-lg');
  const [classAdresse, setClassAdresse] = useState<string>('form-control form-control-lg');
  const [classTva, setClassTva] = useState<string>('form-control form-control-lg');


  const { mutate } = fournisseurHooks.useAddFournisseur();

  const checkNom = () => {
    if (nom.trim().length === 0) {
      setErrorNom(true)
      setClassNom('form-control form-control-lg is-invalid')
      return true
    } else {
      setErrorNom(false)
      setClassNom('form-control form-control-lg')
      return false
    }
  }

  const checkPhone = () => {
    if (telephone.trim().length === 0) {
      setErrorPhone(true)
      setClassPhone('form-control form-control-lg phone-input is-invalid')
      return true
    } else {
      setErrorPhone(false)
      setClassPhone('form-control form-control-lg phone-input')
      return false
    }
  }

  const checkEmail = () => {
    if (email.trim().length === 0) {
      setErrorEmail(true)
      setClassEmail('form-control form-control-lg is-invalid')
      return true
    } else {
      setErrorEmail(false)
      setClassEmail('form-control form-control-lg')
      return false
    }
  }

  const checkAdresse = () => {
    if (adresse.trim().length === 0) {
      setErrorAdresse(true)
      setClassAdresse('form-control form-control-lg is-invalid')
      return true
    } else {
      setErrorAdresse(false)
      setClassAdresse('form-control form-control-lg')
      return false
    }
  }

  const checkTva = () => {
    if (nipTva.trim().length === 0) {
      setErrorTva(true)
      setClassTva('form-control form-control-lg is-invalid')
      return true
    } else {
      setErrorTva(false)
      setClassTva('form-control form-control-lg')
      return false
    }
  }

  const createFournisseur = () => {
    const fournisseur = {
      nom: nom,
      telephone: telephone,
      email: email,
      adresse: adresse,
      nipTVA: nipTva,
    }

    if (checkAdresse() || checkEmail() || checkNom() || checkPhone() || checkTva()) {
      checkAdresse()
      checkEmail()
      checkNom()
      checkPhone()
      checkTva()
      Swal.fire({
        icon: 'error',
        text: 'Veuillez remplir tous les champs',
      });
      return;
    }

    return mutate(fournisseur, {
      onSuccess: async (data) => {
        onHide()
      },
      onError: (error) => {
        console.log(error, 'ato ilay erreur ato')
        onHide()
        Swal.fire({
          icon: 'error',
          text: 'Erreur lors de l\'ajout fournisseur',
        });
      },
    })
  }


  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Ajouter un fournisseur</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Ajouter un fournisseur</p>
        <div className="row gy-4">
          <div className="col-sm-12 mt-0">
            <div className="form-group">
              <label className="form-label">Nom du fournisseur</label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  className={classNom}
                  placeholder="Nom du fournisseur"
                  name="fullname"
                  required
                  onChange={e => setNom(e.target.value)}
                  onBlur={checkNom}
                />
              </div>
              {errorNom && <div className="text-danger">Valeur obligatoire</div>}
            </div>
          </div>
          <div className="col-sm-6 mt-0">
            <div className="form-group">
              <label className="form-label">Numéro de téléphone</label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  className={classPhone}
                  placeholder="Numéro de téléphone"
                  required
                  onChange={e => setTelephone(e.target.value)}
                  onBlur={checkPhone}
                />
              </div>
              {errorPhone && <div className="text-danger">Valeur obligatoire</div>}
            </div>
          </div>
          <div className="col-sm-6 mt-0">
            <div className="form-group">
              <label className="form-label">Adresse e-mail</label>
              <div className="form-control-wrap">
                <input
                  type="email"
                  className={classEmail}
                  placeholder="Adresse e-mail"
                  name="email"
                  onChange={e => setEmail(e.target.value)}
                  onBlur={checkEmail}
                />
              </div>
              {errorEmail && <div className="text-danger">Valeur obligatoire</div>}
            </div>
          </div>
          <div className="col-sm-12 mt-0">
            <div className="form-group">
              <label className="form-label">Adresse</label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  className={classAdresse}
                  placeholder="Adresse"
                  name="address"
                  onChange={e => setAdresse(e.target.value)}
                  onBlur={checkAdresse}
                />
              </div>
              {errorAdresse && <div className="text-danger">Valeur obligatoire</div>}
            </div>
          </div>
          <div className="col-sm-12 mt-0">
            <div className="form-group">
              <label className="form-label">NIP TVA</label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  className={classTva}
                  placeholder="NIP TVA"
                  name="address"
                  onChange={e => setNipTva(e.target.value)}
                  onBlur={checkTva}
                />
              </div>
              {errorTva && <div className="text-danger">Valeur obligatoire</div>}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="bg-light">
        <button
          className="btn btn-white btn-dim btn-outline-light"
          type="button"
          onClick={onHide}
        >
          <em className="icon ni ni-cross-circle"></em>
          <span>Annuler</span>
        </button>
        <button className="btn btn-primary" type="submit" onClick={createFournisseur}>
          <em className="icon ni ni-check-circle-cut"></em>
          <span>Ajouter un fournisseur</span>
        </button>
      </Modal.Footer>
    </Modal>
  );
};
