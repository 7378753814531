import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { network } from "./network";
import { tacheEntityPaginationType } from "../../types";

export const hooks = {
  useGetAllTasks() {
    const reponse = useQuery(['allTasks'], network.getAllTasks);

    return reponse;
  },
  useGetTasksByStatus(statut: { statut: string }) {
    const reponse = useQuery(['tasksByStatus', statut], () => network.getTaskByStatus(statut));

    return reponse;
  },
  useGetTaskByVehicule(donne: { page: number, idVehicule: number }) {
    const reponse = useQuery(['taskByVehicule', donne], () => network.getTaskByVehicule(donne), { keepPreviousData: true });


    return reponse;
  },
  usePrefetchTaskVehicule(donne: { page: number, idVehicule: number }) {
    const queryClient = useQueryClient();

    return queryClient.prefetchQuery(['taskByVehicule', donne], () => network.getTaskByVehicule(donne));
  },
  useCreateTask() {
    const queryClient = useQueryClient()
    const reponse = useMutation(network.createTask, {
      onSuccess: () => {
        queryClient.invalidateQueries(['taskByVehicule'])
      }
    });

    return reponse;
  },
  useGetTaskPaginated(page: number) {
    const reponse = useQuery(['taskPaginate', page], () => network.getTaskPaginated(page), { keepPreviousData: true });

    return reponse;
  },
  usePrefetchTask(page: number) {
    const queryClient = useQueryClient();

    return queryClient.prefetchQuery(['taskPaginate', page], () => network.getTaskPaginated(page))
  },
  useGetTaskStatusPaginated(donne: { page: number, status: string }) {
    const reponse = useQuery(['taskStatusPaginate', donne], () => network.getTaskStatusPaginated(donne), { keepPreviousData: true });

    return reponse;
  },
  usePrefetchTaskStatus(donne: { page: number, status: string }) {
    const queryClient = useQueryClient();

    return queryClient.prefetchQuery(['taskStatusPaginate', donne], () => network.getTaskStatusPaginated(donne));
  },
  useDeleteTask() {
    const queryClient = useQueryClient();

    const reponse = useMutation(network.deleteTask, {
      onSuccess: () => {
        queryClient.invalidateQueries(['taskStatusPaginate'])
        queryClient.invalidateQueries(['taskPaginate'])
      }
    });

    return reponse;
  }
} 