import React from "react";
import { Modal } from "react-bootstrap";

export const SendSMS = ({ show, onHide }: any) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Send SMS</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Create an SMS</p>
        <div className="row gy-4">
          <div className="col-sm-12 mt-0">
            <div className="form-group">
              <label className="form-label">Send To</label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Nom et prénom"
                  name="fullname"
                  disabled
                  value="test"
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12 mt-0">
            <div className="form-group">
              <label className="form-label">Numéro de téléphone</label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  className="form-control form-control-lg phone-input"
                  placeholder="Numéro de téléphone"
                  value="0831 217 506"
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12 mt-0">
            <div className="form-group">
              <label className="form-label">Message</label>
              <div className="form-control-wrap">
                <textarea
                  className="form-control form-control-lg"
                  placeholder="Message"
                  name="Message"
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12 mt-0">
            <div className="form-note">
              We'll include your company name{" "}
              <strong>Dotted Craft Limited</strong> at the end of the message.
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="bg-light">
        <button
          className="btn btn-white btn-dim btn-outline-light"
          type="button"
          onClick={onHide}
        >
          <em className="icon ni ni-cross-circle"></em>
          <span>Cancle</span>
        </button>
        <button className="btn btn-primary" type="submit">
          <em className="icon ni ni-check-circle-cut"></em>
          <span>Send Message</span>
        </button>
      </Modal.Footer>
    </Modal>
  );
};
