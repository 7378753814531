import { useState } from 'react';
import SchedulerView from '../../components/SchedulerView';
import { workStepHooks } from '../../network/work-step';
import Loading from '../../components/Loading';
import { taskItemHooks } from '../../network/task-item';

const user = JSON.parse(localStorage.getItem('AUTH_USER') ?? '{}');

function Planning() {
  const {data, isLoading} = workStepHooks.useGetWorkStepByMember(Number(user.membres.idMembre), ['tous']);
  const { data: dataTask, isLoading: taskIsloading } = taskItemHooks.useGetAllTaskItems();
  
  //Affichage hebdomadaire par défaut pour que l'utilisateur ne se perde pas
  const [currentViewName, setCurrentViewName] = useState('work-week');

  if (isLoading || taskIsloading){
    return (<div className="loading__wrapper">
    <Loading />
  </div>)
  }
  return (
    <div className="row g-gs pt-3">
      <div className="col-md-12">
        <div className="card card-full overflow-hidden border-top border-primary">
          {(isLoading) ? (<Loading></Loading>) : (
            <>
              <SchedulerView currentViewName={currentViewName} setCurrentViewName={setCurrentViewName} dataWorkstep={data?.data ?? []} dataTask={{data: dataTask?.data ?? []}} />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Planning