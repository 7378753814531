import { useMutation, useQuery } from "@tanstack/react-query";

import { network } from "./network";

export const hooks = {
  useLogin() {
    const reponse = useMutation(network.login);

    return reponse;
  },
  useForgotPassword() {
    const response = useMutation(network.forgotPassword, {
      onSuccess: (data) => {
        console.log(data)
      }
    })

    return response;
  },
  useEditPassword() {
    const response = useMutation(network.editPassword, {
      onSuccess: (data) => {
        console.log(data)
      }
    })

    return response;
  },
  useNewPassword() {
    const response = useMutation(network.newPassword, {
      onSuccess(data) {
        console.log(data)
      },
    })

    return response
  },
  useRegisterUser() {
    const reponse = useMutation(network.registerUser);


    return reponse;
  },
  useGetMe() {
    const reponse = useQuery(['me'], network.getUserByToken);

    return reponse
  }
}  