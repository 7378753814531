// Layouts

// Pages
import Home from "../pages/Home";
import Clients from "../pages/Clients";
import Peding from "../pages/Task/Pending";
import Expected from "../pages/Parts/Expected";
import Unpaid from "../pages/Parts/Unpaid";
import Insurance from "../pages/Insurance";
import Quotes from "../pages/Quotes";
import Invoices from "../pages/Invoices";
import Payments from "../pages/Project/Payments";
import Inventory from "../pages/Inventory";
import Receiveables from "../pages/Receiveables";
import Issueables from "../pages/Issueables";
import Suppliers from "../pages/Suppliers";
import Marketing from "../pages/Marketing";
import Team from "../pages/Team";
import Settings from "../pages/Settings";
import BillingPayments from "../pages/Billing/Payments";
import Projects from "../pages/Projects";
import BookingParts from "../pages/Settings/BookingParts";
import ClientDetails from "../pages/ClientDetails";
import ProjectDetails from "../pages/ProjectDetails";
import InsuranceDetails from "../pages/InsuranceDetails";
import Recipients from "../pages/Marketing/Recipients";
import TeamDetails from "../pages/TeamDetails";
import JobCardView from "../pages/JobCards/View";
import InvoiceView from "../pages/Invoices/View";
import QuoteView from "../pages/Quotes/View";
import InventoryView from "../pages/Inventory/report/view";
import SupplierView from "../pages/Suppliers/report";
import ProjectView from "../pages/Projects/report/view";
import ProjectBooking from "../pages/Projects/report/booking";
import TeamView from "../pages/Team/report";
import Authentication from "../pages/Authentication";
import ForgotPasword from "../pages/Authentication/ForgotPassword";
import NewPassword from "../pages/Authentication/NewPassword";
import Technicien from "../pages/Technicien";
import Admin from "../pages/Admin";
import TechDetails from "../pages/TechDetails";
import Completed from "../pages/Task/Completed";
import AllTask from "../pages/Task/AllTask";
import Cancelled from "../pages/Task/Cancelled";
import Validation from "../pages/Validation";
import TaskItems from "../pages/TaskSettings/TaskItems";
import TaskGroups from "../pages/TaskSettings/TaskGroups";
import Planning from "../pages/Planning";
import Works from "../pages/Works";
import PlanningAdmin from "../pages/PlanningAdmin";
import WorksAdmin from "../pages/WorksAdmin";


// Public routes
const publicRoutes = [
  { path: "/home", component: Home },
  { path: "/clients", component: Clients },
  { path: "/clients/details/:id", component: ClientDetails },
  { path: "/projects", component: Projects },
  { path: "/projects/report/view", component: ProjectView },
  { path: "/vehicule/details/:id", component: ProjectDetails },
  { path: "/projects/booking", component: ProjectBooking },
  { path: "/taches", component: AllTask },
  { path: "/taches/pending", component: Peding },
  { path: "/taches/completed", component: Completed },
  { path: "/taches/cancelled", component: Cancelled },
  { path: "/parts/expected", component: Expected },
  { path: "/parts/unpaid", component: Unpaid },
  { path: "/insurance", component: Insurance },
  { path: "/insurance/details", component: InsuranceDetails },
  { path: "/quotes", component: Quotes },
  { path: "/quotes/view", component: QuoteView },
  { path: "/invoices", component: Invoices },
  { path: "/invoices/view", component: InvoiceView },
  { path: "/project/payments", component: Payments },
  { path: "/inventory", component: Inventory },
  { path: "/inventory/report/view", component: InventoryView },
  { path: "/receiveables", component: Receiveables },
  { path: "/issueables", component: Issueables },
  { path: "/suppliers", component: Suppliers },
  { path: "/supplier/report", component: SupplierView },
  { path: "/marketing", component: Marketing },
  { path: "/marketing/recipients", component: Recipients },
  { path: "/team", component: Team },
  { path: "/team/details", component: TeamDetails },
  { path: "/team/report", component: TeamView },
  { path: "/settings", component: Settings },
  { path: "/billing/payments", component: BillingPayments },
  { path: "/settings/bookingparts", component: BookingParts },
  { path: "/jobcards/view", component: JobCardView },
  { path: "/technicien", component: Technicien },
  { path: "/admin", component: Admin },
  { path: "/tech/details/:id", component: TechDetails },
  { path: "/task-items", component: TaskItems },
  { path: "/task-groups", component: TaskGroups },
  { path: "/planning", component: Planning },
  { path: "/planning-admin", component: PlanningAdmin },
  { path: "/works", component: Works },
  { path: "/works-admin", component: WorksAdmin },
];
const routeNoLayout = [
  { path: "/", component: Authentication },
  { path: "/forgot-password", component: ForgotPasword },
  { path: "/users/password-reset-form/:token", component: NewPassword },
  { path: "/users/validation", component: Validation },
]

//const privateRoutes = [];

export { publicRoutes, routeNoLayout };
