import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import Loading from "../../components/Loading";
import { projetHooks } from "../../network/projet";
import Swal from 'sweetalert2'

type inputForm = {
  id: number,
  nom: string,
}
export const CreateProjects = ({ show, onHide }: any) => {
  const [file, setFile] = useState<File>();
  const [showPorteur, setShowPorteur] = useState(false)
  const [status, setStatus] = useState<string>('');
  const [startDate, setDateDebut] = useState<string>('2023-07-05');
  const [endDate, setDateFin] = useState<string>('2023-07-05');
  const [roadTestIn, setRoadTestIn] = useState<string>('');
  const [details, setDetails] = useState<string>('');
  const [piece, setPiece] = useState<string[]>([]);
  const [idAssurance, setIdAssurance] = useState<number>(0);
  const [porteurVehicule, setPorteurVehicule] = useState<{ nomPrenom: string, telephone: string, email: string }>({
    nomPrenom: '',
    telephone: '',
    email: ''
  });
  const [detailsVehicule, setDetailsVehicule] = useState<{
    marque: string, model: string, numero: string,
    vin: string, moteur: string, kilometrage: number
    uniteKilometrage: string, couleur: string,
    dateAssurance: string, campagnieAssurance: string,
    dateEntre: string, heureEntre: string, details: string,
    numeroImmatriculation: string, date1Immatriculation: string,
    typeVersion: string, codeNationalIdentification: string,
  }>({
    marque: '',
    model: "",
    numero: "",
    vin: "",
    moteur: "",
    kilometrage: 0,
    uniteKilometrage: "Kilometers",
    couleur: "",
    dateAssurance: "",
    campagnieAssurance: "",
    dateEntre: "",
    heureEntre: "",
    details: "",
    numeroImmatriculation: "",
    date1Immatriculation: "",
    typeVersion: "",
    codeNationalIdentification: "",
  });
  type clientType = {
    email: string,
    nomPrenom: string,
    telephone: string,
    adresse: string,
  }
  const [client, setClient] = useState<clientType>({
    email: '',
    nomPrenom: '',
    telephone: '',
    adresse: '',
  });
  
  
  const [scanIsloading, setScanIsloading] = useState<boolean>(false);
  const [errorMarque, setErrorMarque] = useState<boolean>(false);
  const [errorModele, setErrorModele] = useState<boolean>(false);
  const [errorVin, setErrorVin] = useState<boolean>(false);
  const [errorMoteur, setErrorMoteur] = useState<boolean>(false);
  const [errorClientEmail, setErrorClientEmail] = useState<boolean>(false);
  const [errorClientPhone, setErrorClientPhone] = useState<boolean>(false);
  const [errorClientAddress, setErrorClientAddress] = useState<boolean>(false);
  const [errorClient, setErrorClient] = useState<boolean>(false);
  const [errorNumeroImmatriculation, setErrorNumeroImmatriculation] = useState<boolean>(false);
  const [errorDate1Immatriculation, setErrorDate1Immatriculation] = useState<boolean>(false);
  const [errorTypeVersion, setErrorTypeVersion] = useState<boolean>(false);
  const [errorCodeNationaleIdentification, setErrorCodeNationaleIdentification] = useState<boolean>(false);
  const [classMarque, setClassMarque] = useState<string>('form-control form-control-lg');
  const [classModele, setClassModele] = useState<string>('form-control form-control-lg');
  const [classVin, setClassVin] = useState<string>('form-control form-control-lg');
  const [classMoteur, setClassMoteur] = useState<string>('form-control form-control-lg');
  const [classClientEmail, setClassClientEmail] = useState<string>('form-control form-control-lg');
  const [classClientPhone, setClassClientPhone] = useState<string>('form-control form-control-lg');
  const [classClient, setClassClient] = useState<string>('form-control form-control-lg');
  const [classClientAddress, setClassClientAddress] = useState<string>('form-control form-control-lg');
  const [classNumeroImmatriculation, setClassNumeroImmatriculation] = useState<string>('form-control form-control-lg');
  const [classDate1Immatriculation, setClassDate1Immatriculation] = useState<string>('form-control form-control-lg');
  const [classTypeVersion, setClassTypeVersion] = useState<string>('form-control form-control-lg');
  const [classCodeNationaleIdentification, setClassCodeNationaleIdentification] = useState<string>('form-control form-control-lg');
  const [travaux, setTravaux] = useState<inputForm[]>([
    {
      id: 0,
      nom: ''
    }
  ])


  type Travaux = {
    id: number;
    nom: string;
  }
  const [defautCarburants, setDefautCarburant] = useState<{
    preAccident: string, niveauCarburant: number, travauxDemmander: Travaux[]
  }>({
    preAccident: '',
    niveauCarburant: 82,
    travauxDemmander: []
  });

  const [pieceVehicules, setPieceVehicule] = useState<{ nomEntrePiece: string, piece: number }[]>([])

  const { data, mutate } = projetHooks.useCreateProject();
  const uploadCarteGrise = projetHooks.useUploadCarteGrise();


  const checkEmpyMarque = () => {
    if (detailsVehicule.marque.trim().length === 0) {
      setErrorMarque(true)
      setClassMarque('form-control form-control-lg is-invalid')
      return true
    } else {
      setErrorMarque(false)
      setClassMarque('form-control form-control-lg')
      return false
    }
  }

  const checkEmptyClientEmail = () => {
    if (client.email.trim().length === 0) {
      setErrorClientEmail(true)
      setClassClientEmail('form-control form-control-lg is-invalid')
      return true
    } else {
      setErrorClientEmail(false)
      setClassClientEmail('form-control form-control-lg')
      return false
    }
  }

  const checkEmptyClientPhone = () => {
    if (client.telephone.trim().length === 0) {
      setErrorClientPhone(true)
      setClassClientPhone('form-control form-control-lg is-invalid')
      return true
    } else {
      setErrorClientPhone(false)
      setClassClientPhone('form-control form-control-lg')
      return false
    }
  }

  const checkEmptyClient = () => {
    if (client.nomPrenom.trim().length === 0) {
      setErrorClient(true)
      setClassClient('form-control form-control-lg is-invalid')
      return true
    } else {
      setErrorClient(false)
      setClassClient('form-control form-control-lg')
      return false
    }
  }

  const checkEmptyClientAddress = () => {
    if (client.adresse.trim().length === 0) {
      setErrorClientAddress(true)
      setClassClientAddress('form-control form-control-lg is-invalid')
      return true
    } else {
      setErrorClientAddress(false)
      setClassClientAddress('form-control form-control-lg')
      return false
    }
  }

  const checkEmptyNumeroImmatriculation = () => {
    if (detailsVehicule.numeroImmatriculation.trim().length === 0) {
      setErrorNumeroImmatriculation(true)
      setClassNumeroImmatriculation('form-control form-control-lg is-invalid')
      return true
    } else {
      setErrorNumeroImmatriculation(false)
      setClassNumeroImmatriculation('form-control form-control-lg')
      return false
    }
  }

  const checkEmptyDate1Immatriculation = () => {
    if (detailsVehicule.date1Immatriculation.trim().length === 0) {
      setErrorDate1Immatriculation(true)
      setClassDate1Immatriculation('form-control form-control-lg is-invalid')
      return true
    } else {
      setErrorDate1Immatriculation(false)
      setClassDate1Immatriculation('form-control form-control-lg')
      return false
    }
  }

  const checkEmptyTypeVersion = () => {
    if (detailsVehicule.typeVersion.trim().length === 0) {
      setErrorTypeVersion(true)
      setClassTypeVersion('form-control form-control-lg is-invalid')
      return true
    } else {
      setErrorTypeVersion(false)
      setClassTypeVersion('form-control form-control-lg')
      return false
    }
  }

  const checkEmptyCodeNationaleIdentification = () => {
    if (detailsVehicule.codeNationalIdentification.trim().length === 0) {
      setErrorCodeNationaleIdentification(true)
      setClassCodeNationaleIdentification('form-control form-control-lg is-invalid')
      return true
    } else {
      setErrorCodeNationaleIdentification(false)
      setClassCodeNationaleIdentification('form-control form-control-lg')
      return false
    }
  }
  const checkEmptyModele = () => {
    if (detailsVehicule.model.trim().length === 0) {
      setErrorModele(true)
      setClassModele('form-control form-control-lg is-invalid')
      return true
    } else {
      setErrorModele(false)
      setClassModele('form-control form-control-lg')
      return false
    }
  }

  const checkEmptyVin = () => {
    if (detailsVehicule.vin.trim().length === 0) {
      setErrorVin(true)
      setClassVin('form-control form-control-lg is-invalid')
      return true
    } else {
      setErrorVin(false)
      setClassVin('form-control form-control-lg')
      return false
    }
  }

  const checkEmptyMoteur = () => {
    if (detailsVehicule.moteur.trim().length === 0) {
      setErrorMoteur(true)
      setClassMoteur('form-control form-control-lg is-invalid')
      return true
    } else {
      setErrorMoteur(false)
      setClassMoteur('form-control form-control-lg')
      return false
    }
  }

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  function resetStates() {
    setDetailsVehicule({
      marque: '',
      model: "",
      numero: "",
      vin: "",
      moteur: "",
      kilometrage: 0,
      uniteKilometrage: "Kilometers",
      couleur: "",
      dateAssurance: "",
      campagnieAssurance: "",
      dateEntre: "",
      heureEntre: "",
      details: "",
      numeroImmatriculation: "",
      date1Immatriculation: "",
      typeVersion: "",
      codeNationalIdentification: "",
    });
  
    setClient({
      email: '',
      nomPrenom: '',
      telephone: '',
      adresse: '',
    });
    setScanIsloading(false);
  setErrorMarque(false);
  setErrorModele(false);
  setErrorVin(false);
  setErrorMoteur(false);
  setErrorClientEmail(false);
  setErrorClientPhone(false);
  setErrorClientAddress(false);
  setErrorClient(false);
  setErrorNumeroImmatriculation(false);
  setErrorDate1Immatriculation(false);
  setErrorTypeVersion(false);
  setErrorCodeNationaleIdentification(false);
  setClassMarque('form-control form-control-lg');
  setClassModele('form-control form-control-lg');
  setClassVin('form-control form-control-lg');
  setClassMoteur('form-control form-control-lg');
  setClassClientEmail('form-control form-control-lg');
  setClassClientPhone('form-control form-control-lg');
  setClassClient('form-control form-control-lg');
  setClassClientAddress('form-control form-control-lg');
  setClassNumeroImmatriculation('form-control form-control-lg');
  setClassDate1Immatriculation('form-control form-control-lg');
  setClassTypeVersion('form-control form-control-lg');
  setClassCodeNationaleIdentification('form-control form-control-lg');
  }

  type detailsVehiculeType = {
    marque: string, model: string, numero: string,
    vin: string, moteur: string, kilometrage: number
    uniteKilometrage: string, couleur: string,
    dateAssurance: string, campagnieAssurance: string,
    dateEntre: string, heureEntre: string, details: string,
    numeroImmatriculation: string, date1Immatriculation: string,
    typeVersion: string, codeNationalIdentification: string,
  }
  const setScannedData = (scannedData) => {
    const scan: detailsVehiculeType = {
      marque: '',
      model: "",
      numero: "",
      vin: "",
      moteur: "",
      kilometrage: 0,
      uniteKilometrage: "Kilometers",
      couleur: "",
      dateAssurance: "",
      campagnieAssurance: "",
      dateEntre: "",
      heureEntre: "",
      details: "",
      numeroImmatriculation: "",
      date1Immatriculation: "",
      typeVersion: "",
      codeNationalIdentification: "",
    };
    scan.marque = scannedData.marque.values.map(v => v.content).join(' ');
    scan.model = scannedData.denomination_commerciale.values.map(v => v.content).join(' ');
    scan.moteur = scannedData.cylindree_cm3.values.map(v => v.content).join(' ');
    scan.numeroImmatriculation = scannedData.numero_immatriculation.values.map(v => v.content).join(' ');
    scan.date1Immatriculation = scannedData.date1_immatriculation.values.map(v => v.content).join(' ');
    scan.typeVersion = scannedData.type_version.values.map(v => v.content).join(' ');
    scan.codeNationalIdentification = scannedData.code_national_identification.values.map(v => v.content).join(' ');
    scan.vin = scannedData.numero_identification_vehicule.values.map(v => v.content).join(' ');

    const owner: clientType = {
      email: '',
      nomPrenom: scannedData.nom.values.map(v => v.content).join(' ') + ' ' + scannedData.prenom.values.map(v => v.content).join(' '),
      telephone: '',
      adresse: scannedData.adresse.values.map(v => v.content).join(' ')
    }

    setDetailsVehicule(scan);
    setClient(owner);
  }

  const handleUploadClick = () => {
    setScanIsloading(true);
    if (!file) {
      setScanIsloading(false);
      return;
    }
    return uploadCarteGrise.mutate(file, {
      onSuccess: async (data) => {
        setScanIsloading(false);
        setScannedData(data.data.scanedFile.document.inference.prediction);
      },
      onError: (error: any) => {
        setScanIsloading(false);
        console.log(error);
        Swal.fire({
          icon: 'error',
          text: error?.response?.data?.message || 'Erreur lors de l\'ajout de la carte grise',
        });
      },
      onSettled: async (data) => {
        console.log('tonga ato oooo')
      },
    })
  };


  const createProject = () => {

    for (let i = 0; i < piece.length; i++) {
      setPieceVehicule(prevPiece => [
        ...prevPiece,
        { nomEntrePiece: piece[i], piece: 1 },
      ])
    }

    setDefautCarburant({ ...defautCarburants, travauxDemmander: travaux });

    const projet = {
      "dateDebut": startDate,
      "dateFin": endDate,
      "status": status,
      "testRoutier": roadTestIn,
      "clients": client,
      "isPorteur": true,
      "isAssurance": true,
      "assurances": idAssurance,
      "photo": "kgkygkgkh",
      "noteReservation": "string",
      "isNewClient": true,
      "porteurVehicule": porteurVehicule,
      "defautCarburants": defautCarburants,
      "detailVehicules": detailsVehicule,
      "pieceVehicules": pieceVehicules
    }

    if (
      checkEmpyMarque() || checkEmptyModele() || checkEmptyVin() ||
      checkEmptyClientEmail() || checkEmptyClientPhone() || checkEmptyClient() || checkEmptyClientAddress() ||
      checkEmptyNumeroImmatriculation() || checkEmptyDate1Immatriculation() || checkEmptyTypeVersion() ||
      checkEmptyCodeNationaleIdentification()
    ) {
      checkEmptyModele()
      checkEmptyVin()
      checkEmpyMarque()
      checkEmptyClientEmail()
      checkEmptyClientPhone()
      checkEmptyClient()
      checkEmptyClientAddress()
      checkEmptyNumeroImmatriculation()
      checkEmptyDate1Immatriculation()
      checkEmptyTypeVersion()
      checkEmptyCodeNationaleIdentification()
      Swal.fire({
        icon: 'error',
        text: 'Veuillez remplir tous les champs pour procéder à la création',
      });
      return;
    }

    return mutate(projet, {
      onSuccess: async (data) => {
        onHide()
        Swal.fire({
          icon: 'success',
          text: 'Création de projet avec succès',
        });
        resetStates()
      },
      onError: (error) => {
        Swal.fire({
          icon: 'error',
          text: 'Erreur lors de l\'ajout du vehicule',
        });
      },
      onSettled: async (data) => {
        console.log('tonga ato oooo')
      },
    })

  }

  return (
    <Modal show={show} onHide={onHide} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Créer un nouveau projet</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          className="simcy-form nk-wizard nk-wizard-simple is-alter mt-4"
          action="{{ url('Projects@create') }}"
          models-url="{{ url('Projects@models') }}"
          data-parsley-validate=""
          method="POST"
        >
          <div className={`nk-wizard-content`}>
            <div className="row gy-4">
              <div className="col-sm-12">
                <h5>Upload carte grise</h5>
                <div className="row mt-4">
                  <div className="col-5">
                    <div className="form-group">
                      <div className="form-control-wrap">
                        <div className="form-control-wrap">
                          <input className="form-control" disabled={scanIsloading} name="document" type="file" onChange={handleFileChange} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-2">
                    <button
                      className="btn btn-primary"
                      onClick={handleUploadClick}
                      type="button"
                      disabled={scanIsloading}
                    >
                      Charger
                    </button>
                  </div>
                  {
                    scanIsloading ? (
                      <div className="col-1">
                        <Loading />
                      </div>
                    ): <></>
                  }
                </div>
              </div>
            </div>
            <div className="nk-divider divider mt-5"></div>
            <div className="row mt-4 mb-4">
              <div className="col-sm-12">
                <div className="form-group">
                  <h5>Client</h5>
                </div>
              </div>
            </div>
            <div className="row gy-4 new-client-inputs">
              <div className="col-sm-4">
                <div className="form-group">
                  <label className="form-label">Nom complet</label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      className={classClient}
                      placeholder="Nom complet"
                      name="nomPrenom"
                      required
                      onChange={e => setClient({ ...client, nomPrenom: e.target.value })}
                      onBlur={checkEmptyClient}
                      value={client.nomPrenom}
                    />
                  </div>
                  {errorClient && <div className="text-danger">Valeur obligatoire</div>}
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-group">
                  <label className="form-label">Numéro de téléphone</label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      className={classClientPhone}
                      placeholder="Numéro de téléphone"
                      name="telephone"
                      required
                      onChange={e => setClient({ ...client, telephone: e.target.value })}
                      onBlur={checkEmptyClientPhone}
                      value={client.telephone}
                    />
                  </div>
                  {errorClientPhone && <div className="text-danger">Valeur obligatoire</div>}
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-group">
                  <label className="form-label">Adresse e-mail</label>
                  <div className="form-control-wrap">
                    <input
                      type="email"
                      className={classClientEmail}
                      placeholder="Adresse e-mail"
                      name="email"
                      required
                      onChange={e => setClient({ ...client, email: e.target.value })}
                      onBlur={checkEmptyClientEmail}
                      value={client.email}
                    />
                  </div>
                  {errorClientEmail && <div className="text-danger">Valeur obligatoire</div>}
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <label className="form-label">Adresse</label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      className={classClientAddress}
                      placeholder="Address"
                      name="adresse"
                      required
                      onChange={e => setClient({ ...client, adresse: e.target.value })}
                      onBlur={checkEmptyClientAddress}
                      value={client.adresse}
                    />
                  </div>
                  {errorClientAddress && <div className="text-danger">Valeur obligatoire</div>}
                </div>
              </div>
            </div>
            <div className="row gy-4">
              <div className="col-sm-12">
                <div className="form-group">
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      name="delivered_by"
                      id="delivered_by"
                      className="custom-control-input"
                      defaultChecked={showPorteur}
                      onChange={(e) =>
                        setShowPorteur(e.target.checked)
                      }
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="delivered_by"
                    >
                      Le vehicule a-t-il été emmené par un porteur
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {showPorteur && (
              <div className="row gy-4 deliveredby-inputs">
                <div className="col-sm-12 pb-0">
                  <p>
                    Entrer les détails de la personne qui a emmené la voiture
                  </p>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="form-label">Nom complet</label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="Nom complet"
                        name="deliveredby_fullname"
                        onChange={e => setPorteurVehicule({ ...porteurVehicule, nomPrenom: e.target.value })}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="form-label">Numéro de téléphone</label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control form-control-lg phone-input deliveredby"
                        placeholder="Numéro de téléphone"
                        onChange={e => setPorteurVehicule({ ...porteurVehicule, telephone: e.target.value })}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="form-label">Adresse e-mail</label>
                    <div className="form-control-wrap">
                      <input
                        type="email"
                        className="form-control form-control-lg"
                        placeholder="Adresse e-mail"
                        name="deliveredby_email"
                        onChange={e => setPorteurVehicule({ ...porteurVehicule, email: e.target.value })}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="form-label">Adresse</label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="Adresse"
                        name="deliveredby_idnumber"
                      />
                    </div>
                  </div>
                </div>
              </div>)}
            <div className="nk-divider divider mt-5"></div>
            <div className="row mt-4 mb-4">
              <div className="col-sm-12">
                <div className="form-group">
                  <h5>Les détails du véhicule.</h5>
                </div>
              </div>
            </div>
            <div className="row gy-4">
              <div className="col-sm-4">
                <div className="form-group">
                  <label className="form-label">Numéro immatriculation</label>
                  <div className="form-control-wrap">
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className={classNumeroImmatriculation}
                        placeholder="Numéro immatriculation"
                        name="numero_immatriculation"
                        required
                        onChange={e => setDetailsVehicule({ ...detailsVehicule, numeroImmatriculation: e.target.value })}
                        onBlur={checkEmptyNumeroImmatriculation}
                        value={detailsVehicule.numeroImmatriculation}
                      />
                    </div>
                    {errorNumeroImmatriculation && <div className="text-danger">Valeur obligatoire</div>}
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-group">
                  <label className="form-label">Date 1ère immatriculation</label>
                  <div className="form-control-wrap">
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className={classDate1Immatriculation}
                        placeholder="Date 1ère immatriculation"
                        name="date1_immatriculation"
                        required
                        onChange={e => setDetailsVehicule({ ...detailsVehicule, date1Immatriculation: e.target.value })}
                        onBlur={checkEmptyDate1Immatriculation}
                        value={detailsVehicule.date1Immatriculation}
                      />
                    </div>
                    {errorDate1Immatriculation && <div className="text-danger">Valeur obligatoire</div>}
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-group">
                  <label className="form-label">Type version</label>
                  <div className="form-control-wrap">
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className={classTypeVersion}
                        placeholder="Type version"
                        name="type_version"
                        required
                        onChange={e => setDetailsVehicule({ ...detailsVehicule, typeVersion: e.target.value })}
                        onBlur={checkEmptyTypeVersion}
                        value={detailsVehicule.typeVersion}
                      />
                    </div>
                    {errorTypeVersion && <div className="text-danger">Valeur obligatoire</div>}
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-group">
                  <label className="form-label">Code nationale d'identification</label>
                  <div className="form-control-wrap">
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className={classCodeNationaleIdentification}
                        placeholder="Code nationale d'identification"
                        name="code_national_identification"
                        required
                        onChange={e => setDetailsVehicule({ ...detailsVehicule, codeNationalIdentification: e.target.value })}
                        onBlur={checkEmptyCodeNationaleIdentification}
                        value={detailsVehicule.codeNationalIdentification}
                      />
                    </div>
                    {errorCodeNationaleIdentification && <div className="text-danger">Valeur obligatoire</div>}
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-group">
                  <label className="form-label">Marque</label>
                  <div className="form-control-wrap">
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className={classMarque}
                        placeholder="Marque"
                        name="marque"
                        required
                        onChange={e => setDetailsVehicule({ ...detailsVehicule, marque: e.target.value })}
                        onBlur={checkEmpyMarque}
                        value={detailsVehicule.marque}
                      />
                    </div>
                    {errorMarque && <div className="text-danger">Valeur obligatoire</div>}
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-group">
                  <label className="form-label">Modèle</label>
                  <div className="form-control-wrap">
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className={classModele}
                        placeholder="Modèle"
                        name="modele"
                        required
                        onChange={e => setDetailsVehicule({ ...detailsVehicule, model: e.target.value })}
                        onBlur={checkEmptyModele}
                        value={detailsVehicule.model}
                      />
                    </div>
                    {errorModele && <div className="text-danger">Valeur obligatoire</div>}
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-group">
                  <label className="form-label">VIN</label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      className={classVin}
                      placeholder="VIN"
                      name="vin"
                      onChange={e => setDetailsVehicule({ ...detailsVehicule, vin: e.target.value })}
                      onBlur={checkEmptyVin}
                      value={detailsVehicule.vin}
                    />
                  </div>
                  {errorVin && <div className="text-danger">Valeur obligatoire</div>}
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-group">
                  <label className="form-label">Moteur</label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      className={classMoteur}
                      placeholder="Moteur Non"
                      name="engine_number"
                      onChange={e => setDetailsVehicule({ ...detailsVehicule, moteur: e.target.value })}
                      onBlur={checkEmptyMoteur}
                      value={detailsVehicule.moteur}
                    />
                  </div>
                  {errorMoteur && <div className="text-danger">Valeur obligatoire</div>}
                </div>
              </div>
            </div>
          </div>

          <div className="actions clearfix">
            <ul role="menu">
              <li aria-hidden="true" onClick={createProject}>
                <a className="cursor">Creer projet</a>
              </li>
            </ul>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};
