import React, { useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { CreateApprovedJobCard, CreateJobCard } from "../../Modal";

export default function JobCards() {
  const [showCreate, setShowCreate] = useState(false);
  const [showCreateApproved, setShowApproved] = useState(false);
  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: `This job card will be deleted permanently.`,
      confirmButtonText: "Yes, delete!",
      cancelButtonText: `Cancel`,
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: "#fff",
    }).then((result) => {
      if (result.isConfirmed) {
        ///When click Yes
      }
    });
  };
  return (
    <>
      <div className="card-inner">
        <div className="nk-block mb-2">
          <div className="nk-block-head">
            <a
              className="btn btn-primary pull-right fetch-display-click cursor"
              onClick={() => setShowCreate(true)}
            >
              <em className="icon ni ni-plus" />
              <span>Create Job Card</span>
            </a>
            <h5 className="title">Project Job Cards</h5>
            <p>A list of job cards for Jubilee Insurance Company projects.</p>
          </div>
          {/* .nk-block-head */}
        </div>
        {/* .nk-block */}
        <div className="bq-note">
          <div className="bq-note-item">
            <div className="bq-note-text">
              {/* <span className="badge badge-sm badge-dot has-bg badge-success float-right">Approved</span> */}
              <span className="badge badge-sm badge-dot has-bg badge-warning float-right">
                Assessment
              </span>

              <h6 className="title">Body Report</h6>
              <p>["test"]</p>
              <div className="nk-divider divider md" />
              <h6 className="title">Mechanical Report</h6>
              <p>["test"]</p>
              <div className="nk-divider divider md" />
              <h6 className="title">Electrical Report</h6>
              <p>["test"]</p>
            </div>
            <div className="bq-note-meta">
              <span className="bq-note-added">
                Job Card <span className="fw-bold">#139 </span> Created on{" "}
                <span className="date">May 24, 2022</span> at{" "}
                <span className="time">10:10am</span>
              </span>
              <span className="bq-note-sep sep">•</span>
              <a
                className="link link-sm link-primary fetch-display-click cursor"
                onClick={() => setShowApproved(true)}
              >
                Edit Job Card
              </a>
              <span className="bq-note-sep sep">•</span>
              <a
                className="link link-sm link-primary fetch-display-click cursor"
                onClick={() => setShowApproved(true)}
              >
                Create Approved Version
              </a>
              <span className="bq-note-sep sep">•</span>
              <Link
                to="/jobcards/view"
                className="link link-sm link-primary cursor"
              >
                View Job Card
              </Link>
              <span className="bq-note-sep sep">•</span>
              <a
                className="link link-sm link-primary cursor"
                href="/pdf/Job_Card_140.pdf"
                download="Job_Card_140.pdf"
              >
                Download Job Card
              </a>

              <span className="bq-note-sep sep">•</span>
              <a
                className="link link-sm link-danger send-to-server-click cursor"
                onClick={handleDelete}
              >
                Delete
              </a>
            </div>
          </div>
          <div className="nk-divider divider md" />

          {/* 
    if empty
    <div className="empty text-center text-muted">
      <em className="icon ni ni-info" />
      <p>No job card created yet!</p>
    </div> */}
        </div>
      </div>
      <CreateJobCard show={showCreate} onHide={() => setShowCreate(false)} />
      <CreateApprovedJobCard
        show={showCreateApproved}
        onHide={() => setShowApproved(false)}
      />
    </>
  );
}
