import React, { useState } from "react";
import { Modal } from "react-bootstrap";
interface UpdatePartProps {
  show: boolean;
  onHide: () => void;
}

export function UpdatePart({ show, onHide }: UpdatePartProps) {
  const [isHasInput, setIsHasInput] = useState(false);
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Update Part</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Add a part to check on vehicle check in and check out</p>
        <div className="row gy-4">
          <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">Part Name</label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Part Name"
                  name="name"
                  required
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  name="has_input"
                  id="has-input"
                  className="custom-control-input has-input"
                  onChange={(e) => setIsHasInput(e.target.checked)}
                />
                <label className="custom-control-label" htmlFor="has-input">
                  Show input field when checked
                </label>
              </div>
            </div>
          </div>
          {isHasInput && (
            <div className="col-sm-12 input-name">
              <div className="form-group">
                <label className="form-label">Input Name</label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    placeholder="Input Name"
                    name="input_name"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className=" bg-light">
        <button
          className="btn btn-white btn-dim btn-outline-light"
          type="button"
          onClick={onHide}
        >
          <em className="icon ni ni-cross-circle"></em>
          <span>Cancel</span>
        </button>
        <button className="btn btn-primary" type="button" onClick={onHide}>
          <em className="icon ni ni-check-circle-cut" />
          <span>Save Changes</span>
        </button>
      </Modal.Footer>
    </Modal>
  );
}
