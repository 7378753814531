import * as React from 'react';
import Paper from '@mui/material/Paper';
import { ViewState } from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  WeekView,
  Appointments,
  Toolbar,
  ViewSwitcher,
  MonthView,
  DayView,
  DateNavigator,
  TodayButton,
  AppointmentTooltip,
  AppointmentForm,
  Resources,
  AllDayPanel,
  
  
} from '@devexpress/dx-react-scheduler-material-ui';
import moment from 'moment';
import { Grid, Typography, useTheme } from '@mui/material';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import { Link } from 'react-router-dom';
import type { workStepEntityType, taskEntityType } from '../../types';
import { SchedulerProps } from '@devexpress/dx-react-scheduler';

const workStatusTabs = [
  {
    text: 'Planifié',
    id: 1,
    color: '#f4bd0e',
  },
  {
    text: 'En cours',
    id: 2,
    color: '#3c8efe',
  },
  {
    text: 'Complété',
    id: 3,
    color: '#07d7a0',
  },
  {
    text: 'Annulé',
    id: 4,
    color: '#e85347',
  },
]
type Props = {
  dataWorkstep: workStepEntityType[],
  dataTask: {data: taskEntityType[]},
  currentViewName: string,
  setCurrentViewName: any
}


const SchedulerView = ({ dataWorkstep, dataTask, currentViewName, setCurrentViewName }: Props) => {
  const theme = useTheme();
  const [appointments, setAppointments] = React.useState<any[]>([]);
  const [resources, setResources] = React.useState<any[]>([])
  const [scrollToID, setScrollToID] = React.useState<{id: number | undefined, date: Date}>(
    {
      id: undefined,
      date: new Date(),
    }
  );

  const currentViewNameChange = (currentViewName) => {
    setCurrentViewName(currentViewName);
  };
  const convertData = React.useCallback(() => {
    const tabAppointment: any[] = [];
    let scrToID = {
      id: scrollToID.id,
      date: scrollToID.date,
    }
    dataWorkstep?.filter(dWs => dWs.status !== 'A faire' && dWs.status !== 'Suspendu').map( d => {

      const vehicules = d.works?.projets?.vehicules?.detailVehicules;
      if (scrToID.id === undefined || (scrToID.date.getTime() > new Date(d.startAt ?? 0).getTime())) {
        scrToID = {
          id: d.works?.projets?.idProjet,
          date: new Date(d.startAt ?? 0),
        }
      }
      if(d.status === "En cours") {
        if (d.startAt && d.endAt) {
          const startAt = d.startAt
          const endAt = d.endAt
          tabAppointment.push({
            startDate: new Date(startAt),
            endDate: new Date(endAt),
            title: `${d.taskGroup?.taskGroupLabel} - ${vehicules?.marque} ${vehicules?.model} | ${vehicules?.numeroImmatriculation}`,
            workStatus: 2,
            workTasks: d.taskGroup.tasks,
            vehicule: `${vehicules?.marque} ${vehicules?.model} | ${vehicules?.numeroImmatriculation}`,
            assignTo: d.assignTo,
            idProjet: d.works?.projets?.idProjet,
          })
        }
      }
      if(d.status !== "En cours") {
        if (d.startAt && d.endAt){
          tabAppointment.push({
            startDate: new Date(d.startAt),
            endDate: new Date(d.endAt),
            title: `${d.taskGroup?.taskGroupLabel} - ${vehicules?.marque} ${vehicules?.model} | ${vehicules?.numeroImmatriculation}`,
            workStatus: (d.status === "Planifié" ? 1 : (d.status === "Complété" ? 3 : 4)),
            workTasks: d.taskGroup.tasks,
            vehicule: `${vehicules?.marque} ${vehicules?.model} | ${vehicules?.numeroImmatriculation}`,
            assignTo: d.assignTo,
            idProjet: d.works?.projets?.idProjet,
          })
        }
      }
      return true;
    });
    setAppointments(tabAppointment);
    setScrollToID(scrToID)
  }, [dataWorkstep])

  const Header: React.FunctionComponent<AppointmentTooltip.HeaderProps> = (({
    children, appointmentData, ...restProps
  }) => (
    <AppointmentTooltip.Header
      {...restProps}
      appointmentData={appointmentData}
    >
      <Typography
        width={'100%'}
        pt={2}
        pl={2}
      >
        <b>Assigné:</b> {` ${appointmentData?.assignTo?.prenom} ${appointmentData?.assignTo?.nom}`}
      </Typography>
    </AppointmentTooltip.Header>
  ));
  
  const Content: React.FunctionComponent<AppointmentTooltip.ContentProps> = ({
    children,
    appointmentData,
    formatDate,
    ...restProps
  }) => (
    <AppointmentTooltip.Content
      {...restProps}
      appointmentData={appointmentData}
      formatDate={formatDate}

    >
      <Grid container alignItems="center">
        <Grid
          item 
          xs={2} 
          textAlign={'center'} 
          style={{
            color: theme.palette.grey[600],
          }}
        >
          <DriveEtaIcon />
        </Grid>
        <Grid 
          item 
          xs={10} 
          style={{
            color: theme.palette.grey[600],
          }}
        >
          <Link to={`/vehicule/details/${appointmentData?.idProjet}`}>
            <span>{appointmentData?.vehicule}</span>
          </Link>
        </Grid>
      </Grid>
    </AppointmentTooltip.Content>
  );
  
  React.useEffect(() => {
    convertData();
    if(resources.length === 0) {
      if(dataTask)  {
        const tabsTask: any[] = [];
        dataTask.data.map(dt => {
          tabsTask.push({
            text: dt.taskTitle,
            id: dt.idTask,
            color: '#7E57C2',
          });
          return true;
        });
        setResources([
          {
            fieldName: 'workStatus',
            title: 'Status travaux',
            instances: workStatusTabs,
          },{
            fieldName: 'workTasks',
            title: 'Taches a faire',
            instances: tabsTask,
          }
        ]);
      }
    }
  }, [convertData, dataTask, resources])

  return (
        <Paper elevation={3}>
          <Scheduler
            data={appointments}
            locale={'fr-FR'}
          >
            <ViewState
              defaultCurrentDate={moment().toDate()}
              currentViewName={currentViewName}
              onCurrentViewNameChange={currentViewNameChange}
            />
            <WeekView
              displayName="Hebdomadaire"
              name='hebdomadaire'
              startDayHour={6}
              endDayHour={19}
            />
            <WeekView
              name="work-week"
              displayName="Jour de travail"
              excludedDays={[0, 6]}
              startDayHour={6}
              endDayHour={19}
            />
            <MonthView displayName="Mensuel" name='mensuel' />
            <DayView 
              displayName="Journalier" 
              startDayHour={6}
              endDayHour={19}
              name='journalier'  />
            <AllDayPanel messages={{allDay: 'Toute la journée'}} />
            <Toolbar />
            <ViewSwitcher />
            <DateNavigator />
            <TodayButton messages={{today: "Aujourd'hui"}} />
            <Appointments 
            />
            <AppointmentTooltip
              showCloseButton
              headerComponent={Header}
              contentComponent={Content}
            />
            <AppointmentForm
              readOnly
            />
            <Resources
              data={resources}
              mainResourceName="workStatus"
            />
          </Scheduler>
        </Paper>
  );
}

export default SchedulerView;