import React from "react";
import { Modal } from "react-bootstrap";

interface ConvertQuoteProps {
  show: boolean;
  onHide: () => void;
}

export const ConvertQuote = ({ show, onHide }: ConvertQuoteProps) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Convertir un devis en facture</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Convertir un devis en facture</p>
        <div className="row gy-4">
          <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">Date de facturation</label>
              <div className="form-control-wrap">
                <input
                  type="date"
                  className="form-control form-control-lg"
                  placeholder="Date de facturation"
                  name="invoice_date"
                  required
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">Paiement dû</label>
              <div className="form-control-wrap">
                <input
                  type="date"
                  className="form-control form-control-lg"
                  placeholder="Paiement dû"
                  name="due_date"
                  required
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">Remarques</label>
              <div className="form-control-wrap">
                <textarea
                  className="form-control form-control-lg unset-mh"
                  placeholder="Remarques"
                  rows={2}
                  name="notes"
                />
              </div>
              <div className="form-note">
                Les notes seront imprimées sur la facture.
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">Détails de paiement</label>
              <div className="form-control-wrap">
                <textarea
                  className="form-control form-control-lg unset-mh"
                  placeholder="Détails de paiement"
                  rows={4}
                  name="payment_details"
                />
              </div>
              <div className="form-note">
                Les détails du paiement seront imprimés sur la facture.
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="bg-light">
        <button
          className="btn btn-white btn-dim btn-outline-light"
          type="button"
          data-dismiss="modal"
          onClick={onHide}
        >
          <em className="icon ni ni-cross-circle"></em>
          <span>Annuler</span>
        </button>
        <button className="btn btn-primary" type="submit">
          <em className="icon ni ni-check-circle-cut"></em>
          <span>Convertir le devis</span>
        </button>
      </Modal.Footer>
    </Modal>
  );
};
