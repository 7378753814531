import React, { useState } from "react";
import Swal from "sweetalert2";
import { CreateNote } from "../../Modal";
import { useParams } from "react-router-dom";
import { membreHooks } from "../../network";
import Loading from "../../components/Loading";

export default function Details() {
  const [showCreateNote, setShowCreateNote] = useState(false);
  const params = useParams()
  let id = params.id || ""

  const { data, isLoading } = membreHooks.useGetMembreById(id);


  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "This note will be deleted permanently.",
      confirmButtonText: "Yes, delete!",
      cancelButtonText: `Cancel`,
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        ///When click Yes
      }
    });
  };

  if (isLoading){
    return (<div className="loading__wrapper">
    <Loading />
  </div>)
  }

  return (
    <>
      {isLoading ? <Loading /> :
        <div className="card-inner">
          <div className="nk-block">
            <div className="nk-block-head">
              <h6 className="title overline-title text-base">
                Détails du membre
              </h6>
            </div>
            <div className="profile-ud-list">
              <div className="profile-ud-item">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Nom</span>
                  <span className="profile-ud-value">{data?.data.nom}</span>
                </div>
              </div>
              <div className="profile-ud-item">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Téléphone</span>
                  <span className="profile-ud-value">{data?.data.telephone}</span>
                </div>
              </div>
              <div className="profile-ud-item">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Email</span>
                  <span className="profile-ud-value">{data?.data.email}</span>
                </div>
              </div>
              <div className="profile-ud-item">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Projet actif</span>
                  <span className="profile-ud-value">1 / 1</span>
                </div>
              </div>
              <div className="profile-ud-item">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Adresse</span>
                  <span className="profile-ud-value">{data?.data.adresse}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="nk-block">
            <div className="nk-block-head nk-block-head-line">
              <h6 className="title overline-title text-base">
                Informations additionnelles
              </h6>
            </div>
            {/* .nk-block-head */}
            <div className="profile-ud-list">
              <div className="profile-ud-item">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Rôle</span>
                  <span className="profile-ud-value">
                    {data?.data.role === "Admin" && <span className="badge badge-sm badge-dot has-bg badge-primary d-mb-inline-flex">
                      Admin
                    </span>}
                    {data?.data.role === "Technicien" && <span className="badge badge-sm badge-dot has-bg badge-warning d-mb-inline-flex">
                      Technicien
                    </span>}
                  </span>
                </div>
              </div>
              <div className="profile-ud-item">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Type d'horaire</span>
                  <span className="profile-ud-value">{data?.data.taper}</span>
                </div>
              </div>
              <div className="profile-ud-item">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Statut</span>
                  <span className="profile-ud-value">
                    {data?.data.statut === 'Active' && <span className="badge badge-sm badge-dot has-bg badge-success d-mb-inline-flex">
                      Active
                    </span>}

                    {data?.data.statut === 'Indisponible' && <span className="badge badge-sm badge-dot has-bg badge-secondary d-mb-inline-flex">
                      Indisponible
                    </span>}
                    {data?.data.statut === 'En congé' && <span className="badge badge-sm badge-dot has-bg badge-warning d-mb-inline-flex">
                      En congé
                    </span>}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>}
    </>
  );
}
