import React, { useState } from "react";
import Header from "../Header";
import Footer from "../Footer";
import "./DefaultLayout.scss";
import SideBar from "../Sidebar";
export default function DefaultLayout({ children }: { children: JSX.Element }) {
  const [showSideBarMb, setShowSideBarMb] = useState(false);
  return (
    <div className="nk-main">
      <SideBar showMobile={showSideBarMb} setShowSideBarMb={setShowSideBarMb} />
      <div className="nk-wrap">
        <Header setShowSideBarMb={() => setShowSideBarMb(!showSideBarMb)} />
        <div className="nk-content ">
          <div className="container-fluid">
            <div className="nk-content-inner">
              <div className="nk-content-body">{children}</div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
