import api from "../../axios";

export const network = {
  async getPiece() {
    const { data } = await api.withToken('/piece-commande');

    return data;
  },
  async getPieceDepensePaginate(donne: { page: number, idProjet: string }) {
    const { data } = await api.withToken.get('/pieceDepenceProjet/page/5/' + donne.page + '/' + donne.idProjet);

    return data;
  },
  async addPieceDepense(donne: Object) {
    const { data } = await api.withToken.post('/pieceDepenceProjet', donne);

    return data;
  },
  async deletePieceDepense(donne: { id: number }) {
    const { data } = await api.withToken.delete('/pieceDepenceProjet/' + donne.id);

    return data;
  }
}