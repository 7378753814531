import React from "react";
import { Modal } from "react-bootstrap";
interface UpdateTaskProps {
  show: boolean;
  onHide: () => void;
}

export function UpdateTask({ show, onHide }: UpdateTaskProps) {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Manage Info</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Update project tasks</p>
        <div className="row gy-4">
          <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">Task Title</label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Task Name"
                  defaultValue="title"
                  name="title"
                  required
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">Status</label>
              <div className="form-control-wrap ">
                <div className="form-control-select">
                  <select
                    className="form-control form-control-lg"
                    name="status"
                  >
                    <option value="In Progress">In Progress</option>
                    <option value="Completed">Completed</option>
                    <option value="Cancelled">Cancelled</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">Task Description</label>
              <div className="form-control-wrap">
                <textarea
                  className="form-control form-control-lg"
                  placeholder="Task Description"
                  rows={5}
                  name="description"
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">Task Cost</label>
              <div className="form-control-wrap">
                <div className="form-text-hint">
                  <span className="overline-title">$</span>
                </div>
                <input
                  type="number"
                  className="form-control form-control-lg"
                  placeholder="Task Cost"
                  data-parsley-pattern="[0-9]*(\.?[0-9]{2}$)"
                  name="cost"
                  step="0.01"
                  readOnly
                />
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label className="form-label">Due Date</label>
              <div className="form-control-wrap">
                <input
                  type="date"
                  className="form-control form-control-lg"
                  placeholder="Due Date"
                  name="due_date"
                  required
                />
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label className="form-label">Due Time</label>
              <div className="form-control-wrap">
                <input
                  type="time"
                  className="form-control form-control-lg"
                  placeholder="Due Time"
                  name="due_time"
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="nk-divider divider mt-0 mb-0" />
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">Select required parts</label>
              <div className="form-control-wrap ">
                <div className="form-control-select">
                  <select
                    className="form-control select2-dynamic"
                    name="parts_required[]"
                  ></select>
                </div>
              </div>
              <div className="form-note">
                If this task requires parts recorded on the system, please
                select the parts below. A task can't be completed if any of the
                required parts are not delivered. Parts are added on{" "}
                <a href="{{ url('Projects@details', array('projectid' => $project->id)) }}?view=expenses">
                  Expenses Tab
                </a>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className=" bg-light">
        <button
          className="btn btn-white btn-dim btn-outline-light"
          type="button"
          onClick={onHide}
        >
          <em className="icon ni ni-cross-circle"></em>
          <span>Cancel</span>
        </button>
        <button className="btn btn-primary" type="button" onClick={onHide}>
          <em className="icon ni ni-check-circle-cut" />
          <span>Save Changes</span>
        </button>
      </Modal.Footer>
    </Modal>
  );
}
