import React, { useState } from "react";
import Spinner from 'react-bootstrap/Spinner';
import {Spin} from 'antd';

export default function Loading() {
  return (
    <>
        <Spin  size="default"/>
    </>
  );
}
