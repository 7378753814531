import api from "../../axios";
import type { taskGroupEntityPaginationType, taskGroupEntityType } from "../../types";

export const network = {
  async getAllTaskGroups(): Promise<{data: taskGroupEntityType[]}> {
    const { data } = await api.withToken.get('/task-group');

    return data;
  },
  async getTaskGroupById(idTaskGroup: string): Promise<{data: taskGroupEntityType}> {
    const { data } = await api.withToken.get('/task-group/' + idTaskGroup);

    return data;
  },
  async createTaskGroup(task: Object): Promise<{data: taskGroupEntityType}> {
    const { data } = await api.withToken.post('/task-group', task);

    return data;
  },
  async updateTaskGroup(taskGroup: taskGroupEntityType): Promise<{data: taskGroupEntityType}> {
    const { data } = await api.withToken.put(`/task-group/${taskGroup.idTaskGroup}`, taskGroup)

    return data;
  },
  async getTaskGroupPaginated(page:number): Promise<{data: taskGroupEntityPaginationType
    hasMore?: boolean
  }> {
    const { data } = await api.withToken.get('/task-group/page/20/' + page);

    return data;
  },
  async deleteTaskGroup(donne: { id: number }): Promise<{data: taskGroupEntityType[]}> {
    const { data } = await api.withToken.delete('/task-group/' + donne.id);

    return data;
  }
}