import React from "react";
import { Modal } from "react-bootstrap";

export const UpdateClient = ({ show, onHide }: any) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Update Client</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Update client account.</p>
        <div className="row gy-4">
          <div className="col-sm-12 mt-0">
            <div className="form-group">
              <label className="form-label">Nom et prénom</label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Nom et prénom"
                  name="fullname"
                  required
                  value="test"
                />
              </div>
            </div>
          </div>
          <div className="col-sm-6 mt-0">
            <div className="form-group">
              <label className="form-label">Numéro de téléphone</label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  className="form-control form-control-lg phone-input"
                  placeholder="Numéro de téléphone"
                  value="0831 217 506"
                  required
                />
              </div>
            </div>
          </div>
          <div className="col-sm-6 mt-0">
            <div className="form-group">
              <label className="form-label">Adresse e-mail</label>
              <div className="form-control-wrap">
                <input
                  type="email"
                  className="form-control form-control-lg"
                  placeholder="Adresse e-mail"
                  name="email"
                  value="test@gmail.com"
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12 mt-0">
            <div className="form-group">
              <label className="form-label">Adresse</label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Adresse"
                  name="address"
                  value="VN"
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="bg-light">
        <button
          className="btn btn-white btn-dim btn-outline-light"
          type="button"
          onClick={onHide}
        >
          <em className="icon ni ni-cross-circle"></em>
          <span>Annuler</span>
        </button>
        <button className="btn btn-primary" type="submit">
          <em className="icon ni ni-check-circle-cut"></em>
          <span>Créer un compte</span>
        </button>
      </Modal.Footer>
    </Modal>
  );
};
