import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { CreateInvoices, SendEmail, UpdateInvoices } from "../../../Modal";
import { Pagination } from "react-bootstrap";
import { factureHooks } from '../../../network/facture';

type Props = {
  data: {
    list: [],
    countPage: number,
  }
  nextPage: Function
  prevPage: Function
  goToPage: Function
}

export default function TableauFacture({ data, nextPage, prevPage, goToPage }: Props) {
  const [showCreate, setShowCreate] = useState(false);
  const [showPageMenu, setShowPageMenu] = useState(false);
  const [showSendEmail, setShowSendEmail] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [activePage, setActivePage] = useState<number>(1);
  const { mutate } = factureHooks.useDeleteFacture();
  const handleDelete = (id: number) => {
    Swal.fire({
      title: "Êtes vous sur",
      text: "This invoice will be deleted permanently.",
      confirmButtonText: "Oui, supprimer",
      cancelButtonText: `Retour`,
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: "#fff",
    }).then((result) => {
      if (result.isConfirmed) {
        return mutate({ id: id }, {
          onSuccess: async (data) => {
          },
          onError: (error) => {
            Swal.fire({
              icon: 'error',
              text: 'Erreur lors de la suppression du facture',
            });
          },
        })
      }
    });
  };

  interface Facture {
    idFacture: number,
    total: number,
    datePaiement: string,
    status: string
    projets: {
      vehicules: {
        clients: {
          nomPrenom: string,
          telephone: string
        }
        detailVehicules: {
          marque: string
        }
      }
    },
    infosFactures: []
  }

  const goToNextPage = () => {
    if (activePage < data.countPage) {
      setActivePage(activePage + 1);
    } else {
      setActivePage(activePage)
    }
    nextPage();
  }

  const goToPrevPage = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1)
    } else {
      setActivePage(activePage)
    }
    prevPage()
  }

  const goToPageOne = (items: number) => {
    setActivePage(items)
    goToPage(items)
  }

  return (
    <>
      <div className="nk-block">
        <div className="card card-stretch">
          <div className="card-inner d-flex flex-column align-items-end">
            <table
              className="datatable-init nk-tb-list nk-tb-ulist"
              data-auto-responsive="false"
            >
              <thead>
                <tr className="nk-tb-item nk-tb-head">
                  <th className="nk-tb-col text-center">#</th>
                  <th className="nk-tb-col">
                    <span className="sub-text">Cliente</span>
                  </th>
                  <th className="nk-tb-col tb-col-md">
                    <span className="sub-text">Projet</span>
                  </th>
                  <th className="nk-tb-col tb-col-md">
                    <span className="sub-text">Articles</span>
                  </th>
                  <th className="nk-tb-col tb-col-md">
                    <span className="sub-text">Date d'échéance</span>
                  </th>
                  <th className="nk-tb-col tb-col-md">
                    <span className="sub-text">Solde total</span>
                  </th>
                  <th className="nk-tb-col tb-col-md">
                    <span className="sub-text">Statut</span>
                  </th>
                  <th className="nk-tb-col nk-tb-col-tools text-right"></th>
                </tr>
              </thead>
              {data.list.map((item: Facture, key) => (
                <tbody key={key}>
                  <tr className="nk-tb-item">
                    <td className="nk-tb-col text-center">{item.idFacture}</td>
                    <td className="nk-tb-col">
                      <div className="user-card">
                        <div className="user-avatar bg-dim-primary d-none d-sm-flex">
                          <span>te</span>
                        </div>
                        <div className="user-info">
                          <span className="tb-lead">
                            {item.projets.vehicules.clients.nomPrenom}
                            <span className="dot dot-success d-md-none ml-1"></span>
                          </span>
                          <span>{item.projets.vehicules.clients.telephone}</span>
                        </div>
                      </div>
                    </td>
                    <td className="nk-tb-col tb-col-md">
                      <div className="user-card">
                        <div className="user-info">
                          <span className="tb-lead">{item.projets.vehicules.detailVehicules.marque}</span>
                          {/* <span>23424</span> */}
                        </div>
                      </div>
                    </td>
                    <td className="nk-tb-col tb-col-md">
                      <span className="tb-amount">{item.infosFactures.length}</span>
                    </td>
                    <td className="nk-tb-col tb-col-md">
                      <span>{item.datePaiement}</span>
                      <br />
                      {/* <span className="text-danger">
                      24 mai 2022
                      <span data-toggle="tooltip" title="Overdue">
                        <em className="icon ni ni-info-fill"></em>
                      </span>
                    </span> */}
                    </td>
                    <td className="nk-tb-col tb-col-md">
                      <span className="tb-amount">{item.total} €</span>
                    </td>
                    <td className="nk-tb-col tb-col-md">
                      {item.status === 'Payé' && <span className="badge badge-sm badge-dot has-bg badge-success d-mb-inline-flex">
                        Payé
                      </span>}

                      {item.status === 'Partiel' && <span className="badge badge-sm badge-dot has-bg badge-warning d-mb-inline-flex">Partielle</span>}

                      {item.status === 'Non payé' && <span className="badge badge-sm badge-dot has-bg badge-danger d-mb-inline-flex">Non payé</span>}
                    </td>
                    <td className="nk-tb-col nk-tb-col-tools">
                      <ul className="nk-tb-actions gx-1">
                        <li>
                          <Dropdown>
                            <Dropdown.Toggle
                              as="a"
                              className="btn btn-icon btn-trigger"
                            >
                              <em className="icon ni ni-more-h"></em>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu-right">
                              <ul className="link-list-opt no-bdr">
                                <li>
                                  <Link to="/invoices/view">
                                    <em className="icon ni ni-eye" />
                                    <span>Voir la facture</span>
                                  </Link>
                                </li>
                                <li>
                                  <a
                                    className="cursor"
                                    href="/pdf/Invoice_66.pdf"
                                    download="Invoice_66.pdf"
                                  >
                                    <em className="icon ni ni-download-cloud"></em>
                                    <span>Télécharger</span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="send-via-email cursor"
                                    onClick={() => setShowSendEmail(true)}
                                  >
                                    <em className="icon ni ni-mail"></em>
                                    <span>Envoyer par e-mail</span>
                                  </a>
                                </li>

                                <li>
                                  <a
                                    className="fetch-display-click cursor"
                                    onClick={() => setShowUpdate(true)}
                                  >
                                    <em className="icon ni ni-pen"></em>
                                    <span>Modifier la facture</span>
                                  </a>
                                </li>

                                <li className="divider"></li>
                                <li>
                                  <a
                                    className="send-to-server-click cursor"
                                    onClick={() => handleDelete(item.idFacture)}
                                  >
                                    <em className="icon ni ni-trash"></em>
                                    <span>Supprimer la facture</span>
                                  </a>
                                </li>
                              </ul>
                            </Dropdown.Menu>
                          </Dropdown>
                        </li>
                      </ul>
                    </td>
                  </tr>

                  {/* <tr>
                                                    <td className="text-center" colSpan={6}>C'est vide ici !</td>
                                                </tr> */}
                </tbody>))}
            </table>
            <Pagination className="nk-block-end">
              <Pagination.First onClick={() => goToPageOne(1)} />
              <Pagination.Prev onClick={goToPrevPage} />
              {
                Array.from(
                  { length: data.countPage }, (v, k) => k + 1
                ).map(items => <Pagination.Item key={items} active={items === activePage} onClick={() => goToPageOne(items)}>{items}</Pagination.Item>)
              }
              <Pagination.Next onClick={goToNextPage} />
              <Pagination.Last onClick={() => goToPageOne(data.countPage)} />
            </Pagination>
          </div>
        </div>
      </div>
      <CreateInvoices show={showCreate} onHide={() => setShowCreate(false)} />
      <SendEmail show={showSendEmail} onHide={() => setShowSendEmail(false)} />
      <UpdateInvoices show={showUpdate} onHide={() => setShowUpdate(false)} />
    </>
  );
}
