import React from "react";
import { Modal } from "react-bootstrap";

type inputForm = {
  quantite: number,
  coutUnite: number,
  impot: number,
  total: number,
  description: string
}

export const UpdateInvoices = ({ show, onHide }: any) => {
  const [infosFacture, setInfosFacture] = React.useState<inputForm[]>(
    [
      {
        'quantite': 0,
        'coutUnite': 0,
        'impot': 0,
        'total': 0,
        'description': '',
      }
    ]
  );

  const handleAddForm = () => {
    let newFomrs = {
      quantite: 0,
      coutUnite: 0,
      impot: 0,
      total: 0,
      description: ''
    }

    setInfosFacture([...infosFacture, newFomrs]);
  }

  const handleRemoveForm = (index) => {
    const values = [...infosFacture];
    values.splice(index, 1);

    setInfosFacture(values);
  }
  return (
    <Modal show={show} onHide={onHide} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>
          Mettre à jour les informations de facturation.
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Mettre à jour les informations de facturation.</p>

        {infosFacture.map((input, index) => (
          <div className="item-lines mt-2" data-type="quote" key={index}>
            <div className="row gy-4">
              <div className="col-sm-4">
                <div className="form-group">
                  <label className="form-label">Item Description</label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      placeholder="Item Description"
                      name="item[]"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-1">
                <div className="form-group">
                  <label className="form-label">Qty</label>
                  <div className="form-control-wrap hide-arrows">
                    <input
                      type="number"
                      className="form-control form-control-lg line-quantity"
                      value="1"
                      min="1"
                      placeholder="Quantity"
                      name="quantity[]"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="form-group">
                  <label className="form-label">Unit Cost ( $ )</label>
                  <div className="form-control-wrap hide-arrows">
                    <input
                      type="number"
                      className="form-control form-control-lg line-cost"
                      placeholder="Unit Cost"
                      data-parsley-pattern="[0-9]*(\.?[0-9]{2}$)"
                      name="cost[]"
                      value="0.00"
                      step="0.01"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="form-group">
                  <label className="form-label">Impôt (%)</label>
                  <div className="form-control-wrap hide-arrows">
                    <input
                      type="number"
                      className="form-control form-control-lg line-tax"
                      placeholder="Impôt (%)"
                      min="0"
                      name="tax[]"
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="form-group">
                  <label className="form-label">Totale ( $ )</label>
                  <div className="form-control-wrap">
                    <input
                      type="number"
                      className="form-control form-control-lg line-total"
                      placeholder="Total"
                      data-parsley-pattern="[0-9]*(\.?[0-9]{2}$)"
                      name="total[]"
                      value="0.00"
                      step="0.01"
                      required
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-1">
                <div className="form-group">
                  <div className="form-control-wrap">
                    <a
                      className="btn btn-icon btn-lg btn-round btn-dim btn-outline-danger mt-gs remove-line"
                      data-toggle="tooltip"
                      title="Retirer l'objet"
                      onClick={() => handleRemoveForm(index)}
                    >
                      <em className="icon ni ni-cross-circle-fill"></em>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>))}
        <div className="item-totals border-top mt-2 pt-2">
          <div className="row gy-4 d-flex justify-content-end">
            <div className="col-sm-7">
              <a
                className="btn btn-dim btn-outline-primary mt-2 add-item"
                data-type="invoice"
                onClick={() => handleAddForm()}
              >
                <em className="icon ni ni-plus"></em>
                <span>Ajouter un item</span>{" "}
              </a>
            </div>
            <div className="col-sm-4 text-right">
              <div className="fw-normal">
                Sous-total:<div className="fw-bold sub-total">$ 0.00</div>
              </div>
              <div className="fw-normal">
                Impôt:<div className="fw-bold tax-total">$ 0.00</div>
              </div>
              <div className="fw-bold fs-19px border-top">
                Totale:<div className="fw-bold grand-total">$ 0.00</div>
              </div>
            </div>
            <div className="col-sm-1"></div>
          </div>
        </div>
        <div className="mt-2">
          <div className="row gy-4 border-top mt-1">
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label">Date de facturation</label>
                <div className="form-control-wrap">
                  <input
                    type="date"
                    className="form-control form-control-lg"
                    placeholder="Date de facturation"
                    value="{{ date('Y-m-d') }}"
                    name="invoice_date"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label">Paiement dû</label>
                <div className="form-control-wrap">
                  <input
                    type="date"
                    className="form-control form-control-lg"
                    placeholder="Paiement dû"
                    value="{{ date('Y-m-d') }}"
                    name="due_date"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label">Remarques</label>
                <div className="form-control-wrap">
                  <textarea
                    className="form-control form-control-lg unset-mh"
                    placeholder="Remarques"
                    rows={2}
                    name="notes"
                  ></textarea>
                </div>
                <div className="form-note">
                  Les notes seront imprimées sur la facture.
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label">Détails de paiement</label>
                <div className="form-control-wrap">
                  <textarea
                    className="form-control form-control-lg unset-mh"
                    placeholder="Détails de paiement"
                    rows={2}
                    name="payment_details"
                  ></textarea>
                </div>
                <div className="form-note">
                  Les détails du paiement seront imprimés sur la facture.
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="bg-light">
        <button
          className="btn btn-white btn-dim btn-outline-light"
          type="button"
          data-dismiss="modal"
          onClick={onHide}
        >
          <em className="icon ni ni-cross-circle"></em>
          <span>Annuler</span>
        </button>
        <button className="btn btn-primary" type="submit">
          <em className="icon ni ni-check-circle-cut"></em>
          <span>Sauvegarder les modifications</span>
        </button>
      </Modal.Footer>
    </Modal>
  );
};
