import api from "../../axios";
import type { taskEntityPaginationType, taskEntityType } from "../../types";

export const network = {
  async getAllTaskItems(): Promise<{data: taskEntityType[]}> {
    const { data } = await api.withToken.get('/task');

    return data;
  },
  async getTaskItemById(taskId: string): Promise<{data: taskEntityType}> {
    const { data } = await api.withToken.get('/task/' + taskId);

    return data;
  },
  async createTaskItem(task: Object): Promise<{data: taskEntityType}> {
    const { data } = await api.withToken.post('/task', task);

    return data;
  },
  async updateTaskItem(taskItem: taskEntityType): Promise<{data: taskEntityType}> {
    const { data } = await api.withToken.put(`/task/${taskItem.idTask}`, taskItem)
    return data;
  },
  async getTaskItemPaginated(page: number): Promise<{data: taskEntityPaginationType
    hasMore?: boolean
  }> {
    const { data } = await api.withToken.get('/task/page/20/' + page);

    return data;
  },
  async deleteTaskItem(donne: { id: number }): Promise<{data: taskEntityType[]}> {
    const { data } = await api.withToken.delete('/task/' + donne.id);

    return data;
  }
}