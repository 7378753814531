import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import { paiementHooks } from "../../network";

interface Paiement {
  idPayement: number,
  montant: number,
  satuts: boolean,
  modePayement: string,
  datePaiement: string,
  factures: {
    projets: {
      vehicules: {
        clients: {
          nomPrenom: string,
          telephone: string
        },
        detailVehicules: {
          model: string,
          marque: string,
        }
      }
    },
    idFacture: number,
  }
}
export const UpdatePayment = ({ show, onHide, data }: any) => {
  const [montant, setMontant] = useState<number>(0);
  const [datePaiement, setDatePaiement] = useState<string>('');
  const [mode, setMode] = useState<string>('');
  const [note, setNote] = useState<string>('');
  const { mutate } = paiementHooks.useUpdatePaiement();

  useEffect(() => {
    setMontant(data.montant);
  }, [data]);

  useEffect(() => {
    setDatePaiement(data.datePaiement);
  }, [data]);

  useEffect(() => {
    setMode(data.modePayement);
  }, [data]);

  useEffect(() => {
    setNote(data.note);
  }, [data]);

  const handleSelectMode = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setMode(e.target.value)
  }

  const updatePaiement = () => {

    const paiement = {
      montant: montant,
      note: note,
      datePaiement: datePaiement,
      modePayement: mode,
      factures: data.factures.idFacture,
      id: data.idPayement
    }

    return mutate(paiement, {
      onSuccess: async (data) => {
        onHide();
      },
      onError: (error) => {
        onHide();
        Swal.fire({
          icon: 'error',
          text: 'Erreur lors de la mise a jour du paiement',
        });
      },
    })
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Mettre à jour le paiement</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Mettre à jour le paiement</p>
        <div className="row gy-4">
          <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">Montant</label>
              <div className="form-control-wrap">
                <div className="form-text-hint">
                  <span className="overline-title">$</span>
                </div>
                <input
                  type="number"
                  className="form-control form-control-lg"
                  placeholder="Montante"
                  /* data-parsley-pattern="[0-9]*(\.?[0-9]{2}$)"
                  name="amount"
                  value="0.00"
                  step="0.01"
                  min="0.01" */
                  value={montant}
                  required
                  onChange={e => setMontant(Number(e.target.value))}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label className="form-label">Date de paiement</label>
              <div className="form-control-wrap">
                <input
                  type="date"
                  className="form-control form-control-lg"
                  placeholder="Date de paiement"
                  value={datePaiement}
                  name="payment_date"
                  onChange={e => setDatePaiement(e.target.value)}
                  required
                />
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label className="form-label">Mode de paiement</label>
              <div className="form-control-wrap ">
                <div className="form-control-select">
                  <select
                    className="form-control form-control-lg"
                    name="method"
                    onChange={handleSelectMode}
                    value={mode}
                  >
                    <option value="Cash">En espèces</option>
                    <option value="Card">En espèces</option>
                    <option value="Mobile Money">Argent mobile</option>
                    <option value="Bank">Banque</option>
                    <option value="Cheque">Chèque</option>
                    <option value="Online Payment">Paiement en ligne</option>
                    <option value="Other">Autre</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">Noter</label>
              <div className="form-control-wrap">
                <textarea
                  className="form-control form-control-lg"
                  placeholder="Noter"
                  rows={2}
                  name="note"
                  value={note}
                  onChange={e => setNote(e.target.value)}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="bg-light">
        <button
          className="btn btn-white btn-dim btn-outline-light"
          type="button"
          onClick={onHide}
        >
          <em className="icon ni ni-cross-circle"></em>
          <span>Annuler</span>
        </button>
        <button className="btn btn-primary" type="submit" onClick={updatePaiement}>
          <em className="icon ni ni-check-circle-cut"></em>
          <span>Sauvegarder les modifications</span>
        </button>
      </Modal.Footer>
    </Modal>
  );
};
