import { SortableContainer, SortableElement } from "react-sortable-hoc";

const SortableItem = SortableElement(({ value, id }) => {
  return (
    <div className="part-order-item cursor" style={{ zIndex: 9999 }}>
      <div className="mt-1 mb-0">
        <div className="part-drag">
          <i className="ni ni-sort" />
        </div>
        <span className="index">{id + 1}</span>
        .) {value}
      </div>
    </div>
  );
});
const SortableList = SortableContainer(({ items }) => {
  return (
    <ul>
      {items.map((value, index) => (
        <SortableItem
          key={`item-${value.value}`}
          index={index}
          value={value.value}
          id={value.id}
        />
      ))}
    </ul>
  );
});
export default SortableList;
