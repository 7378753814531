import { createContext } from "react";

interface Client {
  idUser: number,
  membres: {
    idMembre: number,
    email: string,
    prenom: string,
    nom: string,
    adresse: string
    telephone: string,
    role: string,
    statut: string,
    taper: string
  }
}

interface AuthContextInterface {
  client: Client | undefined;
  loadingClient: boolean;
}

const AuthContext = createContext<AuthContextInterface>({
  client: undefined,
  loadingClient: false,
});

export { AuthContext };
