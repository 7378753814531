import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { taskItemHooks } from "../../network/task-item";
import Swal from "sweetalert2";
import { taskEntityType } from "../../types";
interface UpdateTaskItemProps {
  show: boolean;
  onHide: () => void;
  taskId: number;
  maxOrder: number
}

export function UpdateTaskItem({ show, onHide, taskId, maxOrder }: UpdateTaskItemProps) {
  const { data } = taskItemHooks.useGetTaskItemById(taskId.toString());
  const upsertItem = taskItemHooks.useUpdateTaskItem();

  const [taskItem, setTaskItem] = useState<taskEntityType>({
    taskTitle: '',
  });

  const [errorTitle, setErrorTitle] = useState<boolean>(false);
  const [classTitle, setClassTitle] = useState<string>('form-control form-control-lg');

  React.useEffect(() => {
    if(data?.data) {
      setTaskItem(data?.data);
    }
  }, [data])

  const checkEmptyTaskTitle = () => {
    if (taskItem.taskTitle.trim().length === 0) {
      setErrorTitle(true)
      setClassTitle('form-control form-control-lg is-invalid')
      return true
    } else {
      setErrorTitle(false)
      setClassTitle('form-control form-control-lg')
      return false
    }
  }

  const updateTaskItem = () => {
    const taskItemData = {...data?.data, ...taskItem };

    if (checkEmptyTaskTitle()) {
      checkEmptyTaskTitle()
      Swal.fire({
        icon: 'error',
        text: 'Veuillez remplir tous les champs pour procéder à la création',
      });
      return;
    }

    return upsertItem.mutate(taskItemData, {
      onSuccess: async (data) => {
        onHide()
        Swal.fire({
          icon: 'success',
          text: 'Mise à jour réussi',
        });
      },
      onError: (error) => {
        onHide()
        Swal.fire({
          icon: 'error',
          text: 'Erreur lors de la mise a jour',
        });
      },
      onSettled: async (data) => {
        console.log('tonga ato oooo')
      },
    })
  }
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Manage Info</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Update project tasks</p>
        <div className="row gy-4">
          <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">Titre de la tâche</label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  className={classTitle}
                  placeholder="titre"
                  name="taskTitle"
                  required
                  onChange={e => setTaskItem({ ...taskItem, taskTitle: e.target.value })}
                  onBlur={checkEmptyTaskTitle}
                  value={taskItem.taskTitle}
                />
                {errorTitle && <div className="text-danger">Valeur obligatoire</div>}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className=" bg-light">
        <button
          className="btn btn-white btn-dim btn-outline-light"
          type="button"
          onClick={onHide}
        >
          <em className="icon ni ni-cross-circle"></em>
          <span>Cancel</span>
        </button>
        <button className="btn btn-primary" type="button" onClick={updateTaskItem}>
          <em className="icon ni ni-check-circle-cut" />
          <span>Save Changes</span>
        </button>
      </Modal.Footer>
    </Modal>
  );
}
