import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { CreateInvoices, CreateJobCard, CreateQuote } from "../../Modal";
import { UpdateProjects } from "../../Modal/UpdateProjects";

export default function Projects() {
  const [showUpdate, setShowUpdate] = useState(false);
  const [showCreateJobCard, setShowCreateJobCard] = useState(false);
  const [showCreateQuote, setShowCreateQuote] = useState(false);
  const [showCreateInvoice, setShowCreateInvoice] = useState(false);

  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: `This project will be deleted permanently.`,
      confirmButtonText: "Yes, delete!",
      cancelButtonText: `Cancel`,
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: "#fff",
    }).then((result) => {
      if (result.isConfirmed) {
        ///When click Yes
      }
    });
  };
  const handleCancel = () => {
    Swal.fire({
      title: "Are you sure?",
      text: `This project will be marked as cancelled.`,
      confirmButtonText: "Yes, Cancel!",
      cancelButtonText: `Cancel`,
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: "#fff",
    }).then((result) => {
      if (result.isConfirmed) {
        ///When click Yes
      }
    });
  };

  return (
    <>
      <div className="card-inner">
        <div className="nk-block mb-2">
          <div className="nk-block-head">
            <h5 className="title">Company Projects</h5>
            <p>A list of Jubilee Insurance Company's projects.</p>
          </div>
          {/* .nk-block-head */}
        </div>
        {/* .nk-block */}

        <table className="datatable-init nk-tb-list nk-tb-ulist mt">
          <thead>
            <tr className="nk-tb-item nk-tb-head">
              <th className="nk-tb-col text-center">#</th>
              <th className="nk-tb-col tb-col-md">
                <span className="sub-text">Client</span>
              </th>
              <th className="nk-tb-col">
                <span className="sub-text">Project</span>
              </th>
              <th className="nk-tb-col tb-col-md">
                <span className="sub-text">Profit / Start - Finish</span>
              </th>
              <th className="nk-tb-col tb-col-md">
                <span className="sub-text">Quoted / Cost</span>
              </th>
              <th className="nk-tb-col tb-col-md text-center">
                <span
                  className="sub-text"
                  data-toggle="tooltip"
                  title="Tasks In Progress"
                >
                  P. Tasks <em className="icon ni ni-info-fill" />
                </span>
              </th>
              <th className="nk-tb-col tb-col-md">
                <span className="sub-text">Status</span>
              </th>
              <th className="nk-tb-col nk-tb-col-tools text-right"></th>
            </tr>
          </thead>
          <tbody>
            <tr className="nk-tb-item">
              <td className="nk-tb-col text-center">1</td>
              <td className="nk-tb-col tb-col-md">
                <div className="user-card">
                  <div className="user-avatar bg-dim-primary d-none d-sm-flex">
                    <span>te</span>
                  </div>
                  <div className="user-info">
                    <span className="tb-lead">
                      test <span className="dot dot-success d-md-none ml-1" />
                    </span>
                    <span>+84831217407</span>
                  </div>
                </div>
              </td>
              <td className="nk-tb-col">
                <span className="tb-amount">TATA PRIMA</span>
                <span>23424</span>
              </td>
              <td className="nk-tb-col tb-col-md">
                <span className="tb-amount">$2.20</span>
                <span>May 24, 22 - May 31, 22 </span>
              </td>
              <td className="nk-tb-col tb-col-md">
                <span>$2.20</span>
                <br />
                <span>$2.20</span>
              </td>
              <td className="nk-tb-col tb-col-md text-center">
                <span>0 / 1 </span>
              </td>
              <td className="nk-tb-col tb-col-md">
                {/* <span className="badge badge-sm badge-dot has-bg badge-success d-none d-mb-inline-flex">Completed</span> */}

                {/* <span className="badge badge-sm badge-dot has-bg badge-secondary d-none d-mb-inline-flex">Cancelled</span> */}

                <span className="badge badge-sm badge-dot has-bg badge-warning d-none d-mb-inline-flex">
                  In Progress
                </span>
              </td>
              <td className="nk-tb-col nk-tb-col-tools">
                <ul className="nk-tb-actions gx-1">
                  <Dropdown>
                    <Dropdown.Toggle
                      as="a"
                      className="btn btn-icon btn-trigger"
                    >
                      <em className="icon ni ni-more-h"></em>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu-right">
                      <ul className="link-list-opt no-bdr">
                        <li>
                          <Link to="/projects/details">
                            <em className="icon ni ni-eye" />
                            <span>View Details</span>
                          </Link>
                        </li>
                        <li>
                          <a
                            className="fetch-display-click cursor"
                            onClick={() => setShowUpdate(true)}
                          >
                            <em className="icon ni ni-pen" />
                            <span>Edit Details</span>
                          </a>
                        </li>
                        <li>
                          <a
                            className="create-jobcard cursor"
                            onClick={() => setShowCreateJobCard(true)}
                          >
                            <em className="icon ni ni-property-add" />
                            <span>Create Job Card</span>
                          </a>
                        </li>
                        <li className="divider"></li>
                        <li>
                          <a
                            className="create-quote cursor"
                            onClick={() => setShowCreateQuote(true)}
                          >
                            <em className="icon ni ni-cards" />
                            <span>Create Quote</span>
                          </a>
                        </li>
                        <li>
                          <a
                            className="create-invoice cursor"
                            onClick={() => setShowCreateInvoice(true)}
                          >
                            <em className="icon ni ni-cc" />
                            <span>Create Invoice</span>
                          </a>
                        </li>
                        <li>
                          <a
                            className="send-to-server-click cursor"
                            onClick={handleCancel}
                          >
                            <em className="icon ni ni-na" />
                            <span>Cancel Project</span>
                          </a>
                        </li>

                        <li>
                          <a
                            className="send-to-server-click cursor"
                            onClick={handleDelete}
                          >
                            <em className="icon ni ni-trash" />
                            <span>Delete Project</span>
                          </a>
                        </li>
                      </ul>
                    </Dropdown.Menu>
                  </Dropdown>
                </ul>
              </td>
            </tr>
            {/* if empty <tr>
                        <td className="text-center" colSpan={8}>
                          It's empty here!
                        </td>
                      </tr> */}
          </tbody>
        </table>
      </div>
      <UpdateProjects show={showUpdate} onHide={() => setShowUpdate(false)} />
      <CreateJobCard
        show={showCreateJobCard}
        onHide={() => setShowCreateJobCard(false)}
      />
      <CreateQuote
        show={showCreateQuote}
        onHide={() => setShowCreateQuote(false)}
      />
      <CreateInvoices
        show={showCreateInvoice}
        onHide={() => setShowCreateInvoice(false)}
      />
    </>
  );
}
