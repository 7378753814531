import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from "dayjs";
import 'dayjs/locale/fr';
import moment from "moment";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
interface PlanWorkProps {
  show: boolean;
  onHide: () => void;
  onSubmit: (value) => void;
}

export function PlanWork({ show, onHide, onSubmit }: PlanWorkProps) {
  const [value, setValue] = useState<Dayjs | null>(dayjs());
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Planifier les travaux à faire</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row gy-4">
          <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">Séléctionner la date</label>
              <div className="form-control-wrap">
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                  <DateTimePicker
                    value={value}
                    onChange={(newValue) => setValue(newValue)}
                    disablePast
                  />
                </LocalizationProvider>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className=" bg-light">
        <button
          className="btn btn-white btn-dim btn-outline-light"
          type="button"
          onClick={onHide}
        >
          <em className="icon ni ni-cross-circle"></em>
          <span>Annuler</span>
        </button>
        <button
          className="btn btn-primary"
          type="button"
          onClick={() => {
            onSubmit(value);
            onHide();
          }}
        >
          <em className="icon ni ni-check-circle-cut" />
          <span>Planifier</span>
        </button>
      </Modal.Footer>
    </Modal>
  );
}
