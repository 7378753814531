import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { network } from "./network";

export const hooks = {
  useGetFactureClient(id: string) {
    const reponse = useQuery(['factureClient', id], () => network.getFactureClient(id));

    return reponse;
  },
  useGetAllFacture() {
    const reponse = useQuery(['factures'], network.getAllFacture);

    return reponse
  },
  useGetFacturePaginate(page: number) {
    const reponse = useQuery(['facturePaginate', page], () => network.getFacturePaginate(page), { keepPreviousData: true });

    return reponse
  },
  usePrefetchFacture(page: number) {
    const queryClient = useQueryClient();

    return queryClient.prefetchQuery(['facturePaginate', page], () => network.getFacturePaginate(page));
  },
  useAddFacture() {
    const queryClient = useQueryClient();

    const reponse = useMutation(network.addFacture, {
      onSuccess: () => {
        queryClient.invalidateQueries(['facturePaginate'])
      }
    });

    return reponse;
  },
  useDeleteFacture() {
    const queryClient = useQueryClient();

    const reponse = useMutation(network.deleteFacture, {
      onSuccess: () => {
        queryClient.invalidateQueries(['facturePaginate'])
      }
    });

    return reponse;
  }
}