import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useParams, useNavigate } from 'react-router-dom';
import { userHooks } from '../../../network';

export default function NewPassword() {

  const [passwordType, setPasswordType] = useState<string>('password');
  const [password, setPassword] = useState<string>('');
  const [confPassword, setConfPassword] = useState<string>('');
  const [isConfPassDirty, setIsConfPassDirty] = useState(false);
  const [cPasswordClass, setCPasswordClass] = useState('form-control');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const params = useParams()
  let token = params.token || ""
  const newMdp = userHooks.useNewPassword()
  const route = useNavigate();


  const changePasswordType = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }

  useEffect(() => {
    if (isConfPassDirty) {
      if (password === confPassword) {
        setShowErrorMessage(false);
        setCPasswordClass('form-control is-valid')
      } else {
        setShowErrorMessage(true)
        setCPasswordClass('form-control is-invalid')
      }
    }
  }, [confPassword])

  const handleCPassword = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    setConfPassword(e.target.value);
    setIsConfPassDirty(true);
  }

  const reinitializeMdp = async (event: any) => {
    event.preventDefault()

    console.log(token, 'ato ary ilay token oooooo');

    const { mutate } = newMdp;

    mutate({ token, password });

    route("/");

  }

  return (
    <>
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <Card style={{ marginTop: '11%' }}>
            <Card.Body>
              <h3>
                Reinitialisation mot de passe
              </h3>
              <form onSubmit={reinitializeMdp}>
                <div className="row gy-4">
                  <div className="col-sm-12 mt-0">
                    <div className="form-group">
                      <label className="form-label">Nouveau mot de passe</label>
                      <div className="form-control-wrap">
                        <a
                          onClick={changePasswordType}
                          className="form-icon form-icon-right passcode-switch"
                          data-target="current"
                        >
                          {passwordType === "password" ? <em className="passcode-icon icon-show icon ni ni-eye"></em> :
                            <em className="passcode-icon icon-show icon ni ni-eye-off"></em>
                          }
                        </a>
                        <input
                          type={passwordType}
                          className="form-control form-control-lg"
                          id="current"
                          name="current"
                          placeholder="Mot de passe"
                          required
                          value={password}
                          onChange={(e) => { setPassword(e.target.value) }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row gy-4">
                  <div className="col-sm-12 mt-0">
                    <div className="form-group">
                      <label className="form-label">Confirmation nouveau mot de passe</label>
                      <div className="form-control-wrap">
                        <input
                          type={passwordType}
                          className={cPasswordClass}
                          id="current"
                          name="current"
                          placeholder="Mot de passe"
                          required
                          value={confPassword}
                          onChange={handleCPassword}
                        />
                      </div>
                      {showErrorMessage && isConfPassDirty ? <div> Passwords did not match </div> : ''}
                    </div>
                  </div>
                </div>
                <div className='col-sm-4 offset-4'>
                  <br />
                    <button className="btn btn-primary" type='submit'>
                      <span>Reinitialiser</span>
                    </button>
                </div>
              </form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  )
}
