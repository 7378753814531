import { useEffect, useState } from "react";
import {
  CreateTeamMember,
} from "../../Modal";
import TableauTechnicien from "../../components/Tableau/TableauTechnicien";
import { membreHooks } from "../../network";
import Loading from "../../components/Loading";
import { isNullishCoalesce } from "typescript";

export default function Admin() {
  const [showCreate, setShowCreate] = useState(false);
  const [showPageMenu, setShowPageMenu] = useState(false);
  const [pageActive, setPageActive] = useState<number>(1);
  const [role, setRole] = useState('')
  const { data, isLoading } = membreHooks.useGetMembrePaginate(pageActive, 'Admin');


  const nextPage = () => {
    if (data){
      if (pageActive < data.data.countPage) {
        setPageActive(pageActive + 1);
      } else {
        setPageActive(pageActive);
      }
    }
  }

  const prevPage = () => {
    if (pageActive > 1) {
      setPageActive(pageActive - 1);
    } else {
      setPageActive(pageActive);
    }
  }

  const goToPage = (numPage: number) => {
    setPageActive(numPage);
  }

  useEffect(() => {
    if (data?.hasMore) {
      membreHooks.usePrefetchMembre(pageActive, 'Admin')
    }
  }, [data, pageActive])

  if (isLoading){
    return (<div className="loading__wrapper">
    <Loading />
  </div>)
  }

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          {isLoading ? <Loading /> :
            <div className="nk-block-head-content">
              <h3 className="nk-block-title page-title">Administrateurs</h3>
              <div className="nk-block-des text-soft">
                <p>Un total de {data?.data.countTotal?? 0} administrateur(s)</p>

                {/* <p>Create and manage your team here.</p> */}
              </div>
            </div>}
          <div className="nk-block-head-content">
            <div className="toggle-wrap nk-block-tools-toggle">
              <a
                onClick={() => setShowPageMenu(!showPageMenu)}
                className="btn btn-icon btn-trigger toggle-expand mr-n1"
                data-target="pageMenu"
              >
                <em className="icon ni ni-menu-alt-r"></em>
              </a>
              <div
                className={`toggle-expand-content ${showPageMenu && "d-block"}`}
                data-content="pageMenu"
              >
                <ul className="nk-block-tools g-3">
                  <li>
                    <button
                      onClick={() => {
                        setShowCreate(true)
                        setRole('admin')
                      }}
                      className="btn btn-primary"
                    >
                      <em className="icon ni ni-plus"></em>
                      <span>Ajouter un administrateur</span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading ? <Loading /> : (data && data.data ? <TableauTechnicien data={data.data} nextPage={nextPage} prevPage={prevPage} goToPage={goToPage} /> : null)}
      <CreateTeamMember show={showCreate} onHide={() => setShowCreate(false)}
        roleProp={role}
      />
    </>

  );
}
