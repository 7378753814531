import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { membreHooks, projetHooks } from '../../network';
import Loading from '../../components/Loading';
import { tacheHooks } from '../../network';
import { useParams } from 'react-router-dom';
import { pieceHooks } from "../../network/piece";
import Swal from "sweetalert2";
import { taskGroupHooks } from "../../network/task-group";
import { workHooks } from "../../network/work";
import type { workEntityType, workStepEntityType, taskGroupEntityType, statusType } from "../../types";


interface UpdateWorkProps {
  dataWork: workEntityType;
  show: boolean;
  onHide: () => void;
}

export function UpdateWork({ dataWork, show, onHide }: UpdateWorkProps) {
  const { data: dataMembre, isLoading: loadingMembre } = membreHooks.useGetMembres();
  const [estimation, setEstimation] = useState<number>(0);
  const [hours, setHours] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(0);
  const [errorEstimation, setErrorEstimation] = useState<boolean>(false);
  const [classEstimation, setClassEstimation] = useState<string>('form-control form-control-lg');
  const [tabTaskGroup, setTabTaskGroup] = useState<taskGroupEntityType[]>([]);
  const allTaskGroups = taskGroupHooks.useGetAllTaskGroups();
  const [works, setWorks] = useState<workStepEntityType[]>([]);
  const [selectedTaskGroup, setSelectedTaskGroup] = useState<taskGroupEntityType>({
    idTaskGroup: 0,
    taskGroupLabel: '',
    tasks: [],
  });
  const [timeValue, setTimeValue] = useState<number>(1);
  const [timeType, setTimeType] = useState<string>('h');

  React.useEffect(() => {
    if(allTaskGroups.data?.data) {
      setTabTaskGroup(allTaskGroups.data.data);
    }
    if(works?.length === 0 && dataWork) {
      setWorks(dataWork.workSteps);
    }
  }, [allTaskGroups, setTabTaskGroup, works, setWorks, dataWork])

  const params = useParams()
  let id = params.id || "";

  const { data, isLoading } = projetHooks.useGetProjectById(id);
  const { mutate } = workHooks.useUpdateWork();

  const handleChangeMembre = (e: { target: { value: React.SetStateAction<string>; }; }, indexWork: number) => {
    const w = [...works];
    const membre = dataMembre?.data.find(membre => membre.idMembre === Number(e.target.value));

    if (membre){
      w[indexWork].assignTo = membre
      setWorks(w);
    }
  }

  const handleChangeStatus = (status: statusType , indexWork: number) => {
    const w = [...works];
    w[indexWork].status = status;
    setWorks(w);
  }

  const handleChangeEstimation = (value: number, timeType: 'h'|'min', indexWork: number) => {
    const w = [...works];
    let estimate = w[indexWork].estimate ?? 0;
    let hours = Math.floor(estimate / 3600);
    let minutes = Math.floor((estimate % 3600) / 60);

    if( timeType === 'h'){
      hours = value * 3600;
    }else{
      hours *= 3600;
    }
    if(timeType === "min"){
      minutes = value * 60;
    }else{
      minutes *= 60;
    }

    estimate = hours + minutes
    w[indexWork].estimate = estimate

    setWorks(w);
  }

  const checkEmptyEstimation = () => {
    if (estimation === 0) {
      setErrorEstimation(true)
      setClassEstimation('form-control form-control-lg is-invalid')
      return true
    } else {
      setErrorEstimation(false)
      setClassEstimation('form-control form-control-lg')
      return false
    }
  }

  const addWork = () => {
    setTabTaskGroup(tabTaskGroup.filter(t => t.idTaskGroup !== selectedTaskGroup.idTaskGroup));
    const newWork: workStepEntityType = {
      status: 'A faire',
      estimate: 0,
      assignTo: null,
      taskGroup: selectedTaskGroup
    }

    if(!works.find(work => work.taskGroup.idTaskGroup === selectedTaskGroup.idTaskGroup)) {
      setWorks([...works, ...[newWork]]);
    }
  }

  const removeWork = (idTaskGroup) => {
    const updatedWork = works.filter(work => work.taskGroup.idTaskGroup !== idTaskGroup);
    setWorks(updatedWork);
  }

  const createWork = () => {

    const work = {
      idWork: dataWork.idWork,
      projets: {idProjet: data?.data.idProjet},
      workSteps: works
    }


    // if (checkEmptyEstimation()) {
    //   checkEmptyEstimation()
    //   Swal.fire({
    //     icon: 'error',
    //     text: 'Veuillez remplir tous les champs',
    //   });
    //   return;
    // }

    return mutate({idWork: dataWork.idWork, work}, {
      onSuccess: async (data) => {
        onHide()
        Swal.fire({
          icon: 'success',
          text: 'Travaux cree avec succes',
        });
      },
      onError: (error) => {
        onHide()
        Swal.fire({
          icon: 'error',
          text: 'Erreur lors de l\'ajout du nouveau travail',
        });
      },
      onSettled: async (data) => {
        console.log('tonga ato oooo')
      },
    })
  }

  return (
    <Modal show={show} onHide={onHide} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Modification des travaux</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row gy-4">
          <div className="col-sm-8">
            <div className="form-group">
              <label className="form-label">Ajouter un groupe de tâche à faire</label>
              <div className="form-control-wrap ">
                <div className="form-control-select">
                  <select
                    className="form-control select2"
                    name="taskGroup"
                    required
                    onChange={(e) => {
                      const taskGroup = tabTaskGroup.find(t => t.idTaskGroup === Number(e.target.value))
                      if (taskGroup)
                        setSelectedTaskGroup(taskGroup)
                    }}
                  >
                    <option></option>
                    {tabTaskGroup.map((tg) => (
                      <option value={tg.idTaskGroup}>{tg.taskGroupLabel}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-2 align-self-end">
            <button
              className="btn btn-primary"
              type="button"
              onClick={addWork}
            >
              Ajouter
            </button>
          </div>
          {
            works && works.map((w, i) => (
              <div key={`work-step-${i}`}>
                <div className="nk-divider divider"></div>
                <div className="col-sm-12">
                  <h5>
                    {w.taskGroup.taskGroupLabel}
                    <button className="btn btn-small btn-danger float-right" onClick={() => removeWork(w.taskGroup.idTaskGroup)}>
                      <em className="icon ni ni-cross-circle"></em>
                    </button>
                  </h5>
                  <div className="row mt-5">
                    <div className="col-md-8">
                    <ol className="list-group list-group-numbered">
                      {
                        w.taskGroup?.tasks?.map((task, index) => (
                          <li key={`task-${index}`} className="list-group-item d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                              <div className="fw-bold">{task.taskTitle}</div>
                            </div>
                          </li>
                        ))
                      }
                    </ol>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">Assigner à</label>
                        <div className="form-control-wrap ">
                          {loadingMembre ? <Loading /> :
                            <div className="form-control-select">
                              <select
                                className="form-control select2"
                                name="member"
                                required
                                onChange={e => handleChangeMembre(e, i)}
                                defaultValue={w.assignTo?.idMembre}
                              >
                                <option></option>
                                {dataMembre?.data.map((item: { nom: string, idMembre: number, prenom: string }) => (
                                  <option value={item.idMembre}>{item?.prenom} {item?.nom}</option>
                                ))}
                              </select>
                            </div>}
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="form-label">Statut</label>
                        <div className="form-control-wrap ">
                          <div className="form-control-select">
                            <select
                              className="form-control select2"
                              name="statut"
                              required
                              onChange={e => {
                                const status = e.target.value
                                if (status === "Annulé" ||
                                    status === "A faire" || 
                                    status === "En cours" || 
                                    status === "Complété" || 
                                    status === "Planifié" || 
                                    status === "Suspendu") {
                                  handleChangeStatus(status, i)
                                }
                              }}
                              defaultValue={w.status}
                            >
                              <option value="A faire">A faire</option>
                              <option value="En cours">En cours</option>
                              <option value="Complété">Complété</option>
                              <option value="Annulé">Annulé</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="form-label">Estimation</label>
                        <div className="form-control-wrap">
                          <div className="input-group">
                            <input
                              type="number"
                              className='form-control'
                              name="cost"
                              required
                              min={0}
                              defaultValue={Math.floor(w.estimate / 3600)}
                              onChange={e => {
                                handleChangeEstimation(Number(e.target.value), 'h', i)
                              }}
                            />
                            <span className="input-group-text">H</span>
                            <input
                              type="number"
                              className='form-control'
                              name="cost"
                              required
                              min={0}
                              max={59}
                              defaultValue={Math.floor((w.estimate % 3600) / 60)}
                              onChange={e => {
                                handleChangeEstimation(Number(e.target.value), 'min', i)
                              }}
                            />
                            <span className="input-group-text">Min</span>
                          </div>
                        </div>
                        {errorEstimation && <div className="text-danger">Valeur obligatoire</div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </Modal.Body>
      <Modal.Footer className=" bg-light">
        <button
          className="btn btn-white btn-dim btn-outline-light"
          type="button"
          onClick={onHide}
        >
          <em className="icon ni ni-cross-circle"></em>
          <span>Annuler</span>
        </button>
        <button className="btn btn-primary" type="submit" onClick={createWork}>
          <em className="icon ni ni-check-circle-cut"></em>
          <span>Modifier</span>
        </button>
      </Modal.Footer>
    </Modal>
  );
}
