import React from "react";
import "./Footer.scss";
export default function Footer() {
  return (
    <div className="nk-footer">
      <div className="container-fluid">
        <div className="nk-footer-wrap">
          <div className="nk-footer-copyright">
            &copy; 2022 Carrosserie Des Meulières • Tous les droits sont réservés.
          </div>
        </div>
      </div>
    </div>
  );
}
