import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

interface Factures {
  data: []
}

export default function TableauFactureClient(props: Factures) {

  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: `This invoice will be deleted permanently.`,
      confirmButtonText: "Yes, delete!",
      cancelButtonText: `Cancel`,
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: "#fff",
    }).then((result) => {
      if (result.isConfirmed) {
        ///When click Yes
      }
    });
  };

  interface Facture {
    idFacture: number,
    dateFacturation: string,
    datePaiement: string,
    total: string,
    status: string
    projets: {
      vehicules: {
        detailVehicules: {
          model: string,
          marque: string
        }
      }
    }
  }
  return (
    <>

        <table className="datatable-init nk-tb-list nk-tb-ulist mt">
          <thead>
            <tr className="nk-tb-item nk-tb-head">
              <th className="nk-tb-col tb-col-md">
                <span className="sub-text">ID</span>
              </th>
              <th className="nk-tb-col tb-col-md">
                <span className="sub-text">Modèle et marque de la voiture</span>
              </th>
              <th className="nk-tb-col tb-col-md">
                <span className="sub-text">Date de création /Date de paiement</span>
              </th>
              <th className="nk-tb-col">
                <span className="sub-text">Total </span>
              </th>
              <th className="nk-tb-col tb-col-md">
                <span className="sub-text">Statut</span>
              </th>
              <th className="nk-tb-col nk-tb-col-tools text-right"></th>
            </tr>
          </thead>
          {props.data.map((item: Facture) => (<tbody>
            <tr className="nk-tb-item">
              <td className="nk-tb-col tb-col-md">
                <span className="tb-amount">{item.idFacture}</span>
              </td>
              <td className="nk-tb-col tb-col-md">
                <div className="user-card">
                  <div className="user-info">
                    <span className="tb-lead">{item.projets.vehicules.detailVehicules.marque}</span>
                    <span>{item.projets.vehicules.detailVehicules.model}</span>
                  </div>
                </div>
              </td>
              <td className="nk-tb-col tb-col-md">
                <span>{item.dateFacturation}</span>
                <br />
                <span>{item.datePaiement}</span>
              </td>
              <td className="nk-tb-col">
                <span className="tb-amount">{item.total}</span>
              </td>
              <td className="nk-tb-col">
                <span className="badge badge-sm badge-dot has-bg badge-success d-none d-mb-inline-flex">
                  {item.status}
                </span>

                {/* <span className="badge badge-sm badge-dot has-bg badge-warning d-none d-mb-inline-flex">Partial</span> */}

                {/* <span className="badge badge-sm badge-dot has-bg badge-danger d-none d-mb-inline-flex">Unpaid</span> */}
              </td>
              <td className="nk-tb-col nk-tb-col-tools">
                <ul className="nk-tb-actions gx-1">
                  <li>
                    <Dropdown>
                      <Dropdown.Toggle
                        as="a"
                        className="btn btn-icon btn-trigger"
                      >
                        <em className="icon ni ni-more-h"></em>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu-right">
                        <ul className="link-list-opt no-bdr">
                          <li>
                            <Link to="/invoices/view">
                              <em className="icon ni ni-eye" />
                              <span>Voir la facture</span>
                            </Link>
                          </li>

                          <li>
                            <a
                              href="/pdf/Invoice_66.pdf"
                              download="Invoice_66.pdf"
                            >
                              <em className="icon ni ni-download-cloud" />
                              <span>Télécharger la facture</span>
                            </a>
                          </li>
                        </ul>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                </ul>
              </td>
            </tr>
            {/*
                      if empty
                       <tr>
                        <td className="text-center" colSpan={6}>
                          It's empty here!
                        </td>
                      </tr> */}
          </tbody>))}
        </table>
    </>
  );
}
