import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { membreHooks } from "../../network";
import Swal from 'sweetalert2'

export const CreateTeamMember = ({ show, onHide, roleProp }: any) => {
  const [prenom, setPrenom] = useState<string>('');
  const [nom, setNom] = useState<string>('');
  const [telephone, setTelephone] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [role, setRole] = useState<string>('technicien');
  const [statut, setStatut] = useState<string>('active');
  const [adresse, setAdresse] = useState<string>('');
  const [taper, setTaper] = useState<string>('pleinTemp');
  const [errorPrenom, setErrorPrenom] = useState<boolean>(false);
  const [errorNom, setErrorNom] = useState<boolean>(false);
  const [errorEmail, setErrorEmail] = useState<boolean>(false);
  const [errorPhone, setErrorPhone] = useState<boolean>(false);
  const [errorAdresse, setErrorAdresse] = useState<boolean>(false);
  const [classPrenom, setClassPrenom] = useState<string>('form-control form-control-lg');
  const [classNom, setClassNom] = useState<string>('form-control form-control-lg');
  const [classPhone, setClassPhone] = useState<string>('form-control form-control-lg phone-input');
  const [classEmail, setClassEmail] = useState<string>('form-control form-control-lg');
  const [classAdresse, setClassAdresse] = useState<string>('form-control form-control-lg');

  const { mutate } = membreHooks.useCreateMembre();

  const handleChangeRole = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    setRole(e.target.value)
  }

  const handleChangeStatut = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    setStatut(e.target.value)
  }

  const handleTypeHoraire = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    setTaper(e.target.value);
  }

  const checkPrenom = () => {
    if (prenom.trim().length === 0) {
      setErrorPrenom(true)
      setClassPrenom('form-control form-control-lg is-invalid')
      return true
    } else {
      setErrorPrenom(false)
      setClassPrenom('form-control form-control-lg')
      return false
    }
  }

  const checkNom = () => {
    if (nom.trim().length === 0) {
      setErrorNom(true)
      setClassNom('form-control form-control-lg is-invalid')
      return true
    } else {
      setErrorNom(false)
      setClassNom('form-control form-control-lg')
      return false
    }
  }

  const checkEmail = () => {
    if (email.trim().length === 0) {
      setErrorEmail(true)
      setClassEmail('form-control form-control-lg is-invalid')
      return true
    } else {
      setErrorEmail(false)
      setClassEmail('form-control form-control-lg')
      return false
    }
  }

  const checkPhone = () => {
    if (telephone.trim().length === 0) {
      setErrorPhone(true)
      setClassPhone('form-control form-control-lg phone-input is-invalid')
      return true
    } else {
      setErrorPhone(false)
      setClassPhone('form-control form-control-lg phone-input')
      return false
    }
  }

  const checkAdresse = () => {
    if (adresse.trim().length === 0) {
      setErrorAdresse(true)
      setClassAdresse('form-control form-control-lg phone-input is-invalid')
      return true
    } else {
      setErrorAdresse(false)
      setClassAdresse('form-control form-control-lg phone-inpu')
      return false
    }
  }

  const createMembre = () => {

    const membre = {
      email: email,
      prenom: prenom,
      nom: nom,
      telephone: telephone,
      adresse: adresse,
      statut: statut,
      role: role,
      taper: taper
    }

    if (checkAdresse() || checkEmail() || checkNom() || checkPhone() || checkPrenom()) {
      checkAdresse()
      checkEmail()
      checkNom()
      checkPhone()
      checkPrenom()
      Swal.fire({
        icon: 'error',
        text: 'Veuillez remplir tous les champs',
      });
      return;
    }

    return mutate(membre, {
      onSuccess: async (data) => {
        onHide();
        Swal.fire({
          icon: 'success',
          text: 'Membre ajouté avec succès',
        })
      },
      onError: (error) => {
        onHide();
        Swal.fire({
          icon: 'error',
          text: 'Erreur lors de l\'ajout membre',
        });
      },
    })
  }

  useEffect(()=>{
    setAdresse('')
    setNom('')
    setPrenom('')
    setEmail('')
    setRole('technicien')
    setStatut('active')
    setTelephone('')
    setTaper('pleinTemp')
    setErrorNom(false)
    setErrorAdresse(false)
    setErrorEmail(false)
    setErrorPhone(false)
    setErrorPrenom(false)
    setClassAdresse('form-control form-control-lg')
    setClassNom('form-control form-control-lg')
    setClassEmail('form-control form-control-lg')
    setClassPhone('form-control form-control-lg')
    setClassPrenom('form-control form-control-lg')
  }, [show])

  useEffect(()=>{
    setRole(roleProp)
  }, [roleProp])

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Ajouter un membre</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Information du nouveau membre de l'équipe</p>
        <div className="row gy-4">
          <div className="col-sm-6">
            <div className="form-group">
              <label className="form-label">Prénom</label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  className={classPrenom}
                  placeholder="Prénom"
                  name="fname"
                  required
                  onChange={e => setPrenom(e.target.value)}
                  onBlur={checkPrenom}
                />
              </div>
              {errorPrenom && <div className="text-danger">Valeur obligatoire</div>}
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label className="form-label">Nom de famille</label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  className={classNom}
                  placeholder="Nom de famille"
                  name="lname"
                  required
                  onChange={e => setNom(e.target.value)}
                  onBlur={checkNom}
                />
              </div>
              {errorNom && <div className="text-danger">Valeur obligatoire</div>}
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label className="form-label">Numéro de téléphone</label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  className={classPhone}
                  placeholder="Numéro de téléphone"
                  required
                  onChange={e => setTelephone(e.target.value)}
                  onBlur={checkPhone}
                />
              </div>
              {errorPhone && <div className="text-danger">Valeur obligatoire</div>}
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label className="form-label">Adresse e-mail</label>
              <div className="form-control-wrap">
                <input
                  type="email"
                  className={classEmail}
                  placeholder="Adresse e-mail"
                  name="email"
                  onChange={e => setEmail(e.target.value)}
                  onBlur={checkEmail}
                />
              </div>
              {errorEmail && <div className="text-danger">Valeur obligatoire</div>}
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">Rôle</label>
              <div className="form-control-wrap ">
                <div className="form-control-select">
                  <select value={role} className="form-control form-control-lg" name="role" onChange={handleChangeRole}>
                    <option value="technicien">Technicien</option>
                    <option value="admin">Admin</option>
                  </select>
                </div>
              </div>
              {/* <div className="form-note">
                <code>Propriétaire</code> a un accès complet au système.{" "}
                <code>Gestionnaire</code> avoir un accès complet mais n'a pas de
                permissions de suppression.{" "}
                <code>Responsable des réservations</code> livres véhicules IN et
                OUT. <code>Gestionnaire d'inventaire</code> a accès au module
                d'inventaire. <code>Personnelle</code> n'ont pas accès au
                système.
              </div> */}
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label className="form-label">Type d'horaire</label>
              <div className="form-control-wrap">
                <div className="form-control-select">
                  <select className="form-control form-control-lg" name="type" onChange={handleTypeHoraire}>
                    <option value="pleinTemp">À plein temps</option>
                    <option value="partiel">À temps partiel</option>
                    <option value="sousTraitant">Sous-traitant</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label className="form-label">Statut</label>
              <div className="form-control-wrap">
                <div className="form-control-select">
                  <select
                    className="form-control form-control-lg"
                    name="status"
                    onChange={handleChangeStatut}
                  >
                    <option value="active">Active</option>
                    <option value="enConge">En congé</option>
                    <option value="indisponible">Indisponible</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">Adresse</label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  className={classAdresse}
                  placeholder="Adresse"
                  name="address"
                  onChange={e => setAdresse(e.target.value)}
                  onBlur={checkAdresse}
                />
              </div>
              {errorAdresse && <div className="text-danger">Valeur obligatoire</div>}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="bg-light">
        <button
          className="btn btn-white btn-dim btn-outline-light"
          type="button"
          onClick={onHide}
        >
          <em className="icon ni ni-cross-circle"></em>
          <span>Annuler</span>
        </button>
        <button className="btn btn-primary" type="submit" onClick={createMembre}>
          <em className="icon ni ni-check-circle-cut"></em>
          <span>Créer</span>
        </button>
      </Modal.Footer>
    </Modal>
  );
};
