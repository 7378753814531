import React from "react";
import { Modal } from "react-bootstrap";

interface CreateQuoteProps {
  show: boolean;
  onHide: () => void;
}

export const CreateQuote = ({ show, onHide }: CreateQuoteProps) => {
  return (
    <Modal show={show} onHide={onHide} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Créer un devis</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Créer un devis pour ce projet</p>
        <div className="item-totals border-bottom">
          <div className="row gy-4">
            <div className="col-12">
              <div className="form-group mb-2">
                <label className="form-label">Sélectionnez un projet</label>
                <div className="form-control-wrap">
                  <select
                    className="form-control form-control-lg grouped"
                    name="project"
                    required
                  >
                    <option value="">Sélectionnez un projet</option>

                    <optgroup label="test">
                      <option value="test">test - 123123</option>
                    </optgroup>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-lines mt-2" data-type="quote">
          <div className="row gy-4">
            <div className="col-sm-4">
              <div className="form-group">
                <label className="form-label">Description de l'article</label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    placeholder="Description de l'article"
                    name="item[]"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-1">
              <div className="form-group">
                <label className="form-label">Quantité</label>
                <div className="form-control-wrap hide-arrows">
                  <input
                    type="number"
                    className="form-control form-control-lg line-quantity"
                    value="1"
                    min="1"
                    placeholder="Quantité"
                    name="quantity[]"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="form-group">
                <label className="form-label">Coût unitaire ( $ )</label>
                <div className="form-control-wrap hide-arrows">
                  <input
                    type="number"
                    className="form-control form-control-lg line-cost"
                    placeholder="Coût unitaire"
                    data-parsley-pattern="[0-9]*(\.?[0-9]{2}$)"
                    name="cost[]"
                    value="0.00"
                    step="0.01"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="form-group">
                <label className="form-label">Impôt (%)</label>
                <div className="form-control-wrap hide-arrows">
                  <input
                    type="number"
                    className="form-control form-control-lg line-tax"
                    placeholder="Impôt (%)"
                    min="0"
                    name="tax[]"
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="form-group">
                <label className="form-label">Totale ( $ )</label>
                <div className="form-control-wrap">
                  <input
                    type="number"
                    className="form-control form-control-lg line-total"
                    placeholder="Amount"
                    data-parsley-pattern="[0-9]*(\.?[0-9]{2}$)"
                    name="total[]"
                    value="0.00"
                    step="0.01"
                    required
                    readOnly
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-1"></div>
          </div>
          <div className="row gy-4">
            <div className="col-sm-4">
              <div className="form-group">
                <label className="form-label">Description de l'article</label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    placeholder="Description de l'article"
                    name="item[]"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-1">
              <div className="form-group">
                <label className="form-label">Quantité</label>
                <div className="form-control-wrap hide-arrows">
                  <input
                    type="number"
                    className="form-control form-control-lg line-quantity"
                    value="1"
                    min="1"
                    placeholder="Quantity"
                    name="quantity[]"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="form-group">
                <label className="form-label">Coût unitaire ( $ )</label>
                <div className="form-control-wrap hide-arrows">
                  <input
                    type="number"
                    className="form-control form-control-lg line-cost"
                    placeholder="Coût unitaire"
                    data-parsley-pattern="[0-9]*(\.?[0-9]{2}$)"
                    name="cost[]"
                    value="0.00"
                    step="0.01"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="form-group">
                <label className="form-label">Impôt (%)</label>
                <div className="form-control-wrap hide-arrows">
                  <input
                    type="number"
                    className="form-control form-control-lg line-tax"
                    placeholder="Impôt (%)"
                    min="0"
                    name="tax[]"
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="form-group">
                <label className="form-label">Totale ( $ )</label>
                <div className="form-control-wrap">
                  <input
                    type="number"
                    className="form-control form-control-lg line-total"
                    placeholder="Totale"
                    data-parsley-pattern="[0-9]*(\.?[0-9]{2}$)"
                    name="total[]"
                    value="0.00"
                    step="0.01"
                    required
                    readOnly
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-1">
              <div className="form-group">
                <div className="form-control-wrap">
                  <a
                    href="#"
                    className="btn btn-icon btn-lg btn-round btn-dim btn-outline-danger mt-gs remove-line"
                    data-toggle="tooltip"
                    title="Retirer l'objet"
                  >
                    <em className="icon ni ni-cross-circle-fill"></em>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-totals border-top mt-2 pt-2">
          <div className="row gy-4 d-flex justify-content-end">
            <div className="col-sm-7">
              <a
                href=""
                className="btn btn-dim btn-outline-primary mt-2 add-item"
                data-type="invoice"
              >
                <em className="icon ni ni-plus"></em>
                <span>Ajouter un item</span>{" "}
              </a>
            </div>
            <div className="col-sm-4 text-right">
              <div className="fw-normal">
                Sous-totale:<div className="fw-bold sub-total">$ 0.00</div>
              </div>
              <div className="fw-normal">
                Impôt:<div className="fw-bold tax-total">$ 0.00</div>
              </div>
              <div className="fw-bold fs-19px border-top">
                Totale:<div className="fw-bold grand-total">$ 0.00</div>
              </div>
            </div>
            <div className="col-sm-1"></div>
          </div>
        </div>
        <div className="item-totals border-top mt-2">
          <div className="row gy-4">
            <div className="col-12">
              <div className="form-group mt-1">
                <label className="form-label">Remarques</label>
                <div className="form-control-wrap">
                  <textarea
                    className="form-control form-control-lg unset-mh"
                    placeholder="Remarques"
                    rows={2}
                    name="notes"
                  ></textarea>
                </div>
                <div className="form-note">
                  Les notes seront imprimées sur le devis.
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="bg-light">
        <button
          className="btn btn-white btn-dim btn-outline-light"
          type="button"
          data-dismiss="modal"
          onClick={onHide}
        >
          <em className="icon ni ni-cross-circle"></em>
          <span>Annuler</span>
        </button>
        <button className="btn btn-primary" type="submit">
          <em className="icon ni ni-check-circle-cut"></em>
          <span>Créer un devis</span>
        </button>
      </Modal.Footer>
    </Modal>
  );
};
