import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { network } from "./network";

export const hooks = {
  useGetAllTaskItems() {
    const reponse = useQuery(['allTaskItems'], network.getAllTaskItems);

    return reponse;
  },
  useGetTaskItemById(taskId: string) {
    const reponse = useQuery(['taskItem', taskId], () => network.getTaskItemById(taskId));

    return reponse;
  },
  useCreateTaskItem() {
    const queryClient = useQueryClient()
    const reponse = useMutation(network.createTaskItem, {
      onSuccess: () => {
        queryClient.invalidateQueries(['taskItemPaginate'])
      }
    });

    return reponse;
  },
  useGetTaskItemPaginated(page: number) {
    const reponse = useQuery(['taskItemPaginate', page], () => network.getTaskItemPaginated(page), { keepPreviousData: true });

    return reponse;
  },
  usePrefetchTaskItem(page: number) {
    const queryClient = useQueryClient();

    return queryClient.prefetchQuery(['taskItemPaginate', page], () => network.getTaskItemPaginated(page))
  },

  useUpdateTaskItem() {
    const queryClient = useQueryClient()
    const reponse = useMutation(network.updateTaskItem, {
      onSuccess: () => {
        queryClient.invalidateQueries(['taskItemPaginate'])
      }
    });

    return reponse;
  },

  useDeleteTaskItem() {
    const queryClient = useQueryClient();

    const reponse = useMutation(network.deleteTaskItem, {
      onSuccess: () => {
        queryClient.invalidateQueries(['taskItemPaginate'])
      }
    });

    return reponse;
  }
} 