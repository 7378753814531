import React, { FunctionComponent, useState, useEffect, useRef, MouseEventHandler, ReactNode, Fragment } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

import { Pagination } from "react-bootstrap";
import { clientHooks } from "../../../network";

import {
  CreateClient,
  CreateInvoices,
  CreateJobCard,
  CreateQuote,
  SendSMS,
  UpdateClient,
} from "../../../Modal";
import moment from 'moment';
import { clientEntityPaginationType } from "../../../types";

const INIT_STATE_MODAL = {
  showCreate: false,
  showPageMenu: false,
  showUpdate: false,
  showSendSMS: false,
  showCreateJobCard: false,
  showCreateQuote: false,
  showCreateInvoice: false,
};


type Props = {
  data: clientEntityPaginationType
  nextPage: Function
  prevPage: Function
  goToPage: Function
}

export default function TableauClient({ data, nextPage, prevPage, goToPage }: Props) {
  const [showModal, setShowModal] = useState(INIT_STATE_MODAL);
  const [showPageMenu, setShowPageMenu] = useState(false);
  const [nom, setNom] = useState<string>('Test');
  const [prenom, setPrenom] = useState<string>('Test');
  const [email, setEmail] = useState<string>('test@gmail.com');
  const [tache, setTache] = useState<string>('0/0');
  const [dateCreation, setDateCreation] = useState<string>('May 20,2022');
  const [statut, setStatut] = useState<string>('Active');
  const [activePage, setActivePage] = useState<number>(1);
  const { mutate } = clientHooks.useDeleteClient();


  /* let active = 1;
  const [items, setItems]=useState<ReactNode[]>([])
  useEffect(() => {
    for (let number = 1; number <= data.countPage; number++) {
      setItems([...items,
        <Pagination.Item key={number} active={number === active} onClick={()=>goToPage(number)}>
          {number}
        </Pagination.Item>]
      );
    }
  }, []) */

  const handleDelete = (id: number) => {
    Swal.fire({
      title: "Êtes-vous sures ?",
      text: "Le profile de ce client sera supprimé definitivement",
      confirmButtonText: "Oui supprimer!",
      cancelButtonText: `Retour`,
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: "#fff",
    }).then((result) => {
      if (result.isConfirmed) {
        return mutate({ id: id }, {
          onSuccess: async (data) => {
          },
          onError: (error) => {
            Swal.fire({
              icon: 'error',
              text: 'Erreur lors de la suppression du client',
            });
          },
        })
      }
    });
  };

  const goToNextPage = () => {
    if (activePage < data.countPage) {
      setActivePage(activePage + 1);
    } else {
      setActivePage(activePage)
    }
    nextPage();
  }

  const goToPrevPage = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1)
    } else {
      setActivePage(activePage)
    }
    prevPage()
  }

  const goToPageOne = (items: number) => {
    setActivePage(items)
    goToPage(items)
  }
  return (
    <>
      <div className="nk-block">
        <div className="card card-stretch">
          <div className="card-inner d-flex flex-column align-items-end">
            <table
              className="datatable-init nk-tb-list nk-tb-ulist"
              data-auto-responsive="false"
            >
              <thead>
                <tr className="nk-tb-item nk-tb-head">
                  <th className="nk-tb-col text-center">#</th>
                  <th className="nk-tb-col">
                    <span className="sub-text">Nom et prénom</span>
                  </th>
                  <th className="nk-tb-col tb-col-md">
                    <span className="sub-text">Email</span>
                  </th>
                  <th className="nk-tb-col tb-col-md text-center">
                    <span
                      className="sub-text"
                      data-toggle="tooltip"
                      title="Active Projects"
                    >
                      Tâches en cours
                    </span>
                  </th>
                  <th className="nk-tb-col tb-col-md">
                    <span className="sub-text">Créé le</span>
                  </th>
                  <th className="nk-tb-col tb-col-md">
                    <span className="sub-text">Statut</span>
                  </th>
                  <th className="nk-tb-col nk-tb-col-tools text-right"></th>
                </tr>
              </thead>
              {data?.list.map((item, key) => (
                <tbody key={key}>
                  <tr className="nk-tb-item">
                    <td className="nk-tb-col text-center">{key + 1}</td>
                    <td className="nk-tb-col">
                      <div className="user-card">
                        <div className="user-avatar bg-dim-primary d-none d-sm-flex">
                          <span>te</span>
                        </div>
                        <div className="user-info">
                          <span className="tb-lead">
                            {item.nomPrenom}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className="nk-tb-col tb-col-md">
                      <span>{item.email}</span>
                    </td>
                    <td className="nk-tb-col tb-col-md text-center">
                      <span>0/0</span>
                    </td>
                    <td className="nk-tb-col tb-col-lg">
                      <span>{/*moment(item.createdAt).format()*/}</span>
                    </td>
                    <td className="nk-tb-col tb-col-md">
                      <span className="badge badge-sm badge-dot has-bg badge-success d-none d-mb-inline-flex">
                        {item.status}
                      </span>
                    </td>
                    <td className="nk-tb-col nk-tb-col-tools">
                      <ul className="nk-tb-actions gx-1">
                        <li>
                          <Dropdown>
                            <Dropdown.Toggle
                              as="a"
                              className="btn btn-icon btn-trigger"
                            >
                              <em className="icon ni ni-more-h"></em>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu-right">
                              <ul className="link-list-opt no-bdr">
                                <li>
                                  <Link to={`/clients/details/${item.idClient}`}>
                                    <em className="icon ni ni-eye"></em>
                                    <span>Voir les détails</span>
                                  </Link>
                                </li>
                                <li>
                                  <a
                                    onClick={() =>
                                      setShowModal({
                                        ...showModal,
                                        showUpdate: true,
                                      })
                                    }
                                    className="fetch-display-click cursor"
                                  >
                                    <em className="icon ni ni-pen"></em>
                                    <span>Modifier les détails</span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="fetch-display-click cursor"
                                    onClick={() =>
                                      setShowModal({
                                        ...showModal,
                                        showCreateQuote: true,
                                      })
                                    }
                                  >
                                    <em className="icon ni ni-cards"></em>
                                    <span>Créer un devis</span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="fetch-display-click cursor"
                                    onClick={() =>
                                      setShowModal({
                                        ...showModal,
                                        showCreateInvoice: true,
                                      })
                                    }
                                  >
                                    <em className="icon ni ni-cc"></em>
                                    <span>Créer une facture</span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="send-to-server-click cursor"
                                    onClick={() => handleDelete(Number(item.idClient))}
                                  >
                                    <em className="icon ni ni-trash"></em>
                                    <span>Supprimer le client</span>
                                  </a>
                                </li>
                              </ul>
                            </Dropdown.Menu>
                          </Dropdown>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
            <Pagination className="nk-block-end">
              <Pagination.First onClick={() => goToPageOne(1)} />
              <Pagination.Prev onClick={goToPrevPage} />
              {
                Array.from(
                  { length: data.countPage }, (v, k) => k + 1
                ).map(items => <Pagination.Item key={items} active={items === activePage} onClick={() => goToPageOne(items)}>{items}</Pagination.Item>)
              }
              <Pagination.Next onClick={goToNextPage} />
              <Pagination.Last onClick={() => goToPageOne(data.countPage)} />
            </Pagination>
          </div>
        </div>
        {/* .card */}
      </div>
      <UpdateClient
        show={showModal.showUpdate}
        onHide={() => setShowModal({ ...showModal, showUpdate: false })}
      />
      <SendSMS
        show={showModal.showSendSMS}
        onHide={() => setShowModal({ ...showModal, showSendSMS: false })}
      />
      <CreateJobCard
        show={showModal.showCreateJobCard}
        onHide={() => setShowModal({ ...showModal, showCreateJobCard: false })}
      />
      <CreateQuote
        show={showModal.showCreateQuote}
        onHide={() => setShowModal({ ...showModal, showCreateQuote: false })}
      />
      <CreateInvoices
        show={showModal.showCreateInvoice}
        onHide={() => setShowModal({ ...showModal, showCreateInvoice: false })}
      />
    </>
  );
}
