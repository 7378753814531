import React from "react";
import { Modal } from "react-bootstrap";
import { tacheEntityType } from "../../types";
interface ViewTaskProps {
  show: boolean;
  onHide: () => void;
  data: tacheEntityType
}

export function ViewTask({ show, onHide, data }: ViewTaskProps) {
  console.log(data.idTache, 'ito hijerevana anle id tache raha miova');
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Détails de la tâche</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Titre de la tâche: {data.titreTache}</p>
        <div className="row gy-4">
          <div className="col-sm-12">
            <h5>Client: {data?.projets?.vehicules.clients.nomPrenom}</h5>
            {data.status === 'Complété' && <span className="badge badge-sm badge-dot has-bg badge-success d-mb-inline-flex">
              Complété
            </span>}

            {data.status === 'Annulé' && <span className="badge badge-sm badge-dot has-bg badge-secondary d-mb-inline-flex">
              Annulé
            </span>}
            {data.status === 'En cours' && <span className="badge badge-sm badge-dot has-bg badge-warning d-mb-inline-flex">
              En cours
            </span>}
          </div>
          <div className="col-sm-12">
            <div>
              <table>
                <tbody>
                  <tr>
                    <td className="text-muted w-50">Côut</td>
                    <td className="fw-bold">{data.coutTache}</td>
                  </tr>
                  <tr>
                    <td className="text-muted w-50">Date d'échéance</td>
                    <td className="fw-bold">{data?.dateEcheance} • {data.heureEcheance}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-sm-12">
            <span className="text-muted">Description de la tâche</span>
            <div>{data.description}</div>
          </div>
          {/* <div className="col-sm-12">
            <span className="text-muted">Required Parts</span>
            <div>
              <ol>
                <li className="text-muted text-xs">
                  1.) Engine Oi
                </li>
                <li className="text-muted text-xs">
                  2.) Autre
                </li>
              </ol>
            </div>
          </div> */}
        </div>
      </Modal.Body>
      <Modal.Footer className=" bg-light">
        <button
          className="btn btn-white btn-dim btn-outline-light"
          type="button"
          onClick={onHide}
        >
          <em className="icon ni ni-cross-circle"></em>
          <span>Fermer</span>
        </button>
      </Modal.Footer>
    </Modal>
  );
}
