import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

export default function ConfirmAction({isOpen, onConfirm, onCancel, confirmMessage}) {
  

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      open={isOpen}
      onClose={onCancel}
    >
      <DialogTitle>Demande de confirmation</DialogTitle>
      <DialogContent dividers>
        <span>{confirmMessage}</span>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onCancel}>
          Annuler
        </Button>
        <Button onClick={onConfirm}>Confirmer</Button>
      </DialogActions>
    </Dialog>
  );
}