
import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import Swal from "sweetalert2";
import { Pagination } from "react-bootstrap";
import { taskItemHooks } from "../../../network/task-item";
import { ViewTaskItem } from "../../../Modal/ViewTaskItem";
import { UpdateTaskItem } from "../../../Modal/UpdateTaskItem";
import { taskEntityPaginationType } from "../../../types";

type Props = {
  data: taskEntityPaginationType
  nextPage: Function
  prevPage: Function
  goToPage: Function
}


export default function TableauAllTaskItem({ data, nextPage, prevPage, goToPage }: Props) {
  interface TaskItem {
    idTask: number,
    taskTitle: string,
    description: string,
    ordreTask: number
  }
  const [showView, setShowView] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [taskItem, setTaskItem] = useState<TaskItem>({
    idTask: 0,
    taskTitle: '',
    description: '',
    ordreTask: 1
  });
  const [activePage, setActivePage] = useState<number>(1);
  const { mutate } = taskItemHooks.useDeleteTaskItem();
  const [taskId, setTaskId] = useState<number>(0);
  const allTaskItems = taskItemHooks.useGetAllTaskItems();

  const handleDelete = (id: number | undefined) => {
    if (id){
      Swal.fire({
        title: "Are you sure?",
        text: "This task will be deleted permanently.",
        confirmButtonText: "Oui, supprimer",
        cancelButtonText: `Retour`,
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        cancelButtonColor: "#fff",
      }).then((result) => {
        if (result.isConfirmed) {
          return mutate({ id: id }, {
            onSuccess: async (data) => {
            },
            onError: (error) => {
              Swal.fire({
                icon: 'error',
                text: 'Erreur lors de la suppression de la tâche',
              });
            },
          })
        }
      });
    }
  };

  const goToNextPage = () => {
    if (activePage < data.countPage) {
      setActivePage(activePage + 1);
    } else {
      setActivePage(activePage)
    }
    nextPage();
  }

  const goToPrevPage = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1)
    } else {
      setActivePage(activePage)
    }
    prevPage()
  }

  const goToPageOne = (items: number) => {
    setActivePage(items)
    goToPage(items)
  }

  const displayModal = (taskId: number | undefined) => {
    if (taskId){
      setShowUpdate(true);
      setTaskId(taskId);
    }
  }

  const sendData = (item: TaskItem) => {
    setShowView(true)
    setTaskItem(item)
  }
  return (
    <>
      <div className="nk-block">
        <div className="card card-stretch">
          <div className="card-inner d-flex flex-column align-items-end">
            <table className="datatable-init nk-tb-list nk-tb-ulist mt">
              <thead>
                <tr className="nk-tb-item nk-tb-head">
                  <th className="nk-tb-col text-center">#</th>
                  <th className="nk-tb-col">
                    <span className="sub-text">Titre de la tâche</span>
                  </th>
                  <th className="nk-tb-col nk-tb-col-tools text-right"></th>
                </tr>
              </thead>
              <tbody>
              {data.list.map((item, index) => (
                  <tr className="nk-tb-item">
                    <td className="nk-tb-col text-center">{index + 1}</td>
                    <td className="nk-tb-col tb-col-md">
                      <span>{item.taskTitle}</span>
                    </td>
                    <td className="nk-tb-col nk-tb-col-tools">
                      <ul className="nk-tb-actions gx-1">
                        <li>
                          <Dropdown>
                            <Dropdown.Toggle
                              as="a"
                              className="btn btn-icon btn-trigger"
                            >
                              <em className="icon ni ni-more-h"></em>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu-right">
                              <ul className="link-list-opt no-bdr">
                                <li>
                                  <a
                                    className="fetch-display-click cursor"
                                    onClick={() => displayModal(item.idTask)}
                                  >
                                    <em className="icon ni ni-pen"></em>
                                    <span>Modifier les détails</span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="send-to-server-click cursor"
                                    onClick={() => handleDelete(item.idTask)}
                                  >
                                    <em className="icon ni ni-trash"></em>
                                    <span>Supprimer la tache</span>
                                  </a>
                                </li>
                              </ul>
                            </Dropdown.Menu>
                          </Dropdown>
                        </li>
                      </ul>
                    </td>
                  </tr>
              ))}
              </tbody>
            </table>
            <Pagination className="nk-block-end">
              <Pagination.First onClick={() => goToPageOne(1)} />
              <Pagination.Prev onClick={goToPrevPage} />
              {
                Array.from(
                  { length: data.countPage }, (v, k) => k + 1
                ).map(items => <Pagination.Item key={items} active={items === activePage} onClick={() => goToPageOne(items)}>{items}</Pagination.Item>)
              }
              <Pagination.Next onClick={goToNextPage} />
              <Pagination.Last onClick={() => goToPageOne(data.countPage)} />
            </Pagination>
          </div>
        </div>
      </div>
      <ViewTaskItem show={showView} onHide={() => setShowView(false)} data={taskItem} />
      <UpdateTaskItem show={showUpdate} onHide={() => setShowUpdate(false)} taskId={taskId} maxOrder={allTaskItems.data?.data.length ?? 0} />
    </>
  );
}
