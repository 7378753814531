import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { network } from "./network";

export const hooks = {
  useGetFournisseurs () {
    const reponse = useQuery(['fournisseurs'], network.getFournisseur);

    return reponse;
  },
  useAddFournisseur () {
    const queryClient = useQueryClient();
    const reponse = useMutation(network.addFournisseur, {
      onSuccess: () => {
        queryClient.invalidateQueries(['fournisseurs'])
      }
    });

    return reponse;
  },
  useUpdateFournisseur () {
    const queryClient = useQueryClient();
    const reponse = useMutation(network.updateFournisseur, {
      onSuccess: (data, variables) => {
        queryClient.setQueryData(['fournisseurs', { idFournisseur: variables.id}], data)
      },
    });

    return reponse;
  },
  useGetFournisseurPaginate (page: number) {
    const reponse = useQuery(['fournisseurPaginate', page], () => network.getFournisseurPaginate(page), {keepPreviousData: true});

    return reponse;
  },
  usePrefetchFournisseur (page: number) {
    const queryClient = useQueryClient();

    return queryClient.prefetchQuery(['fournisseurPaginate', page], () => network.getFournisseurPaginate(page));
  }
}