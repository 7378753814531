import api from "../../axios";
import type { workEntityPaginationType, workEntityType } from "../../types";

export const network = {
  async getAllWorks(): Promise<{data: workEntityType []}> {
    const { data } = await api.withToken.get('/work');

    return data;
  },
  async getWorkByStatus(statut: { statut: string }): Promise<{data: workEntityType[]}> {
    const { data } = await api.withToken.get('/work/by-status/' + statut.statut);

    return data;
  },
  // async getWorkByVehicule(donne: { page: number, idVehicule: number }) {
  //   const { data } = await api.withToken.get('/work-vehicule/page/5/' + donne.page + '/' + donne.idVehicule);

  //   return data;
  // },
  async createWork(Work: Object): Promise<{data: workEntityType}> {
    const { data } = await api.withToken.post('/work', Work);

    return data;
  },
  async getWorkPaginated(page: number): Promise<{data: workEntityPaginationType
    hasMore?: boolean
  }> {
    const { data } = await api.withToken.get('/work/page/5/' + page);

    return data;
  },
  // async getWorkStatusPaginated(donne: { page: number, status: string }) {
  //   const { data } = await api.withToken.get('/work/by-status/page/5/' + donne.page + '/' + donne.status);

  //   return data
  // },
  async updateWork(dataWork: { idWork: number, work: Object }): Promise<{data: workEntityType}> {
    const { data } = await api.withToken.put(`/work/${dataWork.idWork}`, dataWork.work)

    return data;
  },
  async getWorkByProject(idProjet: number): Promise<{data: workEntityType}> {
    const { data } = await api.withToken.get(`/work/by-project/${idProjet}`)
    return data;
  },
  async deleteWork(donne: { id: number }): Promise<{data: workEntityType}> {
    const { data } = await api.withToken.delete('/work/' + donne.id);

    return data;
  }
}