import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { inventaireHooks } from "../../network/inventaire";
import Loading from '../../components/Loading';
import { fournisseurHooks } from "../../network/fournisseur";
import { pieceHooks } from "../../network/piece";
import { useParams } from 'react-router-dom';
import Swal from "sweetalert2";


interface AddExpensesProps {
  show: boolean;
  onHide: () => void;
}

export function AddExpenses({ show, onHide }: AddExpensesProps) {
  const [showInventory, setShowInventory] = useState(false);
  const [showCreateSupplier, setShowCreateSupplier] = useState(false);
  const [showExpectedDelivery, setShowExpectedDelivery] = useState(false);
  const [showPaymentDueOn, setShowPaymentDueOn] = useState(false);
  const [piece, setPiece] = useState<string>('');
  const [unite, setUnite] = useState<number>(0);
  const [uniteQte, setUniteQte] = useState<string>('Pièces');
  const [depenseNomArticle, setDepenseNomArticle] = useState<string>('');
  const [fournisseur, setFournisseur] = useState<string>('');
  const [nomFournisseur, setNomFournisseur] = useState<string>('');
  const [telFournisseur, setTelFournisseur] = useState<string>('');
  const [quantite, setQuantite] = useState<number>(0);
  const [montantTotal, setMontantTotal] = useState<string>('');
  const [datePayement, setDatePayement] = useState<string>('');
  const [taper, setTaper] = useState<string>('Part');
  const [dateLivraison, setDateLivraison] = useState<string>('');
  const [delaisLivraison, setDelaisLivraison] = useState<string>('');
  const [source, setSource] = useState<string>('fournisseur');
  const [statut, setStatut] = useState<string>('Livré');
  const [coutPartielle, setCoutPartielle] = useState<number>(0);
  const params = useParams()
  let id = params.id || ""
  const [classUnite, setClassUnite] = useState<string>('form-control form-control-lg expense-consumed')
  const [errorUnite, setErrorUnite] = useState<boolean>(false);
  const [classDatePayInv, setClasDatePayInv] = useState<string>('form-control form-control-lg');
  const [errorDatePayInv, setErrorDatePayInv] = useState<boolean>(false);
  const [classDepense, setClassDepense] = useState<string>('form-control form-control-lg');
  const [errorDepense, setErrorDepense] = useState<boolean>(false);
  const [classQuantite, setClassQuantite] = useState<string>('form-control form-control-lg');
  const [errorQuantite, setErrorQuantite] = useState<boolean>(false);
  const [classMontant, setClassMontant] = useState<string>('form-control form-control-lg');
  const [errorMontant, setErrorMontant] = useState<boolean>(false);
  const [classDatePayFour, setClassDatePayFour] = useState<string>('form-control form-control-lg');
  const [errorDatePayFour, setErrorDatePayFour] = useState<boolean>(false);


  const { data, isLoading } = inventaireHooks.useGetAllInventaire();
  const { data: dataFournisseur, isLoading: loadingFournisseur } = fournisseurHooks.useGetFournisseurs();
  const { mutate } = pieceHooks.useAddPieceDepense();

  const handleChangePiece = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    setPiece(data.data[Number(e.target.value)].idInventaire);
    setCoutPartielle(data.data[Number(e.target.value)].coutUnitaire);
    setFournisseur(data.data[Number(e.target.value)].fournisseurs.idFournisseur);
  }

  const handleChangeUnite = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    setUniteQte(e.target.value);
  }

  const handleChangeFournisseur = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    if (e.target.value === "create") {
      setShowCreateSupplier(true)
    } else {
      setShowCreateSupplier(false)
      setFournisseur(e.target.value)
    }
  }

  const handleChangeTaper = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    setTaper(e.target.value);
  }

  const handleChangeStatut = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    e.target.value === "Commandé" || e.target.value === "En attente de livraison" ? setShowExpectedDelivery(true)
      : setShowExpectedDelivery(false);
    setStatut(e.target.value);
  }

  const handleChangeSource = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    e.target.value === "inventaire" ? setShowInventory(true) : setShowInventory(false)
    setSource(e.target.value)
  }

  const checkUnite = () => {
    if (unite === 0) {
      setClassUnite('form-control form-control-lg expense-consumed is-invalid')
      setErrorUnite(true)
      return true
    } else {
      setClassUnite('form-control form-control-lg expense-consumed')
      setErrorUnite(false)
      return false
    }
  }

  const checkDatePayInv = () => {
    if (datePayement.trim().length === 0) {
      setClasDatePayInv('form-control form-control-lg is-invalid');
      setErrorDatePayInv(true)
      return true
    } else {
      setClasDatePayInv('form-control form-control-lg');
      setErrorDatePayInv(false)
      return false
    }
  }

  const checkDepense = () => {
    if (depenseNomArticle.trim().length === 0) {
      setClassDepense('form-control form-control-lg is-invalid')
      setErrorDepense(true)
      return true
    } else {
      setClassDepense('form-control form-control-lg')
      setErrorDepense(false)
      return false
    }
  }

  const checkQuantite = () => {
    if (quantite === 0) {
      setClassQuantite('form-control form-control-lg is-invalid')
      setErrorQuantite(true)
      return true
    } else {
      setClassQuantite('form-control form-control-lg');
      setErrorQuantite(false)
      return false
    }
  }

  const checkMontant = () => {
    if (montantTotal.trim().length === 0) {
      setClassMontant('form-control form-control-lg is-invalid');
      setErrorMontant(true)
      return true
    } else {
      setClassMontant('form-control form-control-lg');
      setErrorMontant(false)
      return false
    }
  }

  const checkDatePayFour = () => {
    if (datePayement.trim().length === 0) {
      setClassDatePayFour('form-control form-control-lg is-invalid')
      setErrorDatePayFour(true)
      return true
    } else {
      setClassDatePayFour('form-control form-control-lg');
      setErrorDatePayFour(false)
      return false
    }
  }

  const addPiece = () => {
    console.log('tafiditra ato ooo')
    if (source === 'fournisseur') {
      const pieceDepense = {
        depenceNomArticle: depenseNomArticle,
        quantite: quantite,
        uniteQt: uniteQte,
        montantTotal: montantTotal,
        dateDepence: datePayement,
        taper: taper,
        statut: statut,
        projets: id,
        fournisseurs: fournisseur
      }

      if (checkDatePayFour() || checkDepense() || checkMontant() || checkQuantite()) {
        checkDatePayFour()
        checkDepense()
        checkMontant()
        checkQuantite()
        Swal.fire({
          icon: 'error',
          text: 'Veuillez remplir tous les champs',
        });
        return;
      }

      return mutate(pieceDepense, {
        onSuccess: async (data) => {
          onHide()
        },
        onError: (error) => {
          console.log(error, 'ato ilay erreur ato')
          onHide()
          Swal.fire({
            icon: 'error',
            text: 'Erreur lors de l\'ajout pièce dépense',
          });
        },
      })
    } else {
      const montant = unite * coutPartielle
      const pieceFromInventaire = {
        source: source,
        inventaire: piece,
        uniteConsommme: unite,
        montantTotal: montant,
        projets: id,
        dateDepence: datePayement,
        fournisseurs: fournisseur
      }
      if (checkDatePayInv() || checkUnite()) {
        checkDatePayInv()
        checkUnite()
        Swal.fire({
          icon: 'error',
          text: 'Veuillez remplir tous les champs',
        });
        return;
      }

      return mutate(pieceFromInventaire, {
        onSuccess: async (data) => {
          onHide()
        },
        onError: (error) => {
          console.log(error, 'ato ilay erreur ato')
          onHide()
          Swal.fire({
            icon: 'error',
            text: 'Erreur lors de l\'ajout pièce dépense',
          });
        },
      })
    }
  }
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Ajouter une dépense</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Ajouter une dépense de projet</p>
        <div className="row gy-4">
          <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">La source</label>
              <div className="form-control-wrap ">
                <div className="form-control-select">
                  <select
                    className="form-control form-control-lg source-select"
                    name="source"
                    onChange={handleChangeSource}
                  >
                    <option value="fournisseur">Fournisseurs externes</option>
                    <option value="inventaire">De l'inventaire</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showInventory ? (
          <div className="row inventory-source gy-4">
            <div className="col-sm-12">
              <div className="form-group">
                <label className="form-label">Article / Pièce</label>
                {isLoading ? <Loading /> :
                  <div className="form-control-wrap">
                    <div className="form-control-select">
                      <select
                        className="form-control select2 inventory-select"
                        name="inventory"
                        onChange={handleChangePiece}
                      >
                        <option value=""></option>
                        {data.data.map((item: { idInventaire: number, nomArticle: string, coutUnitaire: number }, index) =>
                          (<option value={index}>{item.nomArticle}</option>))}
                      </select>
                    </div>
                  </div>}
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group">
                <label className="form-label">Unités consommées</label>
                <div className="form-control-wrap">
                  <div className="form-text-hint">
                    <span className="overline-title units">Unités</span>
                  </div>
                  <input
                    type="number"
                    className={classUnite}
                    placeholder="Unités consommées"
                    name="consumed"
                    defaultValue={0.0}
                    step="0.01"
                    onChange={e => setUnite(Number(e.target.value))}
                    onBlur={checkUnite}
                  />
                </div>
                {errorUnite && <div className="text-danger">Valeur obligatoire</div>}
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label">Montant</label>
                <div className="form-control-wrap">
                  <div className="form-text-hint">
                    <span className="overline-title">€</span>
                  </div>
                  <input
                    type="number"
                    className="form-control form-control-lg expense-total-amount"
                    placeholder="Montante"
                    name="totalamount"
                    value={unite * coutPartielle}
                    step="0.01"
                    required
                    readOnly
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label">Date de dépense</label>
                <div className="form-control-wrap">
                  <input
                    type="date"
                    className={classDatePayInv}
                    placeholder="Date de dépense"
                    name="expensedate"
                    required
                    onChange={e => setDatePayement(e.target.value)}
                    onBlur={checkDatePayInv}
                  />
                </div>
                {errorDatePayInv && <div className="text-danger">Valeur obligatoire</div>}
              </div>
            </div>
          </div>
        ) : (
          <div className="row external-source gy-4">
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label">Dépense / Nom de l'article</label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    className={classDepense}
                    placeholder="Dépense / Nom de l'article"
                    name="expense"
                    required
                    onChange={e => setDepenseNomArticle(e.target.value)}
                    onBlur={checkDepense}
                  />
                </div>
                {errorDepense && <div className="text-danger">Valeur obligatoire</div>}
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label">Le fournisseur</label>
                <div className="form-control-wrap">
                  {loadingFournisseur ? <Loading /> :
                    <div className="form-control-select">
                      <select
                        className="form-control select2 supplier-select"
                        name="supplier"
                        onChange={handleChangeFournisseur}
                      >
                        <option value="">Sélectionnez le fournisseur</option>
                        <optgroup label="New Supplier">
                          <option value="create" className="fw-bold">
                            Créer un nouveau fournisseur
                          </option>
                        </optgroup>
                        <optgroup label="Select from suppliers">
                          {dataFournisseur.data.map((item: { idFournisseur: number, nom: string }) => (
                            <option value={item.idFournisseur}>{item.nom}</option>))}
                        </optgroup>
                      </select>
                    </div>}
                </div>
              </div>
            </div>
            {showCreateSupplier && (
              <>
                <div className="col-sm-6 new-supplier-input">
                  <div className="form-group">
                    <label className="form-label">Nom du fournisseur</label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="Nom du fournisseur"
                        name="suppliername"
                        onChange={e => setNomFournisseur(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 new-supplier-input">
                  <div className="form-group">
                    <label className="form-label">Numéro de téléphone</label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control form-control-lg phone-input"
                        placeholder="Numéro de téléphone"
                        onChange={e => setTelFournisseur(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label">Quantité</label>
                <div className="form-control-wrap">
                  <input
                    type="number"
                    className={classQuantite}
                    placeholder="Quantité"
                    name="quantity"
                    defaultValue={1}
                    step="0.01"
                    min="0.01"
                    required
                    onChange={e => setQuantite(Number(e.target.value))}
                    onBlur={checkQuantite}
                  />
                </div>
                {errorQuantite && <div className="text-danger">Valeur obligatoire</div>}
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label">Unité de quantité</label>
                <div className="form-control-wrap">
                  <select
                    className="form-control form-control-lg"
                    name="quantity_unit"
                    onChange={handleChangeUnite}
                  >
                    <option value="Pieces">Pièces</option>
                    <option value="Units">Unités</option>
                    <option value="Litres">Litres</option>
                    <option value="Kilograms">Kilogrammes</option>
                    <option value="Pounds">Livres sterling</option>
                    <option value="Gallons">Gallons</option>
                    <option value="Meters">Mètres</option>
                    <option value="Set">Set</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label">Montant total</label>
                <div className="form-control-wrap">
                  <div className="form-text-hint">
                    <span className="overline-title">€</span>
                  </div>
                  <input
                    type="number"
                    className={classMontant}
                    placeholder="Montant total"
                    name="amount"
                    defaultValue={0.0}
                    step="0.01"
                    min={0}
                    required
                    onChange={e => setMontantTotal(e.target.value)}
                    onBlur={checkMontant}
                  />
                </div>
                {errorMontant && <div className="text-danger">Valeur obligatoire</div>}
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label">Date de dépense</label>
                <div className="form-control-wrap">
                  <input
                    type="date"
                    className={classDatePayFour}
                    placeholder="Date de dépense"
                    name="expense_date"
                    required
                    onChange={e => setDatePayement(e.target.value)}
                    onBlur={checkDatePayFour}
                  />
                </div>
                {errorDatePayFour && <div className="text-danger">Valeur obligatoire</div>}
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label">Taper</label>
                <div className="form-control-wrap ">
                  <div className="form-control-select">
                    <select
                      className="form-control form-control-lg"
                      name="type"
                      onChange={handleChangeTaper}
                    >
                      <option value="Part">Partie</option>
                      <option value="Service">Service</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label">Statut</label>
                <div className="form-control-wrap ">
                  <div className="form-control-select">
                    <select
                      className="form-control form-control-lg expense-status"
                      name="status"
                      onChange={handleChangeStatut}
                    >
                      <option value="Livré">Livré</option>
                      <option value="Commandé">Commandé</option>
                      <option value="En attente de livraison">
                        En attente de livraison
                      </option>
                      <option value="Commander">Commander</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            {showExpectedDelivery && (
              <>
                <div className="col-sm-6 expense-delivery">
                  <div className="form-group">
                    <label className="form-label">
                      Date de livraison prévue
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="date"
                        className="form-control form-control-lg"
                        placeholder="Date de livraison prévue"
                        name="expected_delivery_date"
                        onChange={e => setDateLivraison(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 expense-delivery">
                  <div className="form-group">
                    <label className="form-label">
                      Délai de livraison prévu
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="time"
                        className="form-control form-control-lg"
                        placeholder="Délai de livraison prévu"
                        name="expected_delivery_time"
                        onChange={e => setDelaisLivraison(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="col-sm-12">
              <div className="form-group">
                <div className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    name="paid"
                    id="expense-paid"
                    className="custom-control-input expense-paid"
                    defaultValue="Yes"
                    defaultChecked
                    onChange={(e) => setShowPaymentDueOn(!e.target.checked)}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="expense-paid"
                  >
                    Dépense / Article fournisseur payé
                  </label>
                </div>
              </div>
            </div>
            {showPaymentDueOn && (
              <div className="col-sm-12 expense-payment">
                <div className="form-group">
                  <label className="form-label">Paiement dû le</label>
                  <div className="form-control-wrap">
                    <input
                      type="date"
                      className="form-control form-control-lg"
                      placeholder="Paiement dû le"
                      name="payment_due"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className=" bg-light">
        <button
          className="btn btn-white btn-dim btn-outline-light"
          type="button"
          onClick={onHide}
        >
          <em className="icon ni ni-cross-circle"></em>
          <span>Annuler</span>
        </button>
        <button className="btn btn-primary" onClick={addPiece}>
          <em className="icon ni ni-check-circle-cut"></em>
          <span>Économisez des dépenses</span>
        </button>
      </Modal.Footer>
    </Modal>
  );
}
