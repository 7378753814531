import React, { useState } from "react";
import Swal from "sweetalert2";
import { CreateNote } from "../../Modal";
import { clientHooks } from "../../network";
import { useParams } from "react-router-dom";
import Loading from "../../components/Loading";

export default function Details() {
  const [showCreateNote, setShowCreateNote] = useState(false);
  const param = useParams();
  let id = param.id || '';
  const { data, isLoading } = clientHooks.useGetClientById(id);
  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: `This note will be deleted permanently.`,
      confirmButtonText: "Yes, delete!",
      cancelButtonText: `Cancel`,
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: "#fff",
    }).then((result) => {
      if (result.isConfirmed) {
        ///When click Yes
      }
    });
  };

  if (isLoading ){
    return (<div className="loading__wrapper">
    <Loading />
  </div>)
  }
  return (
    <>
      <div className="card-inner">
        <div className="nk-block">
          <div className="nk-block-head">
            <h5 className="title overline-title text-base">Informations du client</h5>
            <p>Résumé et informations basiques</p>
          </div>
          {/* .nk-block-head */}
          {isLoading ? <Loading /> : <div className="profile-ud-list">
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Nom et prénom(s)</span>
                <span className="profile-ud-value">{data?.data.nomPrenom}</span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Téléphone</span>
                <span className="profile-ud-value">{data?.data.telephone}</span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Email</span>
                <span className="profile-ud-value">{data?.data.email}</span>
              </div>
            </div>
            {/* <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Projets actifs</span>
                <span className="profile-ud-value">1 / 1</span>
              </div>
            </div> */}
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Addresse</span>
                <span className="profile-ud-value">{data?.data.adresse}</span>
              </div>
            </div>
          </div>}
          {/* .profile-ud-list */}
        </div>
        {/* .nk-block */}
        {isLoading ? <Loading /> : 
        <div className="nk-block">
          <div className="nk-block-head nk-block-head-line">
            <h6 className="title overline-title text-base">
              Informations additionnelles
            </h6>
          </div>
          <div className="profile-ud-list">
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Nombre de facture</span>
                <span className="profile-ud-value">2</span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Paiement</span>
                <span className="profile-ud-value">102.00 €</span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Statut</span>
                <span className="profile-ud-value">
                  <span className="badge badge-sm badge-dot has-bg badge-success d-none d-mb-inline-flex">
                    {data?.data.status}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>}
        {/* .nk-block */}
        <div className="nk-divider divider md" />
        <div className="nk-block">
          <div className="nk-block-head nk-block-head-sm nk-block-between">
            <h5 className="title overline-title text-base">Notes et commentaires</h5>
            <a
              className="link link-sm cursor"
              onClick={() => setShowCreateNote(true)}
            >
              + Add Note
            </a>
          </div>
          {/* .nk-block-head */}
          {isLoading ? <Loading /> : 
          data?.data.remarques?.map((note) => (<div className="bq-note">
            <div className="bq-note-item">
              <div className="bq-note-text">
                <p>{note.text}</p>
              </div>
              <div className="bq-note-meta">
                {/*<span className="bq-note-added">
                  Ajouter le <span className="date">{note.createdAt}</span>
                </span> */}

                <span className="bq-note-sep sep">•</span>
                <a
                  onClick={handleDelete}
                  className="link link-sm link-danger send-to-server-click cursor"
                >
                  Delete Note
                </a>
              </div>
            </div>
          </div>))}
        </div>
      </div>
      <CreateNote
        show={showCreateNote}
        onHide={() => setShowCreateNote(false)}
      />
    </>
  );
}
