import { ReactNode } from "react";
import "./GlobalStyles.scss";
import "../../assets/css/app.css";
import "../../assets/css/theme.css";
import "../../assets/css/intlTelInput.css";
import "../../assets/css/simcify.min.css";
import "../../assets/css/asilify.css";

function GlobalStyles({ children }: { children: JSX.Element }) {
  return children;
}

export default GlobalStyles;
