import React, { useEffect, memo, useState } from 'react';
import { Avatar, Divider, List, Table, Tag, Tooltip, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { workEntityType, workStepEntityType } from '../../../types';

const statusToBadge = {
  'A faire': <span className="badge badge-sm badge-dot has-bg badge-info d-mb-inline-flex">A faire</span>,
  'Planifié': <span className="badge badge-sm badge-dot has-bg badge-info d-mb-inline-flex">Planifié</span>,
  'En cours': <span className="badge badge-sm badge-dot has-bg badge-warning d-mb-inline-flex">En cours</span>,
  'Suspendu': <span className="badge badge-sm badge-dot has-bg badge-warning d-mb-inline-flex">Suspendu</span>,
  'En pause': <span className="badge badge-sm badge-dot has-bg badge-warning d-mb-inline-flex">En pause</span>,
  'Complété': <span className="badge badge-sm badge-dot has-bg badge-success d-mb-inline-flex">Complété</span>,
  'Annulé': <span className="badge badge-sm badge-dot has-bg badge-danger d-mb-inline-flex">Annulé</span>,
};

export function formatTime(seconds: number | undefined) {
  if (!seconds || seconds <= 0) {
    return "0h 0min";
  }

  // Crée un objet moment à partir du nombre de secondes
  const duration = moment.duration(seconds, 'seconds');

  const days = Math.floor(duration.asDays());
  const hours = Math.floor(duration.hours());
  const mins = Math.floor(duration.minutes());

  let timeString = '';
  if (days > 0) {
    timeString += `${days}j `;
  }
  timeString += `${hours}h ${mins}min`;

  return timeString.trim();
 }
 

const columns: ColumnsType<workStepEntityType> = [
  {
    title: 'Vehicules',
    dataIndex: 'id',
    render: (text: string, row) => (
      <>
        <Typography.Title level={5}><Link to={`/vehicule/details/${row?.works?.projets.vehicules.idVehicule}`}>{row?.works?.projets.vehicules.detailVehicules.marque} {row?.works?.projets.vehicules.detailVehicules.model}</Link></Typography.Title>
        <Typography.Text>{row?.works?.projets.vehicules.detailVehicules.numeroImmatriculation}</Typography.Text>
      </>
    ),
  },
  {
    title: 'Travaux',
    dataIndex: 'taskGroup',
    render: (text: string, row) => (
      <>
        <Typography.Title level={5}>{row?.taskGroup?.taskGroupLabel}</Typography.Title>
      </>
    ),
  },
  {
    title: 'Date de début',
    dataIndex: 'startAt',
    render: (text: string, row) => (moment(row?.startAt).format('DD/MM/YYYY \n HH:mm'))
  },
  {
    title: 'Date de fin',
    dataIndex: 'endAt',
    render: (text: string, row) => (moment(row?.endAt).format('DD/MM/YYYY \n HH:mm'))
  },
  {
    title: 'Durée éstimé',
    dataIndex: 'estimate',
    render: (text: string, row) => {
      return (
          <>
            <Tag color="blue">
              {formatTime(row.estimate ?? 0)}
            </Tag>
          </>
      )
    }
  },
  {
    title: 'Durée effective',
    dataIndex: 'endAt',
    render: (text: string, row) => {
      return (
          <>
            <Tag color="blue">
            {formatTime(row.duration ?? 0)}
            </Tag>
          </>
      )
    }
  },
  {
    title: 'Statuts',
    dataIndex: 'status',
    render: (text: string, row) => (
      <>
        {statusToBadge[row?.status]}
      </>
    ),
  },
  {
    title: 'Assignée',
    dataIndex: 'status',
    render: (text: string, row) => (
      <div
        style={{
          width: '100%',
          textAlign: 'center'
        }}
      >
        <Tooltip
          title={`${row?.assignTo?.prenom} ${row?.assignTo?.nom}`}
        >
          <Avatar
            style={{ backgroundColor: '#e4efff', color: '#0971fe' }}
          >
            {row?.assignTo?.prenom[0]}
          </Avatar>
        </Tooltip>
      </div>
    ),
  },
];

// rowSelection object indicates the need for row selection
const rowSelection = {
  onChange: (selectedRowKeys: React.Key[], selectedRows: workStepEntityType[]) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  },
  getCheckboxProps: (record: any) => ({
    disabled: record.assignTo?.prenom === 'Disabled User',
    name: record.assignTo?.prenom,
  }),
};


const TableResumeTasks = ({dataWs}: {dataWs: workStepEntityType[]}) => {
  const [totalEstimate, setTotalEstimate] = useState<number>(0);
  const [totalDuration, setTotalDuration] = useState<number>(0);

  useEffect(()=>{
    const _totalEstimate = dataWs?.map(data => data.estimate)
    const _totalDuration = dataWs?.map (data => data.duration)

    if (_totalEstimate && _totalDuration){
      //Calcule le total des durées estimées
      let totalEstimate = _totalEstimate.reduce(function (acc, temps){
        return acc + temps ?? 0;
      }, 0) ?? 0;
      setTotalEstimate(totalEstimate)

      //Calcule les totals des durées applicatives
      let totalDuration = _totalDuration.reduce(function (acc, temps){
        return (acc ?? 0) + (temps ?? 0);
      }, 0) ?? 0;
      setTotalDuration(totalDuration)
    }
  }, [dataWs])
  return (
    <div>
      <Table
        pagination={{pageSize: 20}}
        columns={columns}
        dataSource={dataWs}
        bordered
        summary={()=>(
          <>
            <Table.Summary.Row>
              <Table.Summary.Cell className='colored--cell' index={0}>Durée totale</Table.Summary.Cell>
              <Table.Summary.Cell index={1} colSpan={3}>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={4}>
                <Typography.Text className='font-weight-bold'>{formatTime(totalEstimate ?? 0)}</Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={5}>
                <Typography.Text className='font-weight-bold'>{formatTime(totalDuration ?? 0)}</Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={6} colSpan={2}>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        )}
      />
      <Divider />
    </div>
  );
};

export default memo(TableResumeTasks);
