import React from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Upload, Modal, Button } from "antd";
import type { UploadFile } from "antd";
import { UploadOutlined } from "@mui/icons-material";
import { useState } from "react";
import { workStepHooks } from "../../network/work-step";


const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
});


export default function ConfirmEndWork({isOpen, onConfirm, onCancel, workStep, fileList, setFileList}) {

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [isEmpty, setIsEmpty] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleCancel = () => setPreviewOpen(false);


  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };


  const handleChange = async ({ fileList: newFileList }) => {
    // Convertir chaque fichier en Blob
    if (newFileList.length === 0){
      setIsEmpty(true)
    }else{
      setIsEmpty(false)
    }
  
    const fileList = await Promise.all(newFileList.map(async (file) => {
      if (!file.url && !file.preview) {
        file.preview = URL.createObjectURL(file.originFileObj);
      }
      return file;
    }));
  
    // Mettre à jour l'état avec la nouvelle liste de fichiers en Blob
    setFileList(fileList);
  };
  

  const handleConfirm = async ()=>{
    setLoading (true)
    if (fileList.length === 0){
      setIsEmpty(true)
      return;
    }
    setIsEmpty(false)
    await onConfirm()
    .finally(()=>setLoading(false));
  }

  const { isLoading } = workStepHooks.useAddWorkStepImage();

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      open={isOpen}
      onClose={onCancel}
    >
      <DialogTitle>Demande de confirmation</DialogTitle>
      <DialogContent dividers>
        <p>Téléverser des images</p>
        <Upload 
          multiple
          beforeUpload={()=> false}
          listType="picture"
          fileList={fileList}
          onPreview={handlePreview}
          onChange={handleChange}
        >
          <Button type="dashed" icon={<UploadOutlined style={{opacity: '0.7'}}/>}>Ajouter une photo</Button>
        </Upload>
        <Modal open={previewOpen} zIndex={99999} title={previewTitle} footer={null} onCancel={handleCancel}>
          <img
            alt="example"
            style={{
              width: '100%',
            }}
            src={previewImage}
          />
        </Modal>
        {isEmpty && <p style={{color: 'red'}}>Ajoutez au moins une image</p>}
      </DialogContent>
      <DialogActions>
        <Button autoFocus type="text" onClick={onCancel}>
          Annuler
        </Button>
        <Button 
          loading={loading} 
          disabled={loading}
          onClick={handleConfirm} 
          type="primary">Confirmer</Button>
      </DialogActions>
    </Dialog>
  );
}