import React from "react";
import { Modal } from "react-bootstrap";
interface BalanceProps {
  show: boolean;
  onHide: () => void;
}

export function Balance({ show, onHide }: BalanceProps) {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Solde</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Soldes des comptes système.</p>
        <div className="row gy-4">
          <div className="col-md-12">
            <div className="card border-top border-primary mb-4">
              <div className="nk-ecwg nk-ecwg6">
                <div className="card-inner">
                  <div className="card-title-group">
                    <div className="card-title">
                      <h6 className="title">Africa's Talking </h6>
                    </div>
                  </div>
                  <div className="data">
                    <div className="data-group">
                      <div className="amount">-|-</div>
                    </div>
                    <div className="info">
                      <span className="change text-success">
                        <em className="icon ni ni-check-circle-cut" />
                      </span>
                      <span> Solde SMS en masse.</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className=" bg-light">
        <button
          className="btn btn-white btn-dim btn-outline-light"
          type="button"
          onClick={onHide}
        >
          <em className="icon ni ni-cross-circle"></em>
          <span>Annuler</span>
        </button>
      </Modal.Footer>
    </Modal>
  );
}
