import React, { Component } from "react";
import "../../assets/js/jscolor";

/**
 * JsColor
 */
class JsColor extends Component {
  /**
   * JsColor Constructor
   * @param {*} props
   */
  constructor(props) {
    super(props);

    // Initial state
    this.state = {
      color: props.value,
    };

    /**
     * Create references
     */
    this.colorInput = React.createRef();

    // Bind methods
    this.onChange = this.onChange.bind(this);
    this.onFineChange = this.onFineChange.bind(this);
    this.showColorPicker = this.showColorPicker.bind(this);
  }

  /**
   * Component mounted
   */
  componentDidMount() {
    // Setup jscolor
    window.jscolor.installByClassName("jscolor-input");

    // Bind events
    this.colorInput.current.addEventListener("onFineChange", this.onFineChange);
    this.colorInput.current.addEventListener("change", this.onChange);
    this.colorInput.current.addEventListener("input", this.onChange);

    // Set background and color
    this.colorInput.current.style.background = this.state.color;
    this.colorInput.current.style.color = this.state.color;
  }

  /**
   * Component will unmount
   */
  componentWillUnmount() {
    this.colorInput.current.removeEventListener(
      "onFineChange",
      this.onFineChange
    );
    this.colorInput.current.removeEventListener("change", this.onFineChange);
    this.colorInput.current.removeEventListener("input", this.onFineChange);
    this.colorInput.current.textContent = "";
  }

  /**
   * On JsColor value change
   */
  onChange() {
    this.colorInput.current.textContent = "";
    // Set state and call parent methods upon success set
    this.setState(
      { color: this.colorInput.current.jscolor.toHEXString() },
      function () {
        this.colorInput.current.style.background = this.state.color;
        this.colorInput.current.style.color = this.state.color;

        this.props.onChange(this.state.color);
      }
    );
  }

  /**
   * On FineChange of JsColor
   * @param {*} e
   */
  onFineChange(e) {
    this.colorInput.current.textContent = "";
    // Set state and call parent methods upon success set
    this.setState({ color: `#${e.detail.jscolor}` }, function () {
      this.colorInput.current.style.background = this.state.color;
      this.colorInput.current.style.color = this.state.color;

      this.props.onChange(this.state.color);
    });
  }

  /**
   * Show color picker
   */
  showColorPicker() {
    this.colorInput.current.textContent = "";
    this.colorInput.current.jscolor.show();
  }

  /**
   * Render component
   */
  render() {
    return (
      <>
        <button
          id={this.props.id}
          className="jscolor  jscolor-input {borderRadius:'1px', borderColor:'#e6eaee',value:'1418FF',zIndex:'99999',onFineChange:'jsColorOnFineChange(this)'}"
          onClick={this.showColorPicker}
          ref={this.colorInput}
        />
      </>
    );
  }
}

/**
 * JsColor onFineChange callback
 * @param {*} thisObj
 */
window.jsColorOnFineChange = function (thisObj) {
  thisObj.valueElement.dispatchEvent(
    new CustomEvent("onFineChange", { detail: { jscolor: thisObj } })
  );
};

export default JsColor;
