import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { network } from "./network";

export const hooks = {
  useGetAllInventaire() {
    const reponse = useQuery(['allInventaire'], network.getInventaire);

    return reponse;
  },
  useGetInventairePaginate(page: number) {
    const reponse = useQuery(['inventairePaginated', page], () => network.getInventairePaginate(page), { keepPreviousData: true });

    return reponse;
  },
  usePrefetchInventaire(page: number) {
    const queryClient = useQueryClient();

    return queryClient.prefetchQuery(['inventairePaginated', page], () => network.getInventairePaginate(page));

  },
  useAddInventaire() {
    const queryClient = useQueryClient();

    const reponse = useMutation(network.addInventaire, {
      onSuccess: () => {
        queryClient.invalidateQueries(['inventairePaginated'])
      }
    });

    return reponse;
  },
  useDeleteInventaire() {
    const queryClient = useQueryClient();

    const reponse = useMutation(network.deleteInventaire, {
      onSuccess: () => {
        queryClient.invalidateQueries(['inventairePaginated'])
      }
    });

    return reponse;
  }
}