import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { AddPayment, UpdatePayment } from "../../../Modal";
import TableauAllPaiement from "../../../components/Tableau/TableauAllPaiement";
import { paiementHooks } from "../../../network";
import Loading from "../../../components/Loading";

export default function Payments() {
  const [showAdd, setShowAdd] = useState(false);
  const [showPageMenu, setShowPageMenu] = useState(false);
  const [pageActive, setPageActive] = useState<number>(1);
  const { data, isLoading } = paiementHooks.useGetPayementPaginate(pageActive);

  const nextPage = () => {

    if (pageActive < data.data.countPage) {
      setPageActive(pageActive + 1);
    } else {
      setPageActive(pageActive);
    }
  }

  const prevPage = () => {
    if (pageActive > 1) {
      setPageActive(pageActive - 1);
    } else {
      setPageActive(pageActive);
    }
  }

  const goToPage = (numPage: number) => {
    setPageActive(numPage);
  }

  useEffect(() => {
    if (data?.hasMore) {
      paiementHooks.usePrefectPayement(pageActive)
    }
  }, [data, pageActive])

  if (isLoading){
    return (<div className="loading__wrapper">
    <Loading />
  </div>)
  }

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          {isLoading ? <Loading /> :
            <div className="nk-block-head-content">
              <h3 className="nk-block-title page-title">Paiements</h3>
              <div className="nk-block-des text-soft">
                <p>Un total de {data.data.countTotal} paiements.</p>

                {/* <p>Créez et gérez les paiements de vos clients ici.</p> */}
              </div>
            </div>}
          <div className="nk-block-head-content">
            <div className="toggle-wrap nk-block-tools-toggle">
              <a
                onClick={() => setShowPageMenu(!showPageMenu)}
                className="btn btn-icon btn-trigger toggle-expand mr-n1"
                data-target="pageMenu"
              >
                <em className="icon ni ni-menu-alt-r"></em>
              </a>
              <div
                className={`toggle-expand-content ${showPageMenu && "d-block"}`}
                data-content="pageMenu"
              >
                <ul className="nk-block-tools g-3">
                  <li>
                    <button
                      onClick={() => setShowAdd(true)}
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#create"
                    >
                      <em className="icon ni ni-plus"></em>
                      <span>Ajouter un paiement</span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="nk-block">
        <div className="card card-stretch">
          <div className="card-inner">
            {isLoading ? <Loading /> : <TableauAllPaiement data={data.data} nextPage={nextPage} prevPage={prevPage} goToPage={goToPage} />}
          </div>
        </div>
      </div>
      <AddPayment show={showAdd} onHide={() => setShowAdd(false)} />
    </>
  );
}
