import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { ConvertQuote, CreateQuote, SendEmail, UpdateQuote } from "../../Modal";

export default function Quotes() {
  const [showCreate, setShowCreate] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [showConvert, setShowConvert] = useState(false);
  const [showSendEmail, setShowSendEmail] = useState(false);

  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: `This quote will be deleted permanently.`,
      confirmButtonText: "Yes, delete!",
      cancelButtonText: `Cancel`,
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: "#fff",
    }).then((result) => {
      if (result.isConfirmed) {
        ///When click Yes
      }
    });
  };
  return (
    <>
      <div className="card-inner">
        <div className="nk-block mb-2">
          <div className="nk-block-head">
            <a
              className="btn btn-primary pull-right fetch-display-click"
              onClick={() => setShowCreate(true)}
            >
              <em className="icon ni ni-plus" />
              <span>Create Quote</span>
            </a>
            <h5 className="title">Client Quotes</h5>
            <p>A list of quotes for Jubilee Insurance Company.</p>
          </div>
        </div>
        <table className="datatable-init nk-tb-list nk-tb-ulist mt">
          <thead>
            <tr className="nk-tb-item nk-tb-head">
              <th className="nk-tb-col text-center">#</th>
              <th className="nk-tb-col tb-col-md">
                <span className="sub-text">Project</span>
              </th>
              <th className="nk-tb-col tb-col-md">
                <span className="sub-text">Registration</span>
              </th>
              <th className="nk-tb-col tb-col-md">
                <span className="sub-text">Items</span>
              </th>
              <th className="nk-tb-col tb-col-md">
                <span className="sub-text">Date</span>
              </th>
              <th className="nk-tb-col">
                <span className="sub-text">Total</span>
              </th>
              <th className="nk-tb-col nk-tb-col-tools text-right"></th>
            </tr>
          </thead>
          <tbody>
            <tr className="nk-tb-item">
              <td className="nk-tb-col text-center">1</td>
              <td className="nk-tb-col tb-col-md">
                <div className="user-card">
                  <div className="user-info">
                    <span className="tb-lead">TATA PRIMA</span>
                  </div>
                </div>
              </td>
              <td className="nk-tb-col tb-col-md">
                <span className="tb-amount">23424</span>
              </td>
              <td className="nk-tb-col tb-col-md">
                <span className="tb-amount">1</span>
              </td>
              <td className="nk-tb-col tb-col-md">
                <span>June 10, 2022</span>
              </td>
              <td className="nk-tb-col">
                <span className="tb-amount">$21.00</span>
              </td>
              <td className="nk-tb-col nk-tb-col-tools">
                <ul className="nk-tb-actions gx-1">
                  <li>
                    <Dropdown>
                      <Dropdown.Toggle
                        as="a"
                        className="btn btn-icon btn-trigger"
                      >
                        <em className="icon ni ni-more-h"></em>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu-right">
                        <ul className="link-list-opt no-bdr">
                          <li>
                            <Link to="/quotes/view">
                              <em className="icon ni ni-eye" />
                              <span>View Quote</span>
                            </Link>
                          </li>
                          <li>
                            <a href="/pdf/Quote_45.pdf" download="Quote_45.pdf">
                              <em className="icon ni ni-download-cloud" />
                              <span>Download</span>
                            </a>
                          </li>
                          <li>
                            <a
                              className="send-via-email cursor"
                              onClick={() => setShowSendEmail(true)}
                            >
                              <em className="icon ni ni-mail" />
                              <span>Send Via Email</span>
                            </a>
                          </li>
                          <li>
                            <a
                              className="fetch-display-click cursor"
                              onClick={() => setShowUpdate(true)}
                            >
                              <em className="icon ni ni-pen" />
                              <span>Edit Quote</span>
                            </a>
                          </li>
                          <li>
                            <a
                              className="convert-quote cursor"
                              onClick={() => setShowConvert(true)}
                            >
                              <em className="icon ni ni-cc" />
                              <span>Convert to Invoice</span>
                            </a>
                          </li>

                          <li className="divider" />
                          <li>
                            <a
                              className="send-to-server-click cursor"
                              onClick={handleDelete}
                            >
                              <em className="icon ni ni-trash" />
                              <span>Delete Quote</span>
                            </a>
                          </li>
                        </ul>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                </ul>
              </td>
            </tr>

            {/* 
                      if empty
                      <tr>
                        <td className="text-center" colSpan={7}>
                          It's empty here!
                        </td>
                      </tr> */}
          </tbody>
        </table>
      </div>
      <CreateQuote show={showCreate} onHide={() => setShowCreate(false)} />
      <SendEmail show={showSendEmail} onHide={() => setShowSendEmail(false)} />
      <UpdateQuote show={showUpdate} onHide={() => setShowUpdate(false)} />
      <ConvertQuote show={showConvert} onHide={() => setShowConvert(false)} />
    </>
  );
}
