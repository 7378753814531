import api from "../../axios";
import * as _ from "lodash";
import type { membreEntityPaginationType, membreEntityType, roleMembreType } from "../../types";

export const network = {
  async getMembres(): Promise<{data: membreEntityType[]}> {
    const { data } = await api.withToken.get('/membres');

    return data;
  },
  async ajoutMembre(membre: Object): Promise<{data: membreEntityType}> {
    const { data } = await api.withToken.post('/membres', membre)

    return data;
  },
  async getRole(): Promise<{data: roleMembreType}> {
    const { data } = await api.withToken.get('/membre-roles')

    return data;
  },
  async getMembreById(id: string): Promise<{data: membreEntityType}> {
    const { data } = await api.withToken.get('/membres/' + id);

    return data;
  },
  async upateMembre(donneMembre: { id: number }): Promise<{data: membreEntityType}> {
    const { data } = await api.withToken.put('/membres/' + donneMembre.id, _.omit(donneMembre, ['id']));

    return data;
  },
  async getMembrePaginate(page: number, role?: roleMembreType): Promise<{data: membreEntityPaginationType
    hasMore?: boolean
  }> {
    const { data } = await api.withToken.get('membres/page/10/' + page + '?role=' + (role || ''));

    return data;
  },
  async deleteMembre(donne: { id: number }): Promise<{data: membreEntityType}> {
    const { data } = await api.withToken.delete('/membres/' + donne.id);

    return data;
  },
}