import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { network } from "./network";

export const hooks = {
  useGetAllWorks() {
    const reponse = useQuery(['allWorks'], network.getAllWorks);

    return reponse;
  },
  useGetWorksByStatus(statut: { statut: string }) {
    const reponse = useQuery(['WorksByStatus', statut], () => network.getWorkByStatus(statut));

    return reponse;
  },
  useGetWorkByProjet(idProjet: number) {
    const reponse = useQuery(['WorkByProject', idProjet], () => network.getWorkByProject(idProjet), { keepPreviousData: true });


    return reponse;
  },
  // usePrefetchWorkVehicule(donne: { page: number, idVehicule: number }) {
  //   const queryClient = useQueryClient();

  //   return queryClient.prefetchQuery(['WorkByVehicule', donne], () => network.getWorkByVehicule(donne));
  // },
  useCreateWork() {
    const queryClient = useQueryClient()
    const reponse = useMutation(network.createWork, {
      onSuccess: () => {
        queryClient.invalidateQueries(['WorkPaginate'])
        queryClient.invalidateQueries(['WorkByProject'])
        queryClient.invalidateQueries(['allWorks'])
        queryClient.invalidateQueries(['allWorkSteps'])
      }
    });

    return reponse;
  },
  useGetWorkPaginated(page: number) {
    const reponse = useQuery(['WorkPaginate', page], () => network.getWorkPaginated(page), { keepPreviousData: true });

    return reponse;
  },
  usePrefetchWork(page: number) {
    const queryClient = useQueryClient();

    return queryClient.prefetchQuery(['WorkPaginate', page], () => network.getWorkPaginated(page))
  },

  useUpdateWork() {
    const queryClient = useQueryClient()
    const reponse = useMutation(network.updateWork, {
      onSuccess: () => {
        queryClient.invalidateQueries(['WorkPaginate'])
        queryClient.invalidateQueries(['WorkByProject'])
        queryClient.invalidateQueries(['allWorks'])
        queryClient.invalidateQueries(['allWorkSteps'])
      }
    });
    return reponse;
  },

  useDeleteWork() {
    const queryClient = useQueryClient();

    const reponse = useMutation(network.deleteWork, {
      onSuccess: () => {
        queryClient.invalidateQueries(['WorkPaginate'])
        queryClient.invalidateQueries(['WorkByProject'])
        queryClient.invalidateQueries(['allWorks'])
        queryClient.invalidateQueries(['allWorkSteps'])
      }
    });

    return reponse;
  }
} 