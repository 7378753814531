import { useState, useEffect, CSSProperties, useCallback } from "react";
import { Link, Navigate } from "react-router-dom";
import "./Home.scss";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
} from "chart.js";
import { Doughnut, Line } from "react-chartjs-2";
import TableResumeTasks from "../../components/Tableau/TableResumeTasks";
import { workStepHooks } from "../../network/work-step";
import { membreHooks } from "../../network";
import { projetHooks } from "../../network";
import Loading from "../../components/Loading";

import { Tree, TreeDataNode, Button, Input } from 'antd'; //Composant pour gérer les filtre
import { FilterList } from "@mui/icons-material";
import { words } from "lodash";
import { DataNode } from "antd/es/tree";

//Style pour le dropDown
const DropdownStle:CSSProperties = {
  position: 'absolute',
  backgroundColor: 'white',
  boxShadow: '3px 3px 0.3rem 0.2rem rgba(100, 100, 100, 0.2)',
  top: '100%',
  right: '0%',
  borderRadius: '0.8rem',
  padding: '1rem',
  zIndex: '1000',
  width: '300px',
  overflowY: 'auto'
}

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title
);
//ChartJS.defaults.plugins.legend.labels.usePointStyle = true;

const data = {
  labels: ["Terminé", "En cours", "Réservé dans"],
  datasets: [
    {
      label: "# of Votes",
      data: [12, 19, 3],
      backgroundColor: [
        "rgb(15, 172, 129)",
        "rgb(255, 187, 0)",
        "rgb(232, 83, 71)",
      ],
      borderColor: [
        "rgb(15, 172, 129)",
        "rgb(255, 187, 0)",
        "rgb(232, 83, 71)",
      ],
      borderWidth: 1,
    },
  ],
};
const data2 = {
  labels: ["Terminé", "En cours", "Annulé"],
  datasets: [
    {
      label: "# of Votes",
      data: [10, 5, 8],
      backgroundColor: [
        "rgb(15, 172, 129)",
        "rgb(255, 187, 0)",
        "rgb(241, 243, 245)",
      ],
      borderColor: [
        "rgb(15, 172, 129)",
        "rgb(255, 187, 0)",
        "rgb(241, 243, 245)",
      ],
      borderWidth: 1,
    },
  ],
};
const option = {
  cutout: "70%",
  plugins: {
    legend: {
      display: false,
    },
  },
};
const optionsLine = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: { display: false, position: "top" as const },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
    tooltip: {
      displayColors: false,
      callbacks: {
        label: function (context: any) {
          let label = "";

          if (context.parsed.y !== null) {
            label += context.parsed.y + " USD";
          }
          return label;
        },
      },
    },
  },
};
const labels = [
  "Juin",
  "Juil",
  "Août",
  "Sep",
  "Oct",
  "Nov",
  "Déc",
  "Jan",
  "Fév",
  "Mar",
  "Avr",
  "Peut",
];
const dataLine = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: [30, 100, 20, 500, 0, 5, 10, 70, 200, 20, 5, 360, 700],
      borderColor: "rgb(15, 172, 129)",
      backgroundColor: "rgb(15, 172, 129)",
    },
  ],
};



//Récupere la date actuelle
const _newestDate = new Date(); 
const _oldestDate = new Date("2022-01-01T00:00:00.000Z");

//Liste des mois
const mois = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];



const user = JSON.parse(localStorage.getItem('AUTH_USER') ?? "{}");
export default function Home() {
  
  const [showPageMenu, setShowPageMenu] = useState(false)
  const [isFilterTechniciensOpen, setIsFilterTechniciensOpen] = useState<boolean>(false) //Overture filtre techniciens
  const [isFilterVehiculesOpen, setIsFilterVehiculesOpen] = useState<boolean>(false) //Ouverture filtre Vehicules
  const [isFilterMonthOpen, setIsFilterMonthOpen] = useState<boolean>() //Overture filtre mois
  const [membreName, setMembreName] = useState('') //recherche techniciens
  const [vehicleName, setVehicleName] = useState('') //recherche vehicule
  const [memberIds, setMemberIds] = useState<number[]>([])
  const [vehicleIds, setVehicleIds] = useState<number[]> ([])
  const [dateList, setDateList] = useState<Date[] | number[]>([])

  

  //Liste des travaux
  const {data: dataWorksteps, isLoading} = workStepHooks.useGetAllWorkSteps(memberIds, vehicleIds, ['Complété', 'Annulé'], dateList); 

  //mettre la valeur true en 3ème argument pour ne récuperer que les travaux Terminés ou Annulés


  //Liste des techniciens
  const { data: techniciens, isLoading: loadingTechniciens } = membreHooks.useGetMembres(); 
  //Liste des projets
  const {data: projects, isLoading: loadingProjects} = projetHooks.useGeTAllProject();  
  
  const [listTechniciens, setListTechniciens] = useState<TreeDataNode[]>([{ //Liste des techniciens foramtée pour le composant 'Tree'
    key: 'tous',
    title: 'Tous',
  }]);
  const [listVehicules, setListVehicules] = useState<TreeDataNode[]>([{ //Liste des projets foramtée pour le composant 'Tree'
    key: 'tous',
    title: 'Tous',
  }]);
  const [listDate, setListDate] = useState<TreeDataNode[]>([{ //Liste des Mois foramtée pour le composant 'Tree'
    key: 'tous',
    title: 'Tous',
  }]);

  const [checkedTechniciens, setCheckedTechniciens] = useState <any>(['tous']);
  const [checkedVehicules, setCheckedVehicules] = useState <any>(['tous']);
  const [checkedMois, setCheckedMois] = useState <any>(['tous']);

  //Filtre la liste des techniciens
  const Membreloop = useCallback(data =>
    data.map((item, inde )=> {
      const titleLower = item.title.toLowerCase();
      const membreNameLower = membreName.toLowerCase();
      const index = titleLower.includes(membreNameLower);
      const highlightedTitle = item.title.split(membreName).join(`<span class="site-tree-search-value">${membreName}</span>`);
      const title = index ? (
        <span key={inde} dangerouslySetInnerHTML={{ __html: highlightedTitle }} />
      ) : (
        <span key={inde}>{item.title}</span>
      );
      if (item.children) {
        return { title, key: item.key, children: Membreloop(item.children) };
      }
      return {
        title,
        key: item.key,
        className: index ? 'tree-node' : 'tree-node hidden',
      };
  }), [membreName])

  //Filtre la liste des vehicules
  const vehicleloop = useCallback(data =>
    data.map(item => {
      const titleLower = item.title.toLowerCase();
      const vehicleNameLower = vehicleName.toLowerCase();
      const index = titleLower.includes(vehicleNameLower);
      const highlightedTitle = item.title.split(vehicleName).join(`<span class="site-tree-search-value">${vehicleName}</span>`);
      const title = index ? (
        <span dangerouslySetInnerHTML={{ __html: highlightedTitle }} />
      ) : (
        <span>{item.title}</span>
      );
      if (item.children) {
        return { title, key: item.key, children: vehicleloop(item.children) };
      }
      return {
        title,
        key: item.key,
        className: index ? 'tree-node' : 'tree-node hidden',
      };
  }), [vehicleName]);

//Liste des dates

  useEffect(()=>{
    if (_oldestDate && _newestDate){
      let _newestDate = new Date(); 
      let _oldestDate = new Date("2023-01-01T00:00:00.000Z");
      let dateList: DataNode[] = [];
      while(_oldestDate <= _newestDate){
        dateList.push({
          title: mois[Number(_oldestDate.getMonth())] + ' ' + _oldestDate.getFullYear(),
          key: Number(_oldestDate),
        });
        _oldestDate.setMonth(_oldestDate.getMonth() + 1);
      }

      dateList.reverse();

      setListDate([
        {
          title: 'Tous',
          key: 'tous',
          children: dateList,
        }
      ])
    }
  }, [])


  //Prepare la liste des techniciens dans l'arbre à checkbox
  useEffect(()=>{
    if (techniciens){
      const list = techniciens.data.map((technicien: { nom: string, idMembre: number, prenom: string }) => {
        return {key: technicien.idMembre.toString(), title: `${technicien.prenom} ${technicien.nom}`}
      })
  
      setListTechniciens([
        {
          title: 'Tous',
          key: 'tous',
          children: list,
        }
      ])
    }
  }, [techniciens]);

  //Prepare la liste des projets dans l'arbre à checkbox
  useEffect(()=>{
    if (projects){
      const list = projects.data.map((projet) => {
        return {key: projet.vehicules.idVehicule?.toString()?? '', title: `${projet.vehicules.detailVehicules.marque} ${projet.vehicules.detailVehicules.model}`}
      })
      
      setListVehicules([
        {
          title: 'Tous',
          key: 'tous',
          children: list,
        }
      ])
    }
  }, [projects]);

  //Filre les worksteps
  function filterWorkSteps () { 
    if (checkedTechniciens.includes('tous')){
      setMemberIds([-1])
    }
    else{
      const IDs = checkedTechniciens.map(id => Number(id));
      setMemberIds(IDs)
    }

    if (checkedVehicules.includes('tous')){
      setVehicleIds([-1])
    }else{
      const IDs = checkedVehicules.map(id => Number(id));
      setVehicleIds(IDs)
    }
    if (checkedMois.includes('tous')){
      setDateList([-1])
    }else{
      setDateList(checkedMois)
    }
  }

  //Adapte la liste quand le filtre est enclanché
  useEffect(()=>{
    filterWorkSteps();
  }, [checkedTechniciens, checkedVehicules, checkedMois ])
  
  if(user.membres.role !== 'Admin') {
    return <Navigate to='/planning' replace />
  }
  return (
    <>
      <div className="nk-block-head nk-block-head-sm" onClick={(e)=>{
        e.stopPropagation()
        setIsFilterTechniciensOpen(false)
        setIsFilterVehiculesOpen(false)
        setIsFilterMonthOpen(false)
      }}>
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">Aperçu</h3>
          </div>
          <div className="nk-block-head-content">
            <div className="toggle-wrap nk-block-tools-toggle">
              <a
                onClick={() => setShowPageMenu(!showPageMenu)}
                className="btn btn-icon btn-trigger toggle-expand mr-n1"
                data-target="pageMenu"
              >
                <em className="icon ni ni-more-v"></em>
              </a>
              <div
                className={`toggle-expand-content ${showPageMenu && "d-block"}`}
                data-content="pageMenu"
              >
                <div style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  justifyItems: 'center',
                  gap: '1rem'
                }}>
                  {loadingTechniciens? <Loading /> :
                  <div style={{position: 'relative', marginLeft: 'auto'}}>
                    <Button id='dropdown' onClick={(e)=>{
                      e.stopPropagation()
                      setIsFilterTechniciensOpen(!isFilterTechniciensOpen)
                      setIsFilterVehiculesOpen(false)
                    }} icon={<FilterList />}>
                      Filtre par technicien
                    </Button>
                    <div 
                      style={{
                      ...DropdownStle, 
                      display: isFilterTechniciensOpen? 'block': 'none',
                      }} 
                      onClick={(e)=>{
                      e.stopPropagation()
                      setIsFilterTechniciensOpen(true)
                    }}>
                      <Input.Search placeholder="rechercher" value={membreName} onChange={e=>{setMembreName(e.target.value)}} />
                      <Tree 
                      checkable
                      defaultCheckedKeys={['tous']}
                      defaultExpandedKeys={['tous']}
                      treeData={Membreloop(listTechniciens)}
                      onCheck={setCheckedTechniciens}
                      height={400}
                      style={{backgroundColor: 'white'}}
                      />
                    </div>
                  </div>
                  }
                  {loadingProjects? <Loading /> :
                  <div style={{position: 'relative', marginLeft: 'auto'}}>
                    <Button id='dropdown' onClick={(e)=>{
                      e.stopPropagation()
                      setIsFilterVehiculesOpen(!isFilterVehiculesOpen)
                      setIsFilterTechniciensOpen(false)
                      setIsFilterMonthOpen(false)
                    }} icon={<FilterList />}>
                      Filtre par véhicule
                    </Button>
                    <div 
                      style={{
                      ...DropdownStle, 
                      display: isFilterVehiculesOpen? 'block': 'none',
                      }} 
                      onClick={(e)=>{
                      e.stopPropagation()
                      setIsFilterVehiculesOpen(true)
                    }}>
                      <Input.Search placeholder="rechercher" value={vehicleName} onChange={e=>{setVehicleName(e.target.value)}} />
                      <Tree 
                      checkable
                      defaultCheckedKeys={['tous']}
                      defaultExpandedKeys={['tous']}
                      treeData={vehicleloop(listVehicules)}
                      onCheck={setCheckedVehicules}
                      height={400}
                      style={{backgroundColor: 'white'}}
                      />
                    </div>
                  </div>
                  }
                  {loadingProjects? <Loading /> :
                  <div style={{position: 'relative', marginLeft: 'auto'}}>
                    <Button id='dropdown' onClick={(e)=>{
                      e.stopPropagation()
                      setIsFilterMonthOpen(!isFilterMonthOpen)
                      setIsFilterTechniciensOpen(false)
                      setIsFilterVehiculesOpen(false)
                    }} icon={<FilterList />}>
                      Filtre par mois
                    </Button>
                    <div 
                      style={{
                      ...DropdownStle, 
                      display: isFilterMonthOpen? 'block': 'none',
                      }} 
                      onClick={(e)=>{
                      e.stopPropagation()
                      setIsFilterMonthOpen(true)
                    }}>
                      <Tree 
                      checkable
                      defaultCheckedKeys={['tous']}
                      defaultExpandedKeys={['tous']}
                      treeData={listDate}
                      onCheck={setCheckedMois}
                      height={400}
                      style={{backgroundColor: 'white'}}
                      />
                    </div>
                  </div>
                  }
                </div>
                
                {/*<ul className="nk-block-tools g-3">
                  <li>
                    <Link
                      to="/clients"
                      className="btn btn-white btn-dim btn-primary"
                    >
                      <em className="d-none d-sm-inline icon ni ni-users-fill"></em>
                      <span>Afficher les clients</span>{" "}
                    </Link>
                  </li>
                  <li className="nk-block-tools-opt">
                    <Link to="/projects" className="btn btn-primary">
                      <em className="icon ni ni-note-add-fill"></em>
                      <span>Projets</span>
                    </Link>
                  </li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="nk-block">
        <div className="row g-gs">
          <div className="col-xxl-12">
            {isLoading ? <Loading /> : <TableResumeTasks dataWs={dataWorksteps?.data ?? []} />}
          </div>
        </div>
        <div className="row g-gs">
          <div className="col-md-6">
            <div className="row g-gs">
              {/* stats row one */}
              <div className="col-md-6">
                <div className="card border-top border-success">
                  <div className="nk-ecwg nk-ecwg6">
                    <div className="card-inner">
                      <div className="card-title-group">
                        <div className="card-title">
                          <h6 className="title">
                            Factures impayées{" "}
                            <sup className="text-muted">$</sup>
                          </h6>
                        </div>
                      </div>
                      <div className="data">
                        <div className="data-group">
                          <div className="amount">0.00</div>
                        </div>
                        <div className="info">
                          <span className="change text-danger">
                            <em className="icon ni ni-cc"></em> $0.00
                          </span>
                          <span> En retard</span>
                        </div>
                      </div>
                    </div>
                    {/* .card-inner */}
                  </div>
                  {/* .nk-ecwg */}
                </div>
                {/* .card */}
              </div>
              {/* .col */}
              <div className="col-md-6">
                <div className="card border-top border-success">
                  <div className="nk-ecwg nk-ecwg6">
                    <div className="card-inner">
                      <div className="card-title-group">
                        <div className="card-title">
                          <h6 className="title">Projets actifs</h6>
                        </div>
                      </div>
                      <div className="data">
                        <div className="data-group">
                          <div className="amount fw-bold">0</div>
                        </div>
                        <div className="info">
                          <span className="change text-success">
                            <em className="icon ni ni-cards"></em> 0
                          </span>
                          <span> Terminé</span>
                        </div>
                      </div>
                    </div>
                    {/* .card-inner */}
                  </div>
                  {/* .nk-ecwg */}
                </div>
                {/* .card */}
              </div>
              {/* .col */}
              <div className="col-md-6">
                <div className="card border-top border-success">
                  <div className="nk-ecwg nk-ecwg6">
                    <div className="card-inner">
                      <div className="card-title-group">
                        <div className="card-title">
                          <h6 className="title">Tâches en attente</h6>
                        </div>
                      </div>
                      <div className="data">
                        <div className="data-group">
                          <div className="amount fw-bold">0</div>
                        </div>
                        <div className="info">
                          <span className="change up text-success">
                            <em className="icon ni ni-todo"></em>0
                          </span>
                          <span> Terminé</span>
                        </div>
                      </div>
                    </div>
                    {/* .card-inner */}
                  </div>
                  {/* .nk-ecwg */}
                </div>
                {/* .card */}
              </div>
              {/* .col */}
              <div className="col-md-6">
                <div className="card border-top border-success">
                  <div className="nk-ecwg nk-ecwg6">
                    <div className="card-inner">
                      <div className="card-title-group">
                        <div className="card-title">
                          <h6 className="title">
                            Revenu mai <sup className="text-muted">$</sup>
                          </h6>
                        </div>
                      </div>
                      <div className="data">
                        <div className="data-group">
                          <div className="amount fw-bold">0.00</div>
                        </div>
                        <div className="info">
                          <span className="change text-success">
                            <em className="icon ni ni-align-left"></em>0
                          </span>
                          <span> Paiements</span>
                        </div>
                      </div>
                    </div>
                    {/* .card-inner */}
                  </div>
                  {/* .nk-ecwg */}
                </div>
                {/* .card */}
              </div>
              {/* .col */}
            </div>
          </div>

          {/* Project stats */}
          <div className="col-md-6">
            <div className="card card-full overflow-hidden border-top border-success">
              <div className="nk-ecwg nk-ecwg7 h-100">
                <div className="card-inner flex-grow-1">
                  <div className="card-title-group mb-4">
                    <div className="card-title">
                      <h6 className="title">Statistiques du projet</h6>
                    </div>
                  </div>
                  <div className="nk-ecwg7-ck">
                    <Doughnut data={data} options={option} />
                  </div>
                  <ul className="nk-ecwg7-legends">
                    <li>
                      <div className="title">
                        <span
                          className="dot dot-lg sq"
                          style={{ backgroundColor: "#0fac81" }}
                        ></span>
                        <span>Terminé</span>
                      </div>
                    </li>
                    <li>
                      <div className="title">
                        <span
                          className="dot dot-lg sq"
                          style={{ backgroundColor: "#FFBB00" }}
                        ></span>
                        <span>En cours</span>
                      </div>
                    </li>
                    <li>
                      <div className="title">
                        <span
                          className="dot dot-lg sq"
                          style={{ backgroundColor: "#e85347" }}
                        ></span>
                        <span>Réservé dans</span>
                      </div>
                    </li>
                  </ul>
                </div>
                {/* .card-inner */}
              </div>
            </div>
            {/* .card */}
          </div>
          {/* .col */}
        </div>
        <div className="row g-gs pt-3">
          {/* Tasks stats */}
          <div className="col-md-6">
            <div className="card card-full overflow-hidden border-top border-primary">
              <div className="nk-ecwg nk-ecwg7 h-100">
                <div className="card-inner flex-grow-1">
                  <div className="card-title-group mb-4">
                    <div className="card-title">
                      <h6 className="title">Statistiques de tâche</h6>
                    </div>
                  </div>
                  <div className="nk-ecwg7-ck">
                    <Doughnut data={data2} options={option} />
                  </div>
                  <ul className="nk-ecwg7-legends">
                    <li>
                      <div className="title">
                        <span
                          className="dot dot-lg sq"
                          data-bg="#0fac81"
                          style={{ backgroundColor: "#0fac81" }}
                        ></span>
                        <span>Terminé</span>
                      </div>
                    </li>
                    <li>
                      <div className="title">
                        <span
                          className="dot dot-lg sq"
                          data-bg="#FFBB00"
                          style={{ backgroundColor: "#FFBB00" }}
                        ></span>
                        <span>En cours</span>
                      </div>
                    </li>
                    <li>
                      <div className="title">
                        <span
                          className="dot dot-lg sq"
                          data-bg="#f1f3f5"
                          style={{ backgroundColor: "#f1f3f5" }}
                        ></span>
                        <span>Annulé</span>
                      </div>
                    </li>
                  </ul>
                </div>
                {/* .card-inner */}
              </div>
            </div>
            {/* .card */}
          </div>
          {/* .col */}
          <div className="col-md-6">
            <div className="row g-gs">
              {/* stats row one */}
              <div className="col-md-6">
                <div className="card border-top border-primary">
                  <div className="nk-ecwg nk-ecwg6">
                    <div className="card-inner">
                      <div className="card-title-group">
                        <div className="card-title">
                          <h6 className="title">
                            Revenu 2022 <sup className="text-muted">$</sup>
                          </h6>
                        </div>
                      </div>
                      <div className="data">
                        <div className="data-group">
                          <div className="amount fw-bold">0.00</div>
                        </div>
                        <div className="info">
                          <span className="change text-success">
                            <em className="icon ni ni-check-circle-cut"></em>
                          </span>
                          <span> Paiements cette année</span>
                        </div>
                      </div>
                    </div>
                    {/* .card-inner */}
                  </div>
                  {/* .nk-ecwg */}
                </div>
                {/* .card */}
              </div>
              {/* .col */}
              <div className="col-md-6">
                <div className="card border-top border-primary">
                  <div className="nk-ecwg nk-ecwg6">
                    <div className="card-inner">
                      <div className="card-title-group">
                        <div className="card-title">
                          <h6 className="title">
                            Bénéfices 2022 <sup className="text-muted">$</sup>
                          </h6>
                        </div>
                      </div>
                      <div className="data">
                        <div className="data-group">
                          <div className="amount fw-bold">0.00</div>
                        </div>
                        <div className="info">
                          <span className="change text-success">
                            <em className="icon ni ni-check-circle-cut"></em>
                          </span>
                          <span> Revenu moins dépenses</span>
                        </div>
                      </div>
                    </div>
                    {/* .card-inner */}
                  </div>
                  {/* .nk-ecwg */}
                </div>
                {/* .card */}
              </div>
              {/* .col */}
              <div className="col-md-6">
                <div className="card border-top border-primary">
                  <div className="nk-ecwg nk-ecwg6">
                    <div className="card-inner">
                      <div className="card-title-group">
                        <div className="card-title">
                          <h6 className="title">Nombre total de clients</h6>
                        </div>
                      </div>
                      <div className="data">
                        <div className="data-group">
                          <div className="amount fw-bold">0.00</div>
                        </div>
                        <div className="info">
                          <span className="change text-success">
                            <em className="icon ni ni-check-circle-cut"></em>
                          </span>
                          <span> Clients actifs</span>
                        </div>
                      </div>
                    </div>
                    {/* .card-inner */}
                  </div>
                  {/* .nk-ecwg */}
                </div>
                {/* .card */}
              </div>
              {/* .col */}
              <div className="col-md-6">
                <div className="card border-top border-primary">
                  <div className="nk-ecwg nk-ecwg6">
                    <div className="card-inner">
                      <div className="card-title-group">
                        <div className="card-title">
                          <h6 className="title">Personnel total</h6>
                        </div>
                      </div>
                      <div className="data">
                        <div className="data-group">
                          <div className="amount fw-bold">1</div>
                        </div>
                        <div className="info">
                          <span className="change text-success">
                            <em className="icon ni ni-check-circle-cut"></em>
                          </span>
                          <span> Personnel actif et inactif</span>
                        </div>
                      </div>
                    </div>
                    {/* .card-inner */}
                  </div>
                  {/* .nk-ecwg */}
                </div>
                {/* .card */}
              </div>
              {/* .col */}
            </div>
          </div>
        </div>
        <div className="row g-gs pt-3">
        </div>
        <div className="row g-gs pt-3">
          <div className="col-xxl-6">
            <div className="card card-full">
              <div className="nk-ecwg nk-ecwg8 h-100">
                <div className="card-inner">
                  <div className="card-title-group mb-3">
                    <div className="card-title">
                      <h6 className="title">Les paiements durent 12 mois</h6>
                    </div>
                  </div>
                  <ul className="nk-ecwg8-legends">
                    <li>
                      <div className="title">
                        <span
                          className="dot dot-lg sq"
                          style={{ backgroundColor: "#0fac81" }}
                        ></span>
                        <span>Paiements</span>
                      </div>
                    </li>
                  </ul>
                  <div className="" style={{ height: "300px", width: "100%" }}>
                    <Line options={optionsLine} data={dataLine} height={300} />
                  </div>
                </div>
                {/* .card-inner */}
              </div>
            </div>
            {/* .card */}
          </div>
          {/* .col */}
        </div>
        {/* .row */}
      </div>
    </>
  );
}
