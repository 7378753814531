import React from "react";
import { Modal } from "react-bootstrap";
interface ImportTasksProps {
  show: boolean;
  onHide: () => void;
}

export function ImportTasks({ show, onHide }: ImportTasksProps) {
  return (
    <Modal show={show} onHide={onHide} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Manage Info</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Create project tasks</p>
        <div className="row m-0 mb-3 modal-section">
          <div className="col-sm-12 border pt-3 pb-3">
            <div className="row gy-4">
              <div className="col-sm-12 border-bottom">
                <a className="btn btn-round btn-sm cursor btn-icon btn-dim btn-danger remove-bulk-imported pull-right">
                  <em className="icon ni ni-trash" />
                </a>
                <h6 className="mt-1">#1 abcd</h6>
              </div>
            </div>
            <div className="row gy-4  pt-2">
              <div className="col-sm-3">
                <div className="form-group">
                  <label className="form-label">Task Title</label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      placeholder="Task Name"
                      name="title"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-3">
                <div className="form-group">
                  <label className="form-label">Assign to</label>
                  <div className="form-control-wrap ">
                    <div className="form-control-select">
                      <select
                        className="form-control select2-dynamic"
                        name="member"
                        required
                      >
                        <option value="">Select Staff</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-3">
                <div className="form-group">
                  <label className="form-label">Status</label>
                  <div className="form-control-wrap ">
                    <div className="form-control-select">
                      <select
                        className="form-control form-control-lg"
                        name="status"
                      >
                        <option value="In Progress">In Progress</option>
                        <option value="Completed">Completed</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-3">
                <div className="form-group">
                  <label className="form-label">
                    Task Cost{" "}
                    <span
                      data-toggle="tooltip"
                      title="Task cost can't be updated once saved."
                    >
                      <em className="icon ni ni-info-fill" />
                    </span>
                  </label>
                  <div className="form-control-wrap">
                    <div className="form-text-hint">
                      <span className="overline-title">$</span>
                    </div>
                    <input
                      type="number"
                      className="form-control form-control-lg"
                      placeholder="Task Cost"
                      name="cost"
                      defaultValue={0.0}
                      step="0.01"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <label className="form-label">Task Description</label>
                  <div className="form-control-wrap">
                    <textarea
                      className="form-control form-control-lg unset-min-height"
                      placeholder="Task Description"
                      rows={3}
                      name="description"
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-3">
                <div className="form-group">
                  <label className="form-label">Due Date</label>
                  <div className="form-control-wrap">
                    <input
                      type="date"
                      className="form-control form-control-lg"
                      placeholder="Due Date"
                      name="due_date"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-3">
                <div className="form-group">
                  <label className="form-label">Due Time</label>
                  <div className="form-control-wrap">
                    <input
                      type="time"
                      className="form-control form-control-lg"
                      placeholder="Due Time"
                      name="due_time"
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="form-label">Select required parts</label>
                  <div className="form-control-wrap ">
                    <div className="form-control-select">
                      <select
                        className="form-control form-control-lg select2-dynamic"
                        name="parts_required[]"
                      ></select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row gy-4">
          <div className="col-sm-12">
            <div className="alert alert-fill alert-warning alert-icon">
              <em className="icon ni ni-alert-circle" /> No work requested added
              on this project.
            </div>
          </div>
        </div> */}
      </Modal.Body>
      <Modal.Footer className=" bg-light">
        <button
          className="btn btn-white btn-dim btn-outline-light"
          type="button"
          onClick={onHide}
        >
          <em className="icon ni ni-cross-circle"></em>
          <span>Cancel</span>
        </button>
        <button className="btn btn-primary" type="submit">
          <em className="icon ni ni-check-circle-cut"></em>
          <span>Create Tasks</span>
        </button>
      </Modal.Footer>
    </Modal>
  );
}
