import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { SupplierReport } from "../../../Modal/SupplierReport";
import { AddSupplier, UpdateSupplier } from "../../../Modal";
import { Pagination } from "react-bootstrap";

type Props = {
  data: {
    list: [],
    countPage: number,
  }
  nextPage: Function
  prevPage: Function
  goToPage: Function
}

export default function TableauSuppliers({ data, nextPage, prevPage, goToPage }: Props) {
  interface Fournisseur {
    idFournisseur: number,
    email: string,
    telephone: string,
    nom: string,
    nipTVA: string
  }

  const [showAdd, setShowAdd] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [showPageMenu, setShowPageMenu] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [dataForSet, setDataForSet] = useState<Fournisseur>({
    idFournisseur: 0,
    email: '',
    telephone: '',
    nom: '',
    nipTVA: '',
  })
  const [activePage, setActivePage] = useState<number>(1);



  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "This supplier's profile will be deleted permanently.",
      confirmButtonText: "Yes, delete!",
      cancelButtonText: `Cancel`,
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        ///When click Yes
      }
    });
  };

  const setDataUpdate = (donne: Fournisseur) => {
    setDataForSet(donne);

    setShowUpdate(true);
  }

  const goToNextPage = () => {
    if (activePage < data.countPage) {
      setActivePage(activePage + 1);
    } else {
      setActivePage(activePage)
    }
    nextPage();
  }

  const goToPrevPage = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1)
    } else {
      setActivePage(activePage)
    }
    prevPage()
  }

  const goToPageOne = (items: number) => {
    setActivePage(items)
    goToPage(items)
  }
  return (
    <>
      <div className="nk-block">
        <div className="card card-stretch">
          <div className="card-inner d-flex flex-column align-items-end">
            <table
              className="datatable-init nk-tb-list nk-tb-ulist"
              data-auto-responsive="false"
            >
              <thead>
                <tr className="nk-tb-item nk-tb-head">
                  <th className="nk-tb-col text-center">#</th>
                  <th className="nk-tb-col">
                    <span className="sub-text">Nom</span>
                  </th>
                  <th className="nk-tb-col tb-col-md">
                    <span className="sub-text">Email</span>
                  </th>
                  <th className="nk-tb-col tb-col-md">
                    <span className="sub-text">NIP TVA</span>
                  </th>
                  {/* <th className="nk-tb-col tb-col-md">
                    <span className="sub-text">Fournie</span>
                  </th>
                  <th className="nk-tb-col tb-col-md">
                    <span
                      className="sub-text"
                      data-toggle="tooltip"
                      title="Articles en attente de livraison"
                    >
                      A.D <em className="icon ni ni-info-fill"></em>
                    </span>
                  </th>
                  <th className="nk-tb-col tb-col-md">
                    <span
                      className="sub-text"
                      data-toggle="tooltip"
                      title="Montant dû au fournisseur"
                    >
                      Owed <em className="icon ni ni-info-fill"></em>
                    </span>
                  </th> */}
                  <th className="nk-tb-col nk-tb-col-tools text-right"></th>
                </tr>
              </thead>
              {data.list.map((item: Fournisseur) => (
                <tbody>
                  <tr className="nk-tb-item">
                    <td className="nk-tb-col text-center">{item.idFournisseur}</td>
                    <td className="nk-tb-col">
                      <div className="user-card">
                        <div className="user-avatar bg-dim-primary d-none d-sm-flex">
                          <span>Mo</span>
                        </div>
                        <div className="user-info">
                          <span className="tb-lead">{item.nom}</span>
                          <span>{item.telephone}</span>
                        </div>
                      </div>
                    </td>
                    <td className="nk-tb-col tb-col-md">
                      <span>{item.email} </span>
                    </td>
                    <td className="nk-tb-col tb-col-md">
                      <span>{item.nipTVA} </span>
                    </td>
                    {/* <td className="nk-tb-col tb-col-md text-center">
                    <span>0</span>
                  </td>
                  <td className="nk-tb-col tb-col-lg">
                    <span>0</span>
                  </td>
                  <td
                    className="nk-tb-col tb-col-mb"
                    data-order="{{ $supplier->balance }}"
                  >
                    <span className="tb-amount">$0.00 </span>
                  </td> */}
                    <td className="nk-tb-col nk-tb-col-tools">
                      <ul className="nk-tb-actions gx-1">
                        <li>
                          <Dropdown>
                            <Dropdown.Toggle
                              as="a"
                              className="btn btn-icon btn-trigger"
                            >
                              <em className="icon ni ni-more-h"></em>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu-right">
                              <ul className="link-list-opt no-bdr">
                                <li>
                                  <a
                                    className="fetch-display-click cursor"
                                    onClick={() => { setDataUpdate(item) }}
                                  >
                                    <em className="icon ni ni-pen"></em>
                                    <span>Modifier les détails</span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    onClick={() => setShowReport(true)}
                                    className="supplier-report cursor"
                                  >
                                    <em className="icon ni ni-reports"></em>
                                    <span>Voir le rapport</span>
                                  </a>
                                </li>

                                <li className="divider"></li>
                                <li>
                                  <a
                                    className="send-to-server-click cursor"
                                    onClick={handleDelete}
                                  >
                                    <em className="icon ni ni-trash"></em>
                                    <span>Supprimer le fournisseur</span>
                                  </a>
                                </li>
                              </ul>
                            </Dropdown.Menu>
                          </Dropdown>
                        </li>
                      </ul>
                    </td>
                  </tr>
                  {/* <tr>
                  <td className="text-center" colSpan={8}>
                    C'est vide ici !
                  </td>
                </tr> */}
                </tbody>))}
            </table>
            <Pagination className="nk-block-end">
              <Pagination.First onClick={() => goToPageOne(1)} />
              <Pagination.Prev onClick={goToPrevPage} />
              {
                Array.from(
                  { length: data.countPage }, (v, k) => k + 1
                ).map(items => <Pagination.Item key={items} active={items === activePage} onClick={() => goToPageOne(items)}>{items}</Pagination.Item>)
              }
              <Pagination.Next onClick={goToNextPage} />
              <Pagination.Last onClick={() => goToPageOne(data.countPage)} />
            </Pagination>
          </div>
        </div>
      </div>
      <AddSupplier show={showAdd} onHide={() => setShowAdd(false)} />
      <UpdateSupplier show={showUpdate} onHide={() => setShowUpdate(false)} data={dataForSet} />
      <SupplierReport show={showReport} onHide={() => setShowReport(false)} />
    </>
  );
}