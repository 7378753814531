import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { network } from "./network";

export const hooks = {
  useGetPaiementClient(id: string) {
    const reponse = useQuery(['paiementClient', id], () => network.getPaiementClient(id))

    return reponse
  },
  useGetAllPaiement() {
    const reponse = useQuery(['paiements'], network.getAllPaiement);

    return reponse;
  },
  useCreatePaiement() {
    const queryClient = useQueryClient()
    const reponse = useMutation(network.addPaiement, {
      onSuccess: () => {
        queryClient.invalidateQueries(['paiements'])
      }
    });

    return reponse;
  },
  useUpdatePaiement() {
    const queryClient = useQueryClient()
    const reponse = useMutation(network.modifierPaiement, {
      onSuccess: (variables, data) => {
        queryClient.setQueryData(['paiementClient', { id: variables.id }], data)
      }
    })

    return reponse;
  },
  useGetPayementPaginate(page: number) {
    const reponse = useQuery(['paiementPaginate', page], () => network.getPaiementPaginate(page), { keepPreviousData: true });

    return reponse;
  },
  usePrefectPayement(page: number) {
    const queryClient = useQueryClient();

    return queryClient.prefetchQuery(['paiementPaginate', page], () => network.getPaiementPaginate(page))
  },
  useGetPayementClientPaginate(donne: { page: number, idClient: number }) {
    const reponse = useQuery(['paiementClient', donne], () => network.getPaiementClientPaginate(donne), { keepPreviousData: true });

    return reponse;
  },
  usePrefetchPaiementClient(donne: { page: number, idClient: number }) {
    const queryClient = useQueryClient();

    return queryClient.prefetchQuery(['paiementClient', donne], () => network.getPaiementClientPaginate(donne))
  },
  useDeletePaiement() {
    const queryClient = useQueryClient();

    const reponse = useMutation(network.deletePaiement, {
      onSuccess: () => {
        queryClient.invalidateQueries(['paiementPaginate'])
      }
    })

    return reponse;
  },
}