import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { IssueInventory } from "../../Modal";

export default function Issueables() {
  const [showIssue, setShowIssue] = useState(false);
  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "This item will be deleted permanently.",
      confirmButtonText: "Yes, delete!",
      cancelButtonText: `Cancel`,
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        ///When click Yes
      }
    });
  };
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">Problèmes</h3>
            <div className="nk-block-des text-soft">
              <p>
                Articles d'inventaire à distribuer à des véhicules spécifiques.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="nk-block">
        <div className="card card-stretch">
          <div className="card-inner">
            <table
              className="datatable-init nk-tb-list nk-tb-ulist"
              data-auto-responsive="false"
            >
              <thead>
                <tr className="nk-tb-item nk-tb-head">
                  <th className="nk-tb-col text-center">#</th>
                  <th className="nk-tb-col tb-col-md">
                    <span className="sub-text">Fournisseuse</span>
                  </th>
                  <th className="nk-tb-col">
                    <span className="sub-text">Véhicule</span>
                  </th>
                  <th className="nk-tb-col">
                    <span className="sub-text">Article</span>
                  </th>
                  <th className="nk-tb-col tb-col-md">
                    <span className="sub-text">Quantité</span>
                  </th>
                  <th className="nk-tb-col tb-col-md">
                    <span className="sub-text">Coût unitaire</span>
                  </th>
                  <th className="nk-tb-col tb-col-md">
                    <span className="sub-text">Statut</span>
                  </th>
                  <th className="nk-tb-col nk-tb-col-tools text-right"></th>
                </tr>
              </thead>
              <tbody>
                <tr className="nk-tb-item">
                  <td className="nk-tb-col text-center">1</td>
                  <td className="nk-tb-col tb-col-md">
                    <span className="tb-lead">Moshi SPares</span>

                    {/* <span>--|--</span> */}
                  </td>
                  <td className="nk-tb-col">
                    <span className="tb-lead">TATA • 123123</span>
                  </td>
                  <td className="nk-tb-col">
                    <span className="tb-lead">test</span>
                  </td>
                  <td className="nk-tb-col tb-col-mb">
                    <span className="tb-lead">1.00</span>
                  </td>
                  <td
                    className="nk-tb-col tb-col-mb"
                    data-order="{{ $item->unit_cost }}"
                  >
                    <span className="tb-amount">$0.00 </span>
                  </td>
                  <td className="nk-tb-col">
                    <span className="badge badge-sm badge-dot has-bg badge-success d-mb-inline-flex">
                      En stock
                    </span>

                    {/* <span className="badge badge-sm badge-dot has-bg badge-warning d-mb-inline-flex">Restock</span>
                                                           
                                                            <span className="badge badge-sm badge-dot has-bg badge-danger d-mb-inline-flex">En rupture de stock</span>
                                                            */}
                  </td>
                  <td className="nk-tb-col nk-tb-col-tools">
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <Dropdown>
                          <Dropdown.Toggle
                            as="a"
                            className="btn btn-icon btn-trigger"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <a
                                  className="fetch-display-click cursor"
                                  onClick={() => setShowIssue(true)}
                                >
                                  <em className="icon ni ni-check-circle-cut"></em>
                                  <span>Publier</span>
                                </a>
                              </li>

                              <li>
                                <Link
                                  to="/inventory/report/view"
                                  className="cursor"
                                >
                                  <em className="icon ni ni-reports"></em>
                                  <span>Voir le rapport</span>
                                </Link>
                              </li>

                              <li>
                                <a
                                  className="send-to-server-click cursor"
                                  onClick={handleDelete}
                                >
                                  <em className="icon ni ni-trash"></em>
                                  <span>Effacer l'article</span>
                                </a>
                              </li>
                            </ul>
                          </Dropdown.Menu>
                        </Dropdown>
                      </li>
                    </ul>
                  </td>
                </tr>
                {/* <tr>
                                                        <td className="text-center" colSpan={8}>C'est vide ici !</td>
                                                    </tr> */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <IssueInventory show={showIssue} onHide={() => setShowIssue(false)} />
    </>
  );
}
