import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";

export default function Recipients() {
  const [showPageMenu, setShowPageMenu] = useState(false);

  let [searchParams] = useSearchParams();
  let navigate = useNavigate();
  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "This expense will be deleted permanently.",
      confirmButtonText: "Yes, delete!",
      cancelButtonText: `Cancel`,
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        ///When click Yes
      }
    });
  };
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">
              {searchParams.get("campaign")
                ? "Campaign recipients"
                : "SMS History"}
            </h3>
            <div className="nk-block-des text-soft">
              <p>
                {searchParams.get("campaign")
                  ? "Campaigns messages sent."
                  : "A list of SMS sent."}
              </p>
            </div>
          </div>
          <div className="nk-block-head-content">
            <div className="toggle-wrap nk-block-tools-toggle">
              <a
                onClick={() => setShowPageMenu(!showPageMenu)}
                className="btn btn-icon btn-trigger toggle-expand mr-n1"
                data-target="pageMenu"
              >
                <em className="icon ni ni-menu-alt-r" />
              </a>
              <div
                className={`toggle-expand-content ${showPageMenu && "d-block"}`}
              >
                <ul className="nk-block-tools g-3">
                  <li>
                    <a
                      onClick={() => navigate(-1)}
                      className="btn btn-outline-light bg-white d-sm-inline-flex"
                    >
                      <em className="icon ni ni-arrow-left" />
                      <span>Back</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="nk-block">
        <div className="card card-stretch">
          <div className="card-inner">
            <table className="datatable-init nk-tb-list nk-tb-ulist">
              <thead>
                <tr className="nk-tb-item nk-tb-head">
                  <th className="nk-tb-col text-center">#</th>
                  <th className="nk-tb-col">
                    <span className="sub-text">Sent To</span>
                  </th>
                  <th className="nk-tb-col tb-col-md">
                    <span className="sub-text">Message</span>
                  </th>
                  <th className="nk-tb-col tb-col-md">
                    <span className="sub-text">Status</span>
                  </th>
                  <th className="nk-tb-col nk-tb-col-tools text-right"></th>
                </tr>
              </thead>
              <tbody>
                <tr className="nk-tb-item">
                  <td className="nk-tb-col text-center">1</td>
                  <td className="nk-tb-col">
                    <div className="user-card">
                      <div className="user-info">
                        <span className="tb-lead">
                          Riverdalle Tower +254720783834
                        </span>
                        <small className="text-muted">
                          September 14, 2020 17:13
                        </small>
                      </div>
                    </div>
                  </td>
                  <td className="nk-tb-col tb-col-md">
                    <span className="tb-amount">
                      test
                      <br />
                      <br />
                      Dotted Craft Limited
                    </span>
                  </td>
                  <td className="nk-tb-col tb-col-md">
                    <span className="badge badge-sm badge-dot has-bg badge-success d-mb-inline-flex">
                      Sent
                    </span>
                    {/* <span className="badge badge-sm badge-dot has-bg badge-primary d-mb-inline-flex">
                      Queued
                    </span>
                    <span className="badge badge-sm badge-dot has-bg badge-danger d-mb-inline-flex">
                      Failed
                    </span> */}
                  </td>
                  <td className="nk-tb-col nk-tb-col-tools">
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <Dropdown>
                          <Dropdown.Toggle
                            className="btn btn-icon btn-trigger"
                            as="a"
                          >
                            <em className="icon ni ni-more-h" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <a
                                  className="send-to-server-click cursor"
                                  onClick={handleDelete}
                                >
                                  <em className="icon ni ni-trash" />
                                  <span>Delete Message</span>
                                </a>
                              </li>
                            </ul>
                          </Dropdown.Menu>
                        </Dropdown>
                      </li>
                    </ul>
                  </td>
                </tr>
                {/* <tr>
                  <td className="text-center" colSpan={7}>
                    It's empty here!
                  </td>
                </tr> */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
