import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { UpdateExpenses } from "../../../Modal";

export default function Expected() {
  const [showUpdate, setShowUpdate] = useState(false);
  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "This expense will be deleted permanently.",
      confirmButtonText: "Yes, delete!",
      cancelButtonText: `Cancel`,
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: "#fff",
    }).then((result) => {
      if (result.isConfirmed) {
        ///When click Yes
      }
    });
  };

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">Pièces attendues</h3>
            <div className="nk-block-des text-soft">
              <p>
                Liste des services et des pièces qui n'ont pas encore été
                livrés.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="nk-block">
        <div className="card card-stretch">
          <div className="card-inner">
            <table
              className="datatable-init nk-tb-list nk-tb-ulist mt"
              data-auto-responsive="false"
            >
              <thead>
                <tr className="nk-tb-item nk-tb-head">
                  <th className="nk-tb-col text-center">#</th>
                  <th className="nk-tb-col tb-col-md">
                    <span className="sub-text">Projet</span>
                  </th>
                  <th className="nk-tb-col">
                    <span className="sub-text">Dépense / Fournisseur</span>
                  </th>
                  <th className="nk-tb-col tb-col-md">
                    <span className="sub-text">
                      Commande / Date de livraison
                    </span>
                  </th>
                  <th className="nk-tb-col tb-col-md">
                    <span className="sub-text">Montante</span>
                  </th>
                  <th className="nk-tb-col">
                    <span className="sub-text">Statut</span>
                  </th>
                  <th className="nk-tb-col">
                    <span className="sub-text">Paiement</span>
                  </th>
                  <th className="nk-tb-col nk-tb-col-tools text-right"></th>
                </tr>
              </thead>
              <tbody>
                <tr className="nk-tb-item">
                  <td className="nk-tb-col text-center">1</td>
                  <td className="nk-tb-col tb-col-md">
                    <div className="user-card">
                      <div className="user-info">
                        <span className="tb-lead">TATA PRIMA</span>
                        <span>23424</span>
                      </div>
                    </div>
                  </td>
                  <td className="nk-tb-col">
                    <span className="tb-amount">123</span>
                    {/* @if (!empty($expense->supplier))
                                                                <span>{{ $expense->supplier->name }}</span>
                                                                @endif */}
                  </td>
                  <td className="nk-tb-col tb-col-md">
                    <span>May 24, 22</span>
                  </td>
                  <td className="nk-tb-col tb-col-md">
                    <span className="tb-amount">$223</span>
                  </td>
                  <td className="nk-tb-col tb-col-md">
                    <span className="badge badge-sm badge-dot has-bg badge-success d-mb-inline-flex">
                      Livré
                    </span>

                    {/* <span className="badge badge-sm badge-dot has-bg badge-secondary d-mb-inline-flex">Commandé</span>
                                                                
                                                                <span className="badge badge-sm badge-dot has-bg badge-danger d-mb-inline-flex">Commander</span>
                                                                
                                                                <span className="badge badge-sm badge-dot has-bg badge-warning d-mb-inline-flex">En attente de livraison</span>
                                                                 */}
                  </td>
                  <td className="nk-tb-col tb-col-md">
                    <span className="text-success fw-bold">Payé</span>
                    {/*                                                                 
                                                                <span className="text-danger fw-bold">Non payé</span>
                                                                */}
                  </td>
                  <td className="nk-tb-col nk-tb-col-tools">
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <Dropdown>
                          <Dropdown.Toggle
                            as="a"
                            className="btn btn-icon btn-trigger"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <a
                                  className="fetch-display-click cursor"
                                  onClick={() => setShowUpdate(true)}
                                >
                                  <em className="icon ni ni-pen"></em>
                                  <span>Modifier la dépense</span>
                                </a>
                              </li>
                              <li>
                                <Link to="/projects/details">
                                  <em className="icon ni ni-eye"></em>
                                  <span>Voir le projet</span>
                                </Link>
                              </li>

                              <li className="divider"></li>
                              <li>
                                <a
                                  className="send-to-server-click cursor"
                                  onClick={handleDelete}
                                >
                                  <em className="icon ni ni-trash"></em>
                                  <span>Supprimer la dépense</span>
                                </a>
                              </li>
                            </ul>
                          </Dropdown.Menu>
                        </Dropdown>
                      </li>
                    </ul>
                  </td>
                </tr>

                {/* <tr>
                  <td className="text-center" colSpan={8}>
                    C'est vide ici !
                  </td>
                </tr> */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <UpdateExpenses show={showUpdate} onHide={() => setShowUpdate(false)} />
    </>
  );
}
