import React, { useEffect, useRef, useState } from "react";
import { NavLink as Link, useLocation } from "react-router-dom";
import logoDark from "../../../assets/images/logo_site.jpeg";
import AssignmentIcon from '@mui/icons-material/Assignment';
import TodayIcon from '@mui/icons-material/Today';

const user = JSON.parse(localStorage.getItem('AUTH_USER') ?? "{}");

export default function SideBar({ showMobile, setShowSideBarMb }: any) {
  const [currentMenu, setCurrentMenu] = useState("overview");
  const { pathname } = useLocation();
  const divFormRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (showMobile) {
      setShowSideBarMb(false);
    }
  }, [pathname]);
  const handleClickOutside = (event: any) => {
    if (divFormRef.current && !divFormRef.current.contains(event.target)) {
      setShowSideBarMb(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  return (
    <>
      <div
        ref={divFormRef}
        className={`nk-sidebar nk-sidebar-fixed is-light ${showMobile && "nk-sidebar-active"
          }`}
      >
        <div className="nk-sidebar-element nk-sidebar-head">
          <div className="nk-sidebar-brand">
            <Link to="/" className="logo-link nk-sidebar-logo">
              <img
                className="logo-dark logo-img"
                src={logoDark}
                alt="logo-dark"
                srcSet={`${logoDark} 2x`}
              />
            </Link>
          </div>
          <div className="nk-menu-trigger mr-n2">
            <a
              onClick={() => setShowSideBarMb(false)}
              className="nk-nav-toggle nk-quick-nav-icon d-xl-none"
              data-target="sidebarMenu"
            >
              <em className="icon ni ni-arrow-left"></em>
            </a>
          </div>
        </div>
        {/* .nk-sidebar-elemen */}
        <div className="nk-sidebar-element">
          <div className="nk-sidebar-content">
            <div className="nk-sidebar-menu">
              <ul className="nk-menu">
                {
                  user.membres.role === 'Admin' ? (<>
                  <li className="nk-menu-heading">
                    <h6 className="overline-title text-primary-alt">Aperçu</h6>
                  </li>
                  <li
                    className={`nk-menu-item ${currentMenu === "overview" && "active"
                      }`}
                  >
                    <Link
                      to="/"
                      className={`nk-menu-link overview`}
                      onClick={() => setCurrentMenu("overview")}
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-activity-round-fill"></em>
                      </span>
                      <span className="nk-menu-text">Aperçu</span>
                    </Link>
                  </li>
                  </>) : (<></>)
                }
                <li className="nk-menu-heading">
                  <h6 className="overline-title text-primary-alt">Mon espace</h6>
                </li>
                {
                  user.membres.role === 'Admin' ? (
                    <li
                      className={`nk-menu-item ${currentMenu === "planningAdmin" && "active"
                        }`}
                    >
                      <Link
                        to="/planning-admin"
                        className={`nk-menu-link overview`}
                        onClick={() => setCurrentMenu("planningAdmin")}
                      >
                        <span className="nk-menu-icon">
                          <TodayIcon />
                        </span>
                        <span className="nk-menu-text">Planning</span>
                      </Link>
                    </li>
                  ) : (
                    <li
                      className={`nk-menu-item ${currentMenu === "planning" && "active"
                        }`}
                    >
                      <Link
                        to="/planning"
                        className={`nk-menu-link overview`}
                        onClick={() => setCurrentMenu("planning")}
                      >
                        <span className="nk-menu-icon">
                          <TodayIcon />
                        </span>
                        <span className="nk-menu-text">Planning</span>
                      </Link>
                    </li>
                  )
                }
                {
                  user.membres.role === 'Admin' ? (
                    <li
                      className={`nk-menu-item ${currentMenu === "worksAdmin" && "active"
                        }`}
                    >
                      <Link
                        to="/works-admin"
                        className={`nk-menu-link overview`}
                        onClick={() => setCurrentMenu("worksAdmin")}
                      >
                        <span className="nk-menu-icon">
                          <AssignmentIcon />
                        </span>
                        <span className="nk-menu-text">Travaux a faire</span>
                      </Link>
                    </li>
                  ) : (
                    <li
                      className={`nk-menu-item ${currentMenu === "works" && "active"
                        }`}
                    >
                      <Link
                        to="/works"
                        className={`nk-menu-link overview`}
                        onClick={() => setCurrentMenu("works")}
                      >
                        <span className="nk-menu-icon">
                          <AssignmentIcon />
                        </span>
                        <span className="nk-menu-text">Travaux a faire</span>
                      </Link>
                    </li>
                  )
                }
                <li className="nk-menu-heading">
                  <h6 className="overline-title text-primary-alt">
                    Application
                  </h6>
                </li>
                {
                  user.membres.role === 'Admin' ? (<>
                    <li
                      className={`nk-menu-item ${currentMenu === "clients" && "active"
                        }`}
                    >
                      <Link
                        to="/clients"
                        className="nk-menu-link"
                        onClick={() => setCurrentMenu("clients")}
                      >
                        <span className="nk-menu-icon">
                          <em className="icon ni ni-users-fill"></em>
                        </span>
                        <span className="nk-menu-text">Clients</span>
                      </Link>
                    </li>
                  </>) : (<></>)
                }

                <li
                  className={`nk-menu-item has-sub ${currentMenu == "project" && "active"
                    }`}
                >
                  <a
                    className="nk-menu-link nk-menu-toggle cursor"
                    onClick={() =>
                      setCurrentMenu(currentMenu == "project" ? "" : "project")
                    }
                  >
                    <span className="nk-menu-icon">
                      <em className="icon ni ni-note-add-fill"></em>
                    </span>
                    <span className="nk-menu-text">Projets</span>

                    <span className="nk-menu-badge badge-danger">0</span>
                  </a>
                  <ul className="nk-menu-sub">
                    <li className="nk-menu-item">
                      <Link to="/projects" className="nk-menu-link">
                        <span className="nk-menu-text">
                          Projets / Véhicules
                        </span>
                      </Link>
                    </li>

                    {/* <li className="nk-menu-item">
                      <Link to="/taches" className="nk-menu-link">
                        <span className="nk-menu-text">Toutes les travaux</span>
                        <span className="nk-menu-badge badge-danger">2</span>
                      </Link>
                    </li>
                    <li className="nk-menu-item">
                      <Link to="/parts/expected" className="nk-menu-link">
                        <span className="nk-menu-text">Pièces attendues</span>
                        <span className="nk-menu-badge badge-danger">1</span>
                      </Link>
                    </li>
                    <li className="nk-menu-item">
                      <Link to="/parts/unpaid" className="nk-menu-link">
                        <span className="nk-menu-text">Pièces non payées</span>
                        <span className="nk-menu-badge badge-danger">2</span>
                      </Link>
                    </li> */}
                  </ul>
                </li>

                <li
                  className={`nk-menu-item has-sub ${currentMenu == "accounting" && "active"
                    }`}
                >
                  <a
                    className="nk-menu-link nk-menu-toggle cursor"
                    onClick={() =>
                      setCurrentMenu(
                        currentMenu == "accounting" ? "" : "accounting"
                      )
                    }
                  >
                    <span className="nk-menu-icon">
                      <em className="icon ni ni-sign-bgp-alt"></em>
                    </span>
                    <span className="nk-menu-text">Comptabilité</span>
                  </a>
                  <ul className="nk-menu-sub">
                    {/* <li className="nk-menu-item">
                      <Link to="/quotes" className="nk-menu-link">
                        <span className="nk-menu-text">Devis</span>
                      </Link>
                    </li> */}
                    <li className="nk-menu-item">
                      <Link to="/invoices" className="nk-menu-link">
                        <span className="nk-menu-text">Factures</span>
                      </Link>
                    </li>
                    {/* <li className="nk-menu-item">
                      <Link to="/project/payments" className="nk-menu-link">
                        <span className="nk-menu-text">Paiements</span>
                      </Link>
                    </li> */}
                  </ul>
                </li>

                {/* <li
                  className={`nk-menu-item has-sub ${currentMenu == "inventory" && "active"
                    }`}
                >
                  <a
                    className="cursor nk-menu-link nk-menu-toggle"
                    onClick={() =>
                      setCurrentMenu(
                        currentMenu == "inventory" ? "" : "inventory"
                      )
                    }
                  >
                    <span className="nk-menu-icon">
                      <em className="icon ni ni-clipboad-check-fill"></em>
                    </span>
                    <span className="nk-menu-text">Inventaire</span>

                    <span className="nk-menu-badge badge-danger">0</span>
                  </a>
                  <ul className="nk-menu-sub">
                    <li className="nk-menu-item">
                      <Link to="/inventory" className="nk-menu-link">
                        <span className="nk-menu-text">Liste d'inventaire</span>
                      </Link>
                    </li>

                    <li className="nk-menu-item">
                      <Link to="/receiveables" className="nk-menu-link">
                        <span className="nk-menu-text">Créances</span>
                        <span className="nk-menu-badge badge-danger">0</span>
                      </Link>
                    </li>
                    <li className="nk-menu-item">
                      <Link to="/issueables" className="nk-menu-link">
                        <span className="nk-menu-text">Problèmes</span>
                        <span className="nk-menu-badge badge-danger">1</span>
                      </Link>
                    </li>

                    <li className="nk-menu-item">
                      <Link to="/suppliers" className="nk-menu-link">
                        <span className="nk-menu-text">Fournisseurs</span>
                      </Link>
                    </li>
                  </ul>
                </li>

                {
                  user.membres.role === 'Admin' ? (
                    <>
                      <li
                        className={`nk-menu-item ${currentMenu === "marketing" && "active"
                          }`}
                      >
                        <Link
                          to="/marketing"
                          className="nk-menu-link"
                          onClick={() => setCurrentMenu("marketing")}
                        >
                          <span className="nk-menu-icon">
                            <em className="icon ni ni-chat-circle-fill"></em>
                          </span>
                          <span className="nk-menu-text">Commercialisation</span>
                        </Link>
                      </li>
                    </>
                  ) : (<></>)
                } */}
                <li className="nk-menu-heading">
                  <h6 className="overline-title text-primary-alt">
                    La gestion
                  </h6>
                </li>

                {
                  user.membres.role === "Admin" ? (
                    <>
                      <li
                        className={`nk-menu-item has-sub ${currentMenu === "teamsMembers" && "active"
                          }`}
                      >
                        <a
                          className="nk-menu-link nk-menu-toggle cursor"
                          onClick={() =>
                            setCurrentMenu(
                              currentMenu == "teamsMembers" ? "" : "teamsMembers"
                            )
                          }
                        >
                          <span className="nk-menu-icon">
                            <em className="icon ni ni-user-list-fill"></em>
                          </span>
                          <span className="nk-menu-text">Membres de l'equipe</span>
                        </a>
                        <ul className="nk-menu-sub">
                          <li className="nk-menu-item">
                            <Link to="/admin" className="nk-menu-link">
                              <span className="nk-menu-text">Administrateur</span>
                            </Link>
                            <Link to="/technicien" className="nk-menu-link">
                              <span className="nk-menu-text">Technicien</span>
                            </Link>
                          </li>
                        </ul>
                      </li>

                      {/* <li
                        className={`nk-menu-item ${currentMenu === "billing" && "active"
                          }`}
                      >
                        <Link
                          to="/billing/payments"
                          className="nk-menu-link"
                          onClick={() => setCurrentMenu("billing")}
                        >
                          <span className="nk-menu-icon">
                            <em className="icon ni ni-sign-cc-alt2"></em>
                          </span>
                          <span className="nk-menu-text">Facturation</span>
                        </Link>
                      </li> */}

                      <li
                        className={`nk-menu-item ${currentMenu === "task-groups" && "active"
                          }`}
                      >
                        <Link
                          to="/task-groups"
                          className="nk-menu-link"
                          onClick={() => setCurrentMenu("task-groups")}
                        >
                          <span className="nk-menu-icon">
                            <em className="icon ni ni-sign-cc-alt2"></em>
                          </span>
                          <span className="nk-menu-text">Groupe de tâches</span>
                        </Link>
                      </li>

                      <li
                        className={`nk-menu-item ${currentMenu === "task-items" && "active"
                          }`}
                      >
                        <Link
                          to="/task-items"
                          className="nk-menu-link"
                          onClick={() => setCurrentMenu("task-items")}
                        >
                          <span className="nk-menu-icon">
                            <em className="icon ni ni-sign-cc-alt2"></em>
                          </span>
                          <span className="nk-menu-text">Tâches</span>
                        </Link>
                      </li>
                    </>
                  ):(<></>)
                }

                {/* <li
                  className={`nk-menu-item ${currentMenu === "settings" && "active"
                    }`}
                >
                  <Link
                    to="/settings"
                    className="nk-menu-link"
                    onClick={() => setCurrentMenu("settings")}
                  >
                    <span className="nk-menu-icon">
                      <em className="icon ni ni-setting-fill"></em>
                    </span>
                    <span className="nk-menu-text">Réglages</span>
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {showMobile && <div className="nk-sidebar-overlay"></div>}
    </>
  );
}
