import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import Swal from "sweetalert2";
import { AddPayment, UpdatePayment } from "../../../Modal";
import { Pagination } from "react-bootstrap";

type Props = {
  data: {
    list: [],
    countPage: number,
  }
  nextPage: Function
  prevPage: Function
  goToPage: Function
}

export default function TableauPaiementClient({ data, nextPage, prevPage, goToPage }: Props) {
  const [showAdd, setShowAdd] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [activePage, setActivePage] = useState<number>(1);
  const [paiementData, setPaiementData] = useState<{
    idPayement: number, montant: number, modePayement: string,
    datePaiement: string, note: string
  }>({
    idPayement: 0,
    montant: 0,
    modePayement: '',
    datePaiement: '',
    note: '',
  });

  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: `This payment will be deleted permanently.`,
      confirmButtonText: "Yes, delete!",
      cancelButtonText: `Cancel`,
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: "#fff",
    }).then((result) => {
      if (result.isConfirmed) {
        ///When click Yes
      }
    });
  };

  interface Paiement {
    idPayement: number,
    montant: number,
    satuts: boolean,
    modePayement: string,
    datePaiement: string,
    note: string,
    factures: {
      idFacture: number,
      datePaiement: string,
      projets: {
        vehicules: {
          detailVehicules: {
            model: string,
            marque: string
          }
        }
      }
    }
  }

  const goToNextPage = () => {
    if (activePage < data.countPage) {
      setActivePage(activePage + 1);
    } else {
      setActivePage(activePage)
    }
    nextPage();
  }

  const goToPrevPage = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1)
    } else {
      setActivePage(activePage)
    }
    prevPage()
  }

  const goToPageOne = (items: number) => {
    setActivePage(items)
    goToPage(items)
  }

  const addItemUpdate = (dataPayement: Paiement) => {
    setPaiementData(dataPayement)
    setShowUpdate(true);
  }
  return (
    <>

      <div className="d-flex flex-column align-items-end">
        <table className="datatable-init nk-tb-list nk-tb-ulist mt">
          <thead>
            <tr className="nk-tb-item nk-tb-head">
              <th className="nk-tb-col text-center">ID</th>
              <th className="nk-tb-col tb-col-md">
                <span className="sub-text">Modèle et marque de la voiture</span>
              </th>
              <th className="nk-tb-col tb-col-md">
                <span className="sub-text">Référence de la facture</span>
              </th>
              <th className="nk-tb-col tb-col-md">
                <span className="sub-text">Date de paiement</span>
              </th>
              <th className="nk-tb-col">
                <span className="sub-text">Valeur du paiement</span>
              </th>
              <th className="nk-tb-col tb-col-md">
                <span className="sub-text">Statut</span>
              </th>
              <th className="nk-tb-col nk-tb-col-tools text-right"></th>
            </tr>
          </thead>
          {data.list.map((item: Paiement) => (<tbody>
            <tr className="nk-tb-item">
              <td className="nk-tb-col text-center">{item.idPayement}</td>
              <td className="nk-tb-col tb-col-md">
                <div className="user-card">
                  <div className="user-info">
                    <span className="tb-lead">{item.factures.projets.vehicules.detailVehicules.marque}</span>
                    <span>{item.factures.projets.vehicules.detailVehicules.model}</span>
                  </div>
                </div>
              </td>
              <td className="nk-tb-col tb-col-md">
                <span className="tb-amount">Facture #{item.factures.idFacture}</span>
              </td>
              <td className="nk-tb-col tb-col-md">
                <span>{item.factures.datePaiement}</span>
              </td>
              <td className="nk-tb-col">
                <span className="tb-amount">{item.montant}</span>
              </td>
              <td className="nk-tb-col tb-col-md">
                <span className="badge badge-sm badge-dot has-bg badge-success d-none d-mb-inline-flex">
                  {item.satuts}
                </span>
              </td>
              <td className="nk-tb-col nk-tb-col-tools">
                <ul className="nk-tb-actions gx-1">
                  <li>
                    <Dropdown>
                      <Dropdown.Toggle
                        as="a"
                        className="btn btn-icon btn-trigger"
                      >
                        <em className="icon ni ni-more-h"></em>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu-right">
                        <ul className="link-list-opt no-bdr">
                          <li>
                            <a href="">
                              <em className="icon ni ni-eye" />
                              <span>Voir la facture</span>
                            </a>
                          </li>
                          <li>
                            <a
                              className="fetch-display-click cursor"
                              onClick={() => addItemUpdate(item)}
                            >
                              <em className="icon ni ni-pen" />
                              <span>Modifier le paiement</span>
                            </a>
                          </li>

                          <li className="divider" />
                          <li>
                            <a
                              className="send-to-server-click cursor"
                              onClick={handleDelete}
                            >
                              <em className="icon ni ni-trash" />
                              <span>Supprimer le paiement</span>
                            </a>
                          </li>
                        </ul>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                </ul>
              </td>
            </tr>

            {/* 
                      if empty
                      <tr>
                        <td className="text-center" colSpan={7}>
                          It's empty here!
                        </td>
                      </tr> */}
          </tbody>))}
        </table>
        <Pagination className="nk-block-end">
          <Pagination.First onClick={() => goToPageOne(1)} />
          <Pagination.Prev onClick={goToPrevPage} />
          {
            Array.from(
              { length: data.countPage }, (v, k) => k + 1
            ).map(items => <Pagination.Item key={items} active={items === activePage} onClick={() => goToPageOne(items)}>{items}</Pagination.Item>)
          }
          <Pagination.Next onClick={goToNextPage} />
          <Pagination.Last onClick={() => goToPageOne(data.countPage)} />
        </Pagination>

      </div>
      <AddPayment show={showAdd} onHide={() => setShowAdd(false)} />
      <UpdatePayment show={showUpdate} onHide={() => setShowUpdate(false)} data={paiementData} />
    </>
  );
}
