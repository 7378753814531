import {useState, useEffect, CSSProperties} from 'react'
import SchedulerView from '../../components/SchedulerView';
import { workStepHooks } from '../../network/work-step';
import { membreHooks } from '../../network';
import Loading from '../../components/Loading';
import { FilterList } from '@mui/icons-material';
import { taskItemHooks } from '../../network/task-item';
import { TreeDataNode, Tree, Button, Input } from 'antd';
import { appointments } from '../../components/SchedulerView/mocks';

function PlanningAdmin() {
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false)

  //Liste des membres checkés
  const [memberIds, setMemberIds] = useState<number[]>([]); 

  //Filre de la liste des membres dans le composant tree
  const [name, setName] = useState('')
  
  //Liste des workSteps
  const {data, isLoading} = workStepHooks.useGetAllWorkSteps(memberIds, [-1], ['tous']);

  //Liste des membres
  const { data: techniciens, isLoading: loadingTechniciens } = membreHooks.useGetMembres();

  //Affichage hebdomadaire par défaut pour que l'utilisateur ne se perde pas
  const [currentViewName, setCurrentViewName] = useState('work-week'); 

  //Style pour le dropDown
  const DropdownStle:CSSProperties = {
    position: 'absolute',
    backgroundColor: 'white',
    boxShadow: '3px 3px 0.3rem 0.2rem rgba(100, 100, 100, 0.2)',
    top: '100%',
    right: '0%',
    display: isFilterOpen? 'block': 'none',
    borderRadius: '0.8rem',
    padding: '1rem',
    zIndex: '1000',
    width: '300px',
    overflowY: 'auto'
  }
  
  
  //Liste des techniciens foramtée pour le composant 'Tree'
  const [listTechniciens, setListTechniciens] = useState<TreeDataNode[]>([{
    key: 'tous',
    title: 'Tous',
  }]) 
  //Liste des travaux à faire après filtration
  const [checkedID, setCheckedID] = useState <any>(['tous'])

  //Prepare la liste des techniciens dans l'arbre à checkbox
  useEffect(()=>{
    if (techniciens){
      const list = techniciens.data.map((technicien: { nom: string, idMembre: number, prenom: string }) => {
        return {key: technicien.idMembre.toString(), title: `${technicien.prenom} ${technicien.nom}`}
      })


  
      setListTechniciens([
        {
          title: 'Tous',
          key: 'tous',
          children: list,
        }
      ])
    }
  }, [techniciens]);

  //Filre les worksteps
  function filterWorkSteps () { 
    if (checkedID.includes('tous')){
      setMemberIds([-1])
    }
    else{
      const IDs = checkedID.map(id => Number(id));
      setMemberIds(IDs)
    }
  }

  //Adapte la liste quand le filtre est enclanché
  useEffect(()=>{
    filterWorkSteps();
  }, [checkedID])


  const loop = data =>
  data.map(item => {
    const titleLower = item.title.toLowerCase();
    const membreNameLower = name.toLowerCase();
    const index = titleLower.includes(membreNameLower);
    const highlightedTitle = item.title.split(name).join(`<span class="site-tree-search-value">${name}</span>`);
    const title = index ? (
      <span dangerouslySetInnerHTML={{ __html: highlightedTitle }} />
    ) : (
      <span>{item.title}</span>
    );
    if (item.children) {
      return { title, key: item.key, children: loop(item.children) };
    }
    return {
      title,
      key: item.key,
      className: index ? 'tree-node' : 'tree-node hidden',
    };
    });

  const { data: dataTask, isLoading: taskIsloading } = taskItemHooks.useGetAllTaskItems();

  if (isLoading || loadingTechniciens){
    return (<div className="loading__wrapper">
    <Loading />
  </div>)
  }
  
  return (
    <div className="row g-gs pt-3" onClick={(e)=>{
      e.stopPropagation()
      setIsFilterOpen(false)
    }}>
      {loadingTechniciens? <Loading /> :
        <div style={{
          position: 'relative', 
          marginLeft: 'auto',
          display: 'flex',
          justifyContent: 'flex-end'  
        }}>
          <Button id='dropdown' onClick={(e)=>{
            e.stopPropagation()
            setIsFilterOpen(!isFilterOpen)
          }} icon={<FilterList />}>
            Filtre par technicien
          </Button>
          <div style={DropdownStle} onClick={(e)=>{
            e.stopPropagation()
            setIsFilterOpen(true)
          }}>
            <Input.Search value={name} onChange={e =>{setName(e.target.value)}} placeholder='rechercher' />
            <Tree 
            checkable
            defaultCheckedKeys={['tous']}
            defaultExpandedKeys={['tous']}
            treeData={loop(listTechniciens)}
            onCheck={setCheckedID}
            height={400}
            style={{backgroundColor: 'white'}}
            />
          </div>
        </div>
        }
      <div className="col-md-12">
        <div className="card card-full overflow-hidden border-top border-primary">
          {isLoading || taskIsloading ? (<Loading></Loading>) : (
            <>
              <SchedulerView currentViewName={currentViewName} setCurrentViewName={setCurrentViewName} dataWorkstep={data?.data ?? []} dataTask={{data: dataTask?.data ?? []}} />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default PlanningAdmin