import api from "../../axios";
import * as _ from "lodash";
import type { projetEntityPaginationType, projetEntityType } from "../../types";

export const network = {
  async getAllProject(): Promise<{data: projetEntityType[]}> {
    const { data } = await api.withToken.get('/projet');

    return data;
  },
  async createProjet(project: Object): Promise<{data: projetEntityType}> {
    const { data } = await api.withToken.post('/projet', project)

    return data;
  },
  async getProjetById(idProjet: string): Promise<{data: projetEntityType}> {
    const { data } = await api.withToken.get('/projet/' + idProjet);

    return data;
  },
  async updateProjet(project: projetEntityType): Promise<{data: projetEntityType}> {
    const { data } = await api.withToken.put(`/projet/${project.idProjet}`, project)

    return data;
  },
  async getProjectPaginate(page: number): Promise<{data: projetEntityPaginationType
    hasMore?: boolean
  }> {
    const { data } = await api.withToken.get('/projet/page/10/' + page);

    return data;
  },
  async deleteProjet(donne: { id: number }): Promise<{data: projetEntityType}> {
    const { data } = await api.withToken.delete('/projet/' + donne.id);

    return data;
  },
  async uploadCarteGrise(document: File) {
    const formData = new FormData();
    formData.append("document", document);

    const { data } = await api.withToken.post('/projet-vehicule/scan-upload-carte-grise', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });

    return data;
  },
}