import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { network } from "./network";
import { statusType } from "../../types";

export const hooks = {
  useGetAllWorkSteps(memberIds: number[] = [-1], vehicleIds: number[] = [-1], status: statusType[] = ['tous'], date: Date[] | number [] = [-1], cb='') {
    const reponse = useQuery({
      queryKey: ['allWorkSteps', memberIds, vehicleIds, status, date, cb],
      queryFn: ()=> network.getAllWorkSteps(memberIds, vehicleIds, status, date),
      refetchInterval: 60000,
      refetchIntervalInBackground: true,
      keepPreviousData: true
    })

    return reponse;
  },

  useGetWorkStepByMember(idMember: number, status: statusType[] = ['tous'], cb='') {
    const reponse = useQuery({
      queryKey: ['WorkStepByMember', idMember, status, cb],
      queryFn: () => network.getWorkStepByMember(idMember, status),
      refetchIntervalInBackground: true,
      refetchInterval: 60000,
      keepPreviousData: true
    })


    return reponse;
  },

  useCreateWorkStep() {
    const queryClient = useQueryClient()
    const reponse = useMutation(network.createWorkStep, {
      onSuccess: () => {
        queryClient.invalidateQueries(['WorkStepPaginate'])
        queryClient.invalidateQueries(['WorkStepByMember'])
        queryClient.invalidateQueries(['allWorkSteps'])
      }
      
    });

    return reponse;
  },
  useGetWorkStepPaginated(page: number) {
    const reponse = useQuery(['WorkStepPaginate', page], () => network.getWorkStepPaginated(page), { keepPreviousData: true });

    return reponse;
  },
  usePrefetchWorkStep(page: number) {
    const queryClient = useQueryClient();

    return queryClient.prefetchQuery(['WorkStepPaginate', page], () => network.getWorkStepPaginated(page))
  },

  useUpdateWorkStep() {
    const queryClient = useQueryClient()
    const reponse = useMutation(network.updateWorkStep, {
      onSuccess: () => {
        queryClient.invalidateQueries(['WorkStepPaginate'])
        queryClient.invalidateQueries(['WorkStepByMember'])
        queryClient.invalidateQueries(['allWorkSteps'])
      }
    });
    return reponse;
  },

  useDeleteWorkStep() {
    const queryClient = useQueryClient();

    const reponse = useMutation(network.deleteWorkStep, {
      onSuccess: () => {
        queryClient.invalidateQueries(['WorkStepPaginate'])
        queryClient.invalidateQueries(['WorkStepByMember'])
        queryClient.invalidateQueries(['allWorkSteps'])
      }
    });

    return reponse;
  },

  useAddWorkStepImage(){
    const queryClient = useQueryClient()
    const reponse = useMutation(network.addWorkStepImage, {
      onSuccess: () => {
        queryClient.invalidateQueries(['WorkStepPaginate'])
        queryClient.invalidateQueries(['WorkStepByMember'])
        queryClient.invalidateQueries(['allWorkSteps'])
      }
    });
    return reponse;
  }
} 