import React from "react";
import { Modal } from "react-bootstrap";

export const UpdateInventory = ({ show, onHide }: any) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Gérer l'inventaire</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Mettre à jour l'élément</p>
        <div className="row gy-4">
          <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">Nom de l'article</label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Nom de l'article"
                  name="name"
                  required
                />
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label className="form-label">Quantité</label>
              <div className="form-control-wrap">
                <input
                  type="number"
                  className="form-control form-control-lg"
                  placeholder="Quantité"
                  data-parsley-pattern="^[0-9]\d*(\.\d+)?$"
                  name="quantity"
                  step="0.01"
                  min="0.00"
                  required
                />
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label className="form-label">Unité de quantité</label>
              <div className="form-control-wrap">
                <select
                  className="form-control form-control-lg"
                  name="quantity_unit"
                >
                  <option value="Units">Unités</option>
                  <option value="Litres">Litres</option>
                  <option value="Kilograms">Kilogrammes</option>
                  <option value="Pounds">Livres sterling</option>
                  <option value="Gallons">Gallons</option>
                  <option value="Meters">Mètres</option>
                  <option value="Pieces">Des morceaux</option>
                  <option value="Set">Set</option>
                </select>
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">
                Quantité de réapprovisionnement
              </label>
              <div className="form-control-wrap">
                <input
                  type="number"
                  className="form-control form-control-lg"
                  placeholder="Quantité de réapprovisionnement"
                  data-parsley-pattern="^[0-9]\d*(\.\d+)?$"
                  name="restock_quantity"
                  step="0.01"
                  min="0.00"
                />
              </div>
              <div className="form-note">
                L'article affichera le réapprovisionnement si la quantité
                d'articles est inférieure à ce niveau.
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label className="form-label">Coût unitaire</label>
              <div className="form-control-wrap">
                <div className="form-text-hint">
                  <span className="overline-title">USD</span>
                </div>
                <input
                  type="number"
                  className="form-control form-control-lg"
                  placeholder="Coût unitaire"
                  data-parsley-pattern="^[0-9]\d*(\.\d+)?$"
                  name="unit_cost"
                  value="0.00"
                  step="0.01"
                  min="0.00"
                  required
                />
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label className="form-label">Sélectionnez le fournisseur</label>
              <div className="form-control-wrap">
                <select
                  className="form-control form-control-lg select2"
                  name="supplier"
                >
                  <option value="">Sélectionnez le fournisseur</option>

                  <option value="{{ $supplier->id }}">ABC</option>
                  <option value="{{ $supplier->id }}">XYZ</option>
                </select>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label className="form-label">Code de l'article</label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Code de l'article"
                  name="shelf_number"
                />
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label className="form-label">Numéro d'étagère</label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Numéro d'étagère"
                  name="item_code"
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="bg-light">
        <button
          className="btn btn-white btn-dim btn-outline-light"
          type="button"
          onClick={onHide}
        >
          <em className="icon ni ni-cross-circle"></em>
          <span>Annuler</span>
        </button>
        <button className="btn btn-primary" type="submit">
          <em className="icon ni ni-check-circle-cut"></em>
          <span>Sauvegarder les modifications</span>
        </button>
      </Modal.Footer>
    </Modal>
  );
};
