import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import Swal from "sweetalert2";
import { AddPayment, UpdatePayment } from "../../Modal";

export default function Payments() {
  const [showAdd, setShowAdd] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "This paymentwill be deleted permanently.",
      confirmButtonText: "Yes, delete!",
      cancelButtonText: `Cancel`,
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: "#fff",
    }).then((result) => {
      if (result.isConfirmed) {
        ///When click Yes
      }
    });
  };
  return (
    <>
      <div className="card-inner">
        <div className="nk-block mb-2">
          <div className="nk-block-head">
            <a
              className="btn btn-primary pull-right"
              onClick={() => setShowAdd(true)}
            >
              <em className="icon ni ni-plus" />
              <span>Add Payment</span>
            </a>
            <h5 className="title">Project Payments</h5>
            <p>A list of payments for TATA PRIMA - 23424 project.</p>
          </div>
        </div>

        <table className="datatable-init nk-tb-list nk-tb-ulist mt">
          <thead>
            <tr className="nk-tb-item nk-tb-head">
              <th className="nk-tb-col text-center">#</th>
              <th className="nk-tb-col tb-col-md">
                <span className="sub-text">Project</span>
              </th>
              <th className="nk-tb-col tb-col-md">
                <span className="sub-text">Invoice</span>
              </th>
              <th className="nk-tb-col tb-col-md">
                <span className="sub-text">Payment Date</span>
              </th>
              <th className="nk-tb-col">
                <span className="sub-text">Amount</span>
              </th>
              <th className="nk-tb-col tb-col-md">
                <span className="sub-text">Status</span>
              </th>
              <th className="nk-tb-col nk-tb-col-tools text-right"></th>
            </tr>
          </thead>
          <tbody>
            <tr className="nk-tb-item">
              <td className="nk-tb-col text-center">1</td>
              <td className="nk-tb-col tb-col-md">
                <div className="user-card">
                  <div className="user-info">
                    <span className="tb-lead">TATA PRIMA</span>
                    <span>23424</span>
                  </div>
                </div>
              </td>
              <td className="nk-tb-col tb-col-md">
                <span className="tb-amount">Invoice #66</span>
              </td>
              <td className="nk-tb-col tb-col-md">
                <span>May 25, 2022</span>
              </td>
              <td className="nk-tb-col">
                <span className="tb-amount">$100.00</span>
                <span>Cash</span>
              </td>
              <td className="nk-tb-col tb-col-md">
                <span className="badge badge-sm badge-dot has-bg badge-success d-mb-inline-flex">
                  Paid
                </span>
              </td>
              <td className="nk-tb-col nk-tb-col-tools">
                <ul className="nk-tb-actions gx-1">
                  <li>
                    <Dropdown>
                      <Dropdown.Toggle
                        as="a"
                        className="btn btn-icon btn-trigger"
                      >
                        <em className="icon ni ni-more-h" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <ul className="link-list-opt no-bdr">
                          <li>
                            <a>
                              <em className="icon ni ni-eye" />
                              <span>View Invoice</span>
                            </a>
                          </li>
                          <li>
                            <a
                              className="fetch-display-click cursor"
                              onClick={() => setShowUpdate(true)}
                            >
                              <em className="icon ni ni-pen" />
                              <span>Edit Payment</span>
                            </a>
                          </li>
                          <li className="divider" />
                          <li>
                            <a
                              className="send-to-server-click cursor"
                              onClick={handleDelete}
                            >
                              <em className="icon ni ni-trash" />
                              <span>Delete Payment</span>
                            </a>
                          </li>
                        </ul>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                </ul>
              </td>
            </tr>
            {/* <tr>
                      <td className="text-center" colSpan={7}>
                        It's empty here!
                      </td>
                    </tr> */}
          </tbody>
        </table>
      </div>
      <AddPayment show={showAdd} onHide={() => setShowAdd(false)} />
      <UpdatePayment show={showUpdate} onHide={() => setShowUpdate(false)} />
    </>
  );
}
