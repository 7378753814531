import React, { useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import CanvasDraw from "react-canvas-draw";
import JsColor from "../../components/JsColor";
interface SignatureProps {
  show: boolean;
  onHide: () => void;
}

export function Signature({ show, onHide }: SignatureProps) {
  const [color, setColor] = useState("#0f13c6");
  const canvasRef = useRef<CanvasDraw | null>(null);
  const handleUndo = () => {
    if (!canvasRef.current) return;
    canvasRef.current.undo();
  };
  const handleClear = () => {
    if (!canvasRef.current) return;
    canvasRef.current.clear();
  };
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Client Signature</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Once the client has signed, any changes made on this invoice will
          erase the client signature and will require a new signature.
        </p>
        <div className="row gy-4">
          <div className="col-sm-12">
            <div className="form-group">
              <div className="draw-signature-holder  position-relative">
                <CanvasDraw
                  canvasWidth={320}
                  canvasHeight={150}
                  brushColor={color}
                  brushRadius={2}
                  lazyRadius={2}
                  ref={canvasRef}
                  hideInterface
                  hideGrid
                />
              </div>
              <div className="signature-tools text-center" id="controls">
                <div className="signature-tool-item with-picker">
                  <div>
                    <JsColor
                      value={color}
                      onChange={(selected: any) => setColor(selected)}
                    />
                    {/* <button className="jscolor { valueElement:null,borderRadius:'1px', borderColor:'#e6eaee',value:'1418FF',zIndex:'99999', onFineChange:'modules.color(this)'}" /> */}
                  </div>
                </div>

                <div
                  className="signature-tool-item"
                  id="undo"
                  onClick={handleUndo}
                >
                  <div className="tool-icon tool-undo" />
                </div>
                <div
                  className="signature-tool-item"
                  id="clear"
                  onClick={handleClear}
                >
                  <div className="tool-icon tool-erase" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className=" bg-light">
        <button
          className="btn btn-white btn-dim btn-outline-light"
          type="button"
          onClick={onHide}
        >
          <em className="icon ni ni-cross-circle"></em>
          <span>Cancel</span>
        </button>
        <button
          className="btn btn-primary sign-document"
          type="button"
          onClick={onHide}
        >
          <em className="icon ni ni-check-circle-cut"></em>
          <span>Sign Invoice</span>
        </button>
      </Modal.Footer>
    </Modal>
  );
}
