import React from "react";
import { Modal } from "react-bootstrap";
interface SendEmailProps {
  show: boolean;
  onHide: () => void;
}

export const SendEmail = ({ show, onHide }: SendEmailProps) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Send Via Email</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Send via email</p>
        <div className="row gy-4">
          <div className="col-sm-12 mt-0">
            <div className="form-group">
              <label className="form-label">Email Address</label>
              <div className="form-control-wrap">
                <input
                  type="email"
                  className="form-control form-control-lg"
                  placeholder="Email Address"
                  name="fullname"
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12 mt-0">
            <div className="form-group">
              <label className="form-label">Subject</label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  className="form-control form-control-lg phone-input"
                  placeholder="Subject"
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12 mt-0">
            <div className="form-group">
              <label className="form-label">Message</label>
              <div className="form-control-wrap">
                <textarea
                  className="form-control form-control-lg"
                  placeholder="Message"
                  name="Message"
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="bg-light">
        <button
          className="btn btn-white btn-dim btn-outline-light"
          type="button"
          onClick={onHide}
        >
          <em className="icon ni ni-cross-circle"></em>
          <span>Cancle</span>
        </button>
        <button className="btn btn-primary" type="submit">
          <em className="icon ni ni-check-circle-cut"></em>
          <span>Send Email</span>
        </button>
      </Modal.Footer>
    </Modal>
  );
};
