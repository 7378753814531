import React from "react";
import { Modal } from "react-bootstrap";

export const CreateCampaign = ({ show, onHide }: any) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Créer une campagne</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Créer une campagne</p>
        <div className="row gy-4">
          <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">Titre de la campagne</label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Titre de la campagne"
                  name="title"
                  required
                />
              </div>
              <div className="form-note">
                Cela ne sera pas montré aux clients.
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">Envoyer à</label>
              <div className="form-control-wrap ">
                <div className="form-control-select">
                  <select
                    className="form-control form-control-lg"
                    name="sendto"
                    required
                  >
                    <option value="">Sélectionner</option>
                    <option value="clients">Tous les clients</option>
                    <option value="selectedclients">
                      Clients sélectionnés
                    </option>
                    <option value="members">
                      Tous les membres de l'équipe
                    </option>
                    <option value="selectedmembers">
                      Membres de l'équipe sélectionnés
                    </option>
                    <option value="enternumber">
                      Entrer le numéro manuellement
                    </option>
                    <option value="filterbycar">
                      Clients filtrés par marque / modèle de voiture
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 campaign-sendto d-none" data-type="clients">
            <div className="form-group">
              <label className="form-label">Sélectionnez Clients</label>
              <div className="form-control-wrap ">
                <div className="form-control-select">
                  <select
                    className="form-control form-control-lg select2"
                    name="clients[]"
                    multiple
                  >
                    <option value="{{ $client->id }}">ABC</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 campaign-sendto d-none" data-type="members">
            <div className="form-group">
              <label className="form-label">
                Sélectionnez les membres de l'équipe
              </label>
              <div className="form-control-wrap ">
                <div className="form-control-select">
                  <select
                    className="form-control form-control-lg select2"
                    name="members[]"
                    multiple
                  >
                    <option value="{{ $member->id }}">Test test</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-sm-12 campaign-sendto  d-none"
            data-type="manually"
          >
            <div className="form-group">
              <label className="form-label">
                Entrer le numéro manuellement
              </label>
              <div className="form-control-wrap ">
                <div className="form-control-select">
                  <input
                    type="text"
                    className="form-control form-control-lg phone-input"
                    placeholder="Phone Number"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-sm-12 campaign-sendto d-none"
            data-type="filterbycar"
          >
            <div className="form-group">
              <label className="form-label">Faire, construire</label>
              <div className="form-control-wrap">
                <div className="form-control-select">
                  <select
                    className="form-control select2 project-make-select"
                    name="make"
                    required
                  >
                    <option value="">Sélectionnez Marque</option>

                    <option value="{{ $make->id }}">ABCD</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-sm-12 campaign-sendto d-none"
            data-type="filterbycar"
          >
            <div className="form-group">
              <label className="form-label">Modèle</label>
              <div className="form-control-wrap">
                <div className="form-control-select">
                  <select
                    className="form-control select2 project-model-select"
                    name="model"
                  ></select>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">Message</label>
              <div className="form-control-wrap">
                <textarea
                  className="form-control form-control-lg"
                  placeholder="Message"
                  rows={3}
                  name="message"
                  required
                ></textarea>
              </div>
              <div className="form-note">
                Nous inclurons le nom de votre entreprise{" "}
                <strong>Dotted Craft Limited</strong> à la fin de chaque
                message.
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="bg-light">
        <button
          className="btn btn-white btn-dim btn-outline-light"
          type="button"
          onClick={onHide}
        >
          <em className="icon ni ni-cross-circle"></em>
          <span>Annuler</span>
        </button>
        <button className="btn btn-primary" type="submit">
          <em className="icon ni ni-check-circle-cut"></em>
          <span>Envoyer la campagne</span>
        </button>
      </Modal.Footer>
    </Modal>
  );
};
