import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { membreHooks } from "../../network";
import Swal from 'sweetalert2'

export const UpdateTeamMember = ({ show, onHide, data }: any) => {
  const [nom, setNom] = useState<string>(data?.nom);
  const [prenom, setPrenom] = useState<string>(data?.prenom);
  const [phone, setPhone] = useState<string>(data?.telephone);
  const [email, setEmail] = useState<string>(data?.email);
  const [adress, setAdress] = useState<string>(data?.adresse);
  const [statut, setStatut] = useState<string>(data?.statut);
  const [taper, setTaper] = useState<string>(data?.taper);
  const [role, setRole] = useState<string>(data?.role);

  const { mutate } = membreHooks.useUpdateMembre();

  const handleChangeRole = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    setRole(e.target.value)
  }

  const handleTypeHoraire = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    setTaper(e.target.value);
  }

  const handleChangeStatut = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    setStatut(e.target.value)
  }

  const updateMember = () => {
    const membre = {
      email: email,
      prenom: prenom,
      nom: nom,
      telephone: phone,
      adresse: adress,
      statut: statut,
      role: role,
      taper: taper,
      id: data.idMembre
    }

    return mutate(membre, {
      onSuccess: async (data) => {
        onHide();
      },
      onError: (error) => {
        console.log(error, 'ito ary ilay izy')
        onHide();
        Swal.fire({
          icon: 'error',
          text: 'Erreur lors de la mise a jour des données  membre',
        });
      },
    })
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Modifier un membre</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Modifier un membre de l'équipe</p>
        <div className="row gy-4">
          <div className="col-sm-6">
            <div className="form-group">
              <label className="form-label">Prénom</label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Prénom"
                  name="fname"
                  required
                  value={prenom}
                  onChange={event => setPrenom(event.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label className="form-label">Nom de famille</label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Nom de famille"
                  name="lname"
                  required
                  value={nom}
                  onChange={event => setNom(event.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label className="form-label">Numéro de téléphone</label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  className="form-control form-control-lg phone-input"
                  placeholder="Numéro de téléphone"
                  required
                  value={phone}
                  onChange={event => setPhone(event.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label className="form-label">Adresse e-mail</label>
              <div className="form-control-wrap">
                <input
                  type="email"
                  className="form-control form-control-lg"
                  placeholder="Adresse e-mail"
                  name="email"
                  value={email}
                  onChange={event => setEmail(event.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">Rôle</label>
              <div className="form-control-wrap ">
                <div className="form-control-select">
                  <select className="form-control form-control-lg" name="role" onChange={handleChangeRole}>
                    <option value="personnelle">Personnelle</option>
                    <option value="admin">Gestionnaire</option>
                    <option value="resposableRes">
                      Responsable des réservations
                    </option>
                    <option value="gestionnaire">
                      Gestionnaire d'inventaire
                    </option>
                    <option value="proprietaire">Propriétaire</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label className="form-label">Taper</label>
              <div className="form-control-wrap">
                <div className="form-control-select">
                  <select className="form-control form-control-lg" name="type" onChange={handleTypeHoraire}>
                    <option value="pleinTemp">À plein temps</option>
                    <option value="partiel">À temps partiel</option>
                    <option value="sousTraitant">Sous-traitant</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label className="form-label">Statut</label>
              <div className="form-control-wrap">
                <div className="form-control-select">
                  <select
                    className="form-control form-control-lg"
                    name="status"
                    onChange={handleChangeStatut}
                  >
                    <option value="active">Active</option>
                    <option value="enConge">En congé</option>
                    <option value="indisponible">Indisponible</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">Adresse</label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Adresse"
                  name="address"
                  value={adress}
                  onChange={event => setAdress(event.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="bg-light">
        <button
          className="btn btn-white btn-dim btn-outline-light"
          type="button"
          onClick={onHide}
        >
          <em className="icon ni ni-cross-circle"></em>
          <span>Annuler</span>
        </button>
        <button className="btn btn-primary" type="submit" onClick={updateMember}>
          <em className="icon ni ni-check-circle-cut"></em>
          <span>Sauvegarder les modifications</span>
        </button>
      </Modal.Footer>
    </Modal>
  );
};
