import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { network } from "./network";
import { roleMembreType } from "../../types";

export const hooks = {
  useGetMembres() {
    const response = useQuery(['membres'], network.getMembres);

    return response;
  },
  useCreateMembre() {
    const queryClient = useQueryClient();
    const response = useMutation(network.ajoutMembre, {
      onSuccess: () => {
        queryClient.invalidateQueries(['membrePage'])
      }
    });

    return response;
  },
  useGetRole() {
    const reponse = useQuery(['roleMembres'], network.getRole);

    return reponse;
  },
  useGetMembreById(id: string) {
    const reponse = useQuery(['membre', id], () => network.getMembreById(id));

    return reponse;
  },
  useUpdateMembre() {
    const queryClient = useQueryClient();
    const reponse = useMutation(network.upateMembre, {
      onSuccess: (data, variables) => {
        queryClient.setQueryData(['membre', { id: variables.id }], data)
      }
    });

    return reponse;
  },
  useGetMembrePaginate(page: number, role?:roleMembreType) {
    const reponse = useQuery(['membrePage', page, role], () => network.getMembrePaginate(page, role), { keepPreviousData: true });

    return reponse;
  },
  usePrefetchMembre(page: number, role?: roleMembreType) {
    const queryClient = useQueryClient();

    return queryClient.prefetchQuery(['membrePage', page, role], () => network.getMembrePaginate(page, role));
  },

  useDeleteMembre() {
    const queryClient = useQueryClient();

    const reponse = useMutation(network.deleteMembre, {
      onSuccess: () => {
        queryClient.invalidateQueries(['membrePage'])
      },
    });

    return reponse;
  }
}