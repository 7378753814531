import api from "../../axios";

export const network = {
  async getInventaire() {
    const { data } = await api.withToken.get('/inventaire');

    return data;
  },
  async getInventairePaginate(page: number) {
    const { data } = await api.withToken.get('/inventaire/page/5/' + page);

    return data;
  },
  async addInventaire(donne: Object) {
    const { data } = await api.withToken.post('/inventaire', donne);

    return data;
  },
  async deleteInventaire(donne: { id: string }) {
    const { data } = await api.withToken.delete('/inventaire/' + donne.id);

    return data;
  }
}