import React, { useEffect, useState, useRef, useCallback } from "react";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import RenderPdf from "../../../components/RenderPdf";
import { SendEmail, UpdateJobCard } from "../../../Modal";

const URL_FILE = "/pdf/Job_Card_140.pdf";

export default function JobCardView() {
  const navigate = useNavigate();
  const [showSendEmail, setShowSendEmail] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: `This job card will be deleted permanently.`,
      confirmButtonText: "Yes, delete!",
      cancelButtonText: `Cancel`,
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: "#fff",
    }).then((result) => {
      if (result.isConfirmed) {
        ///When click Yes
      }
    });
  };
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">Job card #140</h3>
            <div className="nk-block-des text-soft">
              <p>You are viewing job card #140.</p>
            </div>
          </div>
          <div className="nk-block-head-content">
            <ul className="nk-block-tools g-3">
              <li>
                <a
                  className="btn btn-outline-light bg-white d-none d-sm-inline-flex go-back"
                  onClick={() => navigate(-1)}
                >
                  <em className="icon ni ni-arrow-left" />
                  <span>Back</span>
                </a>
                <a className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none go-back">
                  <em className="icon ni ni-arrow-left" />
                </a>
              </li>
              <li>
                <Dropdown>
                  <Dropdown.Toggle
                    className="btn btn-dim btn-outline-primary"
                    as="a"
                  >
                    <em className="icon ni ni-more-h" /> <span>More</span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <ul className="link-list-opt no-bdr">
                      <li>
                        <a
                          href={URL_FILE}
                          download="Job_Card_140.pdf"
                          className="cursor"
                        >
                          <em className="icon ni ni-download-cloud" />
                          <span>Download</span>
                        </a>
                      </li>
                      <li>
                        <a href={URL_FILE} target="_blank" className="cursor">
                          <em className="icon ni ni-printer" />
                          <span>Print</span>
                        </a>
                      </li>
                      <li>
                        <a
                          className="send-via-email cursor"
                          onClick={() => setShowSendEmail(true)}
                        >
                          <em className="icon ni ni-mail" />
                          <span>Send Via Email</span>
                        </a>
                      </li>
                      <li>
                        <a
                          className="fetch-display-click cursor"
                          onClick={() => setShowUpdate(true)}
                        >
                          <em className="icon ni ni-pen" />
                          <span>Edit Job Card</span>
                        </a>
                      </li>
                      <li className="divider" />
                      <li>
                        <a
                          className="send-to-server-click cursor"
                          onClick={handleDelete}
                        >
                          <em className="icon ni ni-trash" />
                          <span>Delete Job Card</span>
                        </a>
                      </li>
                    </ul>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
          </div>
        </div>
        {/* .nk-block-between */}
      </div>
      <div className="nk-block">
        <div className="card card-stretch">
          <div className="card-inner">
            <RenderPdf url={URL_FILE} />
          </div>
        </div>
      </div>
      <SendEmail show={showSendEmail} onHide={() => setShowSendEmail(false)} />
      <UpdateJobCard show={showUpdate} onHide={() => setShowUpdate(false)} />
    </>
  );
}
