import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import { taskGroupHooks } from "../../network/task-group";
import Loading from "../../components/Loading";
import { taskGroupEntityType } from "../../types";
interface UpdateTaskItemProps {
  show: boolean;
  onHide: () => void;
  taskGroupId: number;
  taskItems: any[];
  loadingTaskItem: any;
  maxOrder: number
}

export function UpdateTaskGroup({ show, onHide, taskGroupId, taskItems, loadingTaskItem, maxOrder }: UpdateTaskItemProps) {
  const { data } = taskGroupHooks.useGetTaskGroupById(taskGroupId.toString());
  const upsertItem = taskGroupHooks.useUpdateTaskGroup();

  const [taskGroup, setTaskGroup] = useState<taskGroupEntityType>({
    idTaskGroup: 0,
    taskGroupLabel: '',
    tasks: []
  });

  const [errorTitle, setErrorTitle] = useState<boolean>(false);

  const [classTitle, setClassTitle] = useState<string>('form-control form-control-lg');

  React.useEffect(() => {
    if (data?.data) {
      setTaskGroup(data?.data);
    }
  }, [data, taskItems])

  const checkEmptyTaskTitle = () => {
    if (taskGroup.taskGroupLabel.trim().length === 0) {
      setErrorTitle(true)
      setClassTitle('form-control form-control-lg is-invalid')
      return true
    } else {
      setErrorTitle(false)
      setClassTitle('form-control form-control-lg')
      return false
    }
  }

  const handleChangeTasktItem = (idTaskItem, isChecked) => {
    let tabTaskGroups = taskGroup?.tasks?.slice();
    if (tabTaskGroups){
      if(isChecked) {
        const ti = taskItems.find(t => t.idTask === parseInt(idTaskItem));
        tabTaskGroups = [...tabTaskGroups, ti];
      }
      if(!isChecked) {
        tabTaskGroups = tabTaskGroups.filter(t => t.idTask !== parseInt(idTaskItem));
      }
      setTaskGroup({...taskGroup, tasks: tabTaskGroups});
    }
  }

  const updateTaskItem = () => {
    const taskGroupData = { ...data?.data, ...taskGroup };

    if (checkEmptyTaskTitle()) {
      checkEmptyTaskTitle()
      Swal.fire({
        icon: 'error',
        text: 'Veuillez remplir tous les champs pour procéder à la création',
      });
      return;
    }

    return upsertItem.mutate(taskGroupData, {
      onSuccess: async (data) => {
        onHide()
        Swal.fire({
          icon: 'success',
          text: 'Mise à jour réussi',
        });
      },
      onError: (error) => {
        onHide()
        Swal.fire({
          icon: 'error',
          text: 'Erreur lors de la mise a jour',
        });
      },
      onSettled: async (data) => {
        console.log('tonga ato oooo')
      },
    })
  }
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Modifier le groupe de tâche</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row gy-4">
          <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">Le label du groupe</label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  className={classTitle}
                  placeholder="titre"
                  name="taskTitle"
                  required
                  onChange={e => setTaskGroup({ ...taskGroup, taskGroupLabel: e.target.value })}
                  onBlur={checkEmptyTaskTitle}
                  value={taskGroup.taskGroupLabel}
                />
                {errorTitle && <div className="text-danger">Valeur obligatoire</div>}
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">Ajouter une tache</label>
              <div className="form-control-wrap ">
                {loadingTaskItem ? <Loading /> :
                  <>
                    <div className="row">
                      {taskItems && taskItems?.map((item: { idTask: number, taskTitle: string, description: string }, index: number) => (

                        <div className="col-sm-4 part-item-holder m-1" >
                          <div className="custom-control custom-switch">
                            <input
                              type="checkbox"
                              name={item.taskTitle}
                              id={item.idTask.toString()}
                              className="custom-control-input"
                              value={item.idTask}
                              onChange={e => handleChangeTasktItem(e.target.value, e.target.checked)}
                              checked={taskGroup?.tasks?.find(t => t.idTask === item.idTask) ? true : false}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={item.idTask.toString()}
                            >
                              {item.taskTitle}
                            </label>
                          </div>
                          <div className="form-group">
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className=" bg-light">
        <button
          className="btn btn-white btn-dim btn-outline-light"
          type="button"
          onClick={onHide}
        >
          <em className="icon ni ni-cross-circle"></em>
          <span>Cancel</span>
        </button>
        <button className="btn btn-primary" type="button" onClick={updateTaskItem}>
          <em className="icon ni ni-check-circle-cut" />
          <span>Save Changes</span>
        </button>
      </Modal.Footer>
    </Modal >
  );
}
