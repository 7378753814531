import React, { useState } from "react";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import TableauVehiculeClient from "../../components/Tableau/TableauVehiculeClient";
import Loading from "../../components/Loading";
import { clientHooks } from "../../network";

export default function Vehicule() {
  
  const param = useParams();
  let id = param.id || '';
  const { data, isLoading } = clientHooks.useGetVehiculeClient(id)
  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: `This project will be deleted permanently.`,
      confirmButtonText: "Yes, delete!",
      cancelButtonText: `Cancel`,
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: "#fff",
    }).then((result) => {
      if (result.isConfirmed) {
        ///When click Yes
      }
    });
  };
  const handleCancel = () => {
    Swal.fire({
      title: "Are you sure?",
      text: `This project will be marked as cancelled.`,
      confirmButtonText: "Yes, Cancel!",
      cancelButtonText: `Cancel`,
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: "#fff",
    }).then((result) => {
      if (result.isConfirmed) {
        ///When click Yes
      }
    });
  };

  if (isLoading){
    return (<div className="loading__wrapper">
    <Loading />
  </div>)
  }

  return (
    <>
      <div className="card-inner">
        <div className="nk-block mb-2">
          <div className="nk-block-head">
            <h5 className="title title overline-title text-base">Véhicule du client</h5>
            <p>Liste des véhicules du client</p>
          </div>
          {/* .nk-block-head */}
        </div>
        {/* .nk-block */}
        {isLoading ? <Loading /> : (data && data.data ?  <TableauVehiculeClient data={data?.data}/> : null)}
      </div>
    </>
  );
}
