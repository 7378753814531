import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import RenderPdf from "../../../../components/RenderPdf";
import { SendEmail, SendSMS, SendWhatsapp, Signature } from "../../../../Modal";
import { UpdateProjects } from "../../../../Modal/UpdateProjects";

const URL_FILE = "/pdf/Project_Booking_314.pdf";

export default function ProjectBooking() {
  const navigate = useNavigate();
  const [showSendEmail, setShowSendEmail] = useState(false);
  const [showSendSMS, setShowSendSMS] = useState(false);
  const [showSendWhatsapp, setShowSendWhatsapp] = useState(false);
  const [showEditBooking, setShowEditBooking] = useState(false);
  const [showEntrySignature, setShowEntrySignature] = useState(false);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">
              Vehicle Booking: TATA PRIMA
            </h3>
            <div className="nk-block-des text-soft">
              <p>You are viewing invoice #314.</p>
            </div>
          </div>
          <div className="nk-block-head-content">
            <ul className="nk-block-tools g-3">
              <li>
                <a
                  className="btn btn-outline-light bg-white d-none d-sm-inline-flex go-back"
                  onClick={() => navigate(-1)}
                >
                  <em className="icon ni ni-arrow-left" />
                  <span>Back</span>
                </a>
                <a className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none go-back">
                  <em className="icon ni ni-arrow-left" />
                </a>
              </li>
              <li>
                <Dropdown>
                  <Dropdown.Toggle
                    className="btn btn-dim btn-outline-primary"
                    as="a"
                  >
                    <em className="icon ni ni-more-h" /> <span>More</span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <ul className="link-list-opt no-bdr">
                      <li>
                        <a
                          href={URL_FILE}
                          download="Project_Booking_314.pdf"
                          className="cursor"
                        >
                          <em className="icon ni ni-download-cloud" />
                          <span>Download</span>
                        </a>
                      </li>
                      <li>
                        <a href={URL_FILE} target="_blank" className="cursor">
                          <em className="icon ni ni-printer" />
                          <span>Print</span>
                        </a>
                      </li>
                      <li>
                        <a
                          className="cursor"
                          onClick={() => setShowSendEmail(true)}
                        >
                          <em className="icon ni ni-mail" />
                          <span>Send Via Email</span>
                        </a>
                      </li>
                      <li>
                        <a
                          className="cursor"
                          onClick={() => setShowSendSMS(true)}
                        >
                          <em className="icon ni ni-msg" />
                          <span>Send Via SMS</span>
                        </a>
                      </li>
                      <li>
                        <a
                          className="cursor"
                          onClick={() => setShowSendWhatsapp(true)}
                        >
                          <em className="icon ni ni-whatsapp" />
                          <span>Send Via Whatsapp</span>
                        </a>
                      </li>
                      <li>
                        <a
                          className="fetch-display-click cursor"
                          onClick={() => setShowEditBooking(true)}
                        >
                          <em className="icon ni ni-pen" />
                          <span>Edit Booking</span>
                        </a>
                      </li>
                      <li>
                        <a
                          className="cursor"
                          onClick={() => setShowEntrySignature(true)}
                        >
                          <em className="icon ni ni-edit" />
                          <span>Entry Signature</span>
                        </a>
                      </li>
                    </ul>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
          </div>
        </div>
        {/* .nk-block-between */}
      </div>
      <div className="nk-block">
        <div className="card card-stretch">
          <div className="card-inner">
            <RenderPdf url={URL_FILE} />
          </div>
        </div>
      </div>
      <SendEmail show={showSendEmail} onHide={() => setShowSendEmail(false)} />
      <SendSMS show={showSendSMS} onHide={() => setShowSendSMS(false)} />
      <SendWhatsapp
        show={showSendWhatsapp}
        onHide={() => setShowSendWhatsapp(false)}
      />
      <UpdateProjects
        show={showEditBooking}
        onHide={() => setShowEditBooking(false)}
      />
      <Signature
        show={showEntrySignature}
        onHide={() => setShowEntrySignature(false)}
      />
    </>
  );
}
