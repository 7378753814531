import { ReactNode, useMemo } from "react";
import { AuthContext } from "../context";
import { userHooks } from "../network";

interface Client {
  idUser: number,
  membres: {
    idMembre: number,
    email: string,
    prenom: string,
    nom: string,
    adresse: string
    telephone: string,
    role: string,
    statut: string,
    taper: string
  }
}

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const { data, isLoading: loadingClient } = userHooks.useGetMe();

  const client: Client = useMemo(() => {
    return data?.data?.user ?? {};
  }, [data]);

  return (
    <AuthContext.Provider value={{ client, loadingClient }}>
      {children}
    </AuthContext.Provider>
  );
};
export default AuthProvider;
