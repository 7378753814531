import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { DefaultLayout } from "./components/Layout";
import { publicRoutes, routeNoLayout } from "./routes";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import PrivateRoutes from "./components/PrivateRoutes";
import ProtectedRoutes from "./components/Layout/ProtectedRoutes";
import AuthProvider from "./provider/AuthProvider";
import moment from 'moment';

//import "../src/assets/js/bundle";
import { useEffect } from "react";
const queryClient = new QueryClient();
moment.locale('FR-fr')
function App() {
  useEffect(() => {
    document.title = 'Cartool';
  }, []);
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Router>
          <div className="nk-body bg-lighter npc-default has-sidebar no-touch nk-nio-theme">
            <Routes>
              {routeNoLayout.map((route, index) => {
                const PageNoLayout = route.component;

                return (
                  <Route
                    key={index} path='/' element={<ProtectedRoutes />}>
                    <Route
                      path={route.path}
                      element={
                        <PageNoLayout />
                      }
                    />
                  </Route>
                )
              })}
              {publicRoutes.map((route, index) => {
                const Page = route.component;

                return (
                  <Route
                    key={index} path='/' element={<PrivateRoutes />}>
                    <Route
                      path={route.path}
                      element={
                        <DefaultLayout>
                          <Page />
                        </DefaultLayout>
                      }
                    />
                  </Route>
                );
              })}
            </Routes>
          </div>
        </Router>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
