import React from "react";
import { Modal } from "react-bootstrap";
import { fournisseurHooks } from "../../network/fournisseur";
import Loading from "../../components/Loading";
import { inventaireHooks } from "../../network/inventaire";
import Swal from "sweetalert2";

export const CreateInventory = ({ show, onHide }: any) => {
  const [nomArticle, setNomArticle] = React.useState<string>('');
  const [classNomArticle, setClassNomArticle] = React.useState<string>('form-control form-control-lg');
  const [errorNomArticle, setErrorNomArticle] = React.useState<boolean>(false);
  const [quantite, setQuantite] = React.useState<number>(0);
  const [classQuantite, setClassQuantite] = React.useState<string>('form-control form-control-lg');
  const [errorQuantite, setErrorQuantite] = React.useState<boolean>(false);
  const [uniteQuantite, setUniteQuantite] = React.useState<string>('Unités');
  const [quantiteReap, setQuantiteReap] = React.useState<number>(0);
  const [classQuantiteReap, setClassQuantiteReap] = React.useState<string>('form-control form-control-lg');
  const [errorQuantiteReap, setErrorQuantiteReap] = React.useState<boolean>(false);
  const [coutUnitaire, setCoutUnitaire] = React.useState<number>(0);
  const [classCoutUnitare, setClassCoutUnitaire] = React.useState<string>('form-control form-control-lg');
  const [errorCoutUnitaire, setErrorCoutUnitaire] = React.useState<boolean>(false);
  const [fournisseur, setFournisseur] = React.useState<number>(0);
  const [codeArticle, setCodeArticle] = React.useState<string>('');
  const [classCodeArticle, setClassCodeArticle] = React.useState<string>('form-control form-control-lg');
  const [errorCodeArticle, setErrorCodeArticle] = React.useState<boolean>(false);
  const [numEtage, setNumEtage] = React.useState<string>('');
  const [classNumEtage, setClassNumEtage] = React.useState<string>('form-control form-control-lg');
  const [errorNumEtage, setErrorNumEtage] = React.useState<boolean>(false);

  const { data, isLoading } = fournisseurHooks.useGetFournisseurs();
  const { mutate } = inventaireHooks.useAddInventaire();

  const handleChangeUnite = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    setUniteQuantite(e.target.value);
  }

  const handleChangeFournisseur = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    setFournisseur(Number(e.target.value))
  }

  const checkNomArticle = () => {
    if (nomArticle.trim().length === 0) {
      setClassNomArticle('form-control form-control-lg is-invalid');
      setErrorNomArticle(true);
      return true;
    } else {
      setClassNomArticle('form-control form-control-lg');
      setErrorNomArticle(false);
      return false;
    }
  }

  const checkQuantite = () => {
    if (quantite === 0) {
      setClassQuantite('form-control form-control-lg is-invalid');
      setErrorQuantite(true);
      return true;
    } else {
      setClassQuantite('form-control form-control-lg');
      setErrorQuantite(false);
      return false;
    }
  }

  const checkQuantiteReap = () => {
    if (quantiteReap === 0) {
      setClassQuantiteReap('form-control form-control-lg is-invalid');
      setErrorQuantiteReap(true);
      return true
    } else {
      setClassQuantiteReap('form-control form-control-lg');
      setErrorQuantiteReap(false);
      return false
    }
  }

  const checkCoutUnitaire = () => {
    if (coutUnitaire === 0) {
      setClassCoutUnitaire('form-control form-control-lg is-invalid');
      setErrorCoutUnitaire(true);
      return true;
    } else {
      setClassCoutUnitaire('form-control form-control-lg');
      setErrorCoutUnitaire(false);
      return false;
    }
  }

  const checkCodeArticle = () => {
    if (codeArticle.trim().length === 0) {
      setClassCodeArticle('form-control form-control-lg is-invalid');
      setErrorCodeArticle(true);
      return true;
    } else {
      setClassCodeArticle('form-control form-control-lg');
      setErrorCodeArticle(false);
      return false;
    }
  }

  const checkNumEtage = () => {
    if (numEtage.trim().length === 0) {
      setClassNumEtage('form-control form-control-lg is-invalid');
      setErrorNumEtage(true);
      return true;
    } else {
      setClassNumEtage('form-control form-control-lg');
      setErrorNumEtage(false);
      return false;
    }
  }

  const addInventaire = () => {
    const inventaire = {
      nomArticle: nomArticle,
      quantite: quantite,
      quantiteReapro: quantiteReap,
      coutUnitaire: coutUnitaire,
      codeArticle: codeArticle,
      numeroEtagere: numEtage,
      uniteQt: uniteQuantite,
      fournisseurs: fournisseur
    }

    if (checkCodeArticle() || checkCoutUnitaire() || checkNomArticle() || checkNumEtage() || checkQuantite() || checkQuantiteReap()) {
      checkCodeArticle()
      checkCoutUnitaire()
      checkNomArticle()
      checkNumEtage()
      checkQuantite()
      checkQuantiteReap()
      Swal.fire({
        icon: 'error',
        text: 'Veuillez remplir tous les champs',
      });

      return
    }

    return mutate(inventaire, {
      onSuccess: async (data) => {
        onHide()
      },
      onError: (error) => {
        onHide()
        Swal.fire({
          icon: 'error',
          text: 'Erreur lors de l\'ajout d\' un nouveau inventaire',
        });
      },
    })
  }


  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Créer un élément</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Ajouter un article</p>
        <div className="row gy-4">
          <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">Nom de l'article</label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  className={classNomArticle}
                  placeholder="Nom de l'article"
                  name="name"
                  required
                  onChange={e => setNomArticle(e.target.value)}
                  onBlur={checkNomArticle}
                />
              </div>
              {errorNomArticle && <div className="text-danger">Valeur obligatoire</div>}
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label className="form-label">Quantité</label>
              <div className="form-control-wrap">
                <input
                  type="number"
                  className={classQuantite}
                  placeholder="Quantité"
                  data-parsley-pattern="^[0-9]\d*(\.\d+)?$"
                  name="quantity"
                  step="0.01"
                  min="0.00"
                  required
                  onChange={e => setQuantite(Number(e.target.value))}
                  onBlur={checkQuantite}
                />
              </div>
              {errorQuantite && <div className="text-danger">Valeur obligatoire</div>}
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label className="form-label">Unité de quantité</label>
              <div className="form-control-wrap">
                <select
                  className="form-control form-control-lg"
                  name="quantity_unit"
                  onChange={handleChangeUnite}
                >
                  <option value="Unités">Unités</option>
                  <option value="Litres">Litres</option>
                  <option value="Kilogrammes">Kilogrammes</option>
                  <option value="Livres sterling">Livres sterling</option>
                  <option value="Gallons">Gallons</option>
                  <option value="Mètres">Mètres</option>
                  <option value="Des morceaux">Des morceaux</option>
                  <option value="Set">Set</option>
                </select>
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">
                Quantité de réapprovisionnement
              </label>
              <div className="form-control-wrap">
                <input
                  type="number"
                  className={classQuantiteReap}
                  placeholder="Quantité de réapprovisionnement"
                  data-parsley-pattern="^[0-9]\d*(\.\d+)?$"
                  name="restock_quantity"
                  step="0.01"
                  min="0.00"
                  onChange={e => setQuantiteReap(Number(e.target.value))}
                  onBlur={checkQuantiteReap}
                />
              </div>
              {errorQuantiteReap && <div className="text-danger">Valeur obligatoire</div>}
              <div className="form-note">
                L'article affichera le réapprovisionnement si la quantité
                d'articles est inférieure à ce niveau.
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label className="form-label">Coût unitaire</label>
              <div className="form-control-wrap">
                <div className="form-text-hint">
                  <span className="overline-title">EUR</span>
                </div>
                <input
                  type="number"
                  className={classCoutUnitare}
                  placeholder="Coût unitaire"
                  data-parsley-pattern="^[0-9]\d*(\.\d+)?$"
                  name="unit_cost"
                  step="0.01"
                  min="0.00"
                  required
                  onChange={e => setCoutUnitaire(Number(e.target.value))}
                  onBlur={checkCoutUnitaire}
                />
              </div>
              {errorCoutUnitaire && <div className="text-danger">Valeur obligatoire</div>}
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label className="form-label">Sélectionnez le fournisseur</label>
              {isLoading ? <Loading /> : <div className="form-control-wrap">
                <select
                  className="form-control form-control-lg select2"
                  name="supplier"
                  onChange={handleChangeFournisseur}
                >
                  <option value="">Sélectionnez le fournisseur</option>
                  {data.data.map((item: { idFournisseur: number, nom: string }) => (<option value={item.idFournisseur}>{item.nom}</option>))}
                </select>
              </div>}
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label className="form-label">Code de l'article</label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  className={classCodeArticle}
                  placeholder="Code de l'article"
                  name="shelf_number"
                  onChange={e => setCodeArticle(e.target.value)}
                  onBlur={checkCodeArticle}
                />
              </div>
              {errorCodeArticle && <div className="text-danger">Valeur obligatoire</div>}
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label className="form-label">Numéro d'étagère</label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  className={classNumEtage}
                  placeholder="Numéro d'étagère"
                  name="item_code"
                  onChange={e => setNumEtage(e.target.value)}
                  onBlur={checkNumEtage}
                />
              </div>
              {errorNumEtage && <div className="text-danger">Valeur obligatoire</div>}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="bg-light">
        <button
          className="btn btn-white btn-dim btn-outline-light"
          type="button"
          onClick={onHide}
        >
          <em className="icon ni ni-cross-circle"></em>
          <span>Annuler</span>
        </button>
        <button className="btn btn-primary" onClick={addInventaire} >
          <em className="icon ni ni-check-circle-cut"></em>
          <span>Ajouter un item</span>
        </button>
      </Modal.Footer>
    </Modal>
  );
};
