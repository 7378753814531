import api from "../../axios";
import type{ clientEntityPaginationType, clientEntityType, vehiculeEntityType } from "../../types";

export const network = {
  async getClient(): Promise<{data: clientEntityType[]}> {
    const { data } = await api.withToken.get('/client');

    return data;
  },
  async getClientById(idClient: string): Promise<{data: clientEntityType}> {
    const { data } = await api.withToken.get('/client/' + idClient);

    return data;
  },
  async getVehiculeClient(idClient: string): Promise<{data: vehiculeEntityType[]}> {
    const { data } = await api.withToken.get('/client-vehicule/' + idClient)

    return data;
  },
  async getClientPaginate(page: number): Promise<{data: clientEntityPaginationType
    hasMore?: boolean
  }> {
    const { data } = await api.withToken.get('/client/page/5/' + page)

    return data;
  },
  async deleteClient(donne: { id: number }): Promise<{data: clientEntityType}> {
    const { data } = await api.withToken.delete('/client/' + donne.id);

    return data;
  }
}