import api from "../../axios";
import * as _ from "lodash";


export const network = {
  async getPaiementClient(id: string) {
    const { data } = await api.withToken.get('/payement-client/' + id);

    return data;
  },
  async getAllPaiement() {
    const { data } = await api.withToken.get('/payement')

    return data
  },
  async addPaiement(paiement: Object) {
    const { data } = await api.withToken.post('/payement', paiement)

    return data;
  },
  async modifierPaiement(paiement: { id: number }) {
    const { data } = await api.withToken.put('/payement/' + paiement.id, _.omit(paiement, ['id']))

    return data
  },
  async getPaiementPaginate(page: number) {
    const { data } = await api.withToken.get('/payement/page/5/' + page);

    return data;
  },
  async getPaiementClientPaginate(donne: { page: number, idClient: number }) {
    const { data } = await api.withToken.get('/payement-client/page/5/' + donne.page + '/' + donne.idClient);

    return data;
  },
  async deletePaiement(donne: { id: number }) {
    const { data } = await api.withToken.delete('/payement/' + donne.id);

    return data;
  }
}