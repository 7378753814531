import React from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
interface SupplierReportProps {
  show: boolean;
  onHide: () => void;
}

export function SupplierReport({ show, onHide }: SupplierReportProps) {
  const navigate = useNavigate();
  const handleSubmit = () => {
    onHide();
    navigate("/supplier/report");
  };
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>View Report</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Generate and view report</p>
        <div className="row gy-4">
          <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">Project</label>
              <div className="form-control-wrap ">
                <div className="form-control-select">
                  <select
                    className="form-control form-control-lg select2"
                    name="project"
                    required
                  >
                    <option value="All">All Projects</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">Delivery Status</label>
              <div className="form-control-wrap">
                <div className="form-control-select">
                  <select
                    className="form-control form-control-lg"
                    name="status"
                  >
                    <option value="All">All</option>
                    <option value="Delivered">Delivered</option>
                    <option value="Ordered">Ordered</option>
                    <option value="Awaiting Delivery">Awaiting Delivery</option>
                    <option value="To Order">To Order</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className=" bg-light">
        <button
          className="btn btn-white btn-dim btn-outline-light"
          type="button"
          onClick={onHide}
        >
          <em className="icon ni ni-cross-circle"></em>
          <span>Cancel</span>
        </button>
        <button
          className="btn btn-primary"
          type="button"
          onClick={handleSubmit}
        >
          <em className="icon ni ni-check-circle-cut"></em>
          <span>View Report</span>
        </button>
      </Modal.Footer>
    </Modal>
  );
}
