import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Swal from "sweetalert2";
import { CreateTask, ImportTasks, UpdateTask, ViewTask } from "../../../Modal";
import { SelectJobCard } from "../../../Modal/SelectJobCard";
import { Pagination } from "react-bootstrap";
import { UpdateWork } from "../../../Modal/UpdateWork";
import { workStepEntityType } from "../../../types";
import { formatTime } from "../TableResumeTasks";

type Props = {
  data: any
  nextPage: Function
  prevPage: Function
  goToPage: Function
}

export default function TableauWork({ data, nextPage, prevPage, goToPage }: Props) {
  interface Task {
    idTache: number,
    titreTache: string,
    dateEcheance: string,
    heureEcheance: string,
    coutTache: string,
    status: string,
    description: string,
    projets: {
      vehicules: {
        detailVehicules: {
          marque: string
        },
        clients: {
          nomPrenom: string;
        }
      }

    }
  }
  interface Membre {
    idMembre: number,
    prenom: string,
    nom: string,
  }
  interface WorkStep {
    idWorkStep: number,
    status: string,
    estimate: string,
    assignTo: Membre,
    taskGroup: {
      taskGroupLabel: string,
      tasks: {
        taskTitle: string,
        orderTask: number,
      }[]
    }
  }
  interface Work {
    idWork: number,
    workSteps: WorkStep[],
    projets: {
      vehicules: {
        detailVehicules: {
          marque: string
        },
        clients: {
          nomPrenom: string;
        }
      }

    }
  }
  const [showImport, setShowImport] = useState(false);
  const [showSelect, setShowSelect] = useState(false);
  const [showView, setShowView] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [tache, setTache] = useState<Task>({
    idTache: 0,
    titreTache: '',
    dateEcheance: '',
    coutTache: '',
    status: '',
    heureEcheance: '',
    description: '',
    projets: {
      vehicules: {
        detailVehicules: {
          marque: '',
        },
        clients: {
          nomPrenom: '',
        }
      }
    },
  });

  const [work, setWork] = useState<Work>({
    idWork: 0,
    workSteps: [],
    projets: {
      vehicules: {
        detailVehicules: {
          marque: ''
        },
        clients: {
          nomPrenom: ''
        }
      }

    }
  });

  const [activePage, setActivePage] = useState<number>(1);



  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "This task will be deleted permanently.",
      confirmButtonText: "Yes, delete!",
      cancelButtonText: `Cancel`,
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: "#fff",
    }).then((result) => {
      if (result.isConfirmed) {
        ///When click Yes
      }
    });
  };
  const handleCancel = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "This task will be marked as cancelled.",
      confirmButtonText: "Yes, Cancel!",
      cancelButtonText: `Cancel`,
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: "#fff",
    }).then((result) => {
      if (result.isConfirmed) {
        ///When click Yes
      }
    });
  };

  const sendData = (item: Task) => {
    setShowView(true)
    setTache(item)
  }

  const goToNextPage = () => {
    if (activePage < data.countPage) {
      setActivePage(activePage + 1);
    } else {
      setActivePage(activePage)
    }
    nextPage();
  }

  const goToPrevPage = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1)
    } else {
      setActivePage(activePage)
    }
    prevPage()
  }

  const goToPageOne = (items: number) => {
    setActivePage(items)
    goToPage(items)
  }

  return (
    <>
      <div className="d-flex flex-column align-items-end">
        <table className="datatable-init nk-tb-list nk-tb-ulist mt">
          <thead>
            <tr className="nk-tb-item nk-tb-head">
              <th className="nk-tb-col text-center">#</th>
              <th className="nk-tb-col tb-col-md">
                <span className="sub-text">Responsable</span>
              </th>
              <th className="nk-tb-col">
                <span className="sub-text">Tâches a faire</span>
              </th>
              <th className="nk-tb-col tb-col-md">
                <span className="sub-text">Estimation</span>
              </th>
              <th className="nk-tb-col tb-col-md">
                <span className="sub-text">Réalisation</span>
              </th>
              <th className="nk-tb-col tb-col-md">
                <span className="sub-text">Status</span>
              </th>
              <th className="nk-tb-col nk-tb-col-tools text-right"></th>
            </tr>
          </thead>
          {data && data.workSteps.map((item: workStepEntityType) => (
            <tbody>
              <tr className="nk-tb-item">
                <td className="nk-tb-col text-center">{item.idWorkStep}</td>
                <td className="nk-tb-col tb-col-md">
                  <div className="user-card">
                    <div className="user-info">
                      <span className="tb-lead">{`${item?.assignTo?.prenom} ${item?.assignTo?.nom}`}</span>
                    </div>
                  </div>
                </td>
                <td className="nk-tb-col tb-col-md">
                  <div className="user-card">
                    <div className="user-info">
                      <span className="tb-lead">{item.taskGroup.taskGroupLabel}</span>
                      <ul>
                        {
                          item.taskGroup.tasks?.map( t => (
                            <li>
                              {t.taskTitle}
                            </li>
                          ))
                        }
                      </ul>
                    </div>
                  </div>
                </td>
                <td className="nk-tb-col tb-col-md">
                  <div className="user-card">
                    <div className="user-info">
                      <span className="tb-lead">{`${Math.floor(item.estimate / 3600)}h ${Math.floor((item.estimate % 3600) /60)}min`}</span>
                    </div>
                  </div>
                </td>
                <td className="nk-tb-col tb-col-md">
                  <div className="user-card">
                    <div className="user-info">
                      <span className="tb-lead">{item.status === 'En cours' 
                      ?formatTime (((new Date().getTime() - new Date(item.lastBreakStart ?? 0).getTime()) / 1000) + (item.duration ?? 0))  
                      : formatTime(item.duration)}</span>
                    </div>
                  </div>
                </td>
                <td className="nk-tb-col tb-col-md">
                  <div className="user-card">
                    <div className="user-info">
                      <span className="tb-lead">{item.status}</span>
                    </div>
                  </div>
                </td>
                <td className="nk-tb-col nk-tb-col-tools">
                  <ul className="nk-tb-actions gx-1">
                    <li>
                      <Dropdown>
                        <Dropdown.Toggle
                          as="a"
                          className="btn btn-icon btn-trigger"
                        >
                          <em className="icon ni ni-more-h" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <ul className="link-list-opt no-bdr">
                            <li>
                              <a
                                className="fetch-display-click cursor"
                              >
                                <em className="icon ni ni-eye" />
                                <span>Voir les détails</span>
                              </a>
                            </li>

                            <li>
                              <a
                                className="send-to-server-click cursor"
                                onClick={handleCancel}
                              >
                                <em className="icon ni ni-na" />
                                <span>Annuler la tâche</span>
                              </a>
                            </li>

                          </ul>
                        </Dropdown.Menu>
                      </Dropdown>
                    </li>
                  </ul>
                </td>
              </tr>
              <ImportTasks show={showImport} onHide={() => setShowImport(false)} />
              <SelectJobCard
                show={showSelect}
                onHide={() => setShowSelect(false)}
                onSelectJobCard={() => {
                  setShowSelect(false);
                  setShowImport(true);
                }}
              />
            </tbody>))}
        </table>
        <Pagination className="nk-block-end">
          <Pagination.First onClick={() => goToPageOne(1)} />
          <Pagination.Prev onClick={goToPrevPage} />
          {/* {
            Array.from(
              { length: data.countPage }, (v, k) => k + 1
            ).map(items => <Pagination.Item key={items} active={items === activePage} onClick={() => goToPageOne(items)}>{items}</Pagination.Item>)
          } */}
          <Pagination.Next onClick={goToNextPage} />
          {/* <Pagination.Last onClick={() => goToPageOne(data.countPage)} /> */}
        </Pagination>
      </div>
      {/* {/* <ViewTask show={showView} onHide={() => setShowView(false)} data={tache} /> */}
    </>
  );
}
