import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { userHooks } from '../../../network';
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";

export default function ForgotPasword() {
  const [email, setEmail] = useState('');
  const route = useNavigate();
  const forgotPass = userHooks.useForgotPassword();

  const reinitialise = async(event: any) => {
    event.preventDefault()

    const { mutate } = forgotPass;

    await mutate({email: email});
    await Swal.fire("Email bien envoyé", "Le lien pour la changement du mot de passe a bien été envoyé", "success");
  }
  return (
    <>
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <Card style={{ marginTop: '11%' }}>
            <Card.Body>
              <h3>
                Mot de passe oublié
              </h3>
              <form onSubmit={reinitialise}>
                <div className="row gy-4">
                  <div className="col-sm-12 mt-0">
                    <div className="form-group">
                      <label className="form-label">Adresse e-mail</label>
                      <div className="form-control-wrap">
                        <input
                          type="email"
                          className="form-control form-control-lg"
                          placeholder="Adresse e-mail"
                          name="email"
                          required
                          onChange={e => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-sm-4 offset-4'>
                  <br />
                  <button className="btn btn-primary" type='submit'>
                      <span>Reinitialiser</span>
                  </button>
                </div>
              </form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  )
}
