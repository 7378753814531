import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { CreateTaskInList, UpdateTask, ViewTask } from "../../../Modal";
import { tacheHooks } from "../../../network/tache";
import TableauAllTask from "../../../components/Tableau/TableauAllTask";
import Loading from "../../../components/Loading";
import { projetHooks } from "../../../network/projet";

export default function AllTask() {
  const [showCreate, setShowCreate] = useState(false);
  const [pageActive, setPageActive] = useState<number>(1);
  const { data, isLoading } = tacheHooks.useGetTaskPaginated(pageActive);
  const {data: dataProjets, isLoading: isProjetsLoading} = projetHooks.useGeTAllProject();

  const nextPage = () => {
    if (data?.data){
      if (pageActive < data.data.countPage) {
        setPageActive(pageActive + 1);
      } else {
        setPageActive(pageActive);
      }
    }
  }

  const prevPage = () => {
    if (pageActive > 1) {
      setPageActive(pageActive - 1);
    } else {
      setPageActive(pageActive);
    }
  }

  const goToPage = (numPage: number) => {
    setPageActive(numPage);
  }

  React.useEffect(() => {
    if (data?.hasMore) {
      tacheHooks.usePrefetchTask(pageActive)
    }
  }, [data, pageActive])

  if (isLoading ||isProjetsLoading){
    return (<div className="loading__wrapper">
    <Loading />
  </div>)
  }
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        {isLoading ? <Loading /> : <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">Toutes les tâches</h3>
            <div className="nk-block-des text-soft">
              <p>Un total de {data?.data.countTotal} tâches</p>
            </div>
          </div>
          <div className="toggle-wrap nk-block-tools-toggle">
            <div
              className={`toggle-expand-content ${"d-block"}`}
              data-content="pageMenu"
            >
              <ul className="nk-block-tools g-3">
                <li>
                  <button
                    className="btn btn-primary cursor fetch-display-click"
                    onClick={() => setShowCreate(true)}
                  >
                    <em className="icon ni ni-plus"></em>
                    <span>Créer une tache</span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>}
      </div>
      {isLoading ? <Loading /> : (data && data.data ?  <TableauAllTask data={data?.data} nextPage={nextPage} prevPage={prevPage} goToPage={goToPage} /> : null)}

      {dataProjets && dataProjets.data ? <CreateTaskInList show={showCreate} loading={isProjetsLoading} dataProject={dataProjets?.data} onHide={() => setShowCreate(false)} /> : null}
    </>
  );
}
