import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import {
  AddStock,
  CreateInventory,
  IssueInventory,
  UpdateInventory,
} from "../../../Modal";
import { Pagination } from "react-bootstrap";
import { inventaireHooks } from "../../../network/inventaire";

type Props = {
  data: {
    list: [],
    countPage: number,
  }
  nextPage: Function
  prevPage: Function
  goToPage: Function
}

export default function TableauInventory({ data, nextPage, prevPage, goToPage }: Props) {
  const [showCreate, setShowCreate] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [showPageMenu, setShowPageMenu] = useState(false);
  const [showIssue, setShowIssue] = useState(false);
  const [showAddStock, setShowAddStock] = useState(false);
  const [activePage, setActivePage] = useState<number>(1);
  const { mutate } = inventaireHooks.useDeleteInventaire();

  interface Inventaire {
    idInventaire: number,
    nomArticle: string,
    numeroEtagere: string,
    quantite: number,
    codeArticle: string,
    coutUnitaire: number,
    fournisseurs: {
      nom: string
    }
  }

  const handleDelete = (idInventaire: number) => {
    Swal.fire({
      title: "Être vous sur?",
      text: "Cette item sera supprimé definitivement",
      confirmButtonText: "Oui supprimer",
      cancelButtonText: `Retour`,
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        return mutate({ id: String(idInventaire) }, {
          onSuccess: async (data) => {
          },
          onError: (error) => {
            Swal.fire({
              icon: 'error',
              text: 'Erreur lors de la suppression de l\'inventaire',
            });
          },
        })
      }
    });
  };

  const goToNextPage = () => {
    if (activePage < data.countPage) {
      setActivePage(activePage + 1);
    } else {
      setActivePage(activePage)
    }
    nextPage();
  }

  const goToPrevPage = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1)
    } else {
      setActivePage(activePage)
    }
    prevPage()
  }

  const goToPageOne = (items: number) => {
    setActivePage(items)
    goToPage(items)
  }
  return (
    <>
      <div className="nk-block">
        <div className="card card-stretch">
          <div className="card-inner d-flex flex-column align-items-end">
            <table
              className="datatable-init nk-tb-list nk-tb-ulist"
              data-auto-responsive="false"
            >
              <thead>
                <tr className="nk-tb-item nk-tb-head">
                  <th className="nk-tb-col text-center">#</th>
                  <th className="nk-tb-col">
                    <span className="sub-text">Article / N° d'étagère</span>
                  </th>
                  <th className="nk-tb-col tb-col-md">
                    <span className="sub-text">Quantité / Code article</span>
                  </th>
                  <th className="nk-tb-col tb-col-md">
                    <span className="sub-text">Coût unitaire</span>
                  </th>
                  <th className="nk-tb-col tb-col-md">
                    <span className="sub-text">Fournisseur</span>
                  </th>
                  {/* <th className="nk-tb-col tb-col-md">
                    <span className="sub-text">Statut</span>
                  </th> */}
                  <th className="nk-tb-col nk-tb-col-tools text-right"></th>
                </tr>
              </thead>
              {data.list.map((items: Inventaire, key) => (
                <tbody key={key}>
                  <tr className="nk-tb-item">
                    <td className="nk-tb-col text-center">{items.idInventaire}</td>
                    <td className="nk-tb-col">
                      <span className="tb-lead">{items.nomArticle} / {items.numeroEtagere}</span>
                    </td>
                    <td className="nk-tb-col tb-col-mb">
                      <span className="tb-lead">{items.quantite} / {items.codeArticle}</span>
                    </td>
                    <td
                      className="nk-tb-col tb-col-mb"
                      data-order="{{ $item->unit_cost }}"
                    >
                      <span className="tb-amount">{items.coutUnitaire} €</span>
                    </td>
                    <td className="nk-tb-col tb-col-md">
                      <span>{items.fournisseurs.nom}</span>

                      {/* <span>--|--</span> */}
                    </td>
                    {/* <td className="nk-tb-col">
                    <span className="badge badge-sm badge-dot has-bg badge-success d-mb-inline-flex">
                      En stock
                    </span>

                    <span className="badge badge-sm badge-dot has-bg badge-warning d-mb-inline-flex">Réapprovisionner</span>

                    <span className="badge badge-sm badge-dot has-bg badge-danger d-mb-inline-flex">En rupture de stock</span>
                  </td> */}
                    <td className="nk-tb-col nk-tb-col-tools">
                      <ul className="nk-tb-actions gx-1">
                        <li>
                          <Dropdown>
                            <Dropdown.Toggle
                              as="a"
                              className="btn btn-icon btn-trigger"
                            >
                              <em className="icon ni ni-more-h"></em>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu-right">
                              <ul className="link-list-opt no-bdr">
                                <li>
                                  <a
                                    className="fetch-display-click cursor"
                                    onClick={() => setShowUpdate(true)}
                                  >
                                    <em className="icon ni ni-pen"></em>
                                    <span>Modifier les détails</span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="fetch-display-click cursor"
                                    onClick={() => setShowIssue(true)}
                                  >
                                    <em className="icon ni ni-check-circle-cut"></em>
                                    <span>Publier</span>
                                  </a>
                                </li>

                                <li>
                                  <a
                                    className="add-stock cursor"
                                    onClick={() => setShowAddStock(true)}
                                  >
                                    <em className="icon ni ni-plus-circle"></em>
                                    <span>Ajouter des actions</span>
                                  </a>
                                </li>
                                <li>
                                  <Link
                                    to="/inventory/report/view"
                                    className="cursor"
                                  >
                                    <em className="icon ni ni-reports"></em>
                                    <span>Voir le rapport</span>
                                  </Link>
                                </li>

                                <li>
                                  <a
                                    className="send-to-server-click cursor"
                                    onClick={() => handleDelete(items.idInventaire)}
                                  >
                                    <em className="icon ni ni-trash"></em>
                                    <span>Effacer l'article</span>
                                  </a>
                                </li>
                              </ul>
                            </Dropdown.Menu>
                          </Dropdown>
                        </li>
                      </ul>
                    </td>
                  </tr>
                  {/* <tr>
                                                        <td className="text-center" colSpan={8}>C'est vide ici !</td>
                                                    </tr> */}
                </tbody>))}
            </table>
            <Pagination className="nk-block-end">
              <Pagination.First onClick={() => goToPageOne(1)} />
              <Pagination.Prev onClick={goToPrevPage} />
              {
                Array.from(
                  { length: data.countPage }, (v, k) => k + 1
                ).map(items => <Pagination.Item key={items} active={items === activePage} onClick={() => goToPageOne(items)}>{items}</Pagination.Item>)
              }
              <Pagination.Next onClick={goToNextPage} />
              <Pagination.Last onClick={() => goToPageOne(data.countPage)} />
            </Pagination>
          </div>

        </div>
      </div>

      <CreateInventory show={showCreate} onHide={() => setShowCreate(false)} />
      <UpdateInventory show={showUpdate} onHide={() => setShowUpdate(false)} />
      <IssueInventory show={showIssue} onHide={() => setShowIssue(false)} />
      <AddStock show={showAddStock} onHide={() => setShowAddStock(false)} />
    </>
  );
}
