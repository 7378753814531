import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {
  CreateInvoices,
  CreateJobCard,
  CreateProjects,
  CreateQuote,
} from "../../../Modal";
import { UpdateProjects } from "../../../Modal/UpdateProjects";
import { Pagination } from "react-bootstrap";
import { projetEntityPaginationType } from "../../../types";
import { Dropdown, MenuProps, Button } from "antd";
import { MoreHoriz } from "@mui/icons-material";

type Props = {
  data: projetEntityPaginationType
  nextPage: Function
  prevPage: Function
  goToPage: Function
}

export default function TableauAllProjet({ data, nextPage, prevPage, goToPage }: Props) {
  const [showCreate, setShowCreate] = useState(false);
  const [showPageMenu, setShowPageMenu] = useState(false);
  const [showCreateJobCard, setShowCreateJobCard] = useState(false);
  const [showCreateQuote, setShowCreateQuote] = useState(false);
  const [showCreateInvoice, setShowCreateInvoice] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [client, setClient] = useState<string>('Test');
  const [vehicule, setVehicule] = useState<string>('Ford Raptor');
  const [statut, setStatut] = useState<string>('En cours');
  const [idProjet, setIdProjet] = useState<number>(0);
  const [activePage, setActivePage] = useState<number>(1);
  const navigate = useNavigate();
  

  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "This project will be deleted permanently.",
      confirmButtonText: "Yes, delete!",
      cancelButtonText: `Cancel`,
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: "#fff",
    }).then((result) => {
      if (result.isConfirmed) {
        ///When click Yes
      }
    });
  };
  const handleCancel = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "This project will be marked as cancelled.",
      confirmButtonText: "Yes, Cancel!",
      cancelButtonText: `Cancel`,
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: "#fff",
    }).then((result) => {
      if (result.isConfirmed) {
        ///When click Yes
      }
    });
  };

  const displayModal = (idProjet: number) => {
    setShowUpdate(true)

    setIdProjet(idProjet)

  }

  interface Project {
    idProjet: number,
    status: string,
    vehicules: {
      detailVehicules: {
        marque: string
      },
      clients: {
        nomPrenom: string
      }
    }
  }

  const goToNextPage = () => {
    if (activePage < data.countPage) {
      setActivePage(activePage + 1);
    } else {
      setActivePage(activePage)
    }
    nextPage();
  }

  const goToPrevPage = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1)
    } else {
      setActivePage(activePage)
    }
    prevPage()
  }

  const goToPageOne = (items: number) => {
    setActivePage(items)
    goToPage(items)
  }

  return (
    <>
      <div className="nk-block">
        <div className="card card-stretch">
          <div className="card-inner d-flex flex-column align-items-end">
            <table className="datatable-init nk-tb-list nk-tb-ulist">
              <thead>
                <tr className="nk-tb-item nk-tb-head">
                  <th className="nk-tb-col text-center">#</th>
                  <th className="nk-tb-col">
                    <span className="sub-text">Client</span>
                  </th>
                  <th className="nk-tb-col">
                    <span className="sub-text">Projet</span>
                  </th>
                  <th className="nk-tb-col">
                    <span className="sub-text">Statut</span>
                  </th>
                  <th className="nk-tb-col nk-tb-col-tools text-right"></th>
                </tr>
              </thead>
              {data?.list && data?.list?.map((item, index) => (
                <tbody>
                  <tr className="nk-tb-item" 
                  style={{ cursor: "pointer" }}
                  onClick={
                    ()=>{ navigate(`/vehicule/details/${item.idProjet}`)}
                  }>
                    <td className="nk-tb-col text-center">{index + 1}</td>
                    <td className="nk-tb-col">
                      <div className="user-card">
                        <div className="user-info">
                          <span className="tb-lead">
                            {item?.vehicules?.clients?.nomPrenom}
                            <span className="dot dot-success d-md-none ml-1"></span>
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className="nk-tb-col">
                      <span className="tb-amount">
                        {item?.vehicules?.detailVehicules?.marque}
                        <span
                          className="text-success"
                          data-toggle="tooltip"
                          title="Covered by Insurance"
                        >
                          <em className="icon ni ni-shield-check-fill"></em>
                        </span>
                      </span>
                    </td>
                    <td className="nk-tb-col">
                      <span className="badge badge-sm badge-dot has-bg badge-warning d-mb-inline-flex">
                        {item.status}
                      </span>
                    </td>
                    <td className="nk-tb-col nk-tb-col-tools">
                      <ul className="nk-tb-actions gx-1">
                        <li>
                          <Dropdown 
                            menu={{items: [
                              {
                                key: '1',
                                label: (<Link className="gray-color" to={`/vehicule/details/${item.idProjet}`}>
                                <em className="icon ni ni-eye gray-color"></em>
                                &emsp;
                                <span className="gray-color">Voir les détails</span>
                                </Link>)
                              },
                              {
                                key: '2',
                                label: (<a
                                  className="fetch-display-click cursor gray-color"
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    displayModal(item.idProjet)
                                  }}
                                >
                                  <em className="icon ni ni-pen gray-color"></em>
                                  &emsp;
                                  <span className="gray-color">Modifier les détails</span>
                                </a>),
                                
                              },
                              {
                                key: '3',
                                label: ( <a
                                  className="create-quote cursor gray-color"
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    setShowCreateQuote(true)
                                  }}
                                >
                                  <em className="icon ni ni-cards gray-color"></em>
                                  &emsp;
                                  <span className="gray-color">Créer un devis</span>
                                </a>)
                              },
                              {
                                key: '4',
                                label: (<a
                                  className="create-invoice cursor gray-color"
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    setShowCreateInvoice(true)
                                  }}
                                >
                                  <em className="gray-color icon ni ni-cc"></em>
                                  &emsp;
                                  <span className="gray-color">Créer une facture</span>
                                </a>)
                              }
                            ]}}
                          >
                            <Button onClick={e=>{e.stopPropagation()}} type="text" icon={<MoreHoriz />} />
                          </Dropdown>
                        </li>
                      </ul>
                    </td>
                  </tr>

                  <tr>
                  </tr>
                </tbody>))}
            </table>
            <Pagination className="nk-block-end">
              <Pagination.First onClick={() => goToPageOne(1)} />
              <Pagination.Prev onClick={goToPrevPage} />
              {
                Array.from(
                  { length: data?.countPage }, (v, k) => k + 1
                ).map(items => <Pagination.Item key={items} active={items === activePage} onClick={() => goToPageOne(items)}>{items}</Pagination.Item>)
              }
              <Pagination.Next onClick={goToNextPage} />
              <Pagination.Last onClick={() => goToPageOne(data.countPage)} />
            </Pagination>
          </div>
        </div>
      </div>
      <CreateJobCard
        show={showCreateJobCard}
        onHide={() => setShowCreateJobCard(false)}
      />
      <CreateQuote
        show={showCreateQuote}
        onHide={() => setShowCreateQuote(false)}
      />
      <CreateInvoices
        show={showCreateInvoice}
        onHide={() => setShowCreateInvoice(false)}
      />
      <UpdateProjects show={showUpdate} onHide={() => setShowUpdate(false)} idProjet={idProjet}/>
    </>
  );
}