import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import RenderPdf from "../../../components/RenderPdf";
import { SupplierReport } from "../../../Modal/SupplierReport";

const URL_FILE = "/pdf/Procurement_Report_Moshi_SPares.pdf";

export default function SupplierView() {
  const [showReport, setShowReport] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">
              Procurement Report: Moshi SPares
            </h3>
            <div className="nk-block-des text-soft">
              <p>You are viewing Moshi SPares's report.</p>
            </div>
          </div>
          <div className="nk-block-head-content">
            <ul className="nk-block-tools g-3">
              <li>
                <a
                  className="btn btn-outline-light bg-white d-none d-sm-inline-flex go-back"
                  onClick={() => navigate(-1)}
                >
                  <em className="icon ni ni-arrow-left" />
                  <span>Back</span>
                </a>
                <a className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none go-back">
                  <em className="icon ni ni-arrow-left" />
                </a>
              </li>
              <li>
                <Dropdown>
                  <Dropdown.Toggle
                    className="btn btn-dim btn-outline-primary"
                    as="a"
                  >
                    <em className="icon ni ni-more-h" /> <span>More</span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <ul className="link-list-opt no-bdr">
                      <li>
                        <a
                          href={URL_FILE}
                          download="Procurement_Report_Moshi_SPares.pdf"
                          className="cursor"
                        >
                          <em className="icon ni ni-download-cloud" />
                          <span>Download</span>
                        </a>
                      </li>
                      <li>
                        <a href={URL_FILE} target="_blank" className="cursor">
                          <em className="icon ni ni-printer" />
                          <span>Print</span>
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => setShowReport(true)}
                          className="cursor"
                        >
                          <em className="icon ni ni-repeat" />
                          <span>Regenerate</span>
                        </a>
                      </li>
                    </ul>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
          </div>
        </div>
        {/* .nk-block-between */}
      </div>
      <div className="nk-block">
        <div className="card card-stretch">
          <div className="card-inner">
            <RenderPdf url={URL_FILE} />
          </div>
        </div>
      </div>
      <SupplierReport show={showReport} onHide={() => setShowReport(false)} />
    </>
  );
}
