import React from "react";
import { Modal } from "react-bootstrap";
import { taskGroupEntityType } from "../../types";


interface ViewTaskItemProps {
  show: boolean;
  onHide: () => void;
  data: taskGroupEntityType
}

export function ViewTaskGroup({ show, onHide, data }: ViewTaskItemProps) {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Détails du groupe de tâche</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Titre de l'étape: <strong>{data?.taskGroupLabel}</strong></p>
        <div className="row gy-4">
          <div className="col-sm-12">
            <span className="text-muted">Liste des tâches</span>
            <ul>
              {
                data?.tasks?.map(t => (
                  <li>{t.taskTitle}</li>
                ))
              }
            </ul>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className=" bg-light">
        <button
          className="btn btn-white btn-dim btn-outline-light"
          type="button"
          onClick={onHide}
        >
          <em className="icon ni ni-cross-circle"></em>
          <span>Fermer</span>
        </button>
      </Modal.Footer>
    </Modal>
  );
}
