import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

export interface Props {
  value: Object;
  open: boolean;
  message: string;
  onClose: (value?: Object) => void;
}

export default function ConfirmActionWork(props: Props) {
  const { onClose, value: valueProp, open, } = props;
  const [value, setValue] = React.useState(valueProp);

  React.useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(valueProp);
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
      onClose={handleCancel}
    >
      <DialogTitle>Demande de confirmation</DialogTitle>
      <DialogContent dividers>
        <span>{props.message}</span>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          Annuler
        </Button>
        <Button onClick={handleOk}>Confirmer</Button>
      </DialogActions>
    </Dialog>
  );
}