import React from "react";
import { Modal } from "react-bootstrap";
interface CreateApprovedJobCardProps {
  show: boolean;
  onHide: () => void;
}

export const CreateApprovedJobCard = ({
  onHide,
  show,
}: CreateApprovedJobCardProps) => {
  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Gérer les informations</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Vous pouvez créer une version approuvée de cette carte de travail en
          supprimant les éléments inutiles et en ajoutant d'autres éléments si
          nécessaire.
        </p>

        <div className="row gy-4">
          <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">Rapport corporel</label>
              <div className="asilify-stack">
                <div className="stacked-inputs">
                  <div className="form-control-wrap stacked">
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      placeholder="Rapport corporel"
                      name="body_report[]"
                    />
                  </div>
                  <div className="form-control-wrap stacked">
                    <a
                      href=""
                      className="btn btn-round btn-sm btn-icon btn-dim btn-danger remove-stack"
                    >
                      <em className="icon ni ni-trash" />
                    </a>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      placeholder="Body Report"
                      name="body_report[]"
                    />
                  </div>
                </div>
                <div>
                  <a
                    href=""
                    className="btn btn-dim btn-primary add-stack"
                    data-name="body_report[]"
                    data-placeholder="Body Report"
                  >
                    <em className="icon ni ni-plus" />
                    <span>Ajouter un item</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row gy-4">
          <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">Rapport mécanique</label>
              <div className="asilify-stack">
                <div className="stacked-inputs">
                  <div className="form-control-wrap stacked">
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      placeholder="Rapport mécanique"
                      name="mechanical_report[]"
                    />
                  </div>
                  <div className="form-control-wrap stacked">
                    <a
                      href=""
                      className="btn btn-round btn-sm btn-icon btn-dim btn-danger remove-stack"
                    >
                      <em className="icon ni ni-trash" />
                    </a>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      placeholder="Mechanical Report"
                      name="mechanical_report[]"
                    />
                  </div>
                </div>
                <div>
                  <a
                    href=""
                    className="btn btn-dim btn-primary add-stack"
                    data-name="mechanical_report[]"
                    data-placeholder="Mechanical Report"
                  >
                    <em className="icon ni ni-plus" />
                    <span>Ajouter un item</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row gy-4">
          <div className="col-sm-12">
            <div className="form-group">
              <label className="form-label">Rapport électrique</label>
              <div className="asilify-stack">
                <div className="stacked-inputs">
                  <div className="form-control-wrap stacked">
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      placeholder="Rapport électrique"
                      name="electrical_report[]"
                    />
                  </div>
                  <div className="form-control-wrap stacked">
                    <a
                      href=""
                      className="btn btn-round btn-sm btn-icon btn-dim btn-danger remove-stack"
                    >
                      <em className="icon ni ni-trash" />
                    </a>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      placeholder="Electrical Report"
                      name="electrical_report[]"
                    />
                  </div>
                </div>
                <div>
                  <a
                    href=""
                    className="btn btn-dim btn-primary add-stack"
                    data-name="electrical_report[]"
                    data-placeholder="Electrical Report"
                  >
                    <em className="icon ni ni-plus" />
                    <span>Ajouter un item</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="bg-light">
        <button
          className="btn btn-white btn-dim btn-outline-light"
          type="button"
          onClick={onHide}
        >
          <em className="icon ni ni-cross-circle"></em>
          <span>Annuler</span>
        </button>
        <button className="btn btn-primary" type="submit">
          <em className="icon ni ni-check-circle-cut"></em>
          <span>Créer une carte de travail approuvée</span>
        </button>
      </Modal.Footer>
    </Modal>
  );
};
