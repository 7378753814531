import api from "../../axios";
import type { tacheEntityPaginationType, tacheEntityType } from "../../types";

export const network = {
  async getAllTasks(): Promise<{data: tacheEntityType[]}> {
    const { data } = await api.withToken.get('/tache');

    return data;
  },
  async getTaskByStatus(statut: { statut: string }): Promise<{data: tacheEntityType[]}> {
    const { data } = await api.withToken.get('/tache/by-status/' + statut.statut);

    return data;
  },
  async getTaskByVehicule(donne: { page: number, idVehicule: number }): Promise<{data: tacheEntityType[]}> {
    const { data } = await api.withToken.get('/tache-vehicule/page/5/' + donne.page + '/' + donne.idVehicule);

    return data;
  },
  async createTask(task: Object): Promise<{data: tacheEntityType}> {
    const { data } = await api.withToken.post('/tache', task);

    return data;
  },
  async getTaskPaginated(page: number): Promise<{
    data: tacheEntityPaginationType
    hasMore?: boolean
  }> {
    const { data } = await api.withToken.get('/tache/page/20/' + page);

    return data;
  },
  async getTaskStatusPaginated(donne: { page: number, status: string }): Promise<{data: tacheEntityPaginationType
    hasMore?: boolean
  }> {
    const { data } = await api.withToken.get('/tache/by-status/page/20/' + donne.page + '/' + donne.status);

    return data
  },
  async deleteTask(donne: { id: number }): Promise<{data: tacheEntityType}> {
    const { data } = await api.withToken.delete('/tache/' + donne.id);

    return data;
  }
}