import React, { useEffect, useState, useRef, useCallback } from "react";

import * as pdfjs from "pdfjs-dist";
// @ts-ignore
import * as pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

interface RenderPdfProps {
  url: string;
}
export default function RenderPdf({ url }: RenderPdfProps) {
  const [pdf, setPDF] = useState<pdfjs.PDFDocumentProxy | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [scale, setScale] = useState(
    document.body.clientWidth > 414 ? 1.1 : 0.6
  );
  const [numPages, setNumPages] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const divMapRef = useRef<HTMLDivElement | null>(null);

  const renderPage = useCallback(
    async ({
      pdfDoc,
      pageNum,
      scale,
    }: {
      pdfDoc: pdfjs.PDFDocumentProxy | null;
      pageNum: number;
      scale: number;
    }) => {
      const docMapEl = divMapRef.current;
      if (!pdfDoc || !canvasRef.current || !docMapEl) return;

      const page = await pdfDoc.getPage(pageNum);
      const ctx = canvasRef.current.getContext("2d");

      const viewport = page.getViewport({ scale });

      canvasRef.current.width = viewport.width;
      canvasRef.current.height = viewport.height;

      ctx &&
        page.render({
          canvasContext: ctx,
          viewport: viewport,
        });
    },
    []
  );
  const prevPage = () => {
    if (currentPage > 1) {
      renderPage({ pdfDoc: pdf, pageNum: currentPage - 1, scale });
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage < numPages) {
      renderPage({ pdfDoc: pdf, pageNum: currentPage + 1, scale });
      setCurrentPage(currentPage + 1);
    }
  };

  const zoomOut = () => {
    renderPage({ pdfDoc: pdf, pageNum: currentPage, scale: scale - 0.1 });
    setScale(scale - 0.1);
  };

  const zoomIn = () => {
    renderPage({ pdfDoc: pdf, pageNum: currentPage, scale: scale + 0.1 });
    setScale(scale + 0.1);
  };
  useEffect(() => {
    const fetchPdf = async () => {
      const loadingTask = pdfjs.getDocument(url);

      const pdfDoc = await loadingTask.promise;

      setPDF(pdfDoc);

      setNumPages(pdfDoc._pdfInfo.numPages);

      renderPage({ pdfDoc, pageNum: 1, scale: 1 });

      setLoaded(true);
    };

    fetchPdf();
  }, [renderPage, url]);

  return (
    <>
      <div className="document">
        <div className="signer-document" ref={divMapRef}>
          {/* open PDF docements */}
          <div className="document-pagination d-flex justify-content-between">
            <div className="pull-left">
              <a
                id="prev"
                className={`btn btn-round btn-dim btn-icon btn-sm btn-light btn-viewer ${
                  currentPage <= 1 && "disabled"
                }`}
                onClick={prevPage}
              >
                <em className="icon ni ni-chevron-left-circle" />
              </a>
              <a
                id="next"
                className={`btn btn-round btn-dim btn-icon btn-sm btn-light btn-viewer ms-1 ${
                  currentPage >= numPages && "disabled"
                }`}
                onClick={nextPage}
              >
                <em className="icon ni ni-chevron-right-circle" />
              </a>
              <span className="text-muted ml-15  ms-1">
                Page <span id="page_num">{currentPage}</span> /{" "}
                <span id="page_count">{numPages}</span>
              </span>
            </div>
            <div>
              <a
                className="btn btn-round btn-dim btn-icon btn-sm btn-light btn-zoom"
                onClick={zoomIn}
              >
                <em className="icon ni ni-plus-circle" />
              </a>
              <a
                className="btn btn-round btn-dim btn-icon btn-sm btn-light btn-zoom  ms-1"
                onClick={zoomOut}
              >
                <em className="icon ni ni-minus-circle" />
              </a>
            </div>
          </div>
          <div className={`document-load ${loaded && "d-none"}`}>
            <div className="loader-box">
              <div className="circle-loader" />
            </div>
          </div>
          <div className={`text-center ${!loaded && "d-none"}`}>
            <div className="document-map d-block" />
            <canvas id="document-viewer" ref={canvasRef} />
          </div>
        </div>
      </div>
    </>
  );
}
