import React from "react";
import { Modal } from "react-bootstrap";
interface ViewTaskItemProps {
  show: boolean;
  onHide: () => void;
  data: TaskItem
}

interface TaskItem {
  idTask: number,
  taskTitle: string,
  description: string,
}

export function ViewTaskItem({ show, onHide, data }: ViewTaskItemProps) {
  console.log(data.idTask, 'ito hijerevana anle id tache raha miova');
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Détails de la tâche</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Titre de la tâche: <strong>{data.taskTitle}</strong></p>
        <div className="row gy-4">
          <div className="col-sm-12">
            <span className="text-muted">Description de la tâche</span>
            <div><strong>{data.description}</strong></div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className=" bg-light">
        <button
          className="btn btn-white btn-dim btn-outline-light"
          type="button"
          onClick={onHide}
        >
          <em className="icon ni ni-cross-circle"></em>
          <span>Fermer</span>
        </button>
      </Modal.Footer>
    </Modal>
  );
}
