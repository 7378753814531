import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import {
  AddExpenses,
  CreateInvoices,
  CreateJobCard,
  CreateQuote,
  CreateTask,
} from "../../Modal";
import { UpdateProjects } from "../../Modal/UpdateProjects";
import Details from "./Details";
import Expenses from "./Expenses";
import Invoices from "./Invoices";
import JobCards from "./JobCards";
import Payments from "./Payments";
import Quotes from "./Quotes";
import Tasks from "./Tasks";
import { projetHooks } from "../../network/projet";
import Loading from "../../components/Loading";

export default function ProjectDetails() {
  const [tab, setTab] = useState("");
  const [showUpdate, setShowUpdate] = useState(false);
  const [showCreateJobCard, setShowCreateJobCard] = useState(false);
  const [showCreateProjectTask, setShowCreateProjectTask] = useState(false);
  const [showAddExpense, setShowAddExpense] = useState(false);
  const [showCreateQuote, setShowCreateQuote] = useState(false);
  const [showCreateInvoice, setShowCreateInvoice] = useState(false);
  const navigate = useNavigate();
  const param = useParams();
  let idProjet = param.id || '';
  const { data, isLoading } = projetHooks.useGetProjectById(idProjet);
  const { mutate } = projetHooks.useDeleteProject();

  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Ce projet sera supprimé définitivement.",
      confirmButtonText: "Oui supprimé",
      cancelButtonText: `Retour`,
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: "#fff",
    }).then((result) => {
      if (result.isConfirmed) {
        return mutate({ id: Number(idProjet) }, {
          onSuccess: async (data) => {
            navigate('/projects')
          },
          onError: (error) => {
            Swal.fire({
              icon: 'error',
              text: 'Erreur lors de la suppression du client',
            });
          },
        })
      }
    });
  };
  const handleCancel = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "This project will be marked as cancelled.",
      confirmButtonText: "Yes, Cancel!",
      cancelButtonText: `Cancel`,
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: "#fff",
    }).then((result) => {
      if (result.isConfirmed) {
        ///When click Yes
      }
    });
  };

  if (isLoading){
    return (<div className="loading__wrapper">
    <Loading />
  </div>)
  }

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between g-3">
          {isLoading ? <Loading /> :
            <div className="nk-block-head-content">
              <h3 className="nk-block-title page-title">
                Projects /
                <strong className="text-primary small">
                  {/*TATA PRIMA - 23424 */}
                  {data?.data.vehicules.detailVehicules.marque + ' - ' + data?.data.vehicules.detailVehicules.model}
                </strong>
              </h3>
              <div className="nk-block-des text-soft">
                <ul className="list-inline">
                  <li>
                    Projet ID: <span className="text-base">AP0314</span>
                  </li>
                  <li>
                    Créé le:{" "}
                    <span className="text-base">May 24, 2022 10:09am</span>
                  </li>
                </ul>
              </div>
            </div>}
          <div className="nk-block-head-content">
            <ul className="nk-block-tools g-3">
              <li>
                <a
                  onClick={() => navigate(-1)}
                  className="btn btn-outline-light bg-white d-none d-sm-inline-flex"
                >
                  <em className="icon ni ni-arrow-left" />
                  <span>Retour</span>
                </a>
                <a
                  className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
                  onClick={() => navigate(-1)}
                >
                  <em className="icon ni ni-arrow-left" />
                </a>
              </li>
              <li>
                <Dropdown>
                  <Dropdown.Toggle className="btn btn-dim btn-outline-primary">
                    <em className="icon ni ni-more-h" /> <span>Options</span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <ul className="link-list-opt no-bdr">
                      <li>
                        <a
                          className="fetch-display-click cursor"
                          onClick={() => setShowUpdate(true)}
                        >
                          <em className="icon ni ni-pen" />
                          <span>Modifier les détails</span>
                        </a>
                      </li>
                      {/* <li>
                        <a
                          className="cursor"
                          onClick={() => setShowCreateJobCard(true)}
                        >
                          <em className="icon ni ni-property-add" />
                          <span>Create Job Card</span>
                        </a>
                      </li> */}
                      <li>
                        <a
                          className="cursor"
                          onClick={() => setShowCreateProjectTask(true)}
                        >
                          <em className="icon ni ni-todo" />
                          <span>Créer une tâche</span>
                        </a>
                      </li>
                      <li className="divider" />
                      <li>
                        <a
                          className="cursor"
                          onClick={() => setShowAddExpense(true)}
                        >
                          <em className="icon ni ni-cart" />
                          <span>Ajouter une dépense</span>
                        </a>
                      </li>
                      {/* <li>
                        <a
                          className="cursor"
                          onClick={() => setShowCreateQuote(true)}
                        >
                          <em className="icon ni ni-cards" />
                          <span>Create Quote</span>
                        </a>
                      </li> */}
                      <li>
                        <a
                          className="cursor"
                          onClick={() => setShowCreateInvoice(true)}
                        >
                          <em className="icon ni ni-cc" />
                          <span>Créer une facture</span>
                        </a>
                      </li>
                      {/* <li>
                        <a
                          className="send-to-server-click cursor"
                          onClick={handleCancel}
                        >
                          <em className="icon ni ni-na" />
                          <span>Cancel Project</span>
                        </a>
                      </li> */}

                      <li>
                        <a
                          className="send-to-server-click cursor"
                          onClick={handleDelete}
                        >
                          <em className="icon ni ni-trash" />
                          <span>Supprimer</span>
                        </a>
                      </li>
                    </ul>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="nk-block">
        <div className="card">
          <div className="card-aside-wrap">
            <div className="card-content">
              <ul className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
                <li className="nav-item">
                  <a
                    className={`nav-link cursor ${!tab && "active"}`}
                    onClick={() => setTab("")}
                  >
                    <em className="icon ni ni-file-text" />
                    <span>Détails</span>
                  </a>
                </li>
                {/* <li className="nav-item">
                  <a
                    className={`nav-link cursor ${tab === "jobcards" && "active"
                      }`}
                    onClick={() => setTab("jobcards")}
                  >
                    <em className="icon ni ni-property-add" />
                    <span>Job Cards</span>
                  </a>
                </li> */}
                <li className="nav-item">
                  <a
                    className={`nav-link cursor ${tab === "tasks" && "active"}`}
                    onClick={() => setTab("tasks")}
                  >
                    <em className="icon ni ni-todo" />
                    <span>Travaux</span>
                  </a>
                </li>
                {/* <li className="nav-item">
                  <a
                    className={`nav-link cursor ${tab === "expenses" && "active"
                      }`}
                    onClick={() => setTab("expenses")}
                  >
                    <em className="icon ni ni-cart" />
                    <span>Dépenses</span>
                  </a>
                </li> */}
                <li className="nav-item">
                  <a
                    className={`nav-link cursor ${tab === "invoices" && "active"
                      }`}
                    onClick={() => setTab("invoices")}
                  >
                    <em className="icon ni ni-cc" />
                    <span>Factures</span>
                  </a>
                </li>
                {/* <li className="nav-item">
                  <a
                    className={`nav-link cursor ${tab === "quotes" && "active"
                      }`}
                    onClick={() => setTab("quotes")}
                  >
                    <em className="icon ni ni-cards" />
                    <span>Quotes</span>
                  </a>
                </li> */}
                <li className="nav-item">
                  <a
                    className={`nav-link cursor ${tab === "payments" && "active"
                      }`}
                    onClick={() => setTab("payments")}
                  >
                    <em className="icon ni ni-align-left" />
                    <span>Paiements</span>
                  </a>
                </li>
              </ul>
              {isLoading ? <Loading /> : !tab && (data && data.data ? <Details data = {data?.data} /> : null)}
              {/* {isLoading ? <Loading /> : tab === "jobcards" && <JobCards />} */}
              {isLoading ? <Loading /> : tab === "tasks" && <Tasks idVehicule={data?.data.vehicules.idVehicule} />}
              {/* {isLoading ? <Loading /> : tab === "expenses" && <Expenses />} */}

              {isLoading ? <Loading /> : tab === "invoices" && <Invoices />}
              {/* {isLoading ? <Loading /> : tab === "quotes" && <Quotes />} */}

              {isLoading ? <Loading /> : tab === "payments" && <Payments />}
            </div>
          </div>
        </div>
      </div>
      <UpdateProjects idProjet={idProjet} show={showUpdate} onHide={() => setShowUpdate(false)}/>
      <CreateJobCard
        show={showCreateJobCard}
        onHide={() => setShowCreateJobCard(false)}
      />
      <CreateTask
        show={showCreateProjectTask}
        onHide={() => setShowCreateProjectTask(false)}
      />
      <AddExpenses
        show={showAddExpense}
        onHide={() => setShowAddExpense(false)}
      />
      <CreateQuote
        show={showCreateQuote}
        onHide={() => setShowCreateQuote(false)}
      />
      <CreateInvoices
        show={showCreateInvoice}
        onHide={() => setShowCreateInvoice(false)}
      />
    </>
  );
}
