import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Header.scss";
import logoDark from "../../../assets/images/logo_site.jpeg";
import { CreateProjects } from "../../../Modal";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context";
import Loading from "../../Loading";
import { data } from "jquery";

export default function Header({ setShowSideBarMb }: any) {
  const [showCreate, setShowCreate] = useState(false);
  const navigate = useNavigate();
  const { client, loadingClient } = React.useContext(AuthContext);

  const disconnect = async () => {
    localStorage.removeItem('token')
    localStorage.removeItem('lastNavigate')
    navigate('/')
  }
  return (
    <div className="nk-header nk-header-fixed is-light">
      <div className="container-fluid">
        <div className="nk-header-wrap">
          <div className="nk-menu-trigger d-xl-none ml-n1">
            <a
              onClick={setShowSideBarMb}
              className="nk-nav-toggle nk-quick-nav-icon"
              data-target="sidebarMenu"
            >
              <em className="icon ni ni-menu"></em>
            </a>
          </div>
          <div className="nk-header-brand d-xl-none">
            <Link to="/" className="logo-link">
              <img
                className="logo-dark logo-img"
                src={logoDark}
                srcSet={`${logoDark} 2x`}
                alt="logo-dark"
              />
            </Link>
          </div>{" "}
          {/* .nk-header-brand */}
          <div className="nk-header-tools">
            <ul className="nk-quick-nav">
              <li className="dropdown">
                <button
                  onClick={() => setShowCreate(true)}
                  className="btn btn-sm btn-primary fetch-display-click record-booking-desktop"
                >
                  <em className="icon ni ni-plus"></em>
                  <span> Nouveau véhicule</span>
                </button>
                <button
                  onClick={() => setShowCreate(true)}
                  className="btn btn-icon btn-sm btn-primary btn-round fetch-display-click record-booking-mobile"
                >
                  <em className="icon ni ni-plus"></em>
                </button>
              </li>
              {loadingClient ? <Loading /> :
                <Dropdown as="li" className="user-dropdown">
                  <Dropdown.Toggle as="a" className="dropdown-toggle mr-n1">
                    <div className="user-toggle">
                      <div className="user-avatar sm">
                        <em className="icon ni ni-user-alt"></em>
                      </div>
                      <div className="user-info d-none d-xl-block">
                        <div className="user-status user-status-verified">
                          {client?.membres?.role}
                        </div>
                        <div className="user-name dropdown-indicator">
                          {client?.membres?.prenom} {client?.membres?.nom}
                        </div>
                      </div>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu-md dropdown-menu-right">
                    <Dropdown.Item
                      disabled
                      as="div"
                      className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block"
                    >
                      <div className="user-card">
                        <div className="user-avatar">
                          <em className="icon ni ni-user-alt"></em>
                        </div>
                        <div className="user-info">
                          <span className="lead-text">{client?.membres?.prenom} {client?.membres?.nom}</span>
                          <span className="sub-text">{client?.membres?.telephone}</span>
                        </div>
                      </div>
                    </Dropdown.Item>

                    <Dropdown.Item
                      as="div"
                      className="dropdown-inner"
                      bsPrefix=" "
                    >
                      <ul className="link-list">
                        <li>
                          <Link to="/settings">
                            <em className="icon ni ni-setting-alt"></em>
                            <span>Paramètre du compte</span>
                          </Link>
                        </li>
                      </ul>
                    </Dropdown.Item>
                    <Dropdown.Item
                      as="div"
                      className="dropdown-inner"
                      bsPrefix=" "
                    >
                      <ul className="link-list">
                        <li>
                          <Link to='/' onClick={disconnect}>
                            <em className="icon ni ni-signout"></em>
                            <span>Déconnexion</span>
                          </Link>
                        </li>
                      </ul>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>}
            </ul>
          </div>
        </div>
      </div>
      <CreateProjects show={showCreate} onHide={() => setShowCreate(false)} />
    </div>
  );
}
