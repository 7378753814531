import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { CreateInvoices, SendEmail, UpdateInvoices } from "../../Modal";
import TableauFactureClient from "../../components/Tableau/TableauFactureClient";
import { factureHooks } from "../../network";
import { useParams } from "react-router-dom";
import Loading from "../../components/Loading";

export default function Facture() {
  const [showCreate, setShowCreate] = useState(false);
  const [showSendEmail, setShowSendEmail] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  
  const param = useParams();
  let id = param.id || '';
  const { data, isLoading } = factureHooks.useGetFactureClient(id)

  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: `This invoice will be deleted permanently.`,
      confirmButtonText: "Yes, delete!",
      cancelButtonText: `Cancel`,
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: "#fff",
    }).then((result) => {
      if (result.isConfirmed) {
        ///When click Yes
      }
    });
  };

  if (isLoading){
    return (<div className="loading__wrapper">
    <Loading />
  </div>)
  }
  return (
    <>
      <div className="card-inner">
        <div className="nk-block mb-2">
          <div className="nk-block-head">
            {/* <a
              className="btn btn-primary pull-right fetch-display-click"
              onClick={() => setShowCreate(true)}
            >
              <em className="icon ni ni-plus" />
              <span>Create Invoice</span>
            </a> */}
            <h5 className="title overline-title text-base">Factures du client</h5>
            <p>Liste des factures du client</p>
          </div>
        </div>
        {isLoading ? <Loading /> : <TableauFactureClient data={data.data} />}

      </div>
      <CreateInvoices show={showCreate} onHide={() => setShowCreate(false)} />
      <SendEmail show={showSendEmail} onHide={() => setShowSendEmail(false)} />
      <UpdateInvoices show={showUpdate} onHide={() => setShowUpdate(false)} />
    </>
  );
}
