import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import {
  CreateTeamMember,
  SendSMS,
  TeamReport,
  UpdateTeamMember,
  CreateAccess
} from "../../../Modal";
import { Pagination } from "react-bootstrap";
import { membreHooks } from "../../../network/membre";
import type { membreEntityPaginationType, membreEntityType, roleMembreType } from "../../../types";

type Props = {
  data: membreEntityPaginationType
  nextPage: Function
  prevPage: Function
  goToPage: Function
}

export default function TableauTechnicien({ data, nextPage, prevPage, goToPage }: Props) {


  const [showUpdate, setShowUpdate] = useState(false);
  const [showAddAcess, setShowAddAccess] = useState<boolean>(false);
  const [showSendSMS, setShowSendSMS] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [technicien, setTechnicien] = useState<membreEntityType>({
    idMembre: 0,
    email: '',
    prenom: '',
    nom: '',
    adresse: '',
    telephone: '',
    role: 'Technicien',
    statut: 'Active',
    taper: 'À plein temps',
  });
  const [activePage, setActivePage] = useState<number>(1);
  const [idMbr, setIdMbr] = useState<any>(undefined);
  const { mutate } = membreHooks.useDeleteMembre();


  const handleDelete = (id: number) => {
    Swal.fire({
      title: "Êtes vous sur?",
      text: "Donnée et profil de ce technicien sera suprimé définitivement",
      confirmButtonText: "Oui, supprimé",
      cancelButtonText: `Retour`,
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        return mutate({ id: id }, {
          onSuccess: async (data) => {
          },
          onError: (error) => {
            Swal.fire({
              icon: 'error',
              text: 'Erreur lors de la suppression du technicien',
            });
          },
        })
      }
    });
  };

  const updateMembre = (tech: membreEntityType) => {
    setTechnicien(tech);
    setShowUpdate(true)
  }


  const goToNextPage = () => {
    if (activePage < data.countPage) {
      setActivePage(activePage + 1);
    } else {
      setActivePage(activePage)
    }
    nextPage();
  }

  const goToPrevPage = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1)
    } else {
      setActivePage(activePage)
    }
    prevPage()
  }

  const goToPageOne = (items: number) => {
    setActivePage(items)
    goToPage(items)
  }

  const giveAccess = (membre: membreEntityType) => {
    setIdMbr(membre);
    setShowAddAccess(true)
  }
  return (
    <>
      <div className="nk-block">
        <div className="card card-stretch">
          <div className="card-inner d-flex flex-column align-items-end">
            <table
              className="datatable-init nk-tb-list nk-tb-ulist"
              data-auto-responsive="false"
            >
              <thead>
                <tr className="nk-tb-item nk-tb-head">
                  <th className="nk-tb-col text-center">#</th>
                  <th className="nk-tb-col">
                    <span className="sub-text">Nom du technicien</span>
                  </th>
                  <th className="nk-tb-col tb-col-md">
                    <span className="sub-text">Rôle</span>
                  </th>
                  <th className="nk-tb-col tb-col-md">
                    <span className="sub-text">Statut</span>
                  </th>
                  <th className="nk-tb-col nk-tb-col-tools text-right"></th>
                </tr>
              </thead>
              {data.list.map((item: membreEntityType, index) => (<tbody>
                <tr className="nk-tb-item">
                  <td className="nk-tb-col text-center">{index + 1}</td>
                  <td className="nk-tb-col">
                    <div className="user-card">
                      <div className="user-avatar bg-dim-primary d-none d-sm-flex">
                        <span>TS</span>
                      </div>
                      <div className="user-info">
                        <span className="tb-lead">
                          {item.prenom} {item.nom}
                          <span className="dot dot-success d-md-none ml-1"></span>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="nk-tb-col tb-col-mb">
                    {item.role === 'Admin' && <span className="badge badge-sm badge-dot has-bg badge-success d-mb-inline-flex">
                      Admin
                    </span>}
                    {item.role === 'Technicien' && <span className="badge badge-sm badge-dot has-bg badge-primary d-mb-inline-flex">
                      Technicien
                    </span>}
                  </td>
                  <td className="nk-tb-col tb-col-md">
                    {item.statut === 'Active' && <span className="text-success">Active</span>}
                    {item.statut === 'En congé' && <span className="text-warning">En congé</span>}
                    {item.statut === 'Indisponible' && <span className="text-danger">Indisponible</span>}
                  </td>
                  <td className="nk-tb-col nk-tb-col-tools">
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <Dropdown>
                          <Dropdown.Toggle
                            as="a"
                            className="btn btn-icon btn-trigger"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <Link to={`/tech/details/${item.idMembre}`}>
                                  <em className="icon ni ni-eye"></em>
                                  <span>Voir les détails</span>
                                </Link>
                              </li>
                              <li>
                                <a
                                  className="fetch-display-click cursor"
                                  onClick={() => updateMembre(item)}
                                >
                                  <em className="icon ni ni-pen"></em>
                                  <span>Modifier les détails</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  className="fetch-display-click cursor"
                                  onClick={() => giveAccess(item)}
                                >
                                  <em className="icon ni ni-pen"></em>
                                  <span>Créer un compte d'accés</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  className="send-to-server-click cursor"
                                  onClick={() => handleDelete(item.idMembre)}
                                >
                                  <em className="icon ni ni-trash"></em>
                                  <span>Supprimer le technicien</span>
                                </a>
                              </li>
                            </ul>
                          </Dropdown.Menu>
                        </Dropdown>
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>))}
            </table>
            <Pagination className="nk-block-end">
              <Pagination.First onClick={() => goToPageOne(1)} />
              <Pagination.Prev onClick={goToPrevPage} />
              {
                Array.from(
                  { length: data.countPage }, (v, k) => k + 1
                ).map(items => <Pagination.Item key={items} active={items === activePage} onClick={() => goToPageOne(items)}>{items}</Pagination.Item>)
              }
              <Pagination.Next onClick={goToNextPage} />
              <Pagination.Last onClick={() => goToPageOne(data.countPage)} />
            </Pagination>
          </div>
        </div>
      </div>
      <UpdateTeamMember show={showUpdate} onHide={() => setShowUpdate(false)} data={technicien} />
      <CreateAccess show={showAddAcess} onHide={() => setShowAddAccess(false)} data={idMbr} />

      <SendSMS show={showSendSMS} onHide={() => setShowSendSMS(false)} />
      <TeamReport show={showReport} onHide={() => setShowReport(false)} />
    </>
  );
}
